import React, { Component } from 'react';
import { connect } from "react-redux";
import ComponentHeader from '../Widget/ComponentHeader';
import Cards from '../Widget/Cards';
import Video from '../Widget/Video';
import BecomeHost from '../Advertisement/BecomeHost';
import firebaseClient from '../../utils/FirebaseClient';
import aboutWelcomeImg from '../../../resources/images/about-welcome.jpg';
import firstFrame from '../../../resources/images/nfb-first-frame.jpg';

class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoSrc: null
        }
    }

    componentDidMount() {
        if (process.env.platform && process.env.platform === 'mobile') {
            this.setState({
                videoSrc: require('../../../resources/videos/NoJoeSizzleMobile.mp4')
            });
        } else {
            this.setState({
                videoSrc: `https://firebasestorage.googleapis.com/v0/b/nofilterbroadcast-net.appspot.com/o/vids%2FNoJoeSizzle.mp4?alt=media&token=c176f30e-00d6-4259-a02d-3e0853d39045`
            });
        }
    }

    render() {
        const { upcomingEvents, ambassadors, auth, roles } = this.props;

        let uevents = upcomingEvents ? upcomingEvents.filter((item) => {
            if (item && !firebaseClient.checkHidenIn(item, 'upcoming')) {
                return item;
            }
        }) : null;

        return (
            <div>
                <ComponentHeader text='about us' />
                <div className='about-us'>
                    <div className='about-us-welcome-wrapper'>
                        <div className='about-us-text-wrapper'>
                            <p className='about-us-title'>Welcome to No Filter Network!</p>
                            <p className='about-us-text'>It’s amazing to think that the concept for this entire platform was created a couple years ago when two dudes had a little too much tequila at a BBQ then somehow decided to democratize the broadcast industry. 😅</p>
                        </div>
                        <img src={aboutWelcomeImg} loading='lazy' className='about-su-welcome-img' />
                    </div>
                    <div className='about-us-things'>
                        <div className='about-us-text-center'>
                            <span>Our goal</span> from the beginning has been to bring No Filter Network users 3 things:
                        </div>
                        <div className='things-items'>
                            <div className='things-item-wrap'>
                                <div className='things-item'>
                                    <span>🎙</span>
                                </div>
                                <p>A Voice</p>
                            </div>
                            <div className='things-item-wrap'>
                                <div className='things-item'>
                                    <span>⚙️</span>
                                </div>
                                <p>Options</p>
                            </div>
                            <div className='things-item-wrap'>
                                <div className='things-item'>
                                    <span>💸</span>
                                </div>
                                <p>Legitimate EARNING<br />potential</p>
                            </div>
                        </div>
                    </div>
                    <div className='about-us-market'>
                        <p className='market-title'><span>the most badass</span>, real time, live streaming interactive <span>platform</span> on the market.</p>
                    </div>
                    <div className='about-us-market'>
                        <p className='market-text'>With the help of our hard charging No Filter SQUAD we have created the most badass, real time, live streaming interactive platform on the market.</p>
                        <p className='market-text'>Add in the easy monetization capabilities and we truly feel we have the ability to change the game by empowering the people that actually EARN IT, the No Filter Network content CREATORS 👊</p>
                    </div>
                    <div className='about-us-video-wrapper'>
                        <div className='about-us-video'>
                            <div className='video-text'>
                                <p className='quotation-marks'>“</p>
                                <p className='video-p'>
                                    Together, as we constantly build, grow and evolve No Filter Network, we appreciate your patience and encourage any and all feedback.
                                <br />
                                    The broadcast revolution is happening and we are humbled and grateful to have you be a part of it.
                            </p>
                                <p>
                                    Much LOVE,
                                <br />
                                    The No Filter Team
                            </p>
                            </div>
                            <div className='video-out'>
                                <Video
                                    src={this.state.videoSrc}
                                    poster={firstFrame}
                                    controls={false}
                                    playsInline={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='about-us-market'>
                        <p className='market-title'><span>Our Mission</span> is to develop an unfiltered platform for entertainment, enrichment, inspiration, and connection.</p>
                        <p className='market-text'>No Filter Network aims to empower hosts with an all-in-one, zero-latency streaming solution to connect directly with viewers. Building a community based on Real-Time, Real Talk, Real People.</p>
                    </div>
                    <Cards
                        title='Upcoming Streams'
                        videos={uevents}
                        readMoreLink='/browse?b=upcoming-streams'
                    />
                    <Cards
                        title='Hosts'
                        videos={ambassadors}
                        hosts={true}
                        readMoreLink='/hosts'
                    />
                </div>
                {(!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                    <BecomeHost />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        upcomingEvents: state.firebase.events.upcoming,
        ambassadors: state.firebase.ambassadors,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
    };
};

const AboutUsContainer = connect(
    mapStateToProps,
    null //mapDispatchToProps
)(AboutUs);

export default AboutUsContainer;