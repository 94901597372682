export const SHOW_KNOCKS_ACTION = 'SHOW_KNOCKS_ACTION';
export const SHOW_BROADCAST_ACTION = 'SHOW_BROADCAST_ACTION';
export const SHOW_ADD_GUEST_ACTION = 'SHOW_ADD_GUEST_ACTION';
export const SHOW_COPY_GUEST_ACTION = 'SHOW_COPY_GUEST_ACTION';
export const CLEAR_ROOM_ACTION = 'CLEAR_ROOM_ACTION';
export const DISPLAY_MESSAGE_ACTION = 'DISPLAY_MESSAGE_ACTION';
export const HIDE_MESSAGE_ACTION = 'HIDE_MESSAGE_ACTION';
export const SET_STREAMING_ACTION = 'SET_STREAMING_ACTION';
export const SET_STREAMING_COUNTER_ACTION = 'SET_STREAMING_COUNTER_ACTION';
export const SET_INBOUND_VIDEO_ACTION = 'SET_INBOUND_VIDEO_ACTION';
export const SET_MODAL_EVENT_OVERRUN_ACTION = 'SET_MODAL_EVENT_OVERRUN_ACTION';
export const SET_GOING_OUT_ACTION = 'SET_GOING_OUT_ACTION';
export const SET_USER_INFO_ACTION = 'SET_USER_INFO_ACTION';
export const SET_EVENT_ITEM_ACTION = 'SET_EVENT_ITEM_ACTION';

export const showKnocks = (value) => {
    return {
        type: SHOW_KNOCKS_ACTION,
        payload: {
            showKnocks: value
        }
    };
}

export const showBroadcast = (value) => {
    return {
        type: SHOW_BROADCAST_ACTION,
        payload: {
            showBroadcast: value
        }
    };
}

export const showAddGuest = (value) => {
    return {
        type: SHOW_ADD_GUEST_ACTION,
        payload: {
            showAddGuest: value
        }
    };
}

export const showCopyGuest = (value) => {
    return {
        type: SHOW_COPY_GUEST_ACTION,
        payload: {
            showCopyGuest: value
        }
    };
}

export const clear = () => {
    return {
        type: CLEAR_ROOM_ACTION
    };
}

export const displayMessage = (data) => {
    return {
        type: DISPLAY_MESSAGE_ACTION,
        payload: data
    };
}

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE_ACTION
    };
}

export const setStreaming = (data) => {
    return {
        type: SET_STREAMING_ACTION,
        payload: data
    };
}

export const setStreamingCounter = (data) => {
    return {
        type: SET_STREAMING_COUNTER_ACTION,
        payload: data
    };
}

export const setInboundVideo = (data) => {
    return {
        type: SET_INBOUND_VIDEO_ACTION,
        payload: data
    };
}


export const setModalEventOverrun = (data) => {
    return {
        type: SET_MODAL_EVENT_OVERRUN_ACTION,
        payload: data
    };
}

export const setGoingOut = (data) => {
    return {
        type: SET_GOING_OUT_ACTION,
        payload: data
    };
}

export const setUserInfo = (data) => {
    return {
        type: SET_USER_INFO_ACTION,
        payload: data
    };
}

export const setEventItem = (data) => {
    return {
        type: SET_EVENT_ITEM_ACTION,
        payload: data
    };
}