import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import fanoutClient from '../../utils/FanoutClient';
import { v1 as uuidv1 } from 'uuid';
import * as chatActions from "../../actions/chat_actions";
import * as roomActions from "../../actions/room_actions";
import StreamingFanout from "../Streaming/StreamingFanout";
import StreamingVoxeet from "../Streaming/StreamingVoxeet";

class Room extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null,
            linkId: null,
            isError: false,
            knockGranted: false,
            show: false,
            username: null,
            userPhoto: null,
            name: null,
            userId: null,
            errorMessage: null,
            registerOverlay: false
        }

        this.sessionId = uuidv1();
        this.handleRoomClosed = this.handleRoomClosed.bind(this);
        this.handleRoomStopped = this.handleRoomStopped.bind(this);
        this.handleFanoutReqestExpired = this.handleFanoutReqestExpired.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.handleKnockGranted = this.handleKnockGranted.bind(this);
        this.handleKnockRevoked = this.handleKnockRevoked.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.clearNetworkTimeout = this.clearNetworkTimeout.bind(this);

        // TODO change this after to get rid of hardcoding and instead request ip from server);
        fanoutClient.on('roomClosed', this.handleRoomClosed);
        fanoutClient.on('roomStopped', this.handleRoomStopped);
        fanoutClient.on('fanoutReqestExpired', this.handleFanoutReqestExpired);
    }


    getCallState() {
        return {};
    }

    async componentDidMount() {

        console.log('Room componentDidMount', this.props)
        if (this.props.role && this.props.role === 'audience') {
            fanoutClient.on('knockGranted', this.handleKnockGranted);
            fanoutClient.on('knockRevoked', this.handleKnockRevoked);
        }

        this.networkTimeout = setTimeout(() => {
            this.setState({
                isError: true,
                errorMessage: null
            });
        }, 30000);
    }

    clearNetworkTimeout() {
        if (this.networkTimeout) {
            clearTimeout(this.networkTimeout);
        }
    }

    componentDidUpdate(prevProps) {
        const { user, userLoaded, role } = this.props;
        console.log('Room has been updated', user, userLoaded, role)

        if (prevProps.role !== role && role === 'audience') {

            if (prevProps.role && prevProps.role === 'audience') {
                fanoutClient.removeListener('knockGranted', this.handleKnockGranted);
                fanoutClient.removeListener('knockRevoked', this.handleKnockRevoked);
            }
            if (role && role === 'audience') {
                fanoutClient.on('knockGranted', this.handleKnockGranted);
                fanoutClient.on('knockRevoked', this.handleKnockRevoked);
            }
        }
    }

    componentWillUnmount() {
        const { role } = this.props;

        fanoutClient.removeListener('roomClosed', this.handleRoomClosed);
        fanoutClient.removeListener('roomStopped', this.handleRoomStopped);
        fanoutClient.removeListener('fanoutReqestExpired', this.handleFanoutReqestExpired);

        if (role && role === 'audience') {
            fanoutClient.removeListener('knockGranted', this.handleKnockGranted);
            fanoutClient.removeListener('knockRevoked', this.handleKnockRevoked);
        }

        // fanoutClient.disconnectFromFanout(this.getCallState());

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
        if (this.revokeTimeout) {
            clearTimeout(this.revokeTimeout);
        }
        if (this.grantedTimeout) {
            clearTimeout(this.grantedTimeout);
        }
        if (this.networkTimeout) {
            clearTimeout(this.networkTimeout);
        }
    }

    handleKnockRevoked(data, showMessage = true) {
        const { displayMessage, role } = this.props;

        if (role === 'presenter' || role === 'moderator' || role === 'guest_speaker')
            return;

        try {
            console.log("handleKnockRevoked response received from server", data);
            if (displayMessage && showMessage) {
                displayMessage('Moving to audience', 2000);
            }
            this.revokeTimeout = setTimeout(() => {
                this.setState({
                    knockGranted: false,
                    token: null,
                });
            }, 2000);
        }
        catch (error) {
            console.error("Failed handleKnockRevoked", error, data);
        }
    }

    handleKnockGranted(data) {
        const { displayMessage, role } = this.props;
        if (role === 'presenter' || role === 'moderator' || role === 'guest_speaker')
            return;

        try {
            console.log("handleKnockGranted response received from server", data);
            if (displayMessage) {
                displayMessage('Moving into live stream', 2000);
            }
            this.grantedTimeout = setTimeout(() => {
                this.setState({
                    knockGranted: true,
                    token: (data && data.payload ? data.payload.token : null)
                });
            }, 2000);
        }
        catch (error) {
            console.error("Failed handleKnockGranted", error, data);
        }
    }

    handleRoomClosed(data) {
        const { displayMessage, hideMessage } = this.props;

        try {
            console.log("handleRoomClosed response received from server", data);
            if (displayMessage && hideMessage) {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 2000);
                }, 500);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 3000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomClosed", error, data);
        }
    }

    handleRoomStopped(data) {
        const { displayMessage, hideMessage, role } = this.props;

        try {
            console.log("handleRoomStopped response received from server", data);
            if (displayMessage && hideMessage && role && role !== 'presenter') {
                hideMessage();
                this.messageTimeout = setTimeout(() => {
                    displayMessage('The event is over', 4000);
                }, 595000);
                this.exitTimeout = setTimeout(() => {
                    this.handleExit(true);
                }, 600000);
            } else {
                this.handleExit(true);
            }
        }
        catch (error) {
            console.error("Failed handleRoomStopped", error, data);
        }
    }

    handleFanoutReqestExpired(data) {

        try {
            console.log("handleFanoutReqestExpired response received from server", data);
            if (data && data.message) {
                this.setState({
                    isError: true,
                    errorMessage: data.message
                });
            }
        }
        catch (error) {
            console.error("Failed handleFanoutReqestExpired", error, data);
        }
    }

    handleExit(end) {
        const { handleExit } = this.props;

        if (handleExit)
            handleExit(end);
    }

    renderError(errorMessage) {
        return (
            <div className='error-wrapper'>
                <div className='error-div'>
                    <p className='error-title'>Network Problem</p>
                    <p className='error-message'>
                        {errorMessage ? errorMessage : null}
                        {errorMessage ? <br /> : null}
                        Please check your internet connection or try reload the page.
                    </p>
                    <button
                        className='btn-reload'
                        onClick={() => window.location.reload()}
                    >
                        Reload
                    </button>
                </div>
            </div>
        );
    }



    render() {
        const { knockGranted, token, isError, errorMessage } = this.state;
        const { role, user, eventItem, userPhoto, eventId, username, name } = this.props;
        console.log('Room render', user, role, eventItem, eventId);

        return (
            <Fragment>
                {(isError) ?
                    this.renderError(errorMessage) :
                    ((role && role !== 'audience') || knockGranted) ?
                        <StreamingVoxeet
                            token={token}
                            name={(name ? name : null)}
                            uid={user ? user.uid : null}
                            isOwner={user ? user.isOwner : false}
                            username={username ? username : (name ? name : '')}
                            role={role}
                            preConfig={true}
                            email={this.props.user ? this.props.user.email : null}
                            userPhoto={userPhoto}
                            eventId={eventId}
                            eventItem={eventItem}
                            knockRevoked={this.handleKnockRevoked}
                            handleExit={this.handleExit}
                            handleJoined={this.clearNetworkTimeout}
                            knockGranted={knockGranted}
                        />
                        : (role && role === 'audience') ?
                            <StreamingFanout
                                name={(name ? name : null)}
                                uid={user ? user.uid : null}
                                isOwner={user ? user.isOwner : false}
                                username={username ? username : (name ? name : '')}
                                userPhoto={userPhoto}
                                eventId={eventId}
                                eventItem={eventItem}
                                handleExit={this.handleExit}
                                handleJoined={this.clearNetworkTimeout}
                            />
                            : <div></div>
                }

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearMessages: () => {
            dispatch(chatActions.clearMessages());
        },
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        },
    };
};

const RoomContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Room);

export default RoomContainer;