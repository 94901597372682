import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import bowser from "bowser";
import PropTypes from "prop-types";
import * as broadcastActions from "../../../actions/broadcast_actions";
import * as roomActions from "../../../actions/room_actions";
import HangUpButtonBottomBar from './HangUpButtonBottomBar';
import BroadcastButtonBottomBar from './BroadcastButtonBottomBar';
import KnocksButtonBottomBar from './KnocksButtonBottomBar';
import KnockButtonBottomBar from './KnockButtonBottomBar';
import TipButtonBottomBar from './TipButtonBottomBar';
import AttendeesListButton from './AttendeesListButton';
import { isMobile } from "../../../utils/text";
import { Buttons } from "@voxeet/react-components";

class ActionsButtons extends Component {
  constructor(props) {
    console.log('ActionsButtons Props', props)
    super(props);
    this.state = {
      moderatorPrevState: null
    }
  }

  componentDidMount() {
    this.checkModerator();
    this.checkGreenRoom();
    this.setBandwidthText();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isRecording, streaming, recordingLocked, attendeesSettingsOpened, inboundVideo, isScreenshare, isVideoPresentation, isFilePresentation } = this.props;

    if (((prevProps.isRecording !== isRecording) && isRecording) ||
      ((prevProps.streaming !== streaming) && streaming) ||
      ((prevProps.recordingLocked !== recordingLocked) && recordingLocked))
      this.checkModerator();

    if (prevProps.streaming !== streaming) {
      this.checkGreenRoom();
    }
  }

  setBandwidthText() {
    let lowBandwidthModeP = document.createElement("p");
    let lowBandwidthModeA = document.createElement("p");
    lowBandwidthModeP.id = 'lowBandwidthMode-text-pre';
    lowBandwidthModeA.id = 'lowBandwidthMode-text-after';
    lowBandwidthModeP.innerHTML = `If you are having problems seeing or hearing other participants or if you are seeing a <span>Connection Error</span> notification, it is likely that you are having internet bandwidth issues. Toggle to <span>Low bandwidth mode</span> to stop downloading video from other participants - your audio will improve and you will still be able to see any screens that are shared.`;
    lowBandwidthModeA.innerHTML = `Note: Enabling <span>Low bandwidth mode</span> will not impact anyone else - they will still be able to see and hear you.`;
    const lowBandwidthMode = document.getElementById("lowBandwidthMode");
    if (lowBandwidthMode) {
      lowBandwidthMode.before(lowBandwidthModeP);
    }
    let labelElement = document.querySelectorAll('[for="lowBandwidthMode"]');
    if (labelElement && labelElement[0]) {
      labelElement[0].after(lowBandwidthModeA);
    }
  }

  checkModerator() {
    const { role, streaming, isRecording, isMuted, recordingLocked, videoEnabled, toggleMicrophone, toggleVideo } = this.props;

    if (role && role === 'moderator' && (streaming || isRecording || recordingLocked)) {
      if (!isMuted && toggleMicrophone)
        toggleMicrophone();

      if (videoEnabled && toggleVideo)
        toggleVideo();
    }
  }

  hideModerator() {
    const { role, isMuted, videoEnabled, toggleMicrophone, toggleVideo } = this.props;

    if (role && role === 'moderator') {
      this.setState({
        moderatorPrevState: {
          isMuted: isMuted,
          videoEnabled: videoEnabled
        }
      })
      if (!isMuted && toggleMicrophone)
        toggleMicrophone();

      if (videoEnabled && toggleVideo)
        toggleVideo();
    }
  }

  prevStateModerator() {
    const { moderatorPrevState } = this.state;
    const { role, isMuted, videoEnabled, toggleMicrophone, toggleVideo } = this.props;

    if (role && role === 'moderator') {
      if (moderatorPrevState && moderatorPrevState.isMuted !== isMuted && toggleMicrophone)
          toggleMicrophone();

      if (moderatorPrevState && moderatorPrevState.videoEnabled !== videoEnabled && toggleVideo)
        toggleVideo();
    }
  }

  checkGreenRoom() {
    const { streaming } = this.props;

    if (!streaming) {
      let el = document.getElementsByClassName('voxeet-wrapper')[0];

      if (el && el.id !== 'green-room') {
        el.id = 'green-room';
      }
    } else {
      let el = document.getElementsByClassName('voxeet-wrapper')[0];

      if (el && el.id === 'green-room') {
        el.id = 'voxeet-wrapper';
      }
    }
  }

  render() {
    const {
      isBottomBar,
      forceFullscreen,
      isMuted,
      isRecording,
      isWidgetFullScreenOn,
      toggleVideoPresentation,
      videoEnabled,
      isVideoPresentation,
      videoPresentationEnabled,
      displayModal,
      conferencePincode,
      convertFilePresentation,
      toggleMicrophone,
      screenShareEnabled,
      filePresentationEnabled,
      toggleRecording,
      toggleVideo,
      toggleScreenShare,
      attendeesSettingsOpened,
      toggleAttendeesSettings,
      toggleAttendeesList,
      attendeesListOpened,
      attendeesChatOpened,
      toggleAttendeesChat,
      recordingLocked,
      toggleModal,
      toggleAudio3D,
      participants,
      isWebinar,
      isAdmin,
      displayActions,
      shareActions,
      leave,
      audio3DEnabled,
      displayExternalLiveModal,
      currentUser,
      isFilePresentation,
      isScreenshare,
      isDemo,
      isListener,
      posterVideo,
      title,
      category,
      conferenceAlias,
      user,
      knockId,
      VoxeetSdk,
      desktop,
      getCallState,
      eventItem,
      knockRevoked,
      role,
      streaming,
      eventId
    } = this.props;
    let nbParticipants = 0;
    if (participants && participants.length) {
      nbParticipants = participants.filter((p) => p.isConnected).length;
    }
    //if ((!isWebinar && !currentUser.isListener) || (isWebinar && isAdmin))
    nbParticipants += 1;


    return (
      <div>
        <ul className={classNames('controls-left', { 'desktop': desktop && !isMobile() })}>
          {!isWidgetFullScreenOn &&
            !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            displayActions.indexOf("mute") > -1 &&
            !isDemo && (
              <Buttons.ToggleMicrophoneButton
                isMuted={isMuted}
                toggle={toggleMicrophone}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            )}
          {!isWidgetFullScreenOn &&
            !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            displayActions.indexOf("video") > -1 &&
            !isDemo && (role && (role !== 'moderator' ||
              (role === 'moderator' && !isRecording && !streaming && !recordingLocked)
            )) && (
              <Buttons.ToggleVideoButton
                videoEnabled={videoEnabled}
                toggle={toggleVideo}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            )}
          {(isBottomBar && isAdmin && !isListener &&
            displayActions.indexOf("knocks") > -1) ? (
              <KnocksButtonBottomBar
                getCallState={getCallState}
              />
            ) : null}
        </ul>
        <ul className={classNames('controls-center', { 'desktop': desktop && !isMobile() })}>
          {(!isWidgetFullScreenOn && !forceFullscreen &&
            ((!isWebinar) || (isWebinar && isAdmin)) &&
            displayActions.indexOf("share") > -1 &&
            !isDemo && shareActions.length > 0) ? (
              <Buttons.ToggleScreenShareButton
                screenShareEnabled={screenShareEnabled}
                filePresentationEnabled={filePresentationEnabled}
                videoPresentationEnabled={videoPresentationEnabled}
                currentUserScreenShare={isScreenshare}
                currentUserFilePresentation={isFilePresentation}
                currentUserVideoPresentation={isVideoPresentation}
                toggle={toggleScreenShare}
                toggleVideoPresentation={toggleVideoPresentation}
                convertFilePresentation={convertFilePresentation}
                shareActions={shareActions}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            ) : null}
          {/* {displayActions.indexOf("chat") > -1 && (
            <Buttons.ToggleAttendeesChatButton
              tooltipPlace={isBottomBar ? "top" : "right"}
              toggle={toggleAttendeesChat}
              isBottomBar={isBottomBar}
              isOpen={attendeesChatOpened}
            />
          )} */}
          {/* {!isWidgetFullScreenOn && !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            displayActions.indexOf("recording") > -1 &&
            !isDemo && isAdmin ||
            !isWidgetFullScreenOn && !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            isModerator && !isDemo && isAdmin ? (
              <Buttons.ToggleRecordingButton
                isRecording={isRecording}
                recordingLocked={recordingLocked}
                toggle={() => {
                  this.handleRecording()
                  toggleRecording()
                }}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            ) : null} */}
          {displayActions.indexOf("attendees") > -1 ? (
            <AttendeesListButton
              nbParticipants={nbParticipants}
              tooltipPlace={isBottomBar ? "top" : "right"}
              toggle={toggleAttendeesList}
              isBottomBar={isBottomBar}
              isOpen={attendeesListOpened}
              isWebinar={isWebinar}
              isAdmin={isAdmin}
              currentUser={currentUser}
            />
          ) : null}
          {/* {isBottomBar && (!isWebinar || (isWebinar && isAdmin)) && !isDemo && (
            <li className="separator"></li>
          )} */}
        </ul>
        <ul className={classNames('controls-right', { 'desktop': desktop && !isMobile() })}>
          {isBottomBar && !isAdmin && isListener && (
            <KnockButtonBottomBar
              conferenceAlias={conferenceAlias}
              knockId={knockId}
            />
          )}
          {isBottomBar && eventItem && eventItem.acceptTips && isListener && (
            <TipButtonBottomBar
              conferenceAlias={conferenceAlias}
              item={eventItem}
            />
          )}
          {!isWidgetFullScreenOn &&
            !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            !bowser.msie &&
            (!currentUser.isListener || bowser.chrome) && !isListener && (
              <Buttons.ToggleSettingsButton
                attendeesSettingsOpened={attendeesSettingsOpened}
                toggle={toggleAttendeesSettings}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            )}
          {(isBottomBar && isAdmin && !isListener &&
            displayActions.indexOf("streaming") > -1) ? (
              <BroadcastButtonBottomBar
                eventItem={eventItem}
                getCallState={getCallState}
                isRecording={isRecording}
                toggleRecording={toggleRecording}
                role={role}
                hideModerator={() => this.hideModerator()}
                prevStateModerator={() => this.prevStateModerator()}
              />
            ) : null}
          {!isWidgetFullScreenOn &&
            !forceFullscreen &&
            (!isWebinar || (isWebinar && isAdmin)) &&
            displayActions.indexOf("pstn") > -1 &&
            conferencePincode.length > 0 &&
            !isDemo && (
              <Buttons.TogglePSTN
                conferencePincode={conferencePincode}
                isBottomBar={isBottomBar}
                tooltipPlace={isBottomBar ? "top" : "right"}
              />
            )}
          {isBottomBar && (
            <HangUpButtonBottomBar
              leave={leave}
              tooltipPlace="top"
              VoxeetSdk={VoxeetSdk}
              getCallState={getCallState}
              knockRevoked={knockRevoked}
              eventId={eventId}
              role={role}
            />
          )}
        </ul>
      </div>
    );
  }
}

ActionsButtons.propTypes = {
  isBottomBar: PropTypes.bool.isRequired,
  forceFullscreen: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  isWebinar: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  videoEnabled: PropTypes.bool.isRequired,
  screenShareEnabled: PropTypes.bool.isRequired,
  filePresentationEnabled: PropTypes.bool.isRequired,
  videoPresentationEnabled: PropTypes.bool.isRequired,
  displayModal: PropTypes.bool.isRequired,
  isScreenshare: PropTypes.bool.isRequired,
  isVideoPresentation: PropTypes.bool.isRequired,
  isFilePresentation: PropTypes.bool.isRequired,
  displayActions: PropTypes.array.isRequired,
  toggleAudio3D: PropTypes.func.isRequired,
  recordingLocked: PropTypes.bool.isRequired,
  audio3DEnabled: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isWidgetFullScreenOn: PropTypes.bool.isRequired,
  toggleMicrophone: PropTypes.func.isRequired,
  leave: PropTypes.func.isRequired,
  toggleRecording: PropTypes.func.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  toggleScreenShare: PropTypes.func.isRequired,
  toggleVideoPresentation: PropTypes.func.isRequired,
  convertFilePresentation: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  toggleAttendeesList: PropTypes.func.isRequired,
  attendeesListOpened: PropTypes.bool.isRequired,
  toggleAttendeesChat: PropTypes.func.isRequired,
  attendeesChatOpened: PropTypes.bool.isRequired,
  toggleAttendeesSettings: PropTypes.func.isRequired,
  attendeesSettingsOpened: PropTypes.bool.isRequired,
};

ActionsButtons.defaultProps = {
  isBottomBar: false,
  forceFullscreen: false,
};

const mapStateToProps = (state) => {
  return {
    desktop: state.app.user_agent_info.platform.type === 'desktop',
    streaming: state.room.streaming,
    inboundVideo: state.room.inboundVideo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRecording: (value) => {
      dispatch(broadcastActions.broadcastRecording(value));
    },
    setBroadcastMessage: (message) => {
      dispatch(broadcastActions.setBroadcastMessage(message));
    },
    setActiveModerator: (value) => {
      dispatch(broadcastActions.setActiveModerator(value));
    },
    setInboundVideo: (value) => {
      dispatch(roomActions.setInboundVideo({ inboundVideo: value }));
    }
  };
};

const ActionsButtonsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsButtons);

export default ActionsButtonsContainer;
