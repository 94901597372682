import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import Cards from './Widget/Cards';
import ComponentHeader from './Widget/ComponentHeader';
import Button from './Widget/Button';
import { getQueryVariable, stringToBoolean } from "../utils/text";
import path from '../../resources/icons/path.svg';

class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            nav: 'all',
            userId: null,
            alias: null,
            featured: false
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    componentDidMount() {
        let showButton = getQueryVariable('b');
        let userId = getQueryVariable('u');
        let alias = getQueryVariable('a');
        let featured = getQueryVariable('f');
        let input = getQueryVariable('q');

        if (showButton) {
            this.setState({
                nav: showButton,
                userId: userId,
                alias: alias,
                featured: stringToBoolean(featured),
                input: input || ''
            }, () => {
                this.handleScrollToId(showButton);
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        let showButton = getQueryVariable('b');
        let userId = getQueryVariable('u');
        let alias = getQueryVariable('a');
        let featured = getQueryVariable('f');

        if (showButton && location && location.search && location.search !== prevProps.location.search) {
            this.setState({
                nav: showButton,
                userId: userId,
                alias: alias,
                featured: stringToBoolean(featured)
            }, () => {
                this.handleScrollToId(showButton);
            });
        }
    }

    handleScrollToId(id) {
        if (id) {
            let el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        }
    }

    handleChangeInput(e) {
        const { nav, userId, alias, featured} = this.state;
        const { history } = this.props;

        const value = e.target.value;

        this.setState({
            input: value
        },() => {
            history.push({
                pathname: '/browse',
                search: `?b=${nav}${featured ? '&f=1' : ''}${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${value ? `&q=${encodeURIComponent(value)}` : ''}`
            });
        });
    }

    filterBrowse(filterArray) {
        const { input, userId, featured } = this.state;
        const { events } = this.props;

        let array = filterArray;

        if (featured) {
            array = events.featured
        }

        if (!array || (array && !array.length))
            return [];

        let inputValue = input.toLowerCase(), newArray = [];

        if (inputValue) {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && newArray.length < 50 &&
                    ((item.title && item.title.toLowerCase().indexOf(inputValue) !== -1) || (item.cat && item.cat.toLowerCase().indexOf(inputValue) !== -1) || (item.email && item.email.toLowerCase().indexOf(inputValue) !== -1) || (item.author && item.author.toLowerCase().indexOf(inputValue) !== -1)) &&
                    (!userId || userId && item.uid && item.uid === userId)) {
                    newArray.push(item);
                    return item;
                }
            });
        } else {
            array.map((item) => {
                if (item && item.visibility && item.visibility === "public" && newArray.length < 50 &&
                    (!userId || userId && item.uid && item.uid === userId)) {
                    newArray.push(item);
                    return item;
                }
            });
        }

        return newArray;
    }

    renderTitle() {
        const { nav, alias, featured } = this.state;

        switch (nav) {
            case 'all':
                return `all${alias ? ` - ${alias}` : ''}${featured ? ` - Popular on the platform` : ''}`;
            case 'upcoming-streams':
                return `upcoming streams${alias ? ` - ${alias}` : ''}`;
            case 'live':
                return `live${alias ? ` - ${alias}` : ''}`;
            case 'sports':
                return `sports${alias ? ` - ${alias}` : ''}`;
            case 'business':
                return `business${alias ? ` - ${alias}` : ''}`;
            case 'entertainment':
                return `entertainment${alias ? ` - ${alias}` : ''}`;
            case 'instructional':
                return `instructional${alias ? ` - ${alias}` : ''}`;
            case 'lifestyle':
                return `lifestyle${alias ? ` - ${alias}` : ''}`;
            case 'fitness':
                return `fitness${alias ? ` - ${alias}` : ''}`;
            case 'politics':
                return `politics${alias ? ` - ${alias}` : ''}`;
            case 'food':
                return `food & drink${alias ? ` - ${alias}` : ''}`;
            default:
                return `all${alias ? ` - ${alias}` : ''}${featured ? ` - Popular on the platform` : ''}`
        }
    }

    renderContent() {
        const { nav } = this.state;
        const { events, videos } = this.props;

        let allItems = [];

        if (events && events.upcoming && videos) {
            allItems = events.upcoming.concat(videos)
        }

        let cat_items = nav && (nav !== 'all' && nav !== 'upcoming-streams' && nav !== 'live') && allItems && allItems.length ? allItems.filter(item => item.cat && item.cat.toLowerCase() == nav && nav.toLowerCase()) : null;

        switch (nav) {
            case 'all':
                return this.filterBrowse(allItems);
            case 'upcoming-streams':
                return this.filterBrowse(events.upcoming);
            case 'live':
                let liveEvents = events && events.upcoming && events.upcoming.length ? events.upcoming.filter(item => item.status && item.status === 'live') : null;
                return this.filterBrowse(liveEvents);
            case 'sports':
            case 'entertainment':
            case 'instructional':
            case 'lifestyle':
            case 'fitness':
            case 'politics':
            case 'food':
            case 'business':
                return this.filterBrowse(cat_items);
            default:
                return this.filterBrowse(allItems);
        }
    }

    render() {
        const { input, nav, featured, userId, alias } = this.state;
        const { categories } = this.props;

        return (
            <div>
                <ComponentHeader
                    text="browse events"
                />
                <div className='hosts-search-wrapper'>
                    <div className='hosts-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='What event are you looking for?'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                </div>
                <div className='profile-div-wrapper'>
                    <div className='profile-nav-wrapper browse-nav-wrapper'>
                        <div className={classNames('profile-nav', { 'hidden': featured })}>
                            <Link id='all' to={`/browse?b=all${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'all' })}>all</Button>
                            </Link>
                            <Link id='upcoming-streams' to={`/browse?b=upcoming-streams${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'upcoming-streams' })}>upcoming streams</Button>
                            </Link>
                            <Link id='live' to={`/browse?b=live${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === 'live' })}>live</Button>
                            </Link>
                            {categories && categories.length ?
                                categories.map((item, index) => {
                                    let id = item && item.id && item.id.toLowerCase ? item.id.toLowerCase().trim() : null;
                                    id = id && id === 'food&drink' ? 'food' : id;
                                    return (
                                        <Link key={index} id={id} to={`/browse?b=${id}${userId ? `&u=${userId}` : ''}${alias ? `&a=${alias}` : ''}${input ? `&q=${input}` : ''}`}>
                                            <Button type='border-white' className={classNames('btn-profile-nav', { 'active': nav === id })}>{item.title}</Button>
                                        </Link>
                                    );
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <Cards
                    title={this.renderTitle()}
                    videos={this.renderContent()}
                    grid={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        events: state.firebase.events,
        videos: state.firebase.videos,
        categories: state.firebase.categories
    };
};

const BrowseContainer = connect(
    mapStateToProps,
    null
)(Browse);

export default BrowseContainer;
