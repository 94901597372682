import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import userPlaceholder from "../../../../resources/images/user-placeholder.png";
import chatOff from "../../../../resources/icons/Chat.svg";
import chatOn from "../../../../resources/icons/Chat-Active.svg";
import listOff from "../../../../resources/icons/Viewers.svg";
import listOn from "../../../../resources/icons/Viewers-Active.svg";

class AttendeesListFanout extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.openChat = this.openChat.bind(this);
        this.openRoster = this.openRoster.bind(this);
    }

    openChat() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('chat');
        }
    }

    openRoster() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('list');
        }
    }

    render() {
        const { roster, showState, hosts } = this.props;

        return (
            <div>
                {roster ? <div className="attendees-list-header">
                    <div className={classNames('header-attendees', { 'active': showState && showState === 'list' })} onClick={this.openRoster}>
                        <img src={showState && showState === 'list' ? listOn : listOff} className='chat-img' />
                        <h1>Hosts</h1>
                    </div>
                    <div className={classNames('header-attendees', { 'active': showState && showState === 'chat' })} onClick={this.openChat}>
                        <img src={showState && showState === 'chat' ? chatOn : chatOff} className='chat-img' />
                        <h1>Chat</h1>
                    </div>
                </div> : null}
                <Fragment>
                    <div>
                        <ul>
                            <li>{`Hosts and Guests (${Object.entries(hosts).length})`}</li>
                            {hosts ?
                                Object.entries(hosts).map(([id, participant], i) => {
                                    return (participant &&
                                        <li key={i}>
                                            <span className="participant-details" >
                                                <img
                                                    src={participant.photoURL || userPlaceholder}
                                                    className="participant-avatar"
                                                    alt='avatar'
                                                />
                                                <span className="participant-username">
                                                    {participant.displayName || participant.name}
                                                </span>
                                            </span>
                                        </li>
                                    );
                                }) : null}
                        </ul>
                    </div>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hosts: state.hosts
    };
};

const AttendeesListFanoutContainer = connect(
    mapStateToProps
)(AttendeesListFanout);

export default AttendeesListFanoutContainer; 