import React, { Component } from "react";
import classNames from "classnames";
import FanoutVideoPresentation from "./FanoutVideoPresentation";

class FanoutPresentationView extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { videoRef, handleLoadedData } = this.props;

        if (videoRef && videoRef.current && handleLoadedData) {
            videoRef.current.addEventListener('loadeddata', handleLoadedData);
        }
    }

    componentWillUnmount() {
        const { videoRef, handleLoadedData } = this.props;

        if (videoRef && videoRef.current && handleLoadedData) {
            videoRef.current.removeEventListener('loadeddata', handleLoadedData);
        }
    }


    checkHorizontalScaling(width, height) {
        if (width > ((height / 9) * 16)) {
            return true;
        } else return false;
    }

    render() {
        const { participantBarContent, videoRef, mobile, videoLoaded, handleSwap,
            orientation, showLabel, canPlay, swapActive, videoURL, videoTS, videoPlaying,
            videoWidth, videoHeight, videoPresentation, videoDuration, sendRef } = this.props;

        return (
            <div className='SidebarSpeaker'>
                <div id='video-active-video-on' className='active-speaker'>
                    {swapActive ?
                        <div className='tile-video video-frame'>
                            <div className='stream-media'>
                                <video ref={videoRef} className={classNames('video-tag', { 'video-cover': mobile && videoLoaded && participantBarContent && participantBarContent.length && participantBarContent.length === 2 && orientation && orientation === 'landscape' })} id="fullscreen-video" playsInline autoPlay muted />
                                {showLabel && canPlay && participantBarContent && participantBarContent.length ?
                                    participantBarContent.map((item, index) => {
                                        let videoCover = mobile && videoLoaded && participantBarContent && participantBarContent.length && participantBarContent.length === 2 && orientation && orientation === 'landscape';
                                        let vHeight = this.checkHorizontalScaling(videoWidth, videoHeight) || videoCover ? videoHeight : (videoWidth * 9) / 16;
                                        let vWidth = !this.checkHorizontalScaling(videoWidth, videoHeight) || videoCover ? videoWidth : (videoHeight / 9) * 16;
                                        let vOffset = (videoHeight - vHeight) / 2;
                                        let hOffset = (videoWidth - vWidth) / 2;
                                        return (
                                            <div key={index} className='participant-bar fanout-bar' style={{ bottom: videoHeight - (vOffset + ((vHeight * item.dy) / 720)), left: hOffset + ((vWidth * item.dx) / 1280) }}>
                                                <div className='name'>{item.name}</div>
                                            </div>
                                        )
                                    })
                                    :
                                    null}
                            </div>
                        </div>
                        :
                        <div className='tile-video video-frame'>
                            <FanoutVideoPresentation
                                isVideoPresentation={videoPresentation}
                                swapActive={swapActive}
                                sendRef={sendRef}
                                duration={videoDuration}
                                videoData={{
                                    url: videoURL,
                                    playing: videoPlaying,
                                    ts: videoTS
                                }}
                            />
                        </div>
                    }
                </div>
                <div className='SidebarList'>
                    <ul className='list-items'>
                        <li className='item small-item participant-available'>
                            <div id='video-undefined-video-on' className='participant-video video-frame'>
                                <div className='item  avatar-vumeter'>
                                    {swapActive ?
                                        <div className='container-avatar-vumeter' onClick={() => handleSwap(false)}>
                                            <FanoutVideoPresentation
                                                isVideoPresentation={videoPresentation}
                                                swapActive={swapActive}
                                                sendRef={sendRef}
                                                duration={videoDuration}
                                                videoData={{
                                                    url: videoURL,
                                                    playing: videoPlaying,
                                                    ts: videoTS
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='container-avatar-vumeter' onClick={() => handleSwap(true)}>
                                            <div className='stream-media'>
                                                <video ref={videoRef} className='video-tag' id="fullscreen-video" playsInline autoPlay muted />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='participant-bar swap-view' onClick={() => handleSwap(swapActive ? false : true)}>
                                <div className='name'>Swap View</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default FanoutPresentationView;