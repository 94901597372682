import {
    APP_STATE_ACTION,
    APP_FANOUT_STATE_ACTION,
    APP_STAGE_LOADING_ACTION,
    APP_NAV_LIST_ACTION,
    APP_LOG_IN_ACTION,
    APP_LOG_IN_NOTIFICATION_ACTION,
    APP_ORIENTATION_CHANGED_ACTION,
    APP_SET_USER_AGENT_INFO_ACTION,
    APP_PIXEL_RATIO_CHANGED_ACTION,
    APP_SIDE_BAR_ACTION,
    APP_STAGE_NOT_LOADED,
    APP_FANOUT_RUNNING
} from '../actions/app_actions';

const initialState = {
    status: 'validation',
    navList: false,
    logIn: false,
    logInNotification: false,
    createAccount: false,
    logInSignup: false,
    orientation: 'horizontal', // TODO: Default could depend on device type
    dppi: 1,
    user_agent_info: null,
    sideBar: false,
    stage: APP_STAGE_NOT_LOADED,
    fanoutState: APP_FANOUT_RUNNING
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case APP_STATE_ACTION:
        case APP_FANOUT_STATE_ACTION:
        case APP_STAGE_LOADING_ACTION:
        case APP_NAV_LIST_ACTION:
        case APP_LOG_IN_ACTION:
        case APP_LOG_IN_NOTIFICATION_ACTION:
        case APP_ORIENTATION_CHANGED_ACTION:
        case APP_PIXEL_RATIO_CHANGED_ACTION:
        case APP_SET_USER_AGENT_INFO_ACTION:
        case APP_SIDE_BAR_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        default:
            return state;
    }
};

export default app;
