export const Defines = {
  Fanout: {
    Status: {
      Starting: "STARTING",
      Running: "RUNNING",
      Restarting: "RESTARTING",
      Stopped: "STOPPED",
      Update: "status-update",
    },
    VirtualUser: "mainProducer",
    Signalling: {
      GetContext: "get-initial-context",
      Initial: "create-room",
      Transport: {
        Create: "create-transport",
        Connect: "connect-transport",
        Recv: 1,
        Send: 2
      },
      Media: {
        Produce: "produce",
        Consume: "consume",
        ConsumeResume: "consume-resume",
        ConsumePause: "consume-pause",
        NewProducer: "new-producer"
      },
      DataChannel: {
        Produce: "data-produce",
        Consume: "data-consume",
        ConsumeAck: "data-consuming",
        NewProducer: "new-data-producer"
      },
      Meta: {
        Layout: "layout-update",
        VideoPresentation: "video-presentation-update",
      },
      Auth: "auth",
      Register: "register",
      VerifyLink: "verify-link",
      VerifyEvent: "verify-event",
      VerifyEmail: "verify-email",
      VerifyTicket: "verify-ticket",
      RequestRoomAccess: "request-room-access",
      CreateGuestTicket: "create-guest-ticket",
      CreateMeGuestTicket: "create-me-guest-ticket",
      GetInvitationLink: "get-me-invitation-link",
      BanUser: "ban-user",
      Streaming: "streaming",
      Started: "started",
      Ended: "ended",
      End: "end",
      Stop: "stop",
      EndForAudience: "end-audience",
      Extend: "extend-streaming",
      ForceStop: "force-stop",
      Reconnect: "reconnect",
      ReconnectMe: "reconnect-me",
      GetReady: "get-ready",
      AtEase: "at-ease",
      Join: "join",
      Cancelled: "cancelled",
      Closed: "peer-closed",
      Error: "error"
    },
    Events: {
      Metadata: {
        Event: "metaData",
        Types: {
          Layout: "layout",
          Swap: "swap",
          Active: "active",
          Stop: "remove"
        }
      }
    },
    DataChannel: {
      VideoPresentation: "Video_Update",
      SpeakerLayout: "Speaker_Metadata",
      Chat: "Chat_Message"
    },
    Chat: {
      Connect: "chat-connect",
      Disconnect: "chat-disconnect",
      Message: "chat-message",
      Hosts: "chat-hosts",
      Audience: "chat-audience",
      History: "chat-history",
      BannedUsers: "chat-banned-users",
      DeleteMessage: "chat-delete-message",
      BanUserByMessage: "chat-ban-user-by-message"
    },
    Knock: {
      Request: "knock-request",
      Granted: "knock-granted",
      Rejected: "knock-hosts",
      Revoked: "knock-revoked"
    },
    Dolby: {
      GetToken: "dolby-get-token",
      RefreshToken: "dolby-refresh-token",
    },
  },
  DolbyEvents: {
    VideoPresentation: {
      Started: "started",
      Stopped: "stopped",
      Played: "played",
      Paused: "paused",
      Sought: "sought"
    }
  },
  Response: {
    OK: 200,
    BadReques: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    NotAcceptable: 406,
    Gone: 410,
    TooEarly: 425,
    TooManyRequests: 429
  }
};