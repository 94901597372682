import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { push as nativePush } from 'connected-react-router';
import logo from '../../resources/icons/logo.svg';
import path from '../../resources/icons/path.svg';
import LogInBtn from './Header/LogInBtn';
import PrivateBookings from './Header/PrivateBookings';
import NavBar from './Header/NavBar';
import LogIn from './Header/LogIn';
import Button from './Widget/Button';
import LogInNotification from './Header/LogInNotification';
import * as appActions from "../actions/app_actions";
import { getQueryVariable, signupCampaignPageLink } from "../utils/text";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            input: ''
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.handlePushToSearch = this.handlePushToSearch.bind(this);
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        });
    }

    enterPressed(event) {
        const { input } = this.state;
        var code = event.keyCode || event.which;
        if (code === 13 && input) {
            this.handlePushToSearch();
        }
    }

    handlePushToSearch() {
        const { input } = this.state;
        const { pushToSearch } = this.props;

        if (input && input.length && pushToSearch) {
            pushToSearch(input);
        }
    }

    componentDidMount() {
        let q = getQueryVariable('q');

        this.setState({
            input: q || ''
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { input } = this.state;
        const { location } = this.props;

        let q = getQueryVariable('q');

        if (location && location.search && location.search !== prevProps.location.search) {
            this.setState({
                input: q || ''
            });
        }

        if (location && location.pathname && location.pathname !== prevProps.location.pathname && location.pathname !== '/search') {
            if (input) {
                this.setState({
                    input: ''
                });
            }
        }
    }

    handleShowLogIn() {
        const { location, showLogIn, logIn, pushToHome, auth } = this.props;
        const pathname = location.pathname;

        if (showLogIn) {
            showLogIn(!logIn);
            if (logIn && pathname && pathname !== "/" && pushToHome && !auth) {
                pushToHome();
            }
        }
    }

    handleShowLogInNotification() {
        const { location, showLogInNotification, logInNotification, pushToHome, auth } = this.props;
        const pathname = location.pathname;

        if (showLogInNotification) {
            showLogInNotification(!logInNotification);
            if (logInNotification && pathname && pathname !== "/" && pushToHome && !auth) {
                pushToHome();
            }
        }
    }

    handleShowNavList() {
        const { showNavList, navList } = this.props;
        if (showNavList) {
            showNavList(!navList);
        }
    }

    render() {
        const { input } = this.state;
        const { history, auth, logIn, logInNotification, navList, roles, mobile } = this.props;

        return (
            <div>
                <div className='header'>
                    <div className='header-left'>
                        <NavBar activeTab={history.location.pathname} />
                        <div className='header-logo'>
                            <Link to="/">
                                <img src={logo} className='logo' loading='lazy' />
                            </Link>
                        </div>
                    </div>
                    <div className='search-wrapper'>
                        <img
                            src={path}
                            className='path'
                            loading='lazy'
                            onClick={this.handlePushToSearch}
                        />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='Search events'
                            value={input}
                            onChange={this.handleChangeInput}
                            onKeyPress={this.enterPressed}
                        />
                    </div>
                    <div className='header-button'>
                        {(!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                            <a href={signupCampaignPageLink}>
                                <Button
                                    type='border'
                                    className='btn-header-become'
                                >
                                    become a host
                                </Button>
                            </a> : null
                        }
                        {auth && roles && roles.broadcaster && history && history.location && history.location.pathname && history.location.pathname.indexOf('/calendar') !== -1 &&
                            <a href="https://www.nofilter.net/schedule-an-event">
                                <Button
                                    type='border'
                                    className='btn-header-become'
                                >
                                    How To Create Event
                                </Button>
                            </a>
                        }
                        {/* {auth && roles && !roles.broadcaster && !roles.administrator ?
                            <a href={signupCampaignPageLink}>
                                <Button
                                    type='text'
                                    className='btn-header-join'
                                >
                                    join the platform
                                </Button>
                            </a>
                            : auth && roles && !roles.broadcaster ?
                                <PrivateBookings />
                                : null
                        } */}
                        <LogInBtn
                            activeLogIn={logIn}
                            activeNavList={navList}
                            activeTab={history.location.pathname}
                            history={history}
                            mobile={mobile}
                            handleShowLogIn={() => this.handleShowLogIn()}
                        />
                    </div>
                    {logIn &&
                        <LogIn
                            handleShowLogIn={() => this.handleShowLogIn()}
                        />
                    }
                    {logInNotification &&
                        <LogInNotification
                            handleShowLogIn={() => this.handleShowLogIn()}
                            handleShowLogInNotification={() => this.handleShowLogInNotification()}
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        navList: state.app.navList,
        orientation: state.app.orientation,
        logIn: state.app.logIn,
        logInNotification: state.app.logInNotification,
        sideBar: state.app.sideBar,
        categories: state.firebase.categories,
        mobile: state.app.user_agent_info.platform.type === 'mobile'
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showNavList: (value) => {
            dispatch(appActions.showNavList(value));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        showLogInNotification: (value) => {
            dispatch(appActions.showLogInNotification(value));
        },
        pushToSearch: (value) => {
            dispatch(nativePush(`/search?q=${value}`));
        },
        pushToHome: () => {
            dispatch(nativePush('/'));
        }
    };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default HeaderContainer;
