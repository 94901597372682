import React, { Component } from "react";
import PropTypes from "prop-types";
import Hangup from "../../../../resources/icons/Exit.svg";

class HangUpButtonFanout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                modal: false
            })
        }
    }

    handleExitStream() {
        const { leave } = this.props;
        if (leave) {
            leave();
        }
    }


    render() {
        const { modal } = this.state;

        return (
            <li className="hangup-bottom-bar">
                <a
                    data-tip
                    data-for="leave"
                    title="Exit Stream"
                    onClick={() => {
                        this.setState({
                            modal: !modal
                        })
                    }}
                >
                    <img src={Hangup} />
                </a>
                {modal ?
                    <div className='hangup-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='hangup-modal'>
                            <div className='hangup-title'>Confirm Exit</div>
                            <button
                                className='btn hangup-end'
                                onClick={() => this.handleExitStream()}
                            >
                                Exit Stream
                            </button>
                            <button
                                className='btn hangup-cancel'
                                onClick={() => {
                                    this.setState({
                                        modal: false
                                    })
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    : null}
            </li>
        );
    }
}

HangUpButtonFanout.propTypes = {
    leave: PropTypes.func.isRequired
};

export default HangUpButtonFanout;
