import React, { Component } from "react";
import { connect } from 'react-redux';
import * as roomActions from "../../../actions/room_actions";
import cameraIcon from "../../../../resources/icons/Camera.svg";
import activeCameraIcon from "../../../../resources/icons/Camera-Active.svg";
import VoxeetSdk from "@voxeet/voxeet-web-sdk";

class InboundVideoButtonBottomBar extends Component {
    constructor(props) {
        super(props);

        this.handleInboundVideo = this.handleInboundVideo.bind(this);
    }

    handleInboundVideo() {
        const { setInboundVideo, inboundVideo } = this.props;

        setInboundVideo(!inboundVideo);
        console.log('call handleInboundVideo', VoxeetSdk.conference.maxVideoForwarding);
    }

    render() {
        const { inboundVideo } = this.props;

        return (
            <li>
                <a
                    data-tip
                    data-for="inbound-video"
                    title="Inbound Video"
                    onClick={() => this.handleInboundVideo()}
                >
                    <img src={inboundVideo ? activeCameraIcon : cameraIcon} alt='inbound' />
                    <div>
                        <span>Inbound Video</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inboundVideo: state.room.inboundVideo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInboundVideo: (value) => {
            dispatch(roomActions.setInboundVideo({ inboundVideo: value }));
        }
    };
};

const InboundVideoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InboundVideoButtonBottomBar);

export default InboundVideoContainer;