import {
    BROADCAST_SET_DEFAULT_SETTINGS_ACTION,
    BROADCAST_STREAMING_ACTION,
    BROADCAST_RECORDING_ACTION,
    BROADCAST_SHOW_TIP_ACTION,
    BROADCAST_INVITE_INPUT_ACTION,
    BROADCAST_LIVE_INPUT_ACTION,
    BROADCAST_KNOCK_ACTION,
    BROADCAST_SET_MESSAGE_ACTION,
    BROADCAST_SET_ACTIVE_MODERATOR_ACTION,
    BROADCAST_DISABLE_STREAMING_BTN_ACTION,
    BROADCAST_SET_FANOUT_ID
} from '../actions/broadcast_actions';

const initialState = {
    streaming: false,
    recording: false,
    showTip: false,
    inviteInput: '',
    liveInput: '',
    knock: false,
    activeModerator: false,
    disableStreamingBtn: false,
    fanoutId: ''
};

const broadcast = (state = initialState, action) => {
    switch (action.type) {
        case BROADCAST_SET_DEFAULT_SETTINGS_ACTION:
        case BROADCAST_STREAMING_ACTION:
        case BROADCAST_RECORDING_ACTION:
        case BROADCAST_SHOW_TIP_ACTION:
        case BROADCAST_INVITE_INPUT_ACTION:
        case BROADCAST_LIVE_INPUT_ACTION:
        case BROADCAST_KNOCK_ACTION:
        case BROADCAST_SET_MESSAGE_ACTION:
        case BROADCAST_SET_ACTIVE_MODERATOR_ACTION:
        case BROADCAST_DISABLE_STREAMING_BTN_ACTION:
        case BROADCAST_SET_FANOUT_ID: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        default:
            return state;
    }
};

export default broadcast;
