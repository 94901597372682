export const ADD_MESSAGE_CHAT = "ADD_MESSAGE_CHAT";
export const DELETE_MESSAGE_BY_ID_CHAT = "DELETE_MESSAGE_BY_ID_CHAT";
export const DELETE_MESSAGES_BY_IDS_CHAT = "DELETE_MESSAGES_BY_IDS_CHAT";
export const RESET_BADGE_MESSAGE_CHAT = "RESET_BADGE_MESSAGE_CHAT";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const ADD_HISTORY_CHAT = "ADD_HISTORY_CHAT";
export const NEW_BADGE_MESSAGE_CHAT = "NEW_BADGE_MESSAGE_CHAT";
export const SHOW_EMOJI_FORM_CHAT = "SHOW_EMOJI_FORM_CHAT";
export const HIDE_EMOJI_FORM_CHAT = "HIDE_EMOJI_FORM_CHAT";

export const addMessage = (chat) => {
  return {
    type: ADD_MESSAGE_CHAT,
    payload: { chat }
  };
}

export const deleteMessageById = (id) => {
  return {
    type: DELETE_MESSAGE_BY_ID_CHAT,
    payload: { id }
  };
}

export const deleteMessagesByIds = (ids) => {
  return {
    type: DELETE_MESSAGES_BY_IDS_CHAT,
    payload: { ids }
  };
}

export const addHistory = (messages) => {
  return {
    type: ADD_HISTORY_CHAT,
    payload: messages
  };
}

export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES
  };
}

export const newBadgeMessage = () => {
  return {
    type: NEW_BADGE_MESSAGE_CHAT
  };
}

export const resetBadgeMessage = () => {
  return {
    type: RESET_BADGE_MESSAGE_CHAT
  };
}

export const showEmojiForm = (emoji) => {
  return {
    type: SHOW_EMOJI_FORM_CHAT,
    payload: { 
      showEmoji: true,
      emoji: emoji
    }
  };
}

export const hideEmojiForm = () => {
  return {
    type: HIDE_EMOJI_FORM_CHAT,
    payload: { 
      showEmoji: false,
      emoji: null 
    }
  };
}
