import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import Cookies from "js-cookie";
import { push as nativePush } from 'connected-react-router';
import wssClient from '../../utils/FanoutClient';
import { Defines } from '../../utils/FanoutDefines';
import Loader from '../Widget/Loader';
import { v1 as uuidv1 } from 'uuid';
import * as chatActions from "../../actions/chat_actions";
import * as appActions from '../../actions/app_actions';
import * as roomActions from '../../actions/room_actions';
import StaticPage from '../Widget/StaticPage';
import EventForm from "../Room/EventForm";
import Room from "../Room/Room";
import { getQueryVariable, isMobile } from '../../utils/text';

var today = new Date();
today.setDate(today.getDate() + 365);
const default_cookies_param = {
    path: "/",
    expires: today,
    secure: true,
    sameSite: 'none',
};

class EventPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null,
            linkId: null,
            isError: false,
            show: false,
            username: null,
            userPhoto: null,
            name: null,
            userId: null,
            eventId: null,
            errorCode: null,
            registerOverlay: false,
            showConfirm: false,
            ticketId: null,
            ticketVerified: false
        }

        this.sessionId = uuidv1();
        this.handleVerifyLinkResponse = this.handleVerifyLinkResponse.bind(this);
        this.handleVerifyTicketResponse = this.handleVerifyTicketResponse.bind(this);
        this.handleAuthResponse = this.handleAuthResponse.bind(this);
        this.handleReconnectResponse = this.handleReconnectResponse.bind(this);
        this.getCallState = this.getCallState.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.handleJoin = this.handleJoin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.renderError = this.renderError.bind(this);
        this.handlePageError = this.handlePageError.bind(this);

        // TODO change this after to get rid of hardcoding and instead request ip from server
        wssClient.on('reconnectResponse', this.handleReconnectResponse);
        wssClient.on('verifyLinkResponse', this.handleVerifyLinkResponse);
        wssClient.on('verifyEventResponse', this.handleVerifyLinkResponse);
        wssClient.on('verifyTicket', this.handleVerifyTicketResponse);
        wssClient.on('pageError', this.handlePageError);
        this.eventItem = null;
    }

    setDefaultCookieParams() {
        let videoEnabled = Cookies.get('videoEnabled');
        // console.log('videoEnabled', videoEnabled);

        if (!videoEnabled || (videoEnabled && (typeof videoEnabled === 'string' || videoEnabled instanceof String) && videoEnabled.toLowerCase() !== 'true')) {
            Cookies.set('videoEnabled', true, default_cookies_param);
        }

        Cookies.set("maxVideoForwarding", isMobile() ? 4 : 16, default_cookies_param);

        let audioTransparentMode = Cookies.get('audioTransparentMode');
        // console.log('audioTransparentMode', audioTransparentMode);

        if (!audioTransparentMode) {
            Cookies.set("audioTransparentMode", true, default_cookies_param);
        }
    }

    connectToWS() {
        const { match, link_id, event_id } = this.props;

        let linkId = link_id || (match && match.params && match.params.linkId ? match.params.linkId : null)
        let eventId = event_id || (match && match.params && match.params.eventId ? match.params.eventId : null)
        console.log('EventPage componentDidMount', linkId, eventId);

        this.setState({
            linkId,
            eventId,
            isError: !linkId && !eventId
        }, async () => {
            await wssClient.connectToFanout({ linkId, eventId, username: 'unknown' });
        });
    }

    checkConnectToWS() {
        const { match, event_id, videos } = this.props;

        let eventId = event_id || (match && match.params && match.params.eventId ? match.params.eventId : null)
        let rtEvent = null, endDate = null;

        if (videos && videos.length) {
            rtEvent = videos.find(item => item.id && item.id === eventId);

            if (rtEvent && rtEvent.startDate && rtEvent.estDuration) {
                endDate = moment(new Date(rtEvent.startDate)).add(rtEvent.estDuration, 'minutes').add(2, 'hours').valueOf();
            }


            if (rtEvent && rtEvent.status && (rtEvent.status === 'ended' || rtEvent.status === 'cancelled') && endDate && Date.now() > endDate) {
                this.eventItem = rtEvent;
                this.setState({
                    eventId: eventId,
                    role: 'unknown',
                    show: false
                })
            } else {
                this.connectToWS();
            }
        }
    }

    componentDidMount() {
        this.checkConnectToWS();
        this.setDefaultCookieParams();
    }

    componentDidUpdate(prevProps, prevState) {
        const { match, videos } = this.props;

        let prevMatchId =
            prevProps.match && prevProps.match.params && prevProps.match.params.eventId ?
                prevProps.match.params.eventId :
                prevProps.match && prevProps.match.params && prevProps.match.params.streamId ?
                    prevProps.match.params.streamId :
                    null;
        let matchId =
            match && match.params && match.params.eventId ?
                match.params.eventId :
                match && match.params && match.params.streamId ?
                    match.params.streamId :
                    null;

        let linkId = (match && match.params && match.params.linkId ? match.params.linkId : null)

        if (prevMatchId && matchId && prevMatchId !== matchId) {
            this.eventItem = null;
            this.setState({
                role: null,
                linkId: null,
                isError: false,
                show: false,
                username: null,
                userPhoto: null,
                name: null,
                userId: null,
                eventId: null,
                errorCode: null,
                registerOverlay: false,
                showConfirm: false
            }, () => {
                this.setState({
                    linkId,
                    eventId: matchId,
                    isError: !matchId
                }, async () => {
                    await wssClient.verifyEvent(matchId);
                });
            });
        }

        if (videos && videos !== prevProps.videos) {
            this.checkConnectToWS();
        }

        function shallowEqual(object1, object2) {
            if (!object1 && !object2)
                return true;
            if ((!object1 && object2) || (object1 && !object2))
                return false;
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);
            if (keys1.length !== keys2.length) {
                console.log('Difference in length %s', keys1, keys2)
                return false;
            }
            for (let key of keys1) {
                if (object1[key] !== object2[key]) {
                    console.log('Difference in key %s', key)
                    return false;
                }
            }
            return true;
        }

        console.log('EventPage componentDidUpdate', prevProps, this.props, prevState, this.state);

        shallowEqual(prevProps, this.props);
        shallowEqual(prevState, this.state);
    }

    componentWillUnmount() {
        wssClient.removeListener('reconnectResponse', this.handleReconnectResponse);
        wssClient.removeListener('verifyLinkResponse', this.handleVerifyLinkResponse);
        wssClient.removeListener('verifyEventResponse', this.handleVerifyLinkResponse);
        wssClient.removeListener('verifyTicket', this.handleVerifyTicketResponse);
        wssClient.removeListener('pageError', this.handlePageError);

        wssClient.disconnectFromFanout(this.getCallState());

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
    }

    getCallState() {
        return {};
    }

    handleVerifyLinkResponse(data) {
        try {
            const { eventId, role } = this.state;
            console.log("handleVerifyLinkResponse response received from server", data);
            if (data.event && data.event.eventId) {
                // TODO: use wss client for this
                if (true)
                    if (data.event.eventItem) {
                        console.log("handleVerifyLinkResponse About to set eventItem", data.event.eventItem);
                        this.eventItem = data.event.eventItem;
                        this.eventItem.id = data.event.eventId;

                        

                        let ticketId = getQueryVariable('ticketId');

                        if (ticketId) {
                            wssClient.verifyTicket(data.event.eventId, ticketId);
                        }

                        if (data.event.eventItem) {
                            if (!eventId || !role) {
                                this.setState({
                                    eventId: data.event.eventId,
                                    role: data.event.role,
                                });
                            }

                            if(data.event.eventItem.type && data.event.eventItem.type === 'test-event') {
                                this.handleJoin(true, 'Test', null, null, null, null, false, 'audience', null);
                            }
                        } else {
                            this.setState({
                                eventId: null,
                                isError: true,
                                errorCode: data.status || 404
                            });
                        }
                    }
            } else {
                this.eventItem = null;
                this.setState({
                    eventId: null,
                    isError: true,
                    errorCode: data.status || 404
                });
            }
        }
        catch (error) {
            console.error("Failed verifyLinkResponse", error, data);
            this.setState({
                isError: true
            });
        }
    }

    handleVerifyTicketResponse(data) {
        try {
            console.log("handleVerifyTicketResponse response received from server", data);
            if (data && data.payload && data.payload.eventId) {
                let ticketId = getQueryVariable('ticketId');
                this.setState({
                    ticketId: ticketId,
                    ticketVerified: true
                })
            } else {
                const { eventId } = this.state;
                const { eventName } = this.eventItem;
                let ticketId = getQueryVariable('ticketId');
                this.eventItem = null;
                this.setState({
                    eventId: null,
                    isError: true,
                    errorCode: data.status || 404
                });
                if (data.status === Defines.Response.Gone ||
                    (data.status === Defines.Response.Forbidden && ticketId)
                ) {
                    // console.log('Gone', eventId);
                    if (eventId) {
                        setTimeout(() => {
                            let { pushToLink } = this.props;
                            // console.log('Gone', pushToLink);
                            if (pushToLink) {
                                let eid = encodeURIComponent(eventId);
                                let title = encodeURIComponent(eventName || '');
                                pushToLink(`/event/${eid}/${title}`);
                            }
                        }, 10000);
                    }
                }
            }
        }
        catch (error) {
            console.error("Failed verifyTicketResponse", error, data);
            this.setState({
                isError: true
            });
        }
    }

    handlePageError(data) {
        try {
            console.log("handlePageError response received from server", data);
            if (data) {
                this.setState({
                    isError: true,
                    errorCode: data.status || 404
                });
            }
        }
        catch (error) {
            console.error("Failed handlePageError", error, data);
            this.setState({
                isError: true
            });
        }
    }

    async handleRegister(username, emailVerificationCode, title, company, email) {
        let reconnectToken = window.localStorage.getItem(`reconnectToken:${(email || 'unknown').replace('@', '_')}`);

        if (reconnectToken) {
            this.setState({
                registerOverlay: true
            });
        } else {
            return wssClient.register(this.state.linkId, username, emailVerificationCode, title, company, email).then((data) => {
                if (data) {
                    this.setState({
                        registerOverlay: true
                    });
                } else {
                    this.setState({
                        isError: true,
                    });
                }
            }).catch((e) => {
                this.setState({
                    isError: true
                });
            });
        }
    }

    handleAuthResponse(data) {
        try {
            if (data && data.status && data.status === Defines.Response.NotAcceptable) {
                this.setState({
                    showConfirm: true
                });
            } else {
                const { uid, name, role, username, picture } = data || {};
                console.log("handleAuthResponse response received from server", data);
                this.setState({
                    userId: uid || null,
                    role: role || 'banned',
                    userPhoto: picture || null,
                    name,
                    username,
                    show: true,
                    isError: !uid,
                    eventId: data.eventId
                });
            }
        }
        catch (error) {
            console.error("Failed handleAuthResponse", error, data);
        }
    }

    handleReconnectResponse(data) {
        try {
            console.log("handleReconnectResponse response received from server", data);
            if (data.result && data.result !== 'error') {
                const { uid, name, role, username, picture, eventId } = data.payload || {};
                console.log("handleReconnectResponse response received from server", data);
                this.setState({
                    userId: uid || null,
                    role: role || 'banned',
                    userPhoto: picture || null,
                    name,
                    username,
                    show: true,
                    isError: !uid,
                    eventId: eventId
                });
            }
        }
        catch (error) {
            console.error("Failed handleReconnectResponse", error, data);
        }
    }

    handleJoin(auth, username, emailVerificationCode, title, company, email, skipauth = false, role = null, ticketId = null) {
        let { redirectOnJoin, pushToLink } = this.props;

        if (redirectOnJoin) {
            if (redirectOnJoin.indexOf(':linkId') !== -1)
                redirectOnJoin = redirectOnJoin.replace(':linkId', encodeURIComponent(this.state.linkId));
            // if(redirectOnJoin.indexOf(':company')!==-1)
            //     redirectOnJoin = redirectOnJoin.replace(':company', encodeURIComponent(company));
            if (redirectOnJoin.indexOf(':event-title') !== -1)
                redirectOnJoin = redirectOnJoin.replace(':event-title', encodeURIComponent(this.eventItem.eventName));
            pushToLink(redirectOnJoin);
        }

        if (auth) {
            const { eventId, ticketVerified } = this.state;
            this.setState({
                show: true,
                username,
                role: null,
                eventId: null
            }, async () => {
                // let ticketId = getQueryVariable('ticketId') && ticketVerified ? getQueryVariable('ticketId') : null;

                let reconnected = await wssClient.reconnectAsMe(email);

                if (!reconnected) {
                    wssClient.join(this.state.linkId, username, emailVerificationCode, title, company, email, skipauth, role, { eventId, ticketId })
                        .then(this.handleAuthResponse).catch((e) => {
                            console.error("handleJoin error received from server", e);
                            this.setState({
                                isError: true,
                            });
                        });
                }
            });
        } else {
            this.setState({
                show: true,
                username
            });
        }
    }

    handleExit(end = false) {
        const { hideMessage, pushToLink, redirectOnEnd } = this.props;

        if (end) {
            if (hideMessage)
                hideMessage();

            if (pushToLink && redirectOnEnd)
                pushToLink(redirectOnEnd);
        } else {
            this.setState({
                show: false
            }, () => {
                if (hideMessage)
                    hideMessage();
            });
        }
    }

    renderError(errorCode) {
        const { pushToLink } = this.props;
        const { eventItem } = this;

        let message = '', message2 = '', title = 'Error', type = 'error', showButton = false, showHomeButton = false;

        switch (errorCode) {
            case Defines.Response.Forbidden:
                message = 'Access to the event is denied and you are not allowed to enter the event.';
                let tid = getQueryVariable('ticketId');
                if (tid) {
                    message += ' Redirecting...'
                }
                break;
            case Defines.Response.Unauthorized:
                message = 'Not authorized to view this page';
                break;
            case Defines.Response.Gone:
                message = 'Ticket is not valid. Redirecting...';
                break;
            case Defines.Response.NotFound:
                message = 'Event not found';
                break;
            case Defines.Response.TooEarly:
                title = 'Notification';
                message = `You're early, the event ${eventItem && eventItem.startDate ? `is starting ${moment(new Date(eventItem.startDate)).fromNow()}` : 'starts later'}.`;
                message2 = eventItem && eventItem.startDate ? `(Start ${moment(new Date(eventItem.startDate)).format('LL')} | ${moment(new Date(eventItem.startDate)).format('LT')})` : '';
                type = 'notification'
                showHomeButton = true;
                break;
            default:
                title = 'Network problem';
                message = 'Could not complete operation';
                showButton = true;
                break;
        }
        return (
            <div className='error-wrapper'>
                <div className='error-div'>
                    <p className='error-title'>{title}</p>
                    <p className={classNames('error-message', { 'notification': type && type !== 'error' }, { 'no-margin': message2 })}>{message}</p>
                    {message2 ?
                        <p className={classNames('error-message', { 'notification': type && type !== 'error' })}>{message2}</p>
                        : null}
                    {showButton ?
                        <button
                            className='btn-reload'
                            onClick={() => window.location.reload()}
                        >
                            Reload
                        </button>
                        : null
                    }
                    {showHomeButton && pushToLink ?
                        <button
                            className='btn-reload'
                            onClick={() => pushToLink("/")}
                        >
                            Go to home page
                        </button>
                        : null
                    }
                </div>
            </div>
        );
    }

    render() {
        const { registerOverlay, isError, errorCode, eventId,
            show, username, userPhoto, role, name, showConfirm, ticketId, ticketVerified } = this.state;
        const { user, match } = this.props;
        const { eventItem } = this;
        console.log('EventPage render', user, role, eventId);


        return (
            <div className='room-wrapper'>
                {(isError) ?
                    this.renderError(errorCode) :
                    (!show) ?
                        <EventForm
                            eventItem={eventItem}
                            joinUser={this.handleJoin}
                            role={role}
                            match={match}
                            ticketId={ticketId}
                            ticketVerified={ticketVerified}
                        />
                        : (role && eventId) ?
                            <Room
                                role={role}
                                user={user}
                                name={name ? name : null}
                                username={username ? username : (name ? name : '')}
                                userPhoto={userPhoto || null}
                                eventId={eventId}
                                eventItem={eventItem}
                                handleExit={this.handleExit}
                                handleError={this.renderError}
                            />
                            : <Loader
                                text="Connecting"
                                dots
                                type="page"
                            />
                }
                {registerOverlay ?
                    <StaticPage
                        title='Registration'
                        text='Thank you. You are registered for this event. See you then!'
                        close={() => this.setState({ registerOverlay: false })}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
        signIn: state.app.signIn,
        userInfo: state.room.userInfo,
        eventItem: state.room.eventItem,
        videos: state.firebase.videos
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearMessages: () => {
            dispatch(chatActions.clearMessages());
        },
        hideMessage: () => {
            dispatch(roomActions.hideMessage());
        },
        showSignIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        pushToLink: (path) => {
            dispatch(nativePush(path));
        }
    };
};

const EventPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventPage);

export default EventPageContainer;