import React, { Component } from "react";
import classNames from "classnames";
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import eyeIcon from '../../../resources/icons/eye.svg';
import eyeDisableIcon from '../../../resources/icons/disable_eye.svg';
import closeIcon from '../../../resources/icons/nav/x.svg';

class LogInMail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: 'signIn',
            processing: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            email: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        }

        this.reset = this.reset.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.signInWithEmailAndPassword = this.signInWithEmailAndPassword.bind(this);
        this.createUserWithEmailAndPassword = this.createUserWithEmailAndPassword.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSignKeyDown = this.handleSignKeyDown.bind(this);
        this.handleCreateKeyDown = this.handleCreateKeyDown.bind(this);
        this.changeInputType = this.changeInputType.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePassword(password) {
        const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        return re.test(String(password));
    }

    signInWithEmailAndPassword() {
        const { email, password } = this.state;

        if (!email || (email && !this.validateEmail(email)) || !password || (password && !this.validatePassword(password))) {
            this.checkForm('signIn');
        } else {
            this.setState({
                processing: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.signInWithEmailAndPassword(email, password).catch(error => {
                    if (error.code && error.code === 'auth/user-not-found') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            processing: false,
                            error,
                            loaderText: '',
                            errorText: "There is no user with this email"
                        });
                    } else if (error.code && error.code === 'auth/wrong-password') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            processing: false,
                            error,
                            loaderText: '',
                            errorText: "The password is invalid"
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            processing: false,
                            error,
                            loaderText: '',
                            errorText: ''
                        });
                    }
                });
            });
        }
    }

    createUserWithEmailAndPassword() {
        const { email, password, confirmPassword } = this.state;

        if (!email || (email && !this.validateEmail(email)) || !password || (password && !this.validatePassword(password)) || !confirmPassword || (confirmPassword && !this.validatePassword(confirmPassword)) || password !== confirmPassword) {
            this.checkForm();
        } else {
            this.setState({
                processing: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.createUserWithEmailAndPassword(email, password).catch(error => {
                    if (error.code && error.code === 'auth/email-already-in-use') {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            processing: false,
                            error,
                            loaderText: '',
                            errorText: "The email address is already in use by another account."
                        });
                    } else {
                        this.setState({
                            showError: true,
                            showMessage: false,
                            processing: false,
                            error,
                            loaderText: '',
                            errorText: ''
                        });
                    }
                });
            });
        }
    }

    reset() {
        this.setState({
            form: 'signIn',
            processing: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        });
    }

    handleChangeEmail(e) {
        const value = e.target.value;
        this.setState({
            email: value,
            notificationText: ''
        }, () => {
            localStorage.setItem('userEmail', value.trim());
        });
    }

    handleSignKeyDown(event) {
        if (event.key === 'Enter') {
            this.signInWithEmailAndPassword();
        }
    }

    handleCreateKeyDown(event) {
        if (event.key === 'Enter') {
            this.createUserWithEmailAndPassword();
        }
    }

    changeInputType(value) {
        const { inputTypeFirst, inputTypeSecond } = this.state;

        switch (value) {
            case 1:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
            case 2:
                if (inputTypeSecond && inputTypeSecond === 'password') {
                    this.setState({
                        inputTypeSecond: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeSecond: 'password'
                    });
                }
                break;
            default:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
        }
    }

    resetPassword() {
        const { email } = this.state;

        if (email) {
            this.setState({
                processing: true,
                showError: false,
                showMessage: false,
                loaderText: '',
                errorText: ''
            }, () => {
                firebaseClient.sendPasswordResetMail(email).then(() => {
                    this.setState({
                        showError: false,
                        showMessage: true,
                        processing: false,
                        loaderText: '',
                        errorText: ''
                    });
                }).catch(error => {
                    this.setState({
                        showError: true,
                        showMessage: false,
                        processing: false,
                        error,
                        loaderText: '',
                        errorText: ''
                    });
                });
            });
        }
    }

    closeForm() {
        const { close } = this.props;

        if (close) {
            this.reset();
            close();
        }
    }

    switchToForm(form) {
        this.setState({
            form: form,
            processing: false,
            error: null,
            showError: false,
            showMessage: false,
            loaderText: '',
            errorText: '',
            notificationText: '',
            password: '',
            confirmPassword: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        })
    }

    checkForm(type) {
        const { email, password, confirmPassword } = this.state;

        if (type && type === 'signIn') {
            let message = '';

            if (!email) {
                message = "You must enter your email";
            } else if (email && !this.validateEmail(email)) {
                message = "You must enter a valid email";
            } else if (!password) {
                message = "You must enter your password";
            } else if (password && !this.validatePassword(password)) {
                message = "You must enter a valid password";
            }

            this.setState({
                notificationText: message
            });
        } else {
            let message = '';

            if (!email) {
                message = "You must enter your email";
            } else if (email && !this.validateEmail(email)) {
                message = "You must enter a valid email";
            } else if (!password) {
                message = "You must enter your password";
            } else if (password && !this.validatePassword(password)) {
                message = "You must enter a valid password";
            } else if (!confirmPassword) {
                message = "You must enter your confirmation password";
            } else if (confirmPassword && !this.validatePassword(confirmPassword)) {
                message = "You must enter a valid confirmation password";
            } else if (password !== confirmPassword) {
                message = "Passwords do not match";
            }

            this.setState({
                notificationText: message
            });
        }
    }

    render() {
        const { processing, showError, showMessage, loaderText, errorText, email, password, confirmPassword,
            error, inputTypeFirst, inputTypeSecond, form, notificationText } = this.state;

        return (
            <div>
                {showError ?
                    <div className='log-in-div'>
                        <div className="log-in-close">
                            <img
                                src={closeIcon}
                                loading='lazy'
                                aria-hidden="true"
                                onClick={this.closeForm}
                            />
                        </div>
                        <p className='log-in-title'>Error</p>
                        <p className='log-in-text error'>{errorText ? errorText : "Some error occurred"}</p>
                        <div className="choose-buttons">
                            {error && error.code && error.code === 'auth/user-not-found' ?
                                <Button
                                    type='default'
                                    className='btn-choose'
                                    disabled={processing}
                                    onClick={() => this.switchToForm('create')}
                                >
                                    Create account
                                </Button>
                                : null}
                            {error && email && this.validateEmail(email) && error.code && error.code === 'auth/wrong-password' ?
                                <Button
                                    type='default'
                                    className='btn-choose'
                                    disabled={processing}
                                    onClick={this.resetPassword}
                                >
                                    Reset password
                                </Button>
                                : null}
                            <Button
                                type='border'
                                className='btn-choose'
                                disabled={processing}
                                onClick={this.reset}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div> :
                    showMessage ?
                        <div className='log-in-div'>
                            <div className="log-in-close">
                                <img
                                    src={closeIcon}
                                    loading='lazy'
                                    aria-hidden="true"
                                    onClick={this.closeForm}
                                />
                            </div>
                            <p className='log-in-title createAccount'>{`A mail for reset password has been sent to ${email}.`}</p>
                            <div className="choose-buttons">
                                <Button
                                    type='border'
                                    className='btn-choose'
                                    disabled={processing}
                                    onClick={this.reset}
                                >
                                    Back
                                </Button>
                            </div>
                        </div> :
                        processing ?
                            <div className='log-in-div'>
                                <Loader
                                    text={loaderText ? loaderText : "Loading"}
                                    login={true}
                                />
                            </div> :
                            form === 'signIn' ?
                                <div className='log-in-div'>
                                    <div className="log-in-close">
                                        <img
                                            src={closeIcon}
                                            loading='lazy'
                                            aria-hidden="true"
                                            onClick={this.closeForm}
                                        />
                                    </div>
                                    <p className='log-in-title createAccount'>Sign in with mail</p>
                                    {notificationText ?
                                        <p className='notification-text'>{notificationText}</p>
                                        : null
                                    }
                                    <div className='log-in-input-wrapper'>
                                        <p>Email</p>
                                        <input
                                            className={classNames({ 'valid': email && this.validateEmail(email) })}
                                            type='email'
                                            onChange={this.handleChangeEmail}
                                            disabled={processing}
                                            value={email}
                                            placeholder="Your email"
                                            onKeyDown={this.handleSignKeyDown}
                                        />
                                    </div>
                                    <div className='log-in-input-wrapper'>
                                        <p>Password</p>
                                        <input
                                            className={classNames('password', { 'valid': password && this.validatePassword(password) })}
                                            type={inputTypeFirst}
                                            onChange={(e) => this.setState({ password: e.target.value, notificationText: '' })}
                                            disabled={processing}
                                            value={password}
                                            placeholder="Your password"
                                            onKeyDown={this.handleSignKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(1)}
                                            src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='no-user'>
                                        Don’t have an account on NoFilter?
                                    <br />
                                        {`Click `}
                                        <span onClick={() => this.switchToForm('create')}>
                                            here
                                    </span>
                                        {` to Sign Up!`}
                                    </p>
                                    <div className="choose-buttons">
                                        <Button
                                            type='default'
                                            className='btn-choose'
                                            disabled={processing}
                                            onClick={this.signInWithEmailAndPassword}
                                        >
                                            Sign in
                                    </Button>
                                        <Button
                                            type='border'
                                            className='btn-choose'
                                            disabled={processing}
                                            onClick={this.closeForm}
                                        >
                                            Cancel
                                    </Button>
                                    </div>
                                </div>
                                :
                                <div className='log-in-div'>
                                    <div className="log-in-close">
                                        <img
                                            src={closeIcon}
                                            loading='lazy'
                                            aria-hidden="true"
                                            onClick={this.closeForm}
                                        />
                                    </div>
                                    <p className='log-in-title createAccount'>Sign up with mail</p>
                                    {notificationText ?
                                        <p className='notification-text'>{notificationText}</p>
                                        : null
                                    }
                                    <div className='log-in-input-wrapper'>
                                        <p>Email</p>
                                        <input
                                            className={classNames({ 'valid': email && this.validateEmail(email) })}
                                            type='email'
                                            onChange={this.handleChangeEmail}
                                            disabled={processing}
                                            value={email}
                                            placeholder="Your email"
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                    </div>
                                    <p className='pass-text'>
                                        Enter your new password for NoFilter.
                                    <br />
                                        Password rules: Minimum 8 characters, at least one letter, at least one number and at least one special character.
                                </p>
                                    <div className='log-in-input-wrapper'>
                                        <p>Password</p>
                                        <input
                                            className={classNames('password', { 'valid': password && this.validatePassword(password) })}
                                            type={inputTypeFirst}
                                            onChange={(e) => this.setState({ password: e.target.value, notificationText: '' })}
                                            disabled={processing}
                                            value={password}
                                            placeholder="Your password"
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(1)}
                                            src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <div className='log-in-input-wrapper'>
                                        <p>Confirm Password</p>
                                        <input
                                            className={classNames('password', { 'valid': confirmPassword && this.validatePassword(confirmPassword) })}
                                            type={inputTypeSecond}
                                            onChange={(e) => this.setState({ confirmPassword: e.target.value, notificationText: '' })}
                                            disabled={processing}
                                            value={confirmPassword}
                                            placeholder="Your confirm password"
                                            onKeyDown={this.handleCreateKeyDown}
                                        />
                                        <img
                                            className='eye'
                                            onClick={() => this.changeInputType(2)}
                                            src={inputTypeSecond && inputTypeSecond === 'password' ? eyeIcon : eyeDisableIcon}
                                            alt='eye'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='no-user'>
                                        Already have an account on NoFilter?
                                    <br />
                                        {`Click `}
                                        <span
                                            onClick={() => this.switchToForm('signIn')}
                                        >
                                            here
                                    </span>
                                        {` to Sign In!`}
                                    </p>
                                    <div className="choose-buttons">
                                        <Button
                                            type='default'
                                            className='btn-choose'
                                            disabled={processing}
                                            onClick={this.createUserWithEmailAndPassword}
                                        >
                                            Create account
                                    </Button>
                                        <Button
                                            type='border'
                                            className='btn-choose'
                                            disabled={processing}
                                            onClick={this.closeForm}
                                        >
                                            Cancel
                                    </Button>
                                    </div>
                                </div>
                }
            </div>
        );
    }
}

export default LogInMail;