import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import 'emoji-mart-virtualized/css/emoji-mart.css';
import { Picker } from 'emoji-mart-virtualized';
import MMSDList from './MMSDList';
import sendIcon from '../../../../resources/icons/send.svg';
import smilingFaceIcon from '../../../../resources/icons/smiling-face.png';
import listOff from "../../../../resources/icons/Viewers.svg";
import listOn from "../../../../resources/icons/Viewers-Active.svg";
import chatOff from "../../../../resources/icons/Chat.svg";
import chatOn from "../../../../resources/icons/Chat-Active.svg";
import * as chatActions from "../../../actions/chat_actions";

class AttendeesChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            runningAnimation: false,
            content: '',
            showNumber: false,
            emojiForm: false,
            caretPosition: 0,
            active: false
        };
        this.sendMessage = this.sendMessage.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
        this.getCaretPosition = this.getCaretPosition.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.openRoster = this.openRoster.bind(this);
        this.openChat = this.openChat.bind(this);
        this.handleShowInputNumber = this.handleShowInputNumber.bind(this);
        this.addEmoji = this.addEmoji.bind(this);
        this.handleSetEmojiForm = this.handleSetEmojiForm.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        this.inputRef = React.createRef();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        if (this.numberTimeout) {
            clearTimeout(this.numberTimeout);
        }
    }

    escFunction(event) {
        if (event.keyCode === 13) {
            this.sendMessage(this.state.content);
            this.setState({ content: "", emojiForm: false });
            this.handleShowInputNumber();
            event.preventDefault();
        }
    }

    scrollToBottom() {
        this.child.scrollToBottom();
    }

    onContentChange(e) {
        let content = e.target.value;
        this.setState({
            content: content
        }, () => {
            this.getCaretPosition();
        });
        this.handleShowInputNumber();
    }

    getCaretPosition() {
        if (this.inputRef && this.inputRef.current) {
            this.setState({
                caretPosition: this.inputRef.current.selectionStart
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateUpdate = { attendeesChatOpened: nextProps.attendeesChatOpened };
        // Check if it should run animation
        if (
            prevState.attendeesChatOpened == true &&
            nextProps.attendeesChatOpened == false
        ) {
            stateUpdate.runningAnimation = true;
        }
        return stateUpdate;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.attendeesChatOpened == true && this.props.attendeesChatOpened == false) {
            setTimeout(() => {
                this.setState({ runningAnimation: false });
            }, 250);
        }

        if (this.props.attendeesChatOpened) {
            var scrollBar = document.getElementById("chat-scrollbar");
            if (scrollBar) scrollBar.scrollTop = scrollBar.scrollHeight;
        }

        if (this.props.attendeesChatOpened == true && prevProps.attendeesChatOpened == false) {
            var contentInputChat = document.getElementById("content-input-chat");
            setTimeout(() => {
                contentInputChat.focus();
            }, 250);
        }
    }

    componentDidMount() {
        var scrollBar = document.getElementById("chat-scrollbar");
        if (scrollBar) scrollBar.scrollTop = scrollBar.scrollHeight;
        document.addEventListener("keydown", this.escFunction, false);
    }

    sendMessage(content) {
        const { sendMessage } = this.props;

        if (content.trim().length > 0) {
            this.scrollToBottom()
            sendMessage(content);
            this.setState({ emojiForm: false });
        }
    }

    openRoster() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('list');
        }
    }

    openChat() {
        const { setShowState } = this.props;
        if (setShowState) {
            setShowState('chat');
        }
    }

    handleShowInputNumber() {
        if (this.numberTimeout) {
            clearTimeout(this.numberTimeout)
        }

        this.setState({
            showNumber: true
        }, () => {
            this.numberTimeout = setTimeout(() => {
                this.setState({
                    showNumber: false
                });
            }, 500);
        });
    }

    addEmoji(data) {
        const { content, caretPosition } = this.state;

        if (data && data.native && data.native.length && (!content || content && content.length && content.length + data.native.length <= 140)) {
            this.setState({
                content: this.insertEmoji(content, data.native, caretPosition) || ''
            }, () => {
                this.setState({
                    caretPosition: caretPosition + data.native.length
                }, () => {
                    this.focusInput();
                });
                this.handleShowInputNumber();
            });
        } else {
            this.focusInput();
        }
    }

    focusInput() {
        const { caretPosition } = this.state;
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.selectionStart = caretPosition;
            this.inputRef.current.selectionEnd = caretPosition;
            // this.inputRef.current.focus();
        }
    }

    handleSetEmojiForm() {
        const { emojiForm } = this.state;

        this.setState({
            emojiForm: !emojiForm
        });
    }

    insertEmoji(mainString, addString, position) {
        if (!position) {
            position = 0;
        }
        if (!addString) {
            addString = '';
        }
        return mainString.slice(0, position) + addString + mainString.slice(position);
    }

    handleOnClick() {
        this.getCaretPosition();
    }

    handleOnFocus() {
        const { emojiForm, active } = this.state;

        this.handleShowInputNumber();

        if(emojiForm && !active) {
            this.setState({
                emojiForm: false
            });
        }

        if(!active) {
            this.setState({
                active: true
            });
        }
    }

    handleOnBlur() {
        const { active, showNumber } = this.state;

        if(active) {
            this.setState({
                active: false
            });
        }

        if(showNumber) {
            this.setState({ 
                showNumber: false 
            });
        }
    }

    render() {
        const { content, showNumber, emojiForm } = this.state;
        const { attendeesChatOpened, messages, roster, showState, width, draggable, role, getCallState } = this.props;

        return (
            <div>
                {!draggable ?
                    <div className="attendees-chat-header">
                        {roster &&
                            <div className={classNames('header-attendees', { 'active': showState && showState === 'list' }, { 'button-wrapper': width && width > 1200 })} onClick={this.openRoster} >
                                <img src={showState && showState === 'list' ? listOn : listOff} className='roster-img' />
                                <h1>Hosts</h1>
                            </div>
                        }
                        {!width || width && width < 1200 ?
                            <div className={classNames('header-attendees', { 'active': showState && showState === 'chat' })} onClick={this.openChat} >
                                <img src={showState && showState === 'chat' ? chatOn : chatOff} className='roster-img' />
                                <h1>Chat</h1>
                            </div>
                            :
                            <h1>Chat</h1>
                        }
                    </div>
                    : roster ?
                        <div className='draggable-header-button draggable-button-wrapper' onClick={this.openRoster}>
                            <img src={showState && showState === 'list' ? listOn : listOff} className='draggable-roster-img' />
                        </div>
                    : null
                }
                <ul id="chat-scrollbar">
                    <MMSDList
                        childRef={ref => (this.child = ref)}
                        messages={messages}
                        attendeesChatOpened={attendeesChatOpened}
                        getCallState={getCallState}
                        role={role}
                    />
                </ul>
                {emojiForm ?
                    <Picker
                        autoFocus
                        theme='dark'
                        title='Pick your emoji…'
                        emoji='point_up'
                        showPreview={false}
                        showSkinTones={false}
                        onSelect={this.addEmoji}
                        native
                        perLine={8}
                    />
                    : null}
                <div className="container-input-chat">
                    <div className="container-attach">
                        <a
                            onClick={this.handleSetEmojiForm}
                        >
                            <img src={smilingFaceIcon} />
                        </a>
                    </div>
                    <div className="container-input">
                        <input
                            ref={this.inputRef}
                            placeholder='Enter your message'
                            onChange={this.onContentChange}
                            onClick={this.handleOnClick}
                            value={content}
                            onFocus={this.handleOnFocus}
                            onBlur={this.handleOnBlur}
                            id="content-input-chat"
                            className="input-message"
                            maxLength="140"
                        ></input>
                    </div>
                    <div className="container-send">
                        <a
                            onClick={e => {
                                this.setState({ content: "" });
                                this.handleShowInputNumber();
                                this.sendMessage(this.state.content);
                                e.preventDefault();
                            }}
                        >
                            <img src={sendIcon} />
                        </a>
                    </div>
                </div>
                <div className='text-small'>
                    {content && content.length && content.length >= 70 && (showNumber || content.length >= 140) ? <small className="form-text text-muted">{`${content.length}/140`}</small> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messages: state.chat.messages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearMessages: () => {
            dispatch(chatActions.clearMessages());
        }
    };
};

const AttendeesChatContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendeesChat);

export default AttendeesChatContainer;