import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import * as paymentActions from "../../actions/payment_actions";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';

let stripe_pk = process.env.build == 'prod' ? process.env.stripe_pk_prod : process.env.stripe_pk_test;
let stripePK = loadStripe(stripe_pk);

class SubscriptionPaymentWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardComplete: false,
            existCard: false,
            cardsInfo: '',
            selectedCard: '',
            defaultCard: null,
            editForm: false,
            showForm: false
        }

        this.resetPaymentError = this.resetPaymentError.bind(this);
    }

    componentDidMount() {
        this.checkPM();
    }

    checkPM() {
        const { user } = this.props;
        if (user && user.uid) {
            firebaseClient.checkPaymentMethod(user.uid).then((res) => {
                this.setState({
                    existCard: res && res.length ? true : false,
                    cardsInfo: res,
                    selectedCard: this.getDefaultPaymentMethod(res),
                    defaultCard: this.getDefaultPaymentMethod(res, true),
                    showForm: true
                });
            });
        }
    }

    getDefaultPaymentMethod(cards, cardObject) {
        let defaultPM = false;
        if (cards && cards.length) {
            cards.map((card) => {
                if (card && card.default && card.last4) {
                    defaultPM = cardObject ? card : card.last4;
                }
            });
        }
        return defaultPM;
    }

    handleChangeCardInput(e) {
        this.setState({
            cardComplete: e.complete
        });
    }

    handleReadyCardInput(elements) {
        const cardElement = elements.getElement(CardElement);
        if (cardElement && cardElement.focus) {
            cardElement.focus();
        }
    }

    hideMessageOverlay() {
        const { successFunction } = this.props;

        if (successFunction) {
            successFunction();
        }
    }

    async handlePay(stripe, elements) {
        const { cardComplete, selectedCard } = this.state;
        const { processingPayment, item, subscriptionType, subscriptionPlan, productId, successPayment, user, updateProfile } = this.props;

        if (!stripe || !elements) {
            return;
        }

        let cardTokenId;

        processingPayment();

        if (subscriptionType && subscriptionType === 'hosting' && updateProfile) {
            await updateProfile();
        }

        if (cardComplete) {
            const cardElement = elements.getElement(CardElement);

            const { token } = await stripe.createToken(cardElement);

            if (token && !token.error) {
                cardTokenId = token.id;
            } else {
                return this.setState(token ? { error: token.error } :
                    { error: { message: 'Unknown error' } });
            }
        }

        let ticketType = '';

        switch (subscriptionPlan) {
            case 'month':
                ticketType = 'month';
                break;
            case 'zero_commitment':
                ticketType = 'zero_commitment';
                break;
            case 'content_creator_package':
                ticketType = 'content_creator_package';
                break;
            default:
                ticketType = 'month';
        }

        console.log("Subscritpion State", subscriptionPlan, subscriptionType, productId, ticketType);
        if (ticketType) {
            firebaseClient.createPaymentIntentsOrThrowError({ type: subscriptionType, streamId: item && item.id ? item.id : user && user.uid ? user.uid : null, ticketType: ticketType, email: user.email, cardTokenId: cardTokenId, selectedCard: selectedCard, productId: productId }).then((res) => {
                if (res) {
                    successPayment();
                    this.successTimeout = setTimeout(() => {
                        this.hideMessageOverlay();
                    }, 30000);
                } else {
                    console.error('Payment failed')
                }
            }).catch((e) => {
                console.error('Payment failed', e)
                this.setState({ error: e });
            });
        }
    };

    componentWillUnmount() {
        const { resetPaymentValue } = this.props;

        if (resetPaymentValue) {
            resetPaymentValue();
        }

        if (this.successTimeout) {
            clearTimeout(this.successTimeout);
            this.successTimeout = null;
        }
    }

    handleBack() {
        const { resetValue, resetPaymentValue } = this.props;

        if (resetValue && resetPaymentValue) {
            resetPaymentValue();
            resetValue();
            this.setState({
                cardComplete: false,
                error: false
            });
        }
    }

    resetPaymentError() {
        const { resetPaymentValue } = this.props;

        if (resetPaymentValue) {
            this.setState({
                error: false,
                cardComplete: false,
                existCard: false,
                cardsInfo: '',
                selectedCard: '',
                defaultCard: null,
                editForm: false,
                showForm: false
            }, () => {
                resetPaymentValue();
                this.checkPM();
            });
        }
    }

    render() {
        const { cardComplete, showForm, cardsInfo, existCard, selectedCard, editForm, defaultCard } = this.state;
        const { processing, succeeded, subscriptionType, privileges, price, title, disabledBtn } = this.props;
        let error = this.props.error || this.state.error;

        // console.log("PROCESSING", processing, clientSecret, this.state, this.props);

        return (
            <div className={classNames('subscription-form-wrapper', { 'hosting': subscriptionType && subscriptionType === 'hosting' })}>
                <div className='subscription-form'>
                    <div className='subscription-card-wrapper'>
                        {error ?
                            <div className='subscription-card-form'>
                                <p className='subscription-card-form-p error-msg'>
                                    Your subscription has failed.
                                </p>
                                <p className='subscription-card-form-p error-msg'>
                                    {error.message}
                                </p>
                                <Button
                                    type='default'
                                    className='btn-subscription-btn no-transform'
                                    onClick={this.resetPaymentError}
                                >
                                    Back
                                </Button>
                            </div>
                            : succeeded ?
                                <div className='subscription-card-form'>
                                    {subscriptionType && subscriptionType === 'subscription' ?
                                        <p className='subscription-card-form-title'>
                                            success!
                                        </p>
                                        : null}
                                    {subscriptionType && subscriptionType === 'hosting' ?
                                        <p className='subscription-card-form-title'>
                                            THANK YOU FOR SIGNING UP TO NO FILTER!
                                        </p>
                                        : null}
                                    {subscriptionType && subscriptionType === 'hosting' ?
                                        <p className='subscription-card-form-p'>We will send you an email with all the information you need to access your bonuses and get started with your account. Please allow a few minutes for our system to register you as a creator and access the event scheduler.</p>
                                        : null
                                    }
                                    {subscriptionType && subscriptionType === 'subscription' ?
                                        <p className='subscription-card-form-p'>
                                            Congratulations, you're now officially subscribed to the host! You can now watch their live stream content, and view their upcoming and vaulted shows in your 'MyStuff' page once you've followed them.
                                </p> : null
                                    }
                                    {subscriptionType && subscriptionType === 'subscription' ?
                                        <p className='subscription-card-form-p'>
                                            Your subscription will renew at the end of the one month period unless you cancel before your subscription ends.
                                </p> : null
                                    }
                                    <Button
                                        type='default'
                                        className='btn-subscription-btn no-transform'
                                        onClick={() => this.hideMessageOverlay()}
                                    >
                                        {subscriptionType && subscriptionType === 'subscription' ? `Return to Host's Profile` : 'Click here to go to your profile'}
                                    </Button>
                                </div>
                                : showForm && !error ?
                                    <div className='subscription-card-form'>
                                        {title && subscriptionType && subscriptionType === 'subscription' ?
                                            <p className='subscription-card-form-title'>
                                                {title}
                                            </p>
                                            : null}
                                        {subscriptionType && subscriptionType === 'subscription' ?
                                            <p className='subscription-card-form-p'>
                                                Confirm your payment for one month subscription to this host's No Filter content.
                                    </p>
                                            : null}
                                        {subscriptionType && subscriptionType === 'subscription' ?
                                            <p className='subscription-card-form-p'>
                                                Subscription will renew automatically in one month's time unless you cancel before the end of the subscription period.
                                    </p>
                                            : null}
                                        <Elements stripe={stripePK}>
                                            <ElementsConsumer>
                                                {({ stripe, elements }) => (
                                                    <div className='stripe-card-form'>
                                                        {!existCard ?
                                                            <div className='subscription-card-input'>
                                                                <p className='subscription-card-title'>New card</p>
                                                                <CardElement
                                                                    id='subscription-card-element'
                                                                    options={{
                                                                        hidePostalCode: true,
                                                                        style: {
                                                                            base: {
                                                                                color: '#fff',
                                                                                fontSize: '14px',
                                                                                fontWeight: 300,
                                                                                '::placeholder': {
                                                                                    color: '#9fa5b5',
                                                                                },
                                                                                ':disabled': {
                                                                                    color: '#343a40',
                                                                                },
                                                                            }
                                                                        },
                                                                        disabled: processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')
                                                                    }}
                                                                    onReady={() => this.handleReadyCardInput(elements)}
                                                                    onChange={(e) => this.handleChangeCardInput(e)}
                                                                />
                                                                {subscriptionType && subscriptionType === 'hosting' && price ?
                                                                    <p className='subscription-text-p center f-18'>
                                                                        {`Subtotal: $${price} renewing every 30 days`}
                                                                    </p>
                                                                    : null}
                                                                <Button
                                                                    type='default'
                                                                    className='btn-subscription-btn no-transform'
                                                                    onClick={() => this.handlePay(stripe, elements)}
                                                                    disabled={(!cardComplete && !existCard) || processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting') || disabledBtn}
                                                                    loading={processing}
                                                                >
                                                                    {this.props.subscriptionPlan === 'zero_commitment' ? "Save Card" : (subscriptionType && subscriptionType === 'subscription') ? "Process Payment" : "Confirm Subscription"}
                                                                </Button>
                                                                {defaultCard && selectedCard ?
                                                                    <Button
                                                                        type='text-white'
                                                                        className='btn-subscription-btn no-transform'
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                existCard: true,
                                                                                editForm: false,
                                                                                cardComplete: false
                                                                            })
                                                                        }
                                                                        disabled={processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')}
                                                                    >
                                                                        Cancel
                                                            </Button>
                                                                    : null
                                                                }
                                                            </div>
                                                            : editForm ?
                                                                <div className='subscription-card-input'>
                                                                    <p className='subscription-card-title'>Your cards</p>
                                                                    {cardsInfo && cardsInfo.length ?
                                                                        cardsInfo.map((card, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    className='subscription-card-item-wrapper'
                                                                                    onClick={() => this.setState({ selectedCard: card.last4, editForm: false, defaultCard: card })}
                                                                                >
                                                                                    <div className='subscription-card-item'>
                                                                                        <i className="fa fa-credit-card" />
                                                                                        <div className='subscription-card-text'>{`${card.brand ? ` ${card.brand}` : ''}${card.last4 ? ` **** ${card.last4}` : ''}`}</div>
                                                                                    </div>
                                                                                    <input
                                                                                        className='subscription-card-radio'
                                                                                        type="radio"
                                                                                        value={card.last4}
                                                                                        checked={selectedCard === card.last4}
                                                                                        onChange={(e) => this.setState({ selectedCard: e.target.value, editForm: false, defaultCard: card })}
                                                                                        disabled={processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                    }
                                                                    <Button
                                                                        type='default'
                                                                        className='btn-subscription-btn no-transform'
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                existCard: false,
                                                                                editForm: false
                                                                            })
                                                                        }
                                                                        disabled={processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')}
                                                                    >
                                                                        Add Card
                                                            </Button>
                                                                    <Button
                                                                        type='text-white'
                                                                        className='btn-subscription-btn no-transform'
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                editForm: false
                                                                            })
                                                                        }
                                                                        disabled={processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')}
                                                                    >
                                                                        Cancel
                                                            </Button>
                                                                </div>
                                                                :
                                                                <div className='subscription-card-input'>
                                                                    {defaultCard && <p className='subscription-card-title'>Card details</p>}
                                                                    {defaultCard ?
                                                                        <div className='subscription-card-item-wrapper'>
                                                                            <div className='subscription-card-item'>
                                                                                <i className="fa fa-credit-card" />
                                                                                <div className='subscription-card-text'>{`${defaultCard.brand ? ` ${defaultCard.brand}` : ''}${defaultCard.last4 ? ` **** ${defaultCard.last4}` : ''}`}</div>
                                                                                <Button
                                                                                    type='border'
                                                                                    className='btn-subscription-change'
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            editForm: true
                                                                                        })
                                                                                    }
                                                                                    disabled={processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting')}
                                                                                >
                                                                                    Change
                                                                        </Button>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    {subscriptionType && subscriptionType === 'hosting' && price ?
                                                                        <p className='subscription-text-p center f-18'>
                                                                            {`Subtotal: $${price} renewing every 30 days`}
                                                                        </p>
                                                                        : null}
                                                                    <Button
                                                                        type='default'
                                                                        className='btn-subscription-btn no-transform'
                                                                        onClick={() => this.handlePay(stripe, elements)}
                                                                        disabled={(!cardComplete && !existCard) || processing || error || (privileges && privileges.active && subscriptionType && subscriptionType === 'hosting') || disabledBtn}
                                                                        loading={processing}
                                                                    >
                                                                        {this.props.subscriptionPlan === 'zero_commitment' ? "Save Card" : (subscriptionType && subscriptionType === 'subscription') ? "Process Payment" : "Confirm Subscription"}
                                                                    </Button>
                                                                </div>
                                                        }
                                                        <div className='subscription-card-message'>
                                                            {error && error.message ?
                                                                <p className='subscription-card-message-p error-msg'>{error.message}</p>
                                                                : error ?
                                                                    <p className='subscription-card-message-p error-msg'>Subscription Failed</p>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </ElementsConsumer>
                                        </Elements>
                                    </div>
                                    :
                                    <div className={classNames('subscription-card-form', { 'hosting': subscriptionType && subscriptionType === 'hosting' })}>
                                        <Loader payment={true} />
                                    </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        processing: state.payment.state === "PAYMENT_STATE_PROCESSING",
        succeeded: state.payment.state === "PAYMENT_STATE_SUCCESS",
        error: state.payment.error,
        clientSecret: state.payment.clientSecret
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        }
    };
};

const SubscripionPaymentWrapperContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionPaymentWrapper);

export default SubscripionPaymentWrapperContainer;
