import React, { Component } from "react";
import { connect } from 'react-redux';
import ChatOn from "../../../../resources/icons/Chat-Active.svg";
import ChatOff from "../../../../resources/icons/Chat.svg";
import ChatroomOn from "../../../../resources/icons/Chatroom-Active.svg";
import ChatroomOff from "../../../../resources/icons/Chatroom.svg";
import { isMobile, numberFormatter } from "../../../utils/text";

class AttendeesListButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            hover: false,
        };
    }

    render() {
        const {
            isOpen,
            toggle,
            isBottomBar,
            isAdmin,
            newMessage,
            hosts,
            audience,
            bannedUsers
        } = this.props;
        const { hover, isMobile } = this.state;

        let nbParticipants = Object.entries(hosts) && Object.entries(hosts).length ? Object.entries(hosts).length : 0;
        nbParticipants += Object.entries(audience) && Object.entries(audience).length ? Object.entries(audience).length : 0;
        // nbParticipants += Object.entries(bannedUsers) && Object.entries(bannedUsers).length ? Object.entries(bannedUsers).length : 0;

        return (
            <li
                className={isOpen ? "active" : ""}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="toggle-attendees"
                    className={" " + (isOpen ? "on" : "off")}
                    title={isAdmin ? 'Chat/Hosts' : 'Chat'}
                    onClick={() => toggle()}
                >
                    {isAdmin ?
                        <span
                            className={"attendees-number activeUsers" + (isOpen || hover ? " active" : "")}
                        >
                            {numberFormatter(nbParticipants)}
                        </span>
                        : null
                    }
                    <img src={isOpen || hover ? isAdmin ? ChatroomOn : ChatOn : isAdmin ? ChatroomOff : ChatOff} />
                    {isBottomBar && (
                        <div>
                            <span>{isAdmin ? 'Chat/Hosts' : 'Chat'}</span>
                        </div>
                    )}
                    {newMessage && <span className='chat-badge' />}
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newMessage: state.chat.newMessage,
        hosts: state.hosts,
        audience: state.audience,
        bannedUsers: state.bannedUsers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const AttendeesListButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendeesListButton);

export default AttendeesListButtonContainer;