import React, { Component } from 'react';
import classNames from 'classnames';
import Button from '../Widget/Button';

export default class PrivateBookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            privateDiv: false
        }

        this.setPrivateWrapperRef = this.setPrivateWrapperRef.bind(this);
        this.handleClickPrivateOutside = this.handleClickPrivateOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickPrivateOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickPrivateOutside);
    }

    setPrivateWrapperRef(node) {
        this.privateWrapperRef = node;
    }

    handleClickPrivateOutside(event) {
        const { privateDiv } = this.state;
        if (this.privateWrapperRef && !this.privateWrapperRef.contains(event.target) && privateDiv) {
            this.setState({
                privateDiv: false
            });
        }
    }

    render() {
        const { privateDiv } = this.state;

        return (
            <div ref={this.setPrivateWrapperRef} className='privateBookingsBtn'>
                <Button
                    type='text'
                    className='btn-pb'
                    onClick={() => this.setState({
                        privateDiv: !privateDiv
                    })}
                >
                    private bookings
                </Button>
                {privateDiv ?
                    <div className='private-bookings-div'>
                        <div className='bookings-title'>PRIVATE BOOKINGS</div>
                        <div className='bookings-text'>Experience private events right here on <span className='nfb-text'>No Filter Network</span>.</div>
                        <div className='bookings-text'><span className='index-number'>1</span> Simply visit your favorite host's profile page to see if they are available for private bookings.</div>
                        <div className='bookings-text'><span className='index-number'>2</span> Fill out the inquiry form and a representative will contact you.</div>
                        <Button
                            type='default'
                            className='btn-bookings'
                            onClick={() => this.setState({
                                privateDiv: false
                            })}
                        >
                            Ok
                        </Button>
                    </div>
                    : null
                }
            </div>
        )
    }
}
