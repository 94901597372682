import React, { Component } from "react";
import { connect } from 'react-redux';
import ChatroomOn from "../../../../resources/icons/Chatroom-Active.svg";
import ChatroomOff from "../../../../resources/icons/Chatroom.svg";
import { isMobile } from "../../../utils/text";

class AttendeesListFanoutButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            hover: false,
        };
    }

    render() {
        const {
            isOpen,
            toggle,
            newMessage,
            hosts
        } = this.props;
        const { hover, isMobile } = this.state;

        let nbParticipants = Object.entries(hosts) && Object.entries(hosts).length ? Object.entries(hosts).length : 0;

        return (
            <li
                className={isOpen ? "active" : ""}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="toggle-attendees"
                    className={" " + (isOpen ? "on" : "off")}
                    title="Chat/Hosts"
                    onClick={() => toggle()}
                >
                    <span
                        className={"attendees-number activeUsers" + (isOpen || hover ? " active" : "")}
                    >
                        {nbParticipants}
                    </span>
                    <img src={isOpen || hover ? ChatroomOn : ChatroomOff} />
                    <div>
                        <span>Chat/Hosts</span>
                    </div>
                    {newMessage && <span className='chat-badge' />}
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hosts: state.hosts
    };
};

const AttendeesListFanoutButtonContainer = connect(
    mapStateToProps
)(AttendeesListFanoutButton);

export default AttendeesListFanoutButtonContainer;