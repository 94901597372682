import React, { Component } from 'react';
import { connect } from "react-redux";
import { push as nativePush } from 'connected-react-router';
import StreamingVoxeet from '../Streaming/StreamingVoxeet';
import Loader from '../Widget/Loader';
import Cookies from "js-cookie";
import firebaseClient from '../../utils/FirebaseClient';
import { isMobile, stringToBoolean, validLink } from "../../utils/text";

var today = new Date();
today.setDate(today.getDate() + 365);
const default_cookies_param = {
    path: "/",
    expires: today,
    secure: true,
    sameSite: 'none',
};

class StreamingGuest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            conferenceAlias: props.match.params.conferenceAlias || '',
            knockId: props.match.params.knockId || '',
            streamPoster: '',
            posterUrl: '',
            streamTitle: '',
            streamCategory: 'SPORTS',
            simulcastMode: false,
            isListener: true,
            isModerator: false,
            widgetMode: false,
            useDefaultSettings: true,
            isDemo: false,
            joinCall: false,
            showForm: false,
            isAdmin: false,
            eventItem: null,
            fanout: false
        }
        this.labelTitleRef = React.createRef();
        this.labelPosterRef = React.createRef();

        this.joinTimeout = null;
    }

    makeConfAlias() {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async joinConference() {
        const { conferenceAlias } = this.state;
        const { history, match, firebaseInit, user } = this.props;
        await firebaseClient.closeConferenceAndSession();
        if (conferenceAlias && firebaseInit && user) {
            firebaseClient.getOne('fanout_events', conferenceAlias).then(async (res) => {
                let isFanout = false;//res && res.data && res.data.enabled ? res.data.enabled : false;
                let fanoutId = await firebaseClient.getEventFanoutId(conferenceAlias);
                console.log('fanoutId', fanoutId, conferenceAlias);
                if(!fanoutId)
                    isFanout = false;
                console.log('Should %s be on fanout', isFanout?'':'not');

                this.setState({
                    fanout: isFanout,
                    isListener: true
                }, () => {
                    if (history && match && match.params.conferenceAlias) {
                        firebaseClient.getOne('calendar', match.params.conferenceAlias).then(async (item) => {
                            if(!item || !item.data) {
                                // Report error
                                this.setState({
                                    error: 'Could not find requested event'
                                });
                                return;
                            }
                            await firebaseClient.createGuestTicket(match.params.conferenceAlias);
                            firebaseClient.listenToEventStatus(match.params.conferenceAlias);
                            if(fanoutId)
                                firebaseClient.subscribeToFanoutStateChanges(fanoutId);
                            this.toggleJoin();
                            if (item && item.data && item.data.uid && user && user.uid && item.data.uid === user.uid) {
                                this.setState({
                                    isAdmin: true,
                                    streamPoster: item.data.archiveCustomCover || item.data.poster,
                                    streamTitle: item.data.title,
                                    streamCategory: item.data.cat,
                                    eventItem: item.data
                                });
                            } else if (item && item.data && item.data.uid && user && user.uid && item.data.uid !== user.uid) {
                                this.setState({
                                    eventItem: item.data
                                });
                            }
                        });
                    }
                    if (history && match && match.params && match.params.conferenceAlias) {
                        window.history.pushState(null, null, `/audience/${match.params.conferenceAlias}`);
                    }
                });
            });
        } else if (conferenceAlias) {
            this.joinTimeout = setTimeout(() => {
                this.joinConference();
            }, 1000);
        }
    }

    componentDidMount() {
        this.joinConference();
        // this.getEvents();
        if (this.state.conferenceAlias) {
            this.setState({
                showForm: false
            });
        } else {
            this.setState({
                showForm: true
            });
        }

        let videoEnabled = Cookies.get('videoEnabled');
        // console.log('videoEnabled', videoEnabled);

        if (!videoEnabled || (videoEnabled && (typeof videoEnabled === 'string' || videoEnabled instanceof String) && videoEnabled.toLowerCase() !== 'true')) {
            Cookies.set('videoEnabled', true, default_cookies_param);
        }

        let maxVideoForwarding = Cookies.get('maxVideoForwarding');
        // console.log('maxVideoForwarding', maxVideoForwarding);

        if (!maxVideoForwarding || typeof maxVideoForwarding !== 'number') {
            Cookies.set("maxVideoForwarding", isMobile() ? 4 : 16, default_cookies_param);
        }

        let audioTransparentMode = Cookies.get('audioTransparentMode');
        // console.log('audioTransparentMode', audioTransparentMode);

        if (!audioTransparentMode) {
            Cookies.set("audioTransparentMode", true, default_cookies_param);
        }
    }

    componentWillUnmount() {
        const { eventItem } = this.state;

        if (this.joinTimeout) {
            clearTimeout(this.joinTimeout);
            this.joinTimeout = null;
        }

        // if (eventItem && eventItem.id) {
        //     firebaseClient.unsubscribeToFanoutStateChanges(this.props.fanoutId/*process.env.fanoutId*/);
        // }
    }

    handleChangeStreamTitle(event) {
        this.setState({
            streamTitle: event.target.value,
            conferenceAlias: this.makeConfAlias(),
            isAdmin: true
        });
    }

    handleChangeStreamPoster(event) {
        this.setState({
            streamPoster: event.target.value
        });
        if (event.target.value) {
            validLink(event.target.value);
        }
    }

    handleChangeIsListener() {
        this.setState({
            isListener: !this.state.isListener
        })
    }

    handleChangeWidgetMode() {
        this.setState({
            widgetMode: !this.state.widgetMode
        })
    }

    handleChangeSimulcastMode() {
        this.setState({
            simulcastMode: !this.state.simulcastMode
        })
    }

    handleChangeUseDefaultSettings() {
        this.setState({
            useDefaultSettings: !this.state.useDefaultSettings
        })
    }

    toggleJoin() {
        this.setState({
            joinCall: !this.state.joinCall
        })
    }

    handleChangeSelectCategories(event) {
        this.setState({
            streamCategory: event.target.value
        })
    }

    enterPressed(event) {
        const { streamTitle, streamCategory } = this.state;
        var code = event.keyCode || event.which;
        if (code === 13 && streamTitle && streamCategory && streamCategory !== 'default') {
            this.toggleJoin();
        }
    }

    checkPosterShow() {
        const { streamPoster } = this.state;
        const { user } = this.props;

        if (streamPoster && validLink(streamPoster)) {
            this.setState({
                posterUrl: streamPoster
            });
        } else if (user && user.profile_picture1) {
            this.setState({
                posterUrl: user.profile_picture1
            });
        }
        else if (user && user.profile_picture) {
            this.setState({
                posterUrl: user.profile_picture
            });
        }
    }

    render() {
        const { conferenceAlias, streamPoster, streamCategory, streamTitle, joinCall, isListener, isModerator, knockId,
            widgetMode, simulcastMode, useDefaultSettings, isDemo, posterUrl, showForm, isAdmin, eventItem, fanout, error } = this.state;
        const { user, categories, leave, fanoutState } = this.props;

        return (
            <div style={{ flexGrow: "1", minHeight: "calc(100vh - 332px)" }}>
                { error && <div className='bc-title'>Streaming
                    <div>{error}</div>
                </div>}
                {!error && showForm && <div className='bc-title'>Streaming</div>}
                {!error && showForm ?
                    <div className='streaming-wrapper'>
                        <div className='input-div input-field'>
                            <input
                                type='text'
                                id="titleInput"
                                className='validate'
                                value={streamTitle}
                                disabled={joinCall}
                                onChange={this.handleChangeStreamTitle.bind(this)}
                                onKeyPress={this.enterPressed.bind(this)}
                            />
                            <label ref={this.labelTitleRef} htmlFor="titleInput">Title of your stream</label>
                        </div>
                        <div className='input-div input-field'>
                            <input
                                type='text'
                                id='posterInput'
                                className='validate'
                                disabled={joinCall}
                                value={streamPoster}
                                onChange={this.handleChangeStreamPoster.bind(this)}
                                onKeyPress={this.enterPressed.bind(this)}
                            />
                            <label ref={this.labelPosterRef} htmlFor="posterInput">Poster of your stream</label>
                        </div>
                        <button
                            className='btn waves-effect waves-light'
                            onClick={() => this.checkPosterShow()}
                            disabled={!streamPoster.length || joinCall}
                        >
                            Check Poster
                        </button>
                        <img src={posterUrl ? posterUrl : user && user.profile_picture1 ? user.profile_picture1 : user && user.profile_picture ? user.profile_picture : ''} loading='lazy' />
                        {categories && categories.length &&
                            <div>
                                <label>Category of your stream</label>
                                <div className="select">
                                    <select
                                        name="select"
                                        disabled={joinCall}
                                        id="select"
                                        className='browser-default'
                                        value={streamCategory}
                                        onChange={this.handleChangeSelectCategories.bind(this)}
                                        onKeyPress={this.enterPressed.bind(this)}
                                    >
                                        <option
                                            value='default'
                                            disabled
                                        >
                                            Category of your stream
                                </option>
                                        {categories.map((item, index) => {
                                            return (
                                                <option
                                                    key={item.id || index}
                                                    value={item.id}
                                                >
                                                    {item.title}
                                                </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                        <button
                            className='btn waves-effect waves-light'
                            onClick={() => this.toggleJoin()}
                            disabled={!streamTitle.length || streamCategory && streamCategory === 'default' || joinCall}
                        >
                            Start streaming
                        {joinCall && <i className="fa fa-circle-o-notch fa-spin" />}
                        </button>
                    </div> :
                    <Loader
                        blackBg={true}
                        text="Connecting"
                    />
                }
                {!error && joinCall /*&& (eventItem)*/?
                    <StreamingVoxeet
                        isAdmin={isAdmin}
                        isListener={isListener}
                        isModerator={isModerator}
                        widgetMode={widgetMode}
                        knockId={knockId}
                        title={streamTitle}
                        category={streamCategory}
                        posterVideo={streamPoster && validLink(streamPoster) ? streamPoster : user && user.profile_picture1 ? user.profile_picture1 : user && user.profile_picture ? user.profile_picture : null}
                        simulcastMode={simulcastMode}
                        isDemo={isDemo}
                        configuration={!useDefaultSettings}
                        handleOnLeave={() => {
                            leave();
                        }}
                        userName={user && user.username ? user.username : ''}
                        photoURL={user && user.profile_picture2 ? user.profile_picture2 : user && user.profile_picture ? user.profile_picture : ''}
                        conferenceAlias={conferenceAlias}
                        history={this.props.history}
                        eventItem={eventItem}
                        fanoutState={fanoutState}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        categories: state.firebase.categories,
        firebaseInit: state.firebase.init,
        fanoutState: state.app.fanoutState,
        fanoutId: state.broadcast.fanoutId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        leave: () => {
            dispatch(nativePush('/'));
        }
    };
};

const StreamingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamingGuest);

export default StreamingContainer;
