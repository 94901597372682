import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { push as nativePush } from 'connected-react-router';
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import classNames from "classnames";
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient'
import * as appActions from "../../actions/app_actions";

class Rotator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goToSlide: 0,
            goToSlideDelay: 200,
            offsetRadius: process.env.platform && process.env.platform === 'mobile' || !props.desktop ? 1 : 5,
            showNavigation: false,
            config: config.gentle,
            videoList: [],
            mouseClientX: 0,
            ticketsLoaded: false
        }

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.setVideoList = this.setVideoList.bind(this);
    }

    componentDidMount() {
        const { videos, appStage, live } = this.props;


        if (appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { videos, appStage, live, teasers } = this.props;
        // console.log('live----------', live, videos)


        this.autoSlide();

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        if (this.playTimeout) {
            clearTimeout(this.playTimeout);
        }
    }

    handleMouseDown(e) {
        this.setState({
            mouseClientX: e.clientX
        });
    }

    handleMouseUp(e) {
        const { mouseClientX, goToSlide } = this.state;
        let upClientX = e.clientX;

        if (mouseClientX !== upClientX) {
            let slide;

            if (mouseClientX > upClientX) {
                slide = goToSlide && goToSlide === 4 ? 0 : goToSlide + 1;
            } else {
                slide = goToSlide && goToSlide === 0 ? 4 : goToSlide - 1
            }

            this.handleGoToSlide(slide);
            this.autoSlide();
        }
    }

    handleClickVideo(item, active, index) {
        if (active && item && item.type && item.id) {
            // this.handlePush(`/${item.type}/${item.id}`);
        } else {
            this.handleGoToSlide(index);
            this.autoSlide();
        }
    }


    handlePush(value, event) {
        const { pushToLink } = this.props;

        if (value && pushToLink) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            pushToLink(value);
        }
    }

    setVideoList(live, videos) {
        try {
            const { videoList, ticketsLoaded } = this.state;
            const { tickets, user, teasers } = this.props;
            const newVideoList = [];
            // console.log('teasers', teasers)
            // return;
            // First get from events with teasers
            if (live && live.length)
                for (let i = 0; i < live.length; i++) {
                    // if(!teasers || !teasers[live[i].id])
                    //     continue;
                    let index = newVideoList.length;
                    let item = live[i];
                    if (item.visibility != 'public')
                        continue;
                    if (firebaseClient.checkHidenIn(item, 'rotator'))
                        continue;
                    // Don't show some users
                    if (item.uid == 'XoIPAjeJG6WiPbUfCne7A5w0atj1')
                        continue;
                    let url = (!teasers || !teasers[item.id]) ? null : teasers[item.id];
                    // console.log('----found', index, item, url, item.id)
                    let slide = {
                        key: index,
                        content:
                            <Link
                                to={this.state.goToSlide === index ? `/event/${item.id}` : '#'}
                                className={classNames('video-wrapper', { 'active': this.state.goToSlide === index })}
                                onClick={() => this.handleClickVideo(item, this.state.goToSlide === index, index)}
                                onMouseDown={this.handleMouseDown}
                                onMouseUp={this.handleMouseUp}
                            >
                                <video
                                    id={`video${index}`}
                                    poster={item ? item.archiveCustomCover ? item.archiveCustomCover : item.poster ? item.poster : item.authorPic : null}
                                    autoPlay={false}
                                    controls={false}
                                    loop
                                    muted
                                    playsInline={true}
                                >
                                    <source type="video/webm" src={url} />
                                </video>
                                {item ?
                                    <div className='video-live'>
                                        <p>
                                            {/*item.status && item.type && item.status === 'live' && item.type === 'event' ?
                                            'live'
                                            :
                                            'archive'*/
                                                'live'
                                            }
                                        </p>
                                    </div>
                                    : null
                                }
                                <div className='video-details-wrapper'>
                                    <div className='video-details'>
                                        <p className='item-cat'>{item.cat}</p>
                                        <p className='item-title'>{item.title}</p>
                                        {ticketsLoaded && item && item.noOfTickets && item.uid && ((user && user.uid && user.uid !== item.uid) || (!user)) && !tickets[item.id] && !tickets[item.uid] ?
                                            <Button
                                                type='default'
                                                className='btn-rotator'
                                                onClick={(event) => this.handlePush(item && item.type && item.id ? `/event/${item.id}?checkout=1` : '/', event)}
                                            >
                                                {/* {`${item && item.price ? 'purchase' : 'reserve'} ticket`} */}
                                                watch
                                            </Button>
                                            : ticketsLoaded && item && (tickets[item.id] || tickets[item.uid]) ?
                                                <Button
                                                    type='default'
                                                    className='btn-rotator'
                                                    onClick={(event) => this.handlePush(item && item.type && item.id ? `/event/${item.id}` : '/', event)}
                                                >
                                                    watch
                                                </Button>
                                                : null
                                        }
                                        <span className='item-price'>
                                            {((item.type && item.type === 'stream') || (item.status && item.status === 'ended')) ?
                                                item.archivePrice ? `$${parseFloat(item.archivePrice)} USD` : 'FREE'
                                                : item.price ? `$${parseFloat(item.price)} USD` : 'FREE'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </Link>
                    }
                    newVideoList.push(slide);
                    if (newVideoList.length >= 5)
                        break;
                }
            // Object.entries(this.state.teasers).forEach(([eventId,videoURL]) => {
            // });
            // Then fill the rest with archived videos
            let five = [];
            if (videos && videos.slice && newVideoList.length < 5) {
                let publicVideos = videos.filter(item => item.visibility === 'public' && !firebaseClient.checkHidenIn(item, 'rotator') && item.archiveEnabled && item.parts && item.parts.length);
                five = publicVideos.slice(0, 5 - newVideoList.length);
            }
            five = five.filter(item => item.uid != 'XoIPAjeJG6WiPbUfCne7A5w0atj1');
            // else
            //     console.log('videos', videos)
            if (five && five.length)
                five.map((item, indexx) => {
                    let index = newVideoList.length;
                    let src = (!teasers || !teasers[item.id]) ? null : teasers[item.id];
                    let slide = {
                        key: index,
                        content:
                            <Link
                                to={this.state.goToSlide === index ? `/${item.type}/${item.id}` : '#'}
                                className={classNames('video-wrapper', { 'active': this.state.goToSlide === index })}
                                onClick={() => this.handleClickVideo(item, this.state.goToSlide === index, index)}
                                onMouseDown={this.handleMouseDown}
                                onMouseUp={this.handleMouseUp}
                            >
                                <video
                                    id={`video${index}`}
                                    src={src}
                                    poster={item ? item.archiveCustomCover ? item.archiveCustomCover : item.poster ? item.poster : item.authorPic : null}
                                    autoPlay={false}
                                    controls={false}
                                    loop
                                    muted
                                    playsInline={true}
                                />
                                {item ?
                                    <div className='video-live'>
                                        <p>
                                            {item.status && item.type && item.status === 'live' && item.type === 'event' ?
                                                'live'
                                                :
                                                'archive'
                                            }
                                        </p>
                                    </div>
                                    : null
                                }
                                <div className='video-details-wrapper'>
                                    <div className='video-details'>
                                        <p className='item-cat'>{item.cat}</p>
                                        <p className='item-title'>{item.title}</p>
                                        {ticketsLoaded && item && item.noOfTickets && item.uid && ((user && user.uid && user.uid !== item.uid) || (!user)) && !tickets[item.id] && !tickets[item.uid] ?
                                            <Button
                                                type='default'
                                                className='btn-rotator'
                                                onClick={(event) => this.handlePush(item && item.type && item.id ? `/${item.type}/${item.id}${item.type === 'event' ? '?checkout=1' : '?video=1'}` : '/', event)}
                                            >
                                                {/* {`${item && item.price ? 'purchase' : 'reserve'} ticket`} */}
                                                watch
                                            </Button>
                                            : ticketsLoaded && item && (tickets[item.id] || tickets[item.uid]) ?
                                                <Button
                                                    type='default'
                                                    className='btn-rotator'
                                                    onClick={(event) => this.handlePush(item && item.type && item.id ? `/${item.type}/${item.id}?video=1` : '/', event)}
                                                >
                                                    watch
                                                </Button>
                                                : null
                                        }
                                        <span className='item-price'>
                                            {((item.type && item.type === 'stream') || (item.status && item.status === 'ended')) ?
                                                item.archivePrice ? `$${parseFloat(item.archivePrice)} USD` : 'FREE'
                                                : item.price ? `$${parseFloat(item.price)} USD` : 'FREE'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </Link>
                    }
                    newVideoList.push(slide);
                });

            // console.log('----------------', newVideoList);
            // this.setState({
            //     videoList: newVideoList
            // }, () => {
            //     this.autoSlide();
            //     if (!videoList || !videoList.length) {
            //         this.handleGoToSlide(0)
            //     }
            // });
            return newVideoList;
        } catch (e) {
            console.error(e)
            return null;
        }
    }

    autoSlide() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            const { goToSlide } = this.state;

            let slideNumber = goToSlide && goToSlide === 4 ? 0 : goToSlide + 1;
            this.handleGoToSlide(slideNumber);
            this.autoSlide();
        }, 15000);
    }

    handleGoToSlide(value) {
        this.setState({
            goToSlide: value
        }, () => {
            const { goToSlide } = this.state;
            const { videos } = this.props;
            if (videos && videos.length && videos.length >= 5) {
                this.setVideoList(videos);
            }
            for (let i = 0; i < 5; i++) {
                if (i === goToSlide) {
                    if (this.playTimeout) {
                        clearTimeout(this.playTimeout);
                    }
                    this.playTimeout = setTimeout(() => {
                        let vid = document.getElementById(`video${i}`);
                        if (vid) {
                            vid.play().catch(e => { });
                        }
                    }, 3000);
                } else {
                    let vid = document.getElementById(`video${i}`);
                    if (vid && !vid.paused) {
                        vid.load();
                    }
                }
            }
        });
    }


    render() {
        const { goToSlide, goToSlideDelay, offsetRadius, showNavigation, config } = this.state;
        const { mobile, live, videos } = this.props;
        const videoList = this.setVideoList(live, videos);

        return (
            <div className={classNames('rotator-wrapper', { 'mobile': mobile })}>
                {/* <p className='rotator-text'>now on live</p> */}
                <div className='rotator'>
                    {videoList && videoList.length ?
                        <Carousel
                            slides={videoList}
                            goToSlide={goToSlide}
                            offsetRadius={offsetRadius}
                            goToSlideDelay={goToSlideDelay}
                            showNavigation={showNavigation}
                            animationConfig={config}
                        /> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        user: state.firebase.user,
        teasers: state.firebase.teasers,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
        desktop: state.app.user_agent_info.platform.type === 'desktop',
        videos: state.firebase.videos,
        live: state.firebase.live,
        tickets: state.mystuff.tickets,
        appStage: state.app.stage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToLink: (value) => {
            dispatch(nativePush(value));
        }
    };
};

const RotatorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Rotator);

export default RotatorContainer;
