import React, { Component } from "react";
import { connect } from 'react-redux';

class NoStreamingText extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { streaming, role } = this.props;

        return (
            <div>
                {(role && ((role === 'presenter') || (role === 'moderator'))) && !streaming ?
                    <div className='streaming-message-wrapp'>
                        <p>
                            When you are ready to start streaming, click on the Start Stream button below
                            <br />
                            <span>
                                Your event will be recorded automatically when you start streaming and will be available in My Stuff after the event ends
                            </span>
                        </p>
                    </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        streaming: state.room.streaming
    };
};

const NoStreamingTextContainer = connect(
    mapStateToProps
)(NoStreamingText);

export default NoStreamingTextContainer;
