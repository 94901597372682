import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import fanoutClient from "../../../utils/FanoutClient";
import * as roomActions from "../../../actions/room_actions";
import KnockImg from "../../../../resources/icons/Knock.svg";
import Button from '../../Widget/Button';

class KnocksButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knockArray: [],
            participantsArray: [],
            runningAnimation: false,
            inviteInput: '',
            liveInput: ''
        }
    }

    getListKnocks(knocks, accepted) {
        let filterJSON = {};

        Object.entries(knocks).filter(([key, value]) => {
            if (accepted && value.accepted) {
                filterJSON[key] = value;
            } else if (!accepted && !value.accepted) {
                filterJSON[key] = value;
            }

            return key;
        });

        return filterJSON;
    }

    componentDidMount() {
        const { inviteInput, liveInput } = this.state;
        const { knocks } = this.props;

        if (knocks && Object.keys(knocks).length && !inviteInput) {
            this.setState({
                knockArray: this.getListKnocks(knocks, false)
            });
        }

        if (knocks && Object.keys(knocks).length && !liveInput) {
            this.setState({
                participantsArray: this.getListKnocks(knocks, true)
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { inviteInput, liveInput } = this.state;
        const { knocks } = this.props;

        if (JSON.stringify(knocks) !== JSON.stringify(prevProps.knocks) && !inviteInput) {
            this.setState({
                knockArray: this.getListKnocks(knocks, false)
            });
        }

        if (JSON.stringify(knocks) !== JSON.stringify(prevProps.knocks) && !liveInput) {
            this.setState({
                participantsArray: this.getListKnocks(knocks, true)
            });
        }
    }

    handleChangeInviteInput(event) {
        const { knocks } = this.props;
        const inputValue = event.target.value;

        this.setState({
            inviteInput: inputValue
        });

        let array = this.getListKnocks(knocks, false);

        if (array && Object.keys(array).length && inputValue) {
            let filterJSON = {};
            let input = inputValue;
            Object.keys(array).filter((key) => {
                let itemName = array[key].name;
                itemName = itemName.toLowerCase();
                input = input.toLowerCase();
                if (itemName.indexOf(input) !== -1) {
                    filterJSON[key] = array[key];
                }
                return array[key];
            });
            this.setState({
                knockArray: filterJSON
            });
        } else {
            this.setState({
                knockArray: []
            });
        }
    }

    handleChangeLiveInput(event) {
        const { knocks } = this.props;
        const inputValue = event.target.value;

        this.setState({
            liveInput: inputValue
        });

        let array = this.getListKnocks(knocks, true);

        if (array && Object.keys(array).length && inputValue) {
            let filterJSON = {};
            let input = inputValue;
            Object.keys(array).filter((key) => {
                let itemName = array[key].name;
                itemName = itemName.toLowerCase();
                input = input.toLowerCase();
                if (itemName.indexOf(input) !== -1) {
                    filterJSON[key] = array[key];
                }
                return array[key];
            });
            this.setState({
                participantsArray: filterJSON
            });
        }  else {
            this.setState({
                participantsArray: []
            });
        }
    }

    handleShowKnocks() {
        const { showKnocksMenu, showKnocks } = this.props;
        if (showKnocksMenu) {
            showKnocksMenu(!showKnocks);
        }
    }

    handleChangeInviteUser(rid) {
        const { getCallState } = this.props;
        if (rid && getCallState) {
            fanoutClient.sendKnockGranted(rid, getCallState());
        }
    }

    handleChangeSwitchUser(rid) {
        const { getCallState } = this.props;
        if (rid && getCallState) {
            fanoutClient.sendKnockRevoked(rid, getCallState());
        }
    }

    render() {
        const { knockArray, participantsArray, runningAnimation, inviteInput, liveInput } = this.state;
        const { showKnocks, knocks } = this.props;



        return (
            <li className={classNames("toggle-knocks", { 'active': (showKnocks) || (knockArray && Object.keys(knockArray).length) || (participantsArray && Object.keys(participantsArray).length) }, { "menu-animation": runningAnimation })}>
                {showKnocks &&
                    <div className="bubble-tip knock-menu">
                        <a
                            className="icon-close"
                            title="Close"
                            onClick={this.handleShowKnocks.bind(this)}
                        />
                        <span className='title'>Knocks panel</span>
                        <div className='knocks-wrapper'>
                            <div className='knocks-div'>
                                <div className='title-text'>Knocks in queue</div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="invite-input"
                                    placeholder="Start typing to search"
                                    value={inviteInput}
                                    onChange={this.handleChangeInviteInput.bind(this)}
                                />
                                <div className={classNames('invite-users', { 'small': false })}>
                                    {inviteInput && knockArray && Object.keys(knockArray).length ?
                                        Object.keys(knockArray).map((key, index) => {
                                            if (knockArray[key] && (knockArray[key].name || knockArray[key].username)) {
                                                return (
                                                    <Button
                                                        type='border-white'
                                                        key={index}
                                                        className='a-invite'
                                                        onClick={() => this.handleChangeInviteUser(knockArray[key].rid)}
                                                    >
                                                        <h4 className="item-name">
                                                            {knockArray[key].name || knockArray[key].username}
                                                        </h4>
                                                        <i className="fa fa-plus-circle" />
                                                    </Button>
                                                )
                                            } else return null;
                                        })
                                        : !inviteInput && knocks && Object.keys(knocks).length ?
                                            Object.keys(knocks).map((key, index) => {
                                                if (knocks[key] && !knocks[key].accepted &&  (knocks[key].name || knocks[key].username)) {
                                                    return (
                                                        <Button
                                                            type='border-white'
                                                            key={index}
                                                            className='a-invite'
                                                            onClick={() => this.handleChangeInviteUser(knocks[key].rid)}
                                                        >
                                                            <h4 className="item-name">
                                                                {knocks[key].name || knocks[key].username}
                                                            </h4>
                                                            <i className="fa fa-plus-circle" />
                                                        </Button>
                                                    )
                                                } else return null;
                                            })
                                            : inviteInput ?
                                                <div className="no-user-text">No filtered users</div>
                                                :
                                                <div />
                                    }
                                </div>
                            </div>
                            <div className='knocks-div'>
                                <div className='title-text'>Guests</div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="invite-input"
                                    placeholder="Start typing to search"
                                    value={liveInput}
                                    onChange={this.handleChangeLiveInput.bind(this)}
                                />
                                <div className={classNames('invite-users', { 'small': false })}>
                                    {liveInput && participantsArray && Object.keys(participantsArray).length ?
                                        Object.keys(participantsArray).map((key, index) => {
                                            if (participantsArray[key] && (participantsArray[key].name || participantsArray[key].username)) {
                                                return (
                                                    <Button
                                                        type='border'
                                                        key={index}
                                                        className='a-invite'
                                                        onClick={() => this.handleChangeSwitchUser(participantsArray[key].rid)}
                                                    >

                                                        <h4 className="item-name">
                                                            {participantsArray[key].name || participantsArray[key].username}
                                                        </h4>
                                                        <i className="fa fa-minus-circle" />
                                                    </Button>
                                                )
                                            } else return null;
                                        })
                                        : !liveInput && knocks && Object.keys(knocks).length ?
                                        Object.keys(knocks).map((key, index) => {
                                            if (knocks[key] && knocks[key].accepted && (knocks[key].name || knocks[key].username)) {
                                                return (
                                                    <Button
                                                        type='border'
                                                        key={index}
                                                        className='a-invite'
                                                        onClick={() => this.handleChangeSwitchUser(knocks[key].rid)}
                                                    >

                                                        <h4 className="item-name">
                                                            {knocks[key].name || knocks[key].username}
                                                        </h4>
                                                        <i className="fa fa-minus-circle" />
                                                    </Button>
                                                )
                                            } else return null;
                                        })
                                        : liveInput ?
                                            <div className="no-user-text">No filtered users</div>
                                            :
                                            <div />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="anchor-popup" />
                    </div>}
                <a
                    data-tip
                    data-for="knocks"
                    title="Knocks"
                    onClick={this.handleShowKnocks.bind(this)}
                >
                    {knocks && Object.keys(knocks).filter((key, index) => !knocks[key].accepted).length ? <span className="attendees-number knockUsers">{Object.keys(knocks).filter((key, index) => !knocks[key].accepted).length}</span> : null}
                    {knocks && Object.keys(knocks).filter((key, index) => knocks[key].accepted).length ? <span className="attendees-number activeUsers">{Object.keys(knocks).filter((key, index) => knocks[key].accepted).length}</span> : null}
                    <img src={KnockImg} />
                    <div>
                        <span>Knocks</span>
                    </div>
                </a>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showKnocks: state.room.showKnocks,
        knocks: state.knocks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showKnocksMenu: (value) => {
            dispatch(roomActions.showKnocks(value));
        }
    };
};

const KnocksContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnocksButtonBottomBar);

export default KnocksContainer;
