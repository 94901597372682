import {
    KNOCKS_KNOCK_ACTION,
    KNOCKS_KNOCKED_ACTION,
    KNOCKS_ACCEPT_ACTION,
    KNOCKS_REJECT_ACTION,
    KNOCKS_REVOKE_ACTION,
    CLEAR_KNOCKS_ACTION
} from '../actions/knocks_actions';

const initialState = {};

const knocks = (state = initialState, action) => {
    switch (action.type) {
        case KNOCKS_KNOCKED_ACTION: {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock action - no valid payload', action.payload)
                return state;
            }
            let update = { [action.payload.rid]: action.payload }
            update[action.payload.rid].mine = true;
            return Object.assign({}, state, update);
        }
        case KNOCKS_KNOCK_ACTION: {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock action - no valid payload', action.payload)
                return state;
            }
            let update = { [action.payload.rid]: action.payload }
            return Object.assign({}, state, update);
        }
        case KNOCKS_ACCEPT_ACTION: {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock accept - no valid payload', action.payload)
                return state;
            }
            let knock = state[action.payload.rid];
            if (!knock) {
                console.warn('Could not find knock data');
                return state;
            }

            let knocksState = {
                ...state
            };
            
            knocksState[action.payload.rid].accepted = true;

            return knocksState;
        }
        case KNOCKS_REJECT_ACTION: {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock reject - no valid payload', action.payload)
                return state;
            }
            // Remove it
            const next = { ...state };
            delete next[action.payload.rid];
            return next;
        }
        case KNOCKS_REVOKE_ACTION: {
            if (!action.payload || !action.payload.rid) {
                console.warn('knock revoke - no valid payload', action.payload)
                return state;
            }
            // Remove it
            const next = { ...state };
            delete next[action.payload.rid];
            return next;
        }
        case CLEAR_KNOCKS_ACTION: {
            return Object.assign({}, initialState);
        }
        default:
            return state;
    }
};

export default knocks;