import React, { Component } from 'react';
import { connect } from "react-redux";
import Copyright from './Copyright';
// import facebookIcon from "../../../resources/icons/nav/facebook.png";
// import instagramIcon from "../../../resources/icons/nav/instagram.png";
// import twitterIcon from "../../../resources/icons/nav/twitter.png";
import { stringToBoolean } from '../../utils/text';

class Footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            supportSubscription: false,
            supportHosting: false
        }
    }

    componentDidMount() {
        const { history } = this.props;
        if (history) {
            history.listen((location) => {
                if (location && location.pathname && this.props.user && this.props.user.uid) {
                    this.setState({
                        supportSubscription: location.pathname.indexOf('/mystuff') !== -1,
                        supportHosting: location.pathname.indexOf('/edit-profile') !== -1
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && this.props.user && this.props.user.uid && window && window.location && window.location.pathname) {
            this.setState({
                supportSubscription: window.location.pathname.indexOf('/mystuff') !== -1,
                supportHosting: window.location.pathname.indexOf('/edit-profile') !== -1
            });
        }
    }

    render() {
        const { supportSubscription, supportHosting } = this.state;
        const { subscribedToHosts, user, hideNav } = this.props;
        return (
            <footer className="footer">
                {(!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) && (supportSubscription && subscribedToHosts && subscribedToHosts.length || supportHosting && user && user.broadcaster) ?
                    <div className='footer-unsubscribe'>
                        To unsubscribe, please contact <a target="_blank" href="https://www.nofilter.net/help#ContactUs">support</a>.
                    </div>
                    : null}
              
                {/* <div className='follow-icons'>
                    <a onClick={hideNav} href="https://www.facebook.com/nofilternetwork/" target="_blank" rel="noopener noreferrer" className="detail">
                        <img src={facebookIcon} loading='lazy' />
                    </a>
                    <a onClick={hideNav} href="https://www.instagram.com/nofilternet/" target="_blank" rel="noopener noreferrer" className="detail">
                        <img src={instagramIcon} loading='lazy' />
                    </a>
                    <a onClick={hideNav} href="https://twitter.com/NoFilterNet/" target="_blank" rel="noopener noreferrer" className="detail">
                        <img src={twitterIcon} loading='lazy' />
                    </a>
                </div> */}
                <div className='footer-links'>
                    <div className='links-item'>
                        <a href="https://www.nofilter.net/help">Help</a>
                    </div>
                    <div className='links-item'>
                        <a href="https://www.nofilter.net/help#ContactUs">Contact Us</a>
                    </div>
                    <div className='links-item'>
                        <a href="https://www.nofilter.net/privacy-policy">Privacy Policy</a>
                    </div>
                    <div className='links-item'>
                        <a href="https://www.nofilter.net/terms">Terms of Service</a>
                    </div>
                </div>
                <Copyright />
            </footer>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        subscribedToHosts: state.mystuff.subscribedToHosts,
    };
};

const FooterContainer = connect(
    mapStateToProps,
    null // mapDispatchToProps
)(Footer);

export default FooterContainer;