import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import Cookies from "js-cookie";
import { push as nativePush } from 'connected-react-router';
import fanoutClient from '../../utils/FanoutClient';
import InboundVideoButtonBottomBar from './Common/InboundVideoButtonBottomBar';
import AttendeesListFanoutButton from './Voxeet/AttendeesListFanoutButton';
import ResizableRosterFanout from './Voxeet/ResizableRosterFanout';
import HangUpButtonFanout from './Fanout/HangUpButtonFanout';
import FanoutPresentationView from './Fanout/FanoutPresentationView';
import KnockButtonBottomBar from './Voxeet/KnockButtonBottomBar';
import TipButtonBottomBar from './Voxeet/TipButtonBottomBar';
import Loader from '../Widget/Loader';
import RoomMessage from "../Room/RoomMessage";
import loadingGif from "../../../resources/images/loading.jpg";
import nfbPoster from '../../../resources/images/NFB-BG.png';
import { Defines } from '../../utils/FanoutDefines';
import { Device } from "mediasoup-client";
import * as MediasoupClient from "./Fanout/mediasoup-client.js";
import * as roomActions from "../../actions/room_actions";
import * as chatActions from "../../actions/chat_actions";
import { isIOS } from '../../utils/text';
import { v1 as uuidv1 } from 'uuid';
import canAutoPlay from 'can-autoplay';

const initialState = {
  videoSource: null,
  displayAttendeesList: false,
  rosterOpened: false,
  noTicketModal: false,
  videoPresentation: false,
  videoURL: '',
  videoPlaying: false,
  videoTS: 0,
  participantBarType: 'layout',
  participantBarContent: [],
  participantBarActive: null,
  swapActive: false,
  videoWidth: 0,
  videoHeight: 0,
  videoDuration: 0,
  videoPlayerRef: null,
  activeParticipant: null,
  showLabel: false,
  ready: false,
  canPlay: false,
  showPlayButton: false,
  loadingVideo: false,
  videoLoaded: false,
  conferenceAlias: null,
  videoResumeTime: 0,
  iphonePlay: false
}

class StreamingFanout extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.sessionEnded = false;

    this.sessionId = uuidv1();

    this.audioConsumer = null;
    this.videoConsumer = null;
    this.remoteMediaStream = null;
    this.recvTransport = null;
    this.remoteVideoId = null;
    this.remoteAudioId = null;
    this.dataProducer = null;
    this.dataConsumers = new Map();
    this.initialDataProducers = null;
    this.dataProducerCallback = null;
    this.dataProducerErrback = null;

    this.previousFanoutState = Defines.Fanout.Status.Starting;

    this.MediasoupDevice = new MediasoupClient.Device();
    this.sendMessage = this.sendMessage.bind(this);
    this.handleFanoutStateChanged = this.handleFanoutStateChanged.bind(this);
    this.handleCreateRoomResponse = this.handleCreateRoomResponse.bind(this);
    this.handleCreateTransportResponse = this.handleCreateTransportResponse.bind(this);
    this.handleConsumeResponse = this.handleConsumeResponse.bind(this);
    this.handleConsumeDataResponse = this.handleConsumeDataResponse.bind(this);
    this.handleProduceDataResponse = this.handleProduceDataResponse.bind(this);
    this.handleNewProducerResponse = this.handleNewProducerResponse.bind(this);
    this.handleNewDataProducerResponse = this.handleNewDataProducerResponse.bind(this);
    this.handlePeerClosedResponse = this.handlePeerClosedResponse.bind(this);
    this.handleConsumeResume = this.handleConsumeResume.bind(this);
    this.handleChatMessage = this.handleChatMessage.bind(this);
    this.getCallState = this.getCallState.bind(this);

    this.toggleList = this.toggleList.bind(this);
    this.backToForm = this.backToForm.bind(this);
    this.exitFanout = this.exitFanout.bind(this);
    this.handleSwap = this.handleSwap.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleLoadedData = this.handleLoadedData.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleSpeakerLayoutMessage = this.handleSpeakerLayoutMessage.bind(this);
    this.handleVideoPresentationMessage = this.handleVideoPresentationMessage.bind(this);
    this.setupRoom = this.setupRoom.bind(this);
    this.cleanupRoom = this.cleanupRoom.bind(this);
    this.reconnectRoom = this.reconnectRoom.bind(this);
    this.enableRemoteVideo = this.enableRemoteVideo.bind(this);
    this.reconnectMediasoup = this.reconnectMediasoup.bind(this);

    // TODO change this after to get rid of hardcoding and instead request ip from server
    fanoutClient.on('fanoutStateChanged', this.handleFanoutStateChanged);
    fanoutClient.on('createRoomResponse', this.handleCreateRoomResponse);
    fanoutClient.on('createTransportResponse', this.handleCreateTransportResponse);
    fanoutClient.on('consumeResponse', this.handleConsumeResponse);
    fanoutClient.on('consumeDataResponse', this.handleConsumeDataResponse);
    fanoutClient.on('produceDataResponse', this.handleProduceDataResponse);
    fanoutClient.on('newProducerResponse', this.handleNewProducerResponse);
    fanoutClient.on('newDataProducerResponse', this.handleNewDataProducerResponse);
    fanoutClient.on('peerClosedResponse', this.handlePeerClosedResponse);
    fanoutClient.on('consumeResume', this.handleConsumeResume);
    fanoutClient.on('chatMessage', this.handleChatMessage);
    fanoutClient.on('speakerLayout', this.handleSpeakerLayoutMessage);
    fanoutClient.on('videoPresentation', this.handleVideoPresentationMessage);
    fanoutClient.on('reconnect', this.reconnectRoom);
    fanoutClient.on('enableRemoteVideo', this.enableRemoteVideo);

    this.videoRef = React.createRef();

    this.remoteVideoEnabled = true;
  }

  handleResize() {
    if (this.videoRef && this.videoRef.current && this.videoRef.current.offsetWidth && this.videoRef.current.offsetHeight) {
      this.setState({
        videoWidth: this.videoRef.current.offsetWidth,
        videoHeight: this.videoRef.current.offsetHeight
      });
    }
  }

  async handleLoadedData() {
    const { iphonePlay } = this.state;
    if (this.videoRef && this.videoRef.current) {
      await this.videoRef.current.play()
        .then(() => {
          this.setState({
            loadingVideo: false,
            videoLoaded: true
          }, () => {
            if (isIOS() && !iphonePlay) {
              this.setState({
                iphonePlay: true
              }, () => {
                this.videoRef.current.load();
                this.videoRef.current.play();
              });
            }
          });
        })
        .catch(e => console.error(e));
      //this.videoRef.current.muted = false;
    }
  }

  async setupRoom() {

    this.sessionEnded = false;

    this.sessionId = uuidv1();

    this.audioConsumer = null;
    this.videoConsumer = null;
    this.remoteMediaStream = null;
    this.recvTransport = null;
    this.remoteVideoId = null;
    this.remoteAudioId = null;
    this.dataProducer = null;
    this.dataConsumers = new Map();
    this.initialDataProducers = null;
    this.dataProducerCallback = null;
    this.dataProducerErrback = null;
    this.consumerData = {};

    this.previousFanoutState = Defines.Fanout.Status.Starting;

    this.MediasoupDevice = new Device();
    this.setState({ ...initialState });

    let payload = { videoCodec: "VP8" };
    fanoutClient.updateSignallingServer('create-room', payload, this.getCallState()).then((res) => {
      fanoutClient.updateSignallingServer('chat-history', {}, this.getCallState()).then((res) => { });
    });
    console.log("FanoutRoom ComponentDidMount called, sending create-room request sessionId:", this.sessionId, this.props);
    const { eventId, eventItem } = this.props;

    let conferenceAlias = eventId ? eventId : null

    this.setState({
      conferenceAlias: conferenceAlias
    });

    if (this.videoRef && this.videoRef.current && eventItem) {
      this.videoRef.current.poster = eventItem && eventItem.branding && eventItem.branding.backgroundLobbyAudience ? eventItem.branding.backgroundLobbyAudience : eventItem && eventItem.logo ? eventItem.logo : nfbPoster;
    }

    this.setAudioDevice();
    this.handleCanAutoPlay();
  }

  async componentDidMount() {
    const { streaming, displayMessage, eventItem } = this.props;

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (this.videoRef && this.videoRef.current) {
      if (eventItem) {
        this.videoRef.current.poster = eventItem && eventItem.archiveCustomCover ? eventItem.archiveCustomCover
          : eventItem && eventItem.poster ? eventItem.poster
            : eventItem && eventItem.authorPic ? eventItem.authorPic
              : null;
      }
    }

    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.addEventListener('loadeddata', this.handleLoadedData);
      this.videoRef.current.addEventListener('pause', this.handleVideoPaused);
    }

    if (!streaming && displayMessage) {
      displayMessage('The event will begin soon', null, 'top');
    }

    this.setupRoom();
  }

  handleVideoPaused() {
    console.log('Video paused. Will try to play it again ');
    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.play();
    }
  }

  handlePlay() {
    this.setState({
      canPlay: true,
      loadingVideo: true,
      showPlayButton: false
    }, () => {
      this.handleVideoReceived();
    });
  }

  async handleCanAutoPlay() {

    let APVideo = await canAutoPlay.video({ inline: true }).then(({ result }) => result);
    let APAudio = await canAutoPlay.audio().then(({ result }) => result);

    console.log('handleCanAutoPlay result', APVideo, APAudio);

    if (APVideo && APAudio) {
      this.setState({
        canPlay: true
      })
    } else {
      this.setState({
        canPlay: true
      })
    }

  }

  checkTimeoutResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = setTimeout(() => {
      this.handleResize();
    }, 250);
  }

  componentDidUpdate(prevProps, prevState) {
    const { ready, videoSource, canPlay, rosterOpened } = this.state;
    const { eventItem, orientation, streaming, displayMessage, hideMessage, roomMessage } = this.props;

    if (eventItem && prevState.eventItem !== eventItem && this.videoRef && this.videoRef.current) {
      this.videoRef.current.poster = eventItem && eventItem.archiveCustomCover ? eventItem.archiveCustomCover
        : eventItem && eventItem.poster ? eventItem.poster
          : eventItem && eventItem.authorPic ? eventItem.authorPic
            : null;
    }
    if (prevProps.streaming !== streaming) {
      if (streaming && hideMessage && roomMessage && roomMessage.displayRoomMessage) {
        hideMessage();
      } else if (!streaming && streaming !== null && displayMessage) {
        displayMessage('Please wait', null, 'top');
      }
    }
    if (prevProps.orientation && orientation && prevProps.orientation !== orientation || canPlay && prevState.canPlay !== canPlay) {
      this.checkTimeoutResize();
    }
    if (prevState.rosterOpened !== rosterOpened) {
      this.refreshLabels();
      this.checkTimeoutResize();
    }
    if (!ready && videoSource) {
      this.setState({
        ready: true
      }, () => {
        this.handleVideoReceived();
      });
    }
  }

  refreshLabels() {
    this.setState({
      showLabel: false
    }, () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setState({
          showLabel: true
        });
      }, 1000);
    });
  }

  async setAudioDevice() {
    let output = Cookies.get('output');

    if (typeof output === 'string' || output instanceof String) {
      try {
        output = JSON.parse(output);
      } catch (e) {
          console.warn('Parse Output Cookies Error', e);
      }
  }


    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(device => device.kind === 'audiooutput');
    if (output && output.deviceId && this.videoRef && this.videoRef.current && audioDevices && audioDevices.length) {
      audioDevices.map((audioDevice) => {
        if (audioDevice && audioDevice.deviceId && audioDevice.deviceId === output.deviceId) {
          this.videoRef.current.setSinkId(audioDevice.deviceId);
        }
        return null;
      });
    }
  }

  /**
   * Keep latest and previous fanout state
   * @param state
   */
  handleFanoutStateChanged(state) {
    try {
      console.log("handleFanoutStateChange", state);

      // check if this.sessionClosed or whatever the flag is if not then set reconnecting
      if (state !== Defines.Fanout.Status.Running &&
        this.previousFanoutState === Defines.Fanout.Status.Running && !this.sessionEnded)
        console.warn("Reconnecting");

      if (this.previousFanoutState != state)
        this.previousFanoutState = state;
    }
    catch (err) {
      console.error(err);
    }
  }

  getCallState() {
    const { eventId, name, uid, username } = this.props;

    if (eventId) {
      console.log('getCallState', {
        fanoutId: process.env.fanoutId,
        username: username ? username : (name ? name : 'Unknown'),
        uid: uid ? uid : null,
        routerId: this.routerId,
        sessionId: this.sessionId,
        conferenceAlias: eventId,
      });
      return {
        fanoutId: process.env.fanoutId,
        username: username ? username : (name ? name : 'Unknown'),
        uid: uid ? uid : null,
        routerId: this.routerId,
        sessionId: this.sessionId,
        conferenceAlias: eventId,
      }
    } else {
      return null;
    }
  }

  async handleCreateRoomResponse(data) {
    console.log('handleCreateRoomResponse() RoomId:%s RouterId:%s', this.props.conferenceAlias, data.routerId);
    if (!this.MediasoupDevice.loaded) {
      await this.MediasoupDevice.load({ routerRtpCapabilities: data.payload.roomRtpCapabilities });
      console.log("Mediasoup Client Device Loaded");
    }
    else
      console.log("Mediasoup Client Device Already Loaded");
    // Set the ID of the router to which we are connected on the Fanout Server
    this.routerId = data.routerId;

    // Get ID's of any present Audio/Video Producers on the Fanout Server
    if (data.payload.video)
      this.remoteVideoId = data.payload.video;
    if (data.payload.audio)
      this.remoteAudioId = data.payload.audio;

    // Create transport
    fanoutClient.updateSignallingServer('create-transport', { type: "recv" }, this.getCallState());
  }

  async handleCreateTransportResponse(data) {
    console.log('handleCreateTransportResponse() transportData:', data.payload.transportData, "type:", data.payload.type);
    let transport = null;
    if (data.payload.type == "recv")
      transport = await this.createRecvTransport(data.payload.transportData);
    else {
      console.warn('Skip send transport')
      return;
    }
    console.log(transport);
    transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      console.log('Transport::connect [direction:%s]', transport.direction);
      try {
        let payload = { dtlsParameters, transportId: transport.id };
        fanoutClient.updateSignallingServer('connect-transport', payload, this.getCallState());
        callback();
      }
      catch (error) {
        console.log("erroooorr in handleCreateTransportResponse");
        errback(error);
      }
    });

    transport.on('connectionstatechange', (state) => {
      console.log("Transport::OnConnectionStateChange", state, transport.direction);
      if (state === "closed") {
        console.warn("Transport", transport.id, state, "Shutting Down Mediasoup for", transport.direction);
        const type = (transport.direction === "recv") ? Defines.Fanout.Signalling.Transport.Recv : Defines.Fanout.Signalling.Transport.Send;
        if (!this.sessionEnded) {
          fanoutClient.deleteSignallingClientEntry(this.getCallState());
          this.destroyMediasoup(type);
          setTimeout(() => {
            try {
              let payload = { videoCodec: "VP8" };
              fanoutClient.updateSignallingServer('create-room', payload, this.getCallState());
            }
            catch (error) {
              console.error("Error restarting the PeerConnection on DTLS close", error);
            }
          }, 500);
        }
        else
          this.destroyMediasoup(type);
      } else if (state === "disconnected") {
        console.warn("Transport", transport.id, state, "Shutting Down Mediasoup for", transport.direction);
        const type = (transport.direction === "recv") ? Defines.Fanout.Signalling.Transport.Recv : Defines.Fanout.Signalling.Transport.Send;
        this.destroyMediasoup(type);
      }
    });
  };

  /********** Create WebRTC Transport for Incoming Data + Media **************/
  async createRecvTransport(transportData) {

    console.log('createRecvTransport()');
    this.recvTransport = await this.MediasoupDevice.createRecvTransport(transportData);

    if (this.remoteVideoId !== null && this.remoteVideoEnabled) {
      let payload = {
        producerPeerId: Defines.Fanout.VirtualUser,
        producerId: this.remoteVideoId, rtpCapabilities:
          this.MediasoupDevice.rtpCapabilities,
        transportId: this.recvTransport.id, kind: "Video"
      }
      fanoutClient.updateSignallingServer('consume', payload, this.getCallState());
    }
    if (this.remoteAudioId !== null) {
      let payload = {
        producerPeerId: Defines.Fanout.VirtualUser,
        producerId: this.remoteAudioId, rtpCapabilities:
          this.MediasoupDevice.rtpCapabilities,
        transportId: this.recvTransport.id, kind: "Audio"
      }
      fanoutClient.updateSignallingServer('consume', payload, this.getCallState());
    }
    if (false && this.initialDataProducers && this.initialDataProducers.size !== 0) {
      for (const val of this.initialDataProducers) {
        let payload = {
          producerPeerId: val[0], transportId: this.recvTransport.id,
          dataConsumerOptions: { dataProducerId: val[1] }
        }
        fanoutClient.updateSignallingServer('data-consume', payload, this.getCallState());
      }
    }
    return this.recvTransport;
  };

  /********** Handle Consumption for Incoming Media ************/
  handleNewProducerResponse(data) {

    if (this.recvTransport) {
      console.log('handleNewProducerResponse() exist recvTransport [producerData:%o]', data.payload.producerData);
      let producerData = data.payload.producerData;
      let payload = {
        producerPeerId: producerData.peerId, producerId: producerData.producerId,
        rtpCapabilities: this.MediasoupDevice.rtpCapabilities, transportId: this.recvTransport.id
      }
      fanoutClient.updateSignallingServer('consume', payload, this.getCallState());
    }
    else {
      console.log("handleNewProducerResponse() don't exist recvTransport [producerData:%o]", data.payload.producerData);
      if (data.payload.producerData.kind === "video")
        this.remoteVideoId = data.payload.producerData.producerId;
      else if (data.payload.producerData.kind === "audio")
        this.remoteAudioId = data.payload.producerData.producerId;
    }
  }

  async handleConsumeResponse(data) {
    console.log('handleConsumeResponse() consumerData:', data.payload.consumerData);
    const { consumerId, kind, producerId, rtpParameters } = data.payload.consumerData;
    const isPaused = (data.payload.paused === 'true');
    this.consumerData[kind] = { consumerId, producerId, rtpParameters };

    if (!this.remoteMediaStream)
      this.remoteMediaStream = new MediaStream();
    try {
      if (kind === 'video') {
        this.videoConsumer = await this.recvTransport.consume({
          id: consumerId,
          kind, producerId, rtpParameters
        });
        this.videoConsumer.on("transportclose", () => {
          console.log("Video Consumer is closed:", this.videoConsumer.closed);
          this.videoConsumer = null;
        });

        console.log('this.videoConsumer.track', this.videoConsumer.track)
        this.remoteMediaStream.addTrack(this.videoConsumer.track);
      }
      else {
        this.audioConsumer = await this.recvTransport.consume({
          id: consumerId,
          kind, producerId, rtpParameters
        });
        this.audioConsumer.on("transportclose", () => {
          console.log("Audio Consumer is closed:", this.audioConsumer.closed);
          this.audioConsumer = null;
        });

        console.log('this.audioConsumer.track', this.audioConsumer.track)
        this.remoteMediaStream.addTrack(this.audioConsumer.track);
      }
    } catch (error) {
      console.error('failed to consume [kind:%s, error:%o]', kind, error);
      throw error;
    }

    console.log("This is the mediastream", this.remoteMediaStream.getVideoTracks(), this.remoteMediaStream.getAudioTracks());

    if (isPaused) {
      let payload = { consumerId };
      fanoutClient.updateSignallingServer('consume-resume', payload, this.getCallState());
    }
    else {
      this.handleVideoReceived();
    }
  };

  async handleConsumeResume(data) {
    if (this.videoConsumer && this.videoConsumer.id === data.payload.consumerId) {
      console.log("Setting Video To Resume", this.videoConsumer.id);
      //this.videoConsumer.resume();
      if (!this.state.canPlay) {
        this.setState({
          showPlayButton: true
        });
      }
    }
    else if (this.audioConsumer && this.audioConsumer.id === data.payload.consumerId) {
      console.log("Setting Audio To Resume", this.audioConsumer.id);
      //this.audioConsumer.resume();
    }
    else {
      console.log("handleConsumeResume something went awry, returning");
      return;
    }

    this.handleVideoReceived();
  }

  /*********** Handle Consumption for Incoming Data ************/
  handleNewDataProducerResponse(data) {
    console.log('handleNewDataProducerResponse() producerData:', data.payload.producerData);
    let producerData = data.payload.producerData;
    if (producerData.kind.includes("data")) {
      console.warn('Will skip response to data producer');
      return;
    }
  }

  async handleConsumeDataResponse(data) {
    console.warn('About to ignore handleConsumeDataResponse', data);
    // return;
    console.log('handleConsumeDataResponse', data);
    const { id, dataProducerId, peerId, sctpStreamParameters } = data.payload.dataConsumerOptions;
    const dataConsumer = await this.recvTransport.consumeData({ id, dataProducerId, sctpStreamParameters });

    dataConsumer.on("transportclose", () => {
      console.log("dataConsumerOnTransport Closed for", dataConsumer.id, "is closed:", dataConsumer.closed);
      this.dataConsumers.delete(dataConsumer.id);
    });
    dataConsumer.on("open", () => {
      console.debug("dataConsumer Opened", dataConsumer.id, peerId);
      // Send consume ack with minor delay to allow for data channel to be ready for data
      // We only want to send it for the data consumer for the Electron window producer
      if (peerId === Defines.Fanout.VirtualUser) {
        setTimeout(() => {
          console.log("dataConsumer for mainProducer sending data-consuming ack");
          try {
            let payload = { producerPeerId: peerId };
            fanoutClient.updateSignallingServer('data-consume-ack', payload, this.getCallState());
          }
          catch (error) {
            console.error("Error sending data-consuming request", error);
          }
        }, 500);
      }
    });
    dataConsumer.on("message", (data) => {
      console.debug("dataConsumer onMessage ", dataConsumer.id, data);
      return null;
    });
    this.dataConsumers.set(dataConsumer.id, dataConsumer);
  }

  swapContentItem(content) {
    const { participantBarContent } = this.state;

    if (content && content.length && participantBarContent && participantBarContent.length) {

      let contentItem = content[0];
      let newArray = [];

      participantBarContent.map((item) => {
        if (item && item.dx && item.dy && contentItem && contentItem.dx && contentItem.dy
          && item.dx === contentItem.dx && item.dy === contentItem.dy) {
          newArray.push(contentItem);
        } else {
          newArray.push(item);
        };
      });

      return newArray;
    } else {
      return participantBarContent;
    }
  }

  deleteContentItem(content) {
    const { participantBarContent } = this.state;

    if (content && content.length && participantBarContent && participantBarContent.length) {

      let contentItem = content[0];
      let newArray = [];

      newArray = participantBarContent.filter((item) => {
        if (item && item.name && contentItem && contentItem.name && item.name !== contentItem.name) {
          return item;
        } else return null;
      });

      return newArray;
    } else {
      return participantBarContent;
    }
  }

  handleChatMessage(data) {
    const { addMessage } = this.props;
    const dataObj = (data.payload);
    const { title } = dataObj;
    console.log('Got chat message');

    if (title && title === Defines.Fanout.DataChannel.Chat && addMessage) {
      console.log('Handling chat message');
      addMessage(dataObj);
    }
  }

  handleSpeakerLayoutMessage(data) {
    console.log('Got datachannel message:', data);
    const { type, content } = data.layout;

    console.log("Speaker_Metadata Event:", type, content);
    switch (type) {
      case Defines.Fanout.Events.Metadata.Types.Layout:
        this.setState({
          participantBarType: type,
          participantBarContent: content && content.length ? content : [],
          activeParticipant: content && content.length ? content[0] : null,
          showLabel: false
        }, () => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.setState({
              showLabel: true
            });
          }, 1000);
        });
        break;
      case Defines.Fanout.Events.Metadata.Types.Swap:
        this.setState({
          participantBarType: type,
          participantBarContent: this.swapContentItem(content),
        });
        break;
      case Defines.Fanout.Events.Metadata.Types.Stop:
        this.setState({
          participantBarType: type,
          participantBarContent: this.deleteContentItem(content),
        });
        break;
      case Defines.Fanout.Events.Metadata.Types.Active:
        this.setState({
          participantBarType: type,
          activeParticipant: content && content.length ? content[0] : null
        });
        break;
      default:
        console.log('Speaker_Metadata default', type, content);
        break;
    }
  }

  handleVideoPresentationMessage(data) {

    console.log('Got VideoPresentation message:', data);
    const { time } = data;
    const { type, content, name } = data.videoUpdate;

    console.log("VideoUpdate Event:", type, name, content);
    switch (type) {
      case Defines.DolbyEvents.VideoPresentation.Started:
      case Defines.DolbyEvents.VideoPresentation.Sought: {
        let { timestamp, url } = content;
        if (time) {
          // Add offset if user gets status right after joining the call
          let delta = Date.now() - time;
          console.log('Found offset for video update', delta);
          timestamp += delta;
        }
        this.setState({
          videoPresentation: true,
          videoURL: url,
          videoPlaying: true,
          videoTS: timestamp,
          videoDuration: parseInt(timestamp ? timestamp / 1000 : 0) || 0
        }, () => {
          this.handleVideoReceived();
        });
      }
        break;
      case Defines.DolbyEvents.VideoPresentation.Paused:
        this.setState({
          videoPresentation: true,
          videoURL: content.url,
          videoPlaying: false,
          videoTS: content.timestamp,
          videoDuration: parseInt(content.timestamp ? content.timestamp / 1000 : 0) || 0
        }, () => {
          this.handleVideoReceived();
        });
        break;
      case Defines.DolbyEvents.VideoPresentation.Played:
        this.setState({
          videoPresentation: true,
          videoURL: content.url,
          videoPlaying: true,
          videoTS: content.timestamp,
          videoDuration: parseInt(content.timestamp ? content.timestamp / 1000 : 0) || 0
        }, () => {
          this.handleVideoReceived();
        });
        break;
      case Defines.DolbyEvents.VideoPresentation.Stopped:
        this.setState({
          videoPresentation: false,
          videoURL: '',
          videoPlaying: false,
          videoTS: 0,
          videoDuration: 0
        }, () => {
          this.handleVideoReceived();
          this.refreshLabels();
          setTimeout(() => {
            this.handleResize();
          }, 250);
        });
        break;
      default:
        console.log('Video_Update default', type, content);
        break;
    }
  }

  /*
   * @brief: Handle response from Fanout server indicating that data production has started
   *         In other words data producer object has been created in the server.
   * param: data - Information about data producer created on server
   */
  handleProduceDataResponse(data) {
    console.warn("About to ignore producedata response received from server", data);

    try {
      console.log("producedata response received from server", data);
      this.dataProducerCallback({ id: data.payload.dataProducerId });
    }
    catch (error) {
      this.dataProducerErrback(error);
      console.error("FAAILLED handleProduceDataResponse");
    }
    this.dataProducerCallback = null;
    this.dataProducerErrback = null;
  }

  /*
   * @brief: Shut down all the active mediasoup entities
   * @param: type - indicates whether shutting down for incoming/outgoing data/media
   */
  async destroyMediasoup(type) {
    console.log("destroyMediasoup", type);
    if (!type)
      return;

    if (type & Defines.Fanout.Signalling.Transport.Recv) {
      if (this.recvTransport && !this.recvTransport.closed) {
        try {
          await this.recvTransport.close();
        } catch (e) { }
        this.recvTransport = null;
      }
    }
    if (type & Defines.Fanout.Signalling.Transport.Send) {
      if (this.sendTransport && !this.sendTransport.closed) {
        try {
          await this.sendTransport.close();
        } catch (e) { }
        this.sendTransport = null;
      }
    }
  };

  /*
   * @brief: Destroy the session, which entails all mediasoup entities, removing ref and
   *         getting rid of incoming table in fbrtdb
   */
  destroySession() {
    if (this.remoteMediaStream && this.remoteMediaStream.getTracks) {
      console.log("FanoutRoom stopping tracks");
      for (let track of this.remoteMediaStream.getTracks()) {
        try {
          track.stop();
        } catch (e) {
          // NOP
        }
      }
    }
    this.remoteMediaStream = null;
    if (this.state.videoSource && this.state.videoSource.getTracks) {
      console.log("FanoutRoom stopping tracks");
      for (let track of this.state.videoSource.getTracks()) {
        try {
          track.stop();
        } catch (e) {
          // NOP
        }
      }
    }

    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.srcObject = null;
      this.videoRef.current.load();
    }

    this.setState({
      videoSource: null,
      loadingVideo: false,
      videoLoaded: false
    }, () => {
      console.log("FanoutRoom session destoyed");
    });
  };

  sendMessage(message) {
    const { username, name, uid } = this.props;

    if (message) {
      const chat = {
        title: Defines.Fanout.DataChannel.Chat,
        content: message,
        time: Date.now(),
        type: "text",
        name: username ? username : (name ? name : 'Unknown'),
        ownerId: uid ? uid : '',
        avatarUrl: null
      };
      fanoutClient.sendChatMessage(JSON.stringify(chat), this.getCallState());
    }
  }

  /*
   * @brief: Update the videoSource and set the state to re-render the video element
   *         setting the media stream for the video object
   */
  handleVideoReceived() {
    const { ready, canPlay, videoLoaded } = this.state;
    console.log("handleVideoReceived ready:%s canPlay:%s videoLoad:%s", ready, canPlay, videoLoaded);

    this.setState({ videoSource: this.remoteMediaStream }, () => {
      const { handleJoined } = this.props;

      if (handleJoined) {
        handleJoined();
      }

      if (ready && canPlay && this.videoRef && this.videoRef.current && !this.videoRef.current.playing && !this.videoRef.current.srcObject) {
        this.setState({
          loadingVideo: !videoLoaded ? true : false
        }, () => {
          this.videoRef.current.autoplay = true;
          // just in case not sure if needed, probably not but hey we shall see
          console.log("setState videoSource Callback", this.remoteMediaStream.getVideoTracks(), this.remoteMediaStream.getAudioTracks());
          this.videoRef.current.srcObject = this.remoteMediaStream;
          this.videoRef.current.controls = false;
          this.videoRef.current.load();
          //this.videoRef.current.muted = false;
        });
      }
    });
  };

  async stop() {
    console.log("FanoutRoom stop called");
    await this.destroyMediasoup(Defines.Fanout.Signalling.Transport.Send | Defines.Fanout.Signalling.Transport.Recv);
    this.destroySession();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    fanoutClient.removeListener('fanoutStateChanged', this.handleFanoutStateChanged);
    fanoutClient.removeListener('createRoomResponse', this.handleCreateRoomResponse);
    fanoutClient.removeListener('createTransportResponse', this.handleCreateTransportResponse);
    fanoutClient.removeListener('consumeResponse', this.handleConsumeResponse);
    fanoutClient.removeListener('consumeDataResponse', this.handleConsumeDataResponse);
    fanoutClient.removeListener('produceDataResponse', this.handleProduceDataResponse);
    fanoutClient.removeListener('newProducerResponse', this.handleNewProducerResponse);
    fanoutClient.removeListener('newDataProducerResponse', this.handleNewDataProducerResponse);
    fanoutClient.removeListener('peerClosedResponse', this.handlePeerClosedResponse);
    fanoutClient.removeListener('consumeResume', this.handleConsumeResume);
    fanoutClient.removeListener('chatMessage', this.handleChatMessage);
    fanoutClient.removeListener('speakerLayout', this.handleSpeakerLayoutMessage);
    fanoutClient.removeListener('videoPresentation', this.handleVideoPresentationMessage);
    fanoutClient.removeListener('reconnect', this.reconnectRoom);

    clearInterval(this.statsInterval);

    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.removeEventListener('loadeddata', this.handleLoadedData);
      this.videoRef.current.removeEventListener('pause', this.handleVideoPaused);
    }

    if (this.vanityTimeout) {
      clearTimeout(this.vanityTimeout);
      this.vanityTimeout = null;
    }

    if (this.streamingTimeout) {
      clearTimeout(this.streamingTimeout);
      this.streamingTimeout = null;
    }
    console.log("StreamingFanout ComponentWillUnmount called");

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    const { hideMessage } = this.props;

    if (hideMessage)
      hideMessage();

    this.cleanupRoom();
  }

  async cleanupRoom() {
    const { clearMessages } = this.props;

    clearInterval(this.statsInterval);

    if (this.vanityTimeout) {
      clearTimeout(this.vanityTimeout);
      this.vanityTimeout = null;
    }

    if (this.streamingTimeout) {
      clearTimeout(this.streamingTimeout);
      this.streamingTimeout = null;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (!this.sessionEnded) {
      this.sessionEnded = true;
      await this.stop();
    }
    else
      console.log("Mediasoup Device Already Destroyed");

    if (clearMessages)
      clearMessages();
  }

  async reconnectMediasoup() {
    this.stop();

    this.sessionEnded = false;

    this.sessionId = uuidv1();

    this.audioConsumer = null;
    this.videoConsumer = null;
    this.remoteMediaStream = null;
    this.recvTransport = null;
    this.remoteVideoId = null;
    this.remoteAudioId = null;
    this.dataProducer = null;
    this.dataConsumers = new Map();
    this.initialDataProducers = null;
    this.dataProducerCallback = null;
    this.dataProducerErrback = null;
    this.consumerData = {};

    this.previousFanoutState = Defines.Fanout.Status.Starting;

    let payload = { videoCodec: "VP8" };
    fanoutClient.updateSignallingServer('create-room', payload, this.getCallState()).then((res) => {
    });
    console.log("FanoutRoom reconnectMediasoup called, sending create-room request sessionId:", this.sessionId, this.props);
    const { eventItem } = this.props;

    if (this.videoRef && this.videoRef.current && eventItem) {
      this.videoRef.current.poster = eventItem && eventItem.branding && eventItem.branding.backgroundLobbyAudience ? eventItem.branding.backgroundLobbyAudience : eventItem && eventItem.logo ? eventItem.logo : nfbPoster;
    }
  }

  async reconnectRoom() {
    console.log('--- About to reconnect ----------')
    await this.cleanupRoom();
    this.sessionEnded = false;
    setTimeout(async () => {
      await this.setupRoom();
    }, 2000);
    console.log('--- Reconnected ----------')
  }

  async enableRemoteVideo(shouldEnable) {
    console.log(`--- About to ${shouldEnable ? 'enable' : 'disable'} video`);
    if (shouldEnable) {
      this.remoteVideoEnabled = true;
      this.reconnectMediasoup();
    } else {
      this.remoteVideoEnabled = false;
      this.reconnectMediasoup();
    }
  }

  exitFanout() {
    console.log("FanoutRoom exitFanout called", this.props);

    if (!this.sessionEnded) {
      this.sessionEnded = true;
      fanoutClient.updateSignallingServer('stop', null, this.getCallState());
      this.stop();
    }
    else
      console.log("Mediasoup Device Already Destroyed");

    fanoutClient.sendChatDisconnect(this.getCallState());
    fanoutClient.cleanTheRoom();
    this.backToForm();
  }

  handlePeerClosedResponse() {
    if (!this.sessionEnded) {
      this.sessionEnded = true;
      this.destroyMediasoup(Defines.Fanout.Signalling.Transport.Send | Defines.Fanout.Signalling.Transport.Recv);
      this.destroySession();
    }
  };

  toggleList() {
    const { displayAttendeesList } = this.state;

    this.setState({
      displayAttendeesList: !displayAttendeesList
    }, () => {
      this.refreshLabels();
      this.checkTimeoutResize();
    });
  }

  backToForm() {
    const { handleExit, knocks } = this.props;

    if (Object.keys(knocks).length) {
      let callState = this.getCallState();
      Object.keys(knocks).map((key) => {
        if (knocks[key] && callState && knocks[key].uid === callState.uid) {
          fanoutClient.sendKnockRevoked(knocks[key].rid, callState);
        }
        return key;
      });
    }

    if (handleExit) {
      handleExit(true);
    }
  }

  pushToVanityPage() {
    const { eventItem, pushToVanity, conferenceAlias } = this.props;
    if (eventItem && eventItem.id && eventItem.type) {
      pushToVanity(`/${eventItem.type}/${conferenceAlias}`)
    }
  }

  handleSwap(value) {
    if (this.state.videoPlayerRef) {
      this.setState({
        videoDuration: parseInt(this.state.videoPlayerRef.getCurrentTime())
      }, () => {
        this.setState({
          swapActive: value
        }, () => {
          this.videoPlaying = true;
          this.handleVideoReceived();
          if (this.state.swapActive) {
            this.refreshLabels();
            this.checkTimeoutResize();
          }
        });
      });
    }
  }

  checkHorizontalScaling(width, height) {
    if (width > ((height / 9) * 16)) {
      return true;
    } else return false;
  }

  render() {
    const { videoSource, displayAttendeesList, videoPresentation, videoURL, videoPlaying, videoTS, videoDuration, videoLoaded,
      participantBarContent, swapActive, videoWidth, videoHeight, showLabel, canPlay, showPlayButton, loadingVideo, rosterOpened, noTicketModal } = this.state;
    const { orientation, mobile, newMessage, eventId, eventItem } = this.props;
    const conferenceAlias = eventId;

    console.log("Calling StreamingFanout Render");
    if (videoSource) {
      console.log("Video", videoSource.getVideoTracks());
      console.log("Audio", videoSource.getAudioTracks());
    }

    const videoCover = ((mobile || (process.env.platform && process.env.platform === 'mobile')) && videoLoaded && participantBarContent && participantBarContent.length && participantBarContent.length === 2 && orientation && orientation === 'landscape');

    return (
      <div className='fanout-wrapper vxt-widget-container vxt-widget-opened vxt-widget-fullscreen-on' >
        <div id="conference-attendees" className="vxt-conference-attendees">
          <ResizableRosterFanout
            attendeesListOpened={displayAttendeesList}
            eventItem={eventItem}
            sendMessage={this.sendMessage}
            setRosterState={(value) => this.setState({ rosterOpened: value })}
            mode={videoPresentation && canPlay ? 'speaker' : 'tiles'}
          />
          <RoomMessage />
          <div
            className={classNames('sidebar-container',
              { 'attendees-list-close': !displayAttendeesList },
              { 'attendees-list-opened': displayAttendeesList }
            )}
            id={rosterOpened ? 'roster-closed' : ''}
          >
            {videoPresentation && canPlay ?
              <FanoutPresentationView
                videoRef={this.videoRef}
                participantBarContent={participantBarContent}
                orientation={orientation}
                videoHeight={videoHeight}
                videoWidth={videoWidth}
                mobile={mobile}
                videoLoaded={videoLoaded}
                handleSwap={this.handleSwap}
                showLabel={showLabel}
                canPlay={canPlay}
                swapActive={swapActive}
                videoURL={videoURL}
                videoTS={videoTS}
                videoPlaying={videoPlaying}
                videoPresentation={videoPresentation}
                videoDuration={videoDuration}
                sendRef={(value) => this.setState({ videoPlayerRef: value })}
                handleLoadedData={this.handleLoadedData}
              />
              :
              <div className='SidebarTiles' data-number-user="0">
                <div className='tiles-list list0'>
                  <div className='tile-local-item participant-available'>
                    <span className='tile-video video-frame'>
                      <div className='stream-media'>
                        <video ref={this.videoRef} className={classNames('video-tag', { 'video-cover': videoCover })} id="fullscreen-video" playsInline autoPlay muted />
                        {loadingVideo && <img src={loadingGif} className='video-loader' />}
                      </div>
                      {showLabel && canPlay && participantBarContent && participantBarContent.length ?
                        participantBarContent.map((item, index) => {
                          let vHeight = this.checkHorizontalScaling(videoWidth, videoHeight) || videoCover ? videoHeight : (videoWidth * 9) / 16;
                          let vWidth = !this.checkHorizontalScaling(videoWidth, videoHeight) || videoCover ? videoWidth : (videoHeight / 9) * 16;
                          let vOffset = (videoHeight - vHeight) / 2;
                          let hOffset = (videoWidth - vWidth) / 2;
                          return (
                            <div
                              key={index}
                              className='participant-bar fanout-bar'
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty('bottom', `${videoHeight - (vOffset + ((vHeight * item.dy) / 720))}px`, 'important');
                                  el.style.setProperty('left', `${hOffset + ((vWidth * item.dx) / 1280)}px`, 'important');
                                }
                              }}
                            >
                              <div className='name'>{item.name}</div>
                            </div>
                          )
                        })
                        :
                        null}
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        {noTicketModal ? <div className='fanout-modal-wrapper'>
          <div className='fanout-modal big-modal'>
            <div className='fanout-text'>You don't have a ticket for this event.</div>
            {eventItem ?
              <button
                className='btn fanout-buy'
                onClick={this.pushToVanityPage}
              >
                Buy Ticket
                </button>
              : null
            }
            <button
              className='btn fanout-cancel'
              onClick={() => this.props.pushToHome()}
            >
              Cancel
              </button>
          </div>
        </div> : null}
        {!noTicketModal && showPlayButton && !canPlay ?
          <div className='fanout-modal-wrapper play' onClick={this.handlePlay}>
            <Loader play={true} text="Click to play" />
          </div> : !noTicketModal && !canPlay ?
            <div className='fanout-modal-wrapper'>
              <Loader text='Connecting stream' />
            </div>
            : null
        }
        {canPlay ? <div className='fanout-bottom-bar vxt-bottom-bar'>
          <div className='vxt-bottom-bar-actions'>
            <ul className='controls-left desktop'>
              <InboundVideoButtonBottomBar
                getCallState={this.getCallState}
              />
            </ul>
            <ul className='controls-center desktop'>
              <AttendeesListFanoutButton
                isOpen={displayAttendeesList}
                toggle={this.toggleList}
                newMessage={newMessage}
              />
            </ul>
            <ul className='controls-right desktop'>
              {conferenceAlias ?
                <KnockButtonBottomBar
                  conferenceAlias={conferenceAlias}
                  getCallState={this.getCallState}
                />
                : null
              }
              {eventItem && eventItem.acceptTips ?
                <TipButtonBottomBar
                  item={eventItem}
                />
                : null
              }
              <HangUpButtonFanout
                leave={this.exitFanout}
              />
            </ul>
          </div>
        </div> : null}
      </div>
    );
  }
}

StreamingFanout.defaultProps = {
  conferenceName: "conference_name",
  userName: "Guest " + Math.floor(Math.random() * 100 + 1),
};

const mapStateToProps = (state) => {
  return {
    user: state.firebase.user,
    newMessage: state.chat.newMessage,
    orientation: state.app.orientation,
    knocks: state.knocks,
    streaming: state.room.streaming,
    roomMessage: state.room.roomMessage,
    mobile: state.app.user_agent_info.platform.type === 'mobile'
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMessage: (data) => {
      dispatch(chatActions.addMessage(data));
    },
    pushToHome: () => {
      dispatch(nativePush('/'));
    },
    pushToVanity: (path) => {
      dispatch(nativePush(path));
    },
    clearMessages: () => {
      dispatch(chatActions.clearMessages());
    },
    displayMessage: (message, timer, type) => {
      dispatch(roomActions.displayMessage({ message: message, timer: timer, type: type }));
    },
    hideMessage: () => {
      dispatch(roomActions.hideMessage());
    }
  };
};

const StreamingFanoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamingFanout);

export default StreamingFanoutContainer;
