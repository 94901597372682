import React, { Component } from 'react';
import { connect } from "react-redux";
import ComponentHeader from '../Widget/ComponentHeader';
import { Link } from 'react-router-dom';
import Button from '../Widget/Button';
import enterStream1 from '../../../resources/icons/enter-stream1.png';
import enterStream2 from '../../../resources/icons/enter-stream2.png';
import htce1 from '../../../resources/images/htce1.png';
import htce2 from '../../../resources/images/htce2.png';
import htce3 from '../../../resources/images/htce3.png';
import htce4 from '../../../resources/images/htce4.png';
import htce5 from '../../../resources/images/htce5.png';
import htce6 from '../../../resources/images/htce6.png';
import htce7 from '../../../resources/images/htce7.png';
import htce8 from '../../../resources/images/htce8.png';
import htce9 from '../../../resources/images/htce9.png';
import htce10 from '../../../resources/images/htce10.png';
import htce11 from '../../../resources/images/htce11.png';
import htce12 from '../../../resources/images/htce12.png';
import monetize1 from '../../../resources/images/monetize1.png';
import { signupCampaignPageLink } from '../../utils/text';

class HowToCreate extends Component {
    constructor(props) {
        super(props);
    }

    handleScrollToId(id) {
        if (id) {
            let el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    render() {
        const { auth, roles } = this.props;

        return (
            <div>
                <ComponentHeader big={true} />
                <div className='how-to-create-event-wrapper'>
                    <p className='how-to-create-event-title title-1'>So what happens next?</p>
                    <div className='htce-wrapper'>
                        <div className='htce-text-wrapper'>
                            <p className='htce-text-title'>The event scheduler</p>
                            <p className='htce-text-p'>The event scheduler has been added to your account menu.</p>
                            <p className='htce-text-p'>Click on it to open the event scheduler and create an event.</p>
                        </div>
                        <div className='htce-img-wrapper'>
                            <img src={htce1} loading='lazy' />
                        </div>
                    </div>
                    <p className='how-to-create-event-title -left title-2'>what you’ll see:</p>
                    <div className='htce-number-wrapper'>
                        <p className='htce-number-p'>01</p>
                        <div className='htce-number-img-wrapper'>
                            <img src={htce2} loading='lazy' />
                            <div className='text-blue-wrapper t1' onClick={() => this.handleScrollToId('htce1')}><p className='text-number'>1</p></div>
                            <div className='text-blue-wrapper t2' onClick={() => this.handleScrollToId('htce2')}><p className='text-number'>2</p></div>
                            <div className='text-blue-wrapper t3' onClick={() => this.handleScrollToId('htce3')}><p className='text-number'>3</p></div>
                            <div className='text-blue-wrapper t4' onClick={() => this.handleScrollToId('htce4')}><p className='text-number'>4</p></div>
                        </div>
                        <div className='htce-p-wrapper'>
                            <div id='htce1' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>
                                    Choose Public or Private
                                    <br />
                                    <span className='p-text-muted'>
                                        Public Streams are visible by anyone who visits NoFilter.net.
                                        <br />
                                        Private Streams can only be accessed by receiving a direct link.
                                    </span>
                                </p>
                            </div>
                            <div id='htce2' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Share the title of your stream</p>
                            </div>
                            <div id='htce3' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>Custom format the stream description for the landing page</p>
                            </div>
                            <div id='htce4' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>4</p>
                                </div>
                                <p className='htce-p'>Select a category for your stream, so viewers can find it more easily</p>
                            </div>
                        </div>
                    </div>
                    <div className='htce-number-wrapper'>
                        <p className='htce-number-p'>02</p>
                        <div className='htce-number-img-wrapper'>
                            <img src={htce3} loading='lazy' />
                            <div className='text-blue-wrapper t5' onClick={() => this.handleScrollToId('htce5')}><p className='text-number'>1</p></div>
                            <div className='text-blue-wrapper t6' onClick={() => this.handleScrollToId('htce6')}><p className='text-number'>2</p></div>
                            <div className='text-blue-wrapper t7' onClick={() => this.handleScrollToId('htce7')}><p className='text-number'>3</p></div>
                            <div className='text-blue-wrapper t8' onClick={() => this.handleScrollToId('htce8')}><p className='text-number'>4</p></div>
                        </div>
                        <div className='htce-p-wrapper'>
                            <div id='htce5' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>Add a maturity rating if your stream is meant for adults only</p>
                            </div>
                            <div id='htce6' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Add content labels for mature content</p>
                            </div>
                            <div id='htce7' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>Hit this button if you want to add a co host; they will be emailed with an event confirmation, but their emails must be registered with No Filter</p>
                            </div>
                            <div id='htce8' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>4</p>
                                </div>
                                <p className='htce-p'>Hit this button if you want to add someone to moderate the chat and guest streamers; they will be emailed with an event confirmation, but their emails must be registered with No Filter</p>
                            </div>
                        </div>
                    </div>

                    <div className='htce-number-wrapper'>
                        <p className='htce-number-p'>03</p>
                        <div className='htce-number-img-wrapper'>
                            <img src={htce4} loading='lazy' />
                            <div className='text-blue-wrapper t9' onClick={() => this.handleScrollToId('htce9')}><p className='text-number'>1</p></div>
                            <div className='text-blue-wrapper t10' onClick={() => this.handleScrollToId('htce10')}><p className='text-number'>2</p></div>
                            <div className='text-blue-wrapper t11' onClick={() => this.handleScrollToId('htce11')}><p className='text-number'>3</p></div>
                            <div className='text-blue-wrapper t12' onClick={() => this.handleScrollToId('htce12')}><p className='text-number'>4</p></div>
                            <div className='text-blue-wrapper t13' onClick={() => this.handleScrollToId('htce13')}><p className='text-number'>5</p></div>
                            <div className='text-blue-wrapper t14' onClick={() => this.handleScrollToId('htce14')}><p className='text-number'>6</p></div>
                        </div>
                        <div className='htce-p-wrapper'>
                            <div id='htce9' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>Set the ticket price and number of tickets available for sale for this event. If you want to make the event free, set the ticket price to $0. You MUST set a value for the number of tickets to be made available for sale. Note that, if you set a non-zero price for event tickets, we will reach out to you, to set you up in our payments system, so that we can pay you your share of ticket sales and tips after the event!</p>
                            </div>
                            <div id='htce10' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Set whether or not you want to make replays available in your vault, and for how long</p>
                            </div>
                            <div id='htce11' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>Set the date and time of your event</p>
                            </div>
                            <div id='htce12' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>4</p>
                                </div>
                                <p className='htce-p'>Set an estimated duration of your live stream event</p>
                            </div>
                            <div id='htce13' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>5</p>
                                </div>
                                <p className='htce-p'>Make sure that you are using the right time zone when scheduling!</p>
                            </div>
                            <div id='htce14' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>6</p>
                                </div>
                                <p className='htce-p'>Toggle whether or not you can accept tips during your stream, and what the minimum amount viewers can tip you is ($4.99 is the default minimum)</p>
                            </div>
                        </div>
                    </div>
                    <div className='htce-number-wrapper last'>
                        <p className='htce-number-p'>04</p>
                        <div className='htce-number-img-wrapper'>
                            <img src={htce5} loading='lazy' />
                            <div className='text-blue-wrapper t15' onClick={() => this.handleScrollToId('htce15')}><p className='text-number'>1</p></div>
                            <div className='text-blue-wrapper t16' onClick={() => this.handleScrollToId('htce16')}><p className='text-number'>2</p></div>
                            <div className='text-blue-wrapper t17' onClick={() => this.handleScrollToId('htce17')}><p className='text-number'>3</p></div>
                        </div>
                        <div className='htce-p-wrapper'>
                            <div id='htce15' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>Set a custom event image for your landing page; if you leave this blank, then the default image will be your host page banner image</p>
                            </div>
                            <div id='htce16' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Browse through your files to select the custom event image</p>
                            </div>
                            <div id='htce17' className='htce-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>Click here to finish scheduling your event and see your new event page</p>
                            </div>
                        </div>
                    </div>
                    <p className='how-to-create-event-title title-3'>Event scheduled! 🎉</p>
                    <div className='htce-image-wrapper'>
                        <div className='htce-image-div img-event-content'>
                            <img src={htce6} loading='lazy' />
                            <div className='image-blue-wrapper t1' onClick={() => this.handleScrollToId('htce18')}><p className='text-number'>1</p></div>
                            <div className='image-blue-wrapper t2' onClick={() => this.handleScrollToId('htce19')}><p className='text-number'>2</p></div>
                            <div className='image-blue-wrapper t3' onClick={() => this.handleScrollToId('htce20')}><p className='text-number'>3</p></div>
                            <div className='image-blue-wrapper t4' onClick={() => this.handleScrollToId('htce21')}><p className='text-number'>4</p></div>
                            <div className='image-blue-wrapper t5' onClick={() => this.handleScrollToId('htce22')}><p className='text-number'>5</p></div>
                            <div className='image-blue-wrapper t6' onClick={() => this.handleScrollToId('htce23')}><p className='text-number'>6</p></div>
                        </div>
                        <div className='htce-image-p-wrapper'>
                            <div id='htce18' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>Click here to enter the room and get ready to start streaming</p>
                            </div>
                            <div id='htce19' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Click here to cancel your event. Note that, if you cancel a paid event, any tickets that have been purchased for that event will automatically be refunded to the buyers.</p>
                            </div>
                            <div id='htce20' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>Using this button will copy this event. The event scheduler will open up with all your personal touches copied and ready to re-create the stream. All you have to do is pick a new date and time!</p>
                            </div>
                            <div id='htce21' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>4</p>
                                </div>
                                <p className='htce-p'>Use this button to edit your event. Very helpful to fix typos, change your image or switch what category the stream is in!</p>
                            </div>
                            <div id='htce22' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>5</p>
                                </div>
                                <p className='htce-p'>Use this button to easily share the link for viewers to register to your event</p>
                            </div>
                            <div id='htce23' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>6</p>
                                </div>
                                <p className='htce-p'>Keep track of how many tickets have been reserved with live analytics; only you can see this number</p>
                            </div>
                        </div>
                    </div>
                    <div className='htce-wrapper'>
                        <div className='htce-text-wrapper'>
                            <p className='htce-text-title'>My stuff</p>
                            <p className='htce-text-p'>You can find all your scheduled events in your “My stuff” page</p>
                            {/* <p className='htce-text-p'>Where all events and vaulted content are kept</p> */}
                        </div>
                        <div className='htce-img-wrapper'>
                            <img src={htce7} loading='lazy' />
                        </div>
                    </div>
                    <p className='how-to-create-event-title title-3'><img src={enterStream1} loading='lazy' /> starting your stream</p>
                    <div className='htce-image-wrapper'>
                        <div className='htce-image-div'>
                            <img src={htce8} loading='lazy' />
                            <div className='image-blue-wrapper t7' onClick={() => this.handleScrollToId('htce24')}><p className='text-number'>1</p></div>
                            <div className='image-blue-wrapper t8' onClick={() => this.handleScrollToId('htce25')}><p className='text-number'>2</p></div>
                            <div className='image-blue-wrapper t9' onClick={() => this.handleScrollToId('htce26')}><p className='text-number'>3</p></div>
                            <div className='image-blue-wrapper t10' onClick={() => this.handleScrollToId('htce27')}><p className='text-number'>4</p></div>
                        </div>
                        <div className='htce-image-p-wrapper'>
                            <div id='htce24' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>Mute/Unmute Yourself</p>
                            </div>
                            <div id='htce25' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Toggle your video on/off</p>
                            </div>
                            <div id='htce26' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>3</p>
                                </div>
                                <p className='htce-p'>View audience members who want to join and stream guests</p>
                            </div>
                            <div id='htce27' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>4</p>
                                </div>
                                <p className='htce-p'>This will glow BLUE when your stream is actually recording</p>
                            </div>
                        </div>
                    </div>
                    <div className='htce-flex-item-wrapper'>
                        <div className='htce-flex-item'>
                            <div className='htce-flex-img-wrapper'>
                                <p>💻</p>
                            </div>
                            <p className='htce-flex-item-title'>Share your screen</p>
                            <div className='htce-flex-item-img'>
                                <img src={htce9} loading='lazy' />
                                <div className='image-blue-wrapper t1' onClick={() => this.handleScrollToId('htce28')}><p className='text-number'>1</p></div>
                                <div className='image-blue-wrapper t2' onClick={() => this.handleScrollToId('htce29')}><p className='text-number'>2</p></div>
                            </div>
                            <div className='htce-image-p-wrapper'>
                                <div id='htce28' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>1</p>
                                    </div>
                                    <p className='htce-p'>Share your whole screen, or a specific window</p>
                                </div>
                                <div id='htce29' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>2</p>
                                    </div>
                                    <p className='htce-p'>Paste a Youtube URL to play inside your stream to your viewers</p>
                                </div>
                            </div>
                        </div>
                        <div className='htce-flex-item'>
                            <div className='htce-flex-img-wrapper'>
                                <p>⚙️</p>
                            </div>
                            <p className='htce-flex-item-title'>settings menu</p>
                            <div className='htce-flex-item-img'>
                                <img src={htce10} loading='lazy' />
                                <div className='image-blue-wrapper t3' onClick={() => this.handleScrollToId('htce30')}><p className='text-number'>1</p></div>
                                <div className='image-blue-wrapper t4' onClick={() => this.handleScrollToId('htce31')}><p className='text-number'>2</p></div>
                                <div className='image-blue-wrapper t5' onClick={() => this.handleScrollToId('htce32')}><p className='text-number'>3</p></div>
                                <div className='image-blue-wrapper t6' onClick={() => this.handleScrollToId('htce33')}><p className='text-number'>4</p></div>
                            </div>
                            <div className='htce-image-p-wrapper'>
                                <div id='htce30' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>1</p>
                                    </div>
                                    <p className='htce-p'>Audio input level display</p>
                                </div>
                                <div id='htce31' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>2</p>
                                    </div>
                                    <p className='htce-p'>Select which microphone you’re using</p>
                                </div>
                                <div id='htce32' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>3</p>
                                    </div>
                                    <p className='htce-p'>Toggle what camera you use for video</p>
                                </div>
                                <div id='htce33' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>4</p>
                                    </div>
                                    <p className='htce-p'>Default Audio Output</p>
                                </div>
                            </div>
                        </div>
                        <div className='htce-flex-item'>
                            <div className='htce-flex-img-wrapper'>
                                <p>💬</p>
                            </div>
                            <p className='htce-flex-item-title'>view the chat/attendees</p>
                            <div className='htce-flex-item-img'>
                                <img src={htce11} loading='lazy' />
                                <div className='image-blue-wrapper t7' onClick={() => this.handleScrollToId('htce34')}><p className='text-number'>1</p></div>
                            </div>
                            <div className='htce-image-p-wrapper'>
                                <div id='htce34' className='htce-image-p-flex'>
                                    <div className='text-number-wrapper'>
                                        <p className='text-number'>1</p>
                                    </div>
                                    <p className='htce-p'>Click here to view a list of attendees</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='how-to-create-event-title title-2'><img src={enterStream2} loading='lazy' /> start the stream!</p>
                    <div className='htce-single-image-wrapper'>
                        <div className='htce-single-img-wrapper'>
                            <img src={htce12} loading='lazy' />
                            <div className='image-blue-wrapper t1' onClick={() => this.handleScrollToId('htce35')}><p className='text-number'>1</p></div>
                            <div className='image-blue-wrapper t2' onClick={() => this.handleScrollToId('htce36')}><p className='text-number'>2</p></div>
                        </div>
                        <div className='htce-image-p-wrapper'>
                            <div id='htce35' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>1</p>
                                </div>
                                <p className='htce-p'>
                                    Start streaming and make available to viewers
                                    <br />
                                    <span className='p-text-muted'>(No: will automatically start recording if selected during the event scheduling process)</span>
                                </p>
                            </div>
                            <div id='htce36' className='htce-image-p-flex'>
                                <div className='text-number-wrapper'>
                                    <p className='text-number'>2</p>
                                </div>
                                <p className='htce-p'>Copy a join link that automatically adds people as co-streamers (must be registered with No Filter)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ad-wrapper'>
                    <div className='ad-text'>
                        <p className='ad-title'>Schedule your <span>first event</span>!</p>
                        {auth && roles && roles.broadcaster ?
                            <Link to='/calendar'>
                                <Button
                                    type='default'
                                    className='btn-ad'
                                >
                                    to schedule
                            </Button>
                            </Link>
                            : (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                                <a href={signupCampaignPageLink}>
                                    <Button
                                        type='default'
                                        className='btn-ad'
                                    >
                                        become a host
                                    </Button>
                                </a>
                                : null
                        }
                    </div>
                    <img src={monetize1} loading='lazy' />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        roles: state.firebase.roles
    };
};

const HowToCreateContainer = connect(
    mapStateToProps,
    null //mapDispatchToProps
)(HowToCreate);

export default HowToCreateContainer;