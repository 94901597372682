import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push as nativePush } from 'connected-react-router';
import moment from "moment";
import Cards from '../Widget/Cards';
import ComponentHeader from '../Widget/ComponentHeader';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import { getQueryVariable } from "../../utils/text";
import path from '../../../resources/icons/path.svg';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            live: false,
            category: 'default',
            search: false,
            showMore: false,
            noMoreVideos: false,
            searchAllVideos: null,
            searchVideos: null,
            upcomingEvents: null
        }

        this.handlePushToSearch = this.handlePushToSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.handleChangeSelectCategories = this.handleChangeSelectCategories.bind(this);
        this.handleClickSearchButton = this.handleClickSearchButton.bind(this);
    }

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            this.showMoreVideos();
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        let q = getQueryVariable('q');
        if (q) {
            this.setState({
                searchInput: q
            }, () => {
                this.handleSearch();
            });
        } else {
            this.setState({
                upcomingEvents: [],
                searchVideos: []
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { location, upcomingEvents } = this.props;

        let q = getQueryVariable('q');

        if (q && location && location.search && location.search !== prevProps.location.search) {
            this.setState({
                searchInput: q
            }, () => {
                this.handleSearch();
            });
        }

        if (q && upcomingEvents && upcomingEvents !== prevProps.upcomingEvents) {
            this.handleSearch();
        }


    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleChangeSearchInput(event) {
        this.setState({
            searchInput: event.target.value
        });
    }

    handleChangeLive() {
        this.setState({
            live: !this.state.live
        });
    }

    handleChangeSelectCategories(event) {
        this.setState({
            category: event.target.value
        })
    }

    enterPressed(event) {
        const { searchInput } = this.state;
        var code = event.keyCode || event.which;
        if (code === 13 && searchInput) {
            this.handlePushToSearch();
        }
    }

    handlePushToSearch() {
        const { searchInput } = this.state;
        const { pushToSearch } = this.props;

        if (searchInput && searchInput.length && pushToSearch) {
            pushToSearch(searchInput);
        }
    }

    handleClickSearchButton() {
        const { searchInput } = this.state;

        let q = getQueryVariable('q');

        if (q === searchInput) {
            this.handleSearch();
        } else {
            this.handlePushToSearch();
        }
    }

    handleSearch() {
        const { searchInput, live, category, noMoreVideos } = this.state;
        const events = this.props.upcomingEvents;
        let cat = category && category !== 'default' ? category : null;
        let upcomingEvents = [];
        let recentVideos = [];

        this.setState({
            search: true,
            noMoreVideos: false
        });

        let start = Date.now();
        let date = searchInput || live || cat ? moment().subtract(6, 'months').unix() * 1000 : moment().subtract(1, 'months').unix() * 1000;

        upcomingEvents = events ?
            events.filter((item) => {
                const t = item.title.toLowerCase();
                const author = item.author.toLowerCase();
                return (!cat || item.cat.toLowerCase() == cat.toLowerCase()) &&
                    (t.search(searchInput.toLowerCase()) != -1 || author.search(searchInput.toLowerCase()) != -1);
            }) :
            [];

        this.setState({
            upcomingEvents
        });

        firebaseClient.getSearchVideosFS(date, live, cat, start).then((res) => {
            let videos = res && res.data ? res.data : []
            if (videos && videos.length) {
                videos.filter((item) => {
                    if (item && item.visibility && item.visibility === "public") {
                        const t = item.title.toLowerCase();
                        const author = item.author.toLowerCase();
                        if (t.search(searchInput.toLowerCase()) != -1 || author.search(searchInput.toLowerCase()) != -1)
                            recentVideos.push(item);
                    }
                })
                this.setState({
                    searchVideos: recentVideos,
                    searchAllVideos: videos,
                    search: false
                });
                if (recentVideos && recentVideos.length && recentVideos.length < 12 && !noMoreVideos) {
                    this.showMoreVideos();
                }
            } else {
                this.setState({
                    searchVideos: recentVideos,
                    search: false
                })
            }
        });
    }

    showMoreVideos() {
        const { showMore, noMoreVideos, searchInput, live, category, searchVideos, searchAllVideos } = this.state;
        let cat = category && category !== 'default' ? category : null;
        let lastVideo = searchAllVideos[searchAllVideos.length - 1];
        let recentVideos = [];

        if (!showMore && !noMoreVideos) {
            this.setState({
                showMore: true
            });

            let date = moment().subtract(6, 'months').unix() * 1000

            firebaseClient.getSearchVideosFS(date, live, cat, lastVideo.startDate).then((res) => {
                let videos = res && res.data ? res.data : []
                if (videos && videos.length) {
                    videos.filter((item) => {
                        if (item && item.visibility && item.visibility === "public") {
                            const t = item.title.toLowerCase();
                            const author = item.author.toLowerCase();
                            if (t.search(searchInput.toLowerCase()) != -1 || author.search(searchInput.toLowerCase()) != -1)
                                recentVideos.push(item);
                        }
                    });
                    this.setState({
                        showMore: false,
                        searchVideos: searchVideos.concat(recentVideos),
                        searchAllVideos: searchAllVideos.concat(videos)
                    })
                } else {
                    this.setState({
                        showMore: false,
                        noMoreVideos: true
                    })
                }
            });
        }
    }

    render() {
        const { searchInput, search, searchVideos, upcomingEvents } = this.state;
        const { categories } = this.props;

        return (
            <div>
                <ComponentHeader
                    text="find your show"
                />
                <div className='hosts-search-wrapper'>
                    <div className='hosts-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            autoFocus
                            placeholder='What are you looking for ?'
                            value={searchInput}
                            onChange={this.handleChangeSearchInput}
                            onKeyPress={this.enterPressed}
                        />
                    </div>
                    {categories && categories.length ?
                        <div className='search-select'>
                            <select
                                defaultValue='default'
                                onChange={this.handleChangeSelectCategories}
                                onKeyPress={this.enterPressed}
                            >
                                <option
                                    value='default'
                                >
                                    All categories
                                </option>
                                {categories.map((item, index) => {
                                    return (
                                        <option
                                            key={item.id || index}
                                            value={item.id}
                                        >
                                            {item.title}
                                        </option>
                                    )
                                })
                                }
                            </select>
                        </div>
                        : null
                    }
                    <Button
                        type='default'
                        className='btn-search'
                        onClick={this.handleClickSearchButton}
                        disabled={search || !searchInput}
                        loading={search}
                    >
                        Search videos
                    </Button>
                </div>
                <Cards
                    title='Upcoming Events'
                    videos={upcomingEvents}
                    readMoreLink='/browse?b=upcoming-streams'
                />
                <Cards
                    title='Vault'
                    videos={searchVideos}
                    readMoreLink='/browse?b=all'
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        categories: state.firebase.categories,
        upcomingEvents: state.firebase.events.upcoming,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToSearch: (value) => {
            dispatch(nativePush(`/search?q=${value}`));
        }
    };
};

const SearchContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);

export default SearchContainer;
