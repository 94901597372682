import { Defines } from './FanoutDefines';
import firebaseClient from './FirebaseClient';
import Cookies from "js-cookie";
import moment from 'moment';
import * as chatActions from "../actions/chat_actions";
import * as audienceActions from "../actions/audience_actions";
import * as hostsActions from "../actions/hosts_actions";
import * as bannedActions from "../actions/banned_actions";
import * as knocksActions from "../actions/knocks_actions";
import * as roomActions from "../actions/room_actions";
import { v1 as uuidv1 } from 'uuid';
const EventEmitter = require('events');

class FanoutClient extends EventEmitter {
    /**
     * Constructor
     * @param store Redux store
     */
    constructor(store) {
        super();

        this._store = store;
        this._ws = null;
        this._keepAliveinterval = null;
        this._shouldReconnectToWS = true;
        this.connectToWS = this.connectToWS.bind(this);
        this.cleanTheRoom = this.cleanTheRoom.bind(this);
        this.join = this.join.bind(this);
        this.reconnectAsMe = this.reconnectAsMe.bind(this);

        this.handleSignalClientMessage = this.handleSignalClientMessage.bind(this);
        this.handleFanoutStateChanged = this.handleFanoutStateChanged.bind(this);
        this.responses = {};
    }

    /**
     * Initializa new connection to fanout
     * @param props
     */
    async connectToFanout(data) {
        const { linkId, eventId, username } = data;
        // Connect to ws
        try {
            if (linkId) {
                await this.connectToWS(linkId, username);
            } else if (eventId) {
                await this.connectToEventWS(eventId, username);
            }
        } catch (e) {
            console.error('Could not connect to WS', e.message)
        }
        // Subscribe to fanout events on firebase
        console.log('------------------------');
        firebaseClient.subscribeToFanoutStateChanges(process.env.fanoutId, this.handleFanoutStateChanged);
    }

    connectToWS(linkId, username, shouldReconnectToRoom = false) {
        console.log('Connecting to message dispatcher', `${process.env.messageDispatcherHost}:${process.env.messageDispatcherPort}`)
        Cookies.set('linkId', linkId, { sameSite: 'None', secure: true, domain: process.env.messageDispatcherHost });
        document.cookie = "linkId=" + linkId + ";SameSite=None; Secure"
        let connected = false;

        return new Promise((resolve, reject) => {
            try {
                // Create WebSocket connection.
                const socket = new WebSocket(`${process.env.messageDispatcherHost}:${process.env.messageDispatcherPort}`);
                this._ws = socket;
                setTimeout(() => {
                    reject(false);
                }, 30000);

                // Connection opened
                socket.addEventListener('open', (event) => {
                    connected = true;
                    // resolve(true);
                    let rid = uuidv1();
                    console.log('Connected to message dispatcher');
                    socket.send(JSON.stringify({
                        message: 'Hello Server!',
                        type: Defines.Fanout.Signalling.VerifyLink,
                        request: Defines.Fanout.Signalling.VerifyLink,
                        linkId: linkId,
                        rid
                    }));
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (shouldReconnectToRoom) {
                        this.emit('reconnect');
                    }

                    // this._keepAliveinterval = setInterval(() => {
                    //     // socket.ping()
                    // }, 5000);
                });

                // Listen for messages
                socket.addEventListener('message', (event) => {
                    console.log('Message from server: ', event.data);
                    let parsed = null;
                    try {
                        parsed = JSON.parse(event.data);
                    } catch (e) {
                        console.error('Could not parse fanout message', event.data)
                    }
                    if (parsed) {
                        if (parsed.request === Defines.Fanout.Signalling.Auth || parsed.request === Defines.Fanout.Signalling.VerifyLink)
                            resolve(true);
                        this.handleSignalClientMessage(parsed);
                    }
                });
                // Listen for possible errors
                socket.addEventListener('error', (event) => {
                    console.error('WebSocket error: ', event);
                    if (!connected) {
                        reject(event);
                    }
                });
                // Listen for close event
                socket.addEventListener('close', (event) => {
                    console.log(`Got websocket close event for ${username} on likId ${linkId}.`);
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (this._shouldReconnectToWS) {
                        setTimeout(() => {
                            this.connectToWS(linkId, username, true);
                        }, 3000);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }
    connectToEventWS(eventId, username, shouldReconnectToRoom = false) {
        console.log('Connecting to message dispatcher', `${process.env.messageDispatcherHost}:${process.env.messageDispatcherPort}`)
        Cookies.set('eventId', eventId, { sameSite: 'None', secure: true, domain: process.env.messageDispatcherHost });
        document.cookie = "eventId=" + eventId + ";SameSite=None; Secure"
        let connected = false;

        return new Promise((resolve, reject) => {
            try {
                // Create WebSocket connection.
                const socket = new WebSocket(`${process.env.messageDispatcherHost}:${process.env.messageDispatcherPort}`);
                this._ws = socket;
                setTimeout(() => {
                    reject(false);
                }, 30000);

                // Connection opened
                socket.addEventListener('open', (event) => {
                    connected = true;
                    // resolve(true);
                    let rid = uuidv1();
                    console.log('Connected to message dispatcher');
                    socket.send(JSON.stringify({
                        message: 'Hello Server!',
                        type: Defines.Fanout.Signalling.VerifyEvent,
                        request: Defines.Fanout.Signalling.VerifyEvent,
                        eventId: eventId,
                        rid
                    }));
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (shouldReconnectToRoom) {
                        this.emit('reconnect');
                    }

                    // this._keepAliveinterval = setInterval(() => {
                    //     // socket.ping()
                    // }, 5000);
                });

                // Listen for messages
                socket.addEventListener('message', (event) => {
                    console.log('Message from server: ', event.data);
                    let parsed = null;
                    try {
                        parsed = JSON.parse(event.data);
                    } catch (e) {
                        console.error('Could not parse fanout message', event.data)
                    }
                    if (parsed) {
                        if (parsed.request === Defines.Fanout.Signalling.Auth || parsed.request === Defines.Fanout.Signalling.VerifyEvent)
                            resolve(true);
                        this.handleSignalClientMessage(parsed);
                    }
                });
                // Listen for possible errors
                socket.addEventListener('error', (event) => {
                    console.error('WebSocket error: ', event);
                    if (!connected) {
                        reject(event);
                    }
                });
                // Listen for close event
                socket.addEventListener('close', (event) => {
                    console.log(`Got websocket close event for ${username} on eventId ${eventId}.`);
                    if (this._keepAliveinterval) {
                        clearInterval(this._keepAliveinterval);
                        this._keepAliveinterval = null;
                    }
                    if (this._shouldReconnectToWS) {
                        setTimeout(() => {
                            this.connectToEventWS(eventId, username, true);
                        }, 3000);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async join(linkId, name, emailVerificationCode, title, company, email, skipAuth = false, role = null, eventData = null) {
        console.log('About to join to conference [%s] as [%s]', linkId, name);
        let rid = uuidv1();
        // Get user token
        let idToken = null;

        if (!skipAuth) {
            idToken = await firebaseClient.getUserToken();
        }

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'join',
                    message: 'Timeout for authentication expired'
                });
                console.log('Timeout for authentication expired');
                reject('Timeout for authentication expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully joined', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let authData = {
            linkId,
            idToken,
            emailVerificationCode,
            name,
            title,
            company,
            email,
            role,
            participant_tos_accepted: true,
            ...eventData
        }

        this._ws.send(JSON.stringify({
            message: 'Joining to conference!',
            request: Defines.Fanout.Signalling.Auth,
            rid,
            payload: authData
        }));

        return p;
    }

    async register(linkId, name, emailVerificationCode, title, company, email) {
        console.log('About to register to conference [%s] as [%s]', linkId, name);
        let rid = uuidv1();
        // Get user token
        let idToken = await firebaseClient.getUserToken();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'register',
                    message: 'Timeout for authentication expired'
                });
                reject('Timeout for authentication expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully joined', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let registerData = {
            linkId,
            idToken,
            emailVerificationCode,
            name,
            title,
            company,
            email,
            participant_tos_accepted: true
        }

        this._ws.send(JSON.stringify({
            message: 'Registering to conference!',
            request: Defines.Fanout.Signalling.Register,
            rid,
            payload: registerData
        }));

        return p;
    }

    async reconnectAsMe(email) {
        let reconnectToken = window.localStorage.getItem(`reconnectToken:${(email || 'unknown').replace('@', '_')}`);
        window.localStorage.removeItem(`reconnectToken:${(email || 'unknown').replace('@', '_')}`);
        if (!reconnectToken)
            return Promise.resolve(false);
        let rid = uuidv1();
        console.log('About to reconnect to conference', reconnectToken, email);

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                console.log('Timeout for reconnection expired');
                this.emit('fanoutReqestExpired', {
                    where: 'reconnectAsMe',
                    message: 'Timeout for reconnection expired'
                });
                resolve(false);
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Successfully reconnected', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    resolve(false);
                }
            }
        });

        this._ws.send(JSON.stringify({
            message: 'Reconnecting to conference!',
            type: 'reconnect-me',
            request: 'reconnect-me',
            rid,
            payload: { reconnectToken, email }
        }));

        return p;
    }

    async verifyEvent(eventId) {
        if (!this._ws) {
            console.error('Could not connect to ws');
            return false;
        }
        console.log('About to verify event [%s]', eventId);
        let rid = uuidv1();


        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'verifyEvent',
                    message: 'Timeout for event verification expired'
                });
                reject('Timeout for verify event expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got event data', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        this._ws.send(JSON.stringify({
            type: Defines.Fanout.Signalling.VerifyEvent,
            request: Defines.Fanout.Signalling.VerifyEvent,
            eventId: eventId,
            rid
        }));

        return p;
    }

    async verifyTicket(eventId, ticketId/*, name, emailVerificationCode, title, company, email, skipAuth = false, role = null*/) {
        console.log('About to verify ticket [%s]', ticketId);
        let rid = uuidv1();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'verifyTicket',
                    message: 'Timeout for ticket verification expired'
                });
                reject('Timeout for ticket verification expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got verifyTicket response', data);
                    resolve(data);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let authData = {
            eventId,
            ticketId,
        }

        this._ws.send(JSON.stringify({
            message: 'Checking a ticket!',
            request: Defines.Fanout.Signalling.VerifyTicket,
            type: Defines.Fanout.Signalling.VerifyTicket,
            rid,
            payload: authData
        }));

        return p;
    }

    async createGuestTicket(name/*, name, emailVerificationCode, title, company, email, skipAuth = false, role = null*/) {
        console.log('About to create guest ticket for user [%s]', name);
        let rid = uuidv1();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'createGuestTicket',
                    message: 'Timeout for new guest ticket expired'
                });
                reject('Timeout for new guest ticket expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got createGuestTicket response', data);
                    resolve(data.ticketId);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let payload = {
            name,
        }

        this._ws.send(JSON.stringify({
            // message: 'Checking a ticket!',
            request: Defines.Fanout.Signalling.CreateGuestTicket,
            type: Defines.Fanout.Signalling.CreateGuestTicket,
            rid,
            payload
        }));

        return p;
    }

    async createMeGuestTicket(giid) {
        console.log('About to create guest ticket for current user for event [%s]', giid);
        let rid = uuidv1();

        let idToken = await firebaseClient.getUserToken();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'createMeGuestTicket',
                    message: 'Timeout for new guest ticket expired'
                });
                reject('Timeout for new guest ticket expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got createMeGuestTicket response', data);
                    resolve(data.ticketId);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let payload = {
            idToken, 
            invitationId: giid
        }

        this._ws.send(JSON.stringify({
            request: Defines.Fanout.Signalling.CreateMeGuestTicket,
            type: Defines.Fanout.Signalling.CreateMeGuestTicket,
            rid,
            payload
        }));

        return p;
    }

    async getMeInvitationLink(id = null) {
        console.log('About to get invitation link for current event');
        let rid = uuidv1();

        let idToken = await firebaseClient.getUserToken();

        let p = new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                this.emit('fanoutReqestExpired', {
                    where: 'getMeInvitationLink',
                    message: 'Timeout for new guest ticket expired'
                });
                reject('Timeout for new guest ticket expired');
            }, 20000);

            this.responses[rid] = (data) => {
                try {
                    clearTimeout(t);
                    console.log('Got getMeInvitationLink response', data);
                    if(data.result == 'error') {
                        // TODO: Set message according to status
                        reject({error: data.status, status: data.status});
                    }
                    else
                        resolve(data.link);
                    delete this.responses[rid];
                } catch (e) {
                    console.error('Error running callback %s', rid, e.message);
                    reject(e.message);
                }
            }
        });

        let payload = { 
            idToken,
            eventId: id
        }

        this._ws.send(JSON.stringify({
            request: Defines.Fanout.Signalling.GetInvitationLink,
            type: Defines.Fanout.Signalling.GetInvitationLink,
            rid,
            payload
        }));

        return p;
    }

    /**
     * Send chat message
     * @param props
     */
    sendChatMessage(payload, callState) {
        let parsePayload = JSON.parse(payload);
        parsePayload.rid = uuidv1();
        parsePayload = JSON.stringify(parsePayload);

        this.updateSignallingServer('chat-message', parsePayload, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendChatConnect(callState) {
        this.updateSignallingServer('chat-connect', {}, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendChatDisconnect(callState) {
        this.updateSignallingServer('chat-disconnect', {}, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendStartStreaming(callState) {
        this.updateSignallingServer('streaming', { streaming: true }, callState);
    }

    /**
     * Connect to chat
     * @param props
     */
    sendStopStreaming(callState) {
        this.updateSignallingServer('streaming', { streaming: false }, callState);
    }

    /**
     * Send end for audience
     * @param props
     */
    endEventForAudience(callState) {
        this.updateSignallingServer('end-audience', {}, callState);
    }

    /**
     * Knck to connect as speaker
     * @param props
     */
    sendKnockRequest(callState) {
        let rid = uuidv1();
        this.updateSignallingServer('knock-request', {}, callState, rid);
        let data = { ...callState, rid }
        this._store.dispatch(knocksActions.knocked(data));
    }

    /**
     * Grant knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockGranted(request_id, callState) {
        this.updateSignallingServer('knock-granted', {}, callState, request_id);
        this._store.dispatch(knocksActions.accept({ rid: request_id }));
    }

    /**
     * Reject knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockRejected(request_id, callState) {
        this.updateSignallingServer('knock-rejected', {}, callState, request_id);
    }

    /**
     * Revoke knock request
     * @param request_id received in reques as rid roperty
     * @param callState {{conferenceAlias, uid}}
     */
    sendKnockRevoked(request_id, callState) {
        this.updateSignallingServer('knock-revoked', {}, callState, request_id);
        let data = { ...callState, rid: request_id }
        this._store.dispatch(knocksActions.revoke(data));
    }

    /**
     * Verify email request
     * @param email user email
     */
    async verifyEmail(email, role) {
        let rid = uuidv1();
        let idToken = await firebaseClient.getUserToken();

        let verifyData = {
            email,
            role,
            idToken,
            participant_tos_accepted: true
        };

        return this.updateSignallingServer('verify-email', verifyData, {}, rid);
    }

    /**
     * Ban user
     * @param userId
     * @param banned
     */
    banUser(userId, banned) {
        let rid = uuidv1();
        this.updateSignallingServer('ban-user', { userId, banned }, {}, rid);
    }

    /**
    * Ban user by message id
    * @param id
    */
    banUserByMessage(id) {
        let rid = uuidv1();
        this.updateSignallingServer('chat-ban-user-by-message', { id }, {}, rid);
    }

    /**
    * Delete message by id
    * @param id
    */
    deleteMessage(id, callState) {
        let rid = uuidv1();
        this.updateSignallingServer('chat-delete-message', { id }, callState, rid);
        this._store.dispatch(chatActions.deleteMessageById(id));
    }


    /**
     * Return accepted knock if any
     */
    isKnockGranted() {
        let knocks = this._store.getState().knocks;
        for (const [rid, knock] of Object.entries(knocks)) {
            if (knock.accepted) {
                console.log("Found accepted knock", rid, knock);
                return knock;
            }
        }
        return null;
    }

    /**
     * Refresh token
     */
    getDolbyToken() {
        return new Promise((resolve, reject) => {
            this.updateSignallingServer('dolby-get-token', {});
        });
    }

    /**
     * Refresh token
     */
    refreshDolbyToken() {
        let user = this._store.getState().app.user

        return new Promise((resolve, reject) => {

            let grantedKnock = (user && user.role === 'presenter') ? user : this.isKnockGranted();
            this.onRefreshToken = resolve;

            this.updateSignallingServer('dolby-refresh-token', {}, grantedKnock, grantedKnock ? grantedKnock.rid : 'host');
        });
    }

    /**
     * Send call ended to WSS
     * @param props
     */
    sendEndCall(callState) {
        let rid = uuidv1();
        this.updateSignallingServer('end-call', {}, callState, rid);
    }

    /**
     * Verify email request
     * @param email user email
     */
    sendExtendCall(timestamp, alias) {
        let rid = uuidv1();
        this.updateSignallingServer('extend-call', { timestamp, alias }, {}, rid);
    }

    /**
     * Send GetReady signal to hosts
     * @param startAt timestamp
     */
    sendStreamingGetReady(startAt) {
        this.updateSignallingServer('get-ready', { startAt }, {});
    }

    /**
     * Send GetReady signal to hosts
     * @param startAt timestamp
     */
    sendStreamingAtEase() {
        this.updateSignallingServer('at-ease', {}, {});
    }

    /**
     * Send call ended to WSS
     * @param props
     */
    reconnectMeToEvent(callState) {
        const { email } = callState || { email: 'unknown' };
        let rid = uuidv1();
        let reconnectToken = window.localStorage.getItem(`reconnectToken:${(email || 'unknown').replace('@', '_')}`);
        this.updateSignallingServer('reconnect-me', { reconnectToken, email }, callState, rid);
    }

    /**
     * Destroy connection to fanout
     * @param props
     */
    disconnectFromFanout(props = {}) {
        console.log('About to disconnect from fanout');
        this._shouldReconnectToWS = false;
        if (this._keepAliveinterval) {
            clearInterval(this._keepAliveinterval);
            this._keepAliveinterval = null;
        }
        if (this._ws) {
            this._ws.close();
            this._ws = null;
        }
        const { uid, conferenceAlias } = props;
        // Subscribe from fanout events on firebase
        if (uid && conferenceAlias) {
            firebaseClient.unsubscribeFromSignallingClientChanges(uid, conferenceAlias);
        }
    }


    handleFanoutStateChanged(state) {
        try {
            console.log("handleFanoutStateChange", state);
            this.emit('fanoutStateChanged', state);
        }
        catch (err) {
            console.error(err);
        }
    }

    handleSignalClientMessage(data) {
        try {
            console.log("handleSignalClientMessage", data);
            let { rid, payload } = data;
            // This no good in long run cause I think anyone can just change this value in JS right?
            // if ((data.sessionId !== this.sessionId) && (data.request !== Defines.Fanout.Signalling.Closed || data.request !== Defines.Fanout.Signalling.ForceStop)) {
            //     console.error("This message was not meant for me, returning");
            //     return;
            // }

            if(data && data.status && data.status === Defines.Response.TooEarly && (data.error || (data.result && data.result === 'error'))) {
                this.emit('pageError', data);
                return;
            }

            switch (data.request) {
                case Defines.Fanout.Signalling.Initial:
                    this.emit('createRoomResponse', data);
                    break;
                case Defines.Fanout.Signalling.Transport.Create:
                    this.emit('createTransportResponse', data);
                    break;
                case Defines.Fanout.Signalling.Transport.Connect:
                    this.emit('connectTransportResponse', data);
                    break;
                case Defines.Fanout.Signalling.Media.Consume:
                    this.emit('consumeResponse', data);
                    break;
                case Defines.Fanout.Signalling.DataChannel.Consume:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.Consume');
                    this.emit('consumeDataResponse', data);
                    break;
                case Defines.Fanout.Signalling.DataChannel.Produce:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.Produce');
                    this.emit('produceDataResponse', data);
                    break;
                case Defines.Fanout.Signalling.Media.NewProducer:
                    this.emit('newProducerResponse', data);
                    break;
                case Defines.Fanout.Signalling.DataChannel.NewProducer:
                    console.warn('Don\'t want to see this: Defines.Fanout.Signalling.DataChannel.NewProducer');
                    this.emit('newDataProducerResponse', data);
                    break;
                case Defines.Fanout.Signalling.Closed:
                    this.emit('peerClosedResponse', data);
                    break;
                case Defines.Fanout.Signalling.Reconnect:
                    this.emit('reconnect');
                    break;
                case Defines.Fanout.Signalling.Stop:
                    this.emit('roomStopped', data);
                    this._store.dispatch(roomActions.setStreaming({ streaming: false }));
                    break;
                case Defines.Fanout.Signalling.End:
                    this.emit('roomClosed', data);
                    this._store.dispatch(roomActions.setStreaming({ streaming: false }));
                    break;
                case Defines.Fanout.Signalling.Auth:
                    if ((data.status !== Defines.Response.OK) && (data.status !== Defines.Response.NotAcceptable)) {
                        // Disconnect and don't reconnect
                        this.disconnectFromFanout();
                    }
                    if (data && data.payload && data.payload.reconnectToken) {
                        console.log('About to set reconnectToken to', data.payload.reconnectToken);
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown').replaceAll('@', '_');
                        window.localStorage.setItem(`reconnectToken:${email}`, data.payload.reconnectToken);
                    }
                    this.emit('authResponse', data);
                    break;
                case Defines.Fanout.Signalling.Register:
                    if (data.status !== Defines.Response.OK) {
                        // Disconnect and don't reconnect
                        //this.disconnectFromFanout();
                    }
                    if (data && data.payload && data.payload.reconnectToken) {
                        console.log('About to set reconnectToken to', data.payload.reconnectToken);
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown').replaceAll('@', '_');
                        window.localStorage.setItem(`reconnectToken:${email}`, data.payload.reconnectToken);
                    }
                    this.emit('registerResponse', data);
                    break;
                case Defines.Fanout.Signalling.ReconnectMe:
                    // if (data.status !== Defines.Response.OK) {
                    //     // Disconnect and don't reconnect
                    //     this.disconnectFromFanout();
                    // }
                    if (data && data.payload && data.payload.reconnectToken) {
                        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
                        this._store.dispatch(roomActions.setUserInfo({ userInfo }));
                        let email = (userInfo.email || 'unknown').replaceAll('@', '_');
                        window.localStorage.setItem(`reconnectToken:${email}`, data.payload.reconnectToken);
                    }
                    this.emit('reconnectResponse', data);
                    break;
                case Defines.Fanout.Signalling.VerifyLink:
                    if (data.status !== Defines.Response.OK) {
                        // Disconnect and don't reconnect
                        this.disconnectFromFanout();
                    }
                    if (payload && payload.eventItem)
                        this._store.dispatch(roomActions.setEventItem({ eventItem: payload.eventItem }));
                    this.emit('verifyLinkResponse', data);
                    break;
                case Defines.Fanout.Signalling.EndForAudience:
                    if (data && data.payload) {
                        this._store.dispatch(roomActions.setStreaming(data.payload));
                    }
                    break;
                case Defines.Fanout.Signalling.VerifyEvent:
                    if (data.status !== Defines.Response.OK) {
                        // Disconnect and don't reconnect
                        this.disconnectFromFanout();
                    }
                    if (payload && payload.eventItem)
                        this._store.dispatch(roomActions.setEventItem({ eventItem: payload.eventItem }));
                    // TODO: Set other stuff
                    this.emit('verifyEventResponse', data);
                    break;
                case Defines.Fanout.Signalling.Streaming:
                    this.emit('streamingResponse', data);
                    // TODO: Checked if stopped and end call if it is
                    if (data.payload && data.payload.conference && data.payload.conference === Defines.Fanout.Signalling.Ended) {
                        console.log('About to emit roomClosed');
                        this.emit('roomClosed', data);
                        this.cleanTheRoom();
                    } else {
                        if (data && data.result && data.result === 'error' && data.status) {
                            switch (data.status) {
                                case Defines.Response.Forbidden:
                                    this._store.dispatch(roomActions.displayMessage({ message: 'An event host must be present in the event before streaming can begin', timer: 4000, type: 'error' }));
                                    break;
                                case Defines.Response.TooManyRequests:
                                    this._store.dispatch(roomActions.displayMessage({ message: 'Too many started events', timer: 4000, type: 'error' }));
                                    break;
                                default:
                                    console.warn('Use default case');
                                    this._store.dispatch(roomActions.displayMessage({ message: 'Streaming error', timer: 4000, type: 'error' }));
                                    break;
                            }
                        } else {
                            this._store.dispatch(roomActions.setStreaming(data.payload));
                        }
                    }
                    break;
                case Defines.Fanout.Signalling.Media.ConsumeResume:
                    this.emit('consumeResume', data);
                    break;
                case Defines.Fanout.Signalling.VerifyEmail:
                    this.emit('verifyEmail', data); // true or false
                    console.log('Received email verification request status:', data);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Signalling.BanUser:
                    this.emit('userBanned', data.result); // true or false
                    console.log('Received user banned request status:', data);
                    if (data && data.result && data.result === 'success' && data.status && data.status === 200 && data.payload) {
                        this._store.dispatch(bannedActions.updateListItem(data.payload));
                    }
                    // alert(data.error);
                    break;
                // case Defines.Fanout.Signalling.Extend:
                //     console.log('Signalling.Extend Data:', data);
                //     // TODO: Set this info somewhere
                //     this.emit('call-extended', data.payload);
                //     if (this.overrunsTimeout) {
                //         clearTimeout(this.overrunsTimeout);
                //     }
                //     if (this.closeModalTimeout) {
                //         clearTimeout(this.closeModalTimeout);
                //     }
                //     this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: false }));
                //     this.sendExtendAgain(data.payload.timestamp);
                //     break;
                case Defines.Fanout.Signalling.Error:
                    this.emit('signallingError', data);
                    console.error('received server error:', data.error);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.DeleteMessage:
                    this.emit('deleteMessage', data);
                    console.log('received delete chat message:', data);
                    const { id, ids } = data && data.payload ? data.payload : { id: null, ids: null };
                    if (id) {
                        this._store.dispatch(chatActions.deleteMessageById(id));
                    }
                    if (ids && ids.length) {
                        this._store.dispatch(chatActions.deleteMessagesByIds(ids));
                    }
                    break;
                case Defines.Fanout.Chat.Message:
                    this.emit('chatMessage', data);
                    console.log('received chat message:', data);
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.Audience:
                    this.emit('chatAudienceUpdate', data);
                    console.log('received chat audience update:', data);
                    this._store.dispatch(audienceActions.updateList(data.payload));
                    break;
                case Defines.Fanout.Chat.Hosts:
                    this.emit('chatHostsUpdate', data);
                    console.log('received chat hosts update:', data);
                    this._store.dispatch(hostsActions.updateList(data.payload));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.BannedUsers:
                    this.emit('bannedUsers', data.payload);
                    console.log('received banned users:', data);
                    this._store.dispatch(bannedActions.updateList(data.payload));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Chat.History:
                    this.emit('chatHistory', data.payload);
                    console.log('received chat history:', data);
                    const { history } = data && data.payload ? data.payload : { history: [] };
                    this._store.dispatch(chatActions.addHistory(history));
                    // alert(data.error);
                    break;
                case Defines.Fanout.Knock.Request:
                    this.emit('knockRequest', data);
                    this._store.dispatch(knocksActions.knock(data.payload));
                    console.log('received knock request:', data);
                    break;
                case Defines.Fanout.Knock.Granted: {
                    console.log('received knock granted:', data);
                    let knocks = this._store.getState().knocks;
                    const payloadRid = data && data.payload && data.payload.rid ? data.payload.rid : null;
                    if (knocks[payloadRid])
                        this.emit('knockGranted', data);
                    else
                        console.warn('Could not find request', payloadRid);
                    this._store.dispatch(knocksActions.accept(data.payload));
                    break;
                }
                case Defines.Fanout.Knock.Rejected: {
                    console.log('received knock rejected:', data);
                    let knocks = this._store.getState().knocks;
                    if (knocks[rid])
                        this.emit('knockRejected', data);
                    else
                        console.warn('Could not find request', rid);
                    this._store.dispatch(knocksActions.reject(data.payload));
                    break;
                }
                case Defines.Fanout.Knock.Revoked:
                    this.emit('knockRevoked', data);
                    this._store.dispatch(knocksActions.revoke(data.payload));
                    console.log('received knock revoked:', data);
                    break;
                case Defines.Fanout.Dolby.RefreshToken:
                    console.log('received Refresh token:', data);
                    this.emit('tokenRefreshed', data.payload);
                    if (this.onRefreshToken)
                        this.onRefreshToken(data.payload ? data.payload.token : null);
                    break;
                case Defines.Fanout.Dolby.GetToken:
                    console.log('received Dolby token:', data);
                    this.emit('tokenFetched', data.payload);
                    break;
                case Defines.Fanout.DataChannel.SpeakerLayout:
                    console.log('SpeakerLayout Data:', data);
                    this.emit('speakerLayout', data.payload);
                    break;
                case Defines.Fanout.DataChannel.VideoPresentation:
                    console.log('VideoPresentation Data:', data);
                    this.emit('videoPresentation', data.payload);
                    break;
                case Defines.Fanout.Signalling.GetReady:
                    this.emit('streamingGetReady', data.payload);
                    this._store.dispatch(roomActions.setStreamingCounter({ streamingCounter: true }));
                    break;
                case Defines.Fanout.Signalling.AtEase:
                    this.emit('streamingAtEase', data.payload);
                    this._store.dispatch(roomActions.setStreamingCounter({ streamingCounter: false }));
                    break;
                case Defines.Fanout.Signalling.Cancelled:
                    this.emit('streamingCancelled', data.payload);
                    break;
                case Defines.Fanout.Signalling.Join:
                    this.emit('streamingJoin', data);
                    break;
                case Defines.Fanout.Signalling.VerifyTicket:
                    this.emit('verifyTicket', data);
                    break;
                case Defines.Fanout.Signalling.CreateGuestTicket:
                    this.emit('createGuestTicket', data);
                    break;
                case Defines.Fanout.Signalling.CreateMeGuestTicket:
                    this.emit('createMeGuestTicket', data);
                    break;
                default: console.warn('Unknown message', data);
            }
            if (this.responses[rid]) {
                console.log('Found response for request', rid)
                this.responses[rid](payload);
            }
        } catch (error) {
            console.error('failed to handle socket message', error);
        }
    }

    checkBannedUser(userId) {
        let hosts = this._store.getState().hosts;

        let retValue = true;

        if (userId && hosts && Object.entries(hosts).length &&
            hosts[userId] && hosts[userId].role &&
            ((hosts[userId].role === 'presenter') || (hosts[userId].role === 'moderator') || (hosts[userId].role === 'banned'))
        ) {
            retValue = false;
        }

        return retValue;
    }

    /**
     * Create transport
     * @param type
     * @param call_state
     */
    createTransport(payload, call_state) {
        const { conferenceAlias, routerId, sessionId, username } = call_state;

        console.log('createTransport()', payload.type);
        if (payload.type === "recv" || payload.type === "send") {
            let createTransport = {
                request: Defines.Fanout.Signalling.Transport.Create,
                fanoutId: process.env.fanoutId,
                name: username,
                routerId,
                sessionId,
                alias: conferenceAlias,
                payload
            };
            if (this._ws) {
                this._ws.send(JSON.stringify(createTransport));
            }
        }
        else
            console.error("createTransport Unknown Type:", payload.type);
    };


    /**
     * Create WebRTC Transport for Incoming Data + Media
     * @param action
     * @param payload
     * @param call_state
     * @returns {Promise<boolean>}
     */
    async updateSignallingServer(action, payload, call_state, rid = null) {

        console.log('About to send %s action to fanout [%s,%s,%s]', action, call_state, process.env.fanoutId);
        let request = 'unknown';

        let data = {
            request,
            fanoutId: process.env.fanoutId,
            name: call_state && call_state.username ? call_state.username : '',
            routerId: call_state && call_state.routerId ? call_state.routerId : null,
            sessionId: call_state && call_state.sessionId ? call_state.sessionId : null,
            uid: call_state && call_state.uid ? call_state.uid : null,
            alias: call_state && call_state.conferenceAlias ? call_state.conferenceAlias : '',
            payload,
            rid: rid || uuidv1(),
        }

        switch (action) {
            case 'create-room':
                data.request = Defines.Fanout.Signalling.Initial;
                break;
            case 'create-transport':
                data.request = Defines.Fanout.Signalling.Transport.Create;
                break;
            case 'connect-transport':
                data.request = Defines.Fanout.Signalling.Transport.Connect;
                break;
            case 'consume':
                data.request = Defines.Fanout.Signalling.Media.Consume;
                break;
            case 'consume-resume':
                data.request = Defines.Fanout.Signalling.Media.ConsumeResume;
                break;
            case 'data-consume':
                data.request = Defines.Fanout.Signalling.DataChannel.Consume;
                break;
            case 'data-consume-ack':
                data.request = Defines.Fanout.Signalling.DataChannel.ConsumeAck;
                break;
            case 'produce':
                data.request = Defines.Fanout.Signalling.Media.Produce;
                break;
            case 'data-produce':
                data.request = Defines.Fanout.Signalling.DataChannel.Produce;
                break;
            case 'stop':
                data.request = Defines.Fanout.Signalling.Stop;
                break;
            case 'streaming':
                data.request = Defines.Fanout.Signalling.Streaming;
                break;
            case 'end-audience':
                data.request = Defines.Fanout.Signalling.EndForAudience;
                break;
            case 'chat-connect':
                data.request = Defines.Fanout.Chat.Connect;
                break;
            case 'chat-disconnect':
                data.request = Defines.Fanout.Chat.Disconnect;
                break;
            case 'chat-history':
                data.request = Defines.Fanout.Chat.History;
                break;
            case 'chat-message':
                data.request = Defines.Fanout.Chat.Message;
                break;
            case 'knock-request':
                data.request = Defines.Fanout.Knock.Request;
                break;
            case 'knock-granted':
                data.request = Defines.Fanout.Knock.Granted;
                break;
            case 'knock-rejected':
                data.request = Defines.Fanout.Knock.Rejected;
                break;
            case 'knock-revoked':
                data.request = Defines.Fanout.Knock.Revoked;
                break;
            case 'dolby-get-token':
                data.request = Defines.Fanout.Dolby.GetToken;
                break;
            case 'dolby-refresh-token':
                data.request = Defines.Fanout.Dolby.RefreshToken;
                break;
            case 'end-call':
                data.request = Defines.Fanout.Signalling.End;
                break;
            case 'verify-email':
                data.request = Defines.Fanout.Signalling.VerifyEmail;
                break;
            case 'ban-user':
                data.request = Defines.Fanout.Signalling.BanUser;
                break;
            case 'chat-delete-message':
                data.request = Defines.Fanout.Chat.DeleteMessage;
                break;
            case 'chat-ban-user-by-message':
                data.request = Defines.Fanout.Chat.BanUserByMessage
                break;
            case 'reconnect':
                data.request = Defines.Fanout.Signalling.Reconnect
                break;
            case 'reconnect-me':
                data.request = Defines.Fanout.Signalling.ReconnectMe
                break;
            case 'extend-call':
                data.request = Defines.Fanout.Signalling.Extend
                break;
            case 'get-ready':
                data.request = Defines.Fanout.Signalling.GetReady
                break;
            case 'at-ease':
                data.request = Defines.Fanout.Signalling.AtEase
                break;
            case 'cancelled':
                data.request = Defines.Fanout.Signalling.Cancelled
                break;
            case 'join':
                data.request = Defines.Fanout.Signalling.Join
                break;
            case 'verify-ticket':
                data.request = Defines.Fanout.Signalling.VerifyTicket
                break;
            case 'create-guest-ticket':
                data.request = Defines.Fanout.Signalling.CreateGuestTicket
                break;
            case 'create-me-guest-ticket':
                data.request = Defines.Fanout.Signalling.CreateMeGuestTicket
                break;
            default:
                break;
        }
        try {
            if (this._ws) {
                this._ws.send(JSON.stringify(data));
                console.log("FanoutSignalling::%s()   Sent object %o to WS", "update", data);
            }
            else
                console.warn("FanoutSignalling::%s()   No WS defined", ["update"]);
        } catch (e) {
            console.log('FanoutSignalling::%s()  Could not send ws data [%s]', ['update', e.messge]);
        }
        return Promise.resolve(true);
    };

    deleteSignallingClientEntry(call_state) {
        const { conferenceAlias } = call_state;


        let data = {
            request: "delete-entry",
            alias: conferenceAlias,
            fanoutId: process.env.fanoutId
        };
        // this.sendToSignallingServerUpdate(data);
        try {
            if (this._ws) {
                this._ws.send(JSON.stringify(data));
                console.log("FanoutSignalling::%s()   Sent object %o to WS", "update", data);
            }
            else
                console.warn("FanoutSignalling::%s()   No WS defined", ["update"]);
        } catch (e) {
            console.log('FanoutSignalling::%s()  Could not send ws data [%s]', ['update', e.messge]);
        }
    }

    cleanTheRoom() {
        this._store.dispatch(knocksActions.clear());
        this._store.dispatch(chatActions.clearMessages());
        this._store.dispatch(audienceActions.clearList());
        this._store.dispatch(hostsActions.clearList());
        this._store.dispatch(bannedActions.clearList());
        this._store.dispatch(roomActions.clear());
    }

    checkOverrunsTimeout(eventItem, role, isOwner) {
        console.log('call checkOverrunsTimeout', eventItem, role);

        if (this.overrunsTimeout) {
            return;
        } else {
            if (eventItem && eventItem.startDate && eventItem.duration && role && ((role === 'presenter') || (role === 'moderator'))) {
                let now = Date.now(), creator = false;

                if (isOwner) {
                    creator = true;
                }

                let timeoutTimestamp = moment(new Date(eventItem.startDate ? eventItem.startDate : moment().unix())).add(parseInt(eventItem.duration) + 1, 'hours').subtract(creator ? 1 : 0, "minutes").valueOf() - now;

                if (timeoutTimestamp) {
                    this.overrunsTimeout = setTimeout(() => {
                        this.setCloseModalTimeout();
                        this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: true }));
                    }, parseInt(timeoutTimestamp) || 0);
                }
            }
        }
    }

    sendExtendAgain(timestamp) {
        if (this.overrunsTimeout) {
            clearTimeout(this.overrunsTimeout);
        }

        this.overrunsTimeout = setTimeout(() => {
            this.setCloseModalTimeout();
            this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: true }));
        }, parseInt(timestamp - Date.now()) || 0);
    }

    setCloseModalTimeout() {
        if (this.closeModalTimeout) {
            clearTimeout(this.closeModalTimeout);
        }

        this.closeModalTimeout = setTimeout(() => {
            this._store.dispatch(roomActions.setModalEventOverrun({ modalEventOverrun: false }));
            this._store.dispatch(roomActions.setGoingOut({ goingOut: true }));
        }, 5 * 60 * 1000);
    }

    // --- Other -------------------------------------------------------------------------------------------

    /**
     * Set Redux store
     * @param store
     */
    setStore(store) {
        this._store = store;
    }
    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            // let state = getState();
            let res = next(action);
            switch (action.type) {
                case roomActions.SET_INBOUND_VIDEO_ACTION: {
                    console.log('call room/setInboundVideo action', action);

                    if (this && this.emit) {
                        console.log('About to enable/disable remote video ', action.payload.inboundVideo);

                        this.emit('enableRemoteVideo', action.payload.inboundVideo)
                    }
                    break;
                }
                default: { }
            }
            return res;
        };
    }
}
let fc = new FanoutClient();
window.fanoutClient = fc;
export default fc;