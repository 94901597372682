import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classNames from "classnames";
import Message from '../Header/Message';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import PaymentWrapper from '../Payment/PaymentWrapper';
import VideoWrapper from './VideoWrapper';
import Cards from '../Widget/Cards';
import Feedback from "../Widget/Feedback";
import BecomeHost from '../Advertisement/BecomeHost';
import linkIcon from '../../../resources/icons/link5.svg';
import playIcon from '../../../resources/icons/event-play.svg';
import closeIcon from '../../../resources/icons/event-x.svg';
import copyIcon from '../../../resources/icons/event-copy.svg';
import editIcon from '../../../resources/icons/event-edit.svg';
import {
    toSeoUrl,
    getQueryVariable,
    stringToBoolean,
    appLauncherOpenVanityUrl,
    isIOSPrice,
    isIOS
} from "../../utils/text";
import * as appActions from "../../actions/app_actions";
import { Editor } from "react-draft-wysiwyg";
import firebaseClient from "../../utils/FirebaseClient";
import appClient from '../../utils/AppClient';
import { Helmet } from "react-helmet";
import logo from "../../../resources/icons/NF0620_Logo.png";
import * as mystuffActions from "../../actions/mystuff_actions";
import * as paymentActions from "../../actions/payment_actions";
import { APP_STAGE_LOADED } from '../../actions/app_actions';

class EventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item || null,
            ticketId: props.ticketId || null,
            paymentWrapper: false,
            videoWrapper: false,
            ticketType: 'stream-ticket',
            cancelModal: false,
            hideModal: false,
            deleting: false,
            host: false,
            coHost: false,
            guest: false,
            moderator: false,
            eventSoldTickets: 0,
            streamSoldTickets: 0,
            processing: false,
            ticketsLoaded: false,
            readMore: false,
            readMoreBtn: false,
            feedback: false,
            feedbackItem: null,
            message: false,
            messageText: false,
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.descRef = React.createRef();
        this.editorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        this.handleChangeSoldTicketsNumber = this.handleChangeSoldTicketsNumber.bind(this);
        this.eventId = null;
    }

    listenToPublicEvent(room) {
        this.eventId = room;
        //console.log('call listenToPublicEvent', room);
        if (room) {
            firebaseClient.listenToPublicEvent(this.eventId);
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.getStreamItem.call(this);
        this.checkUserTickets.call(this);
        this.publicEventTimeout = setInterval(() => {
            if (!this.eventId)
                return;
            // console.log('Listening to event', this.eventId);
            this.listenToPublicEvent(this.eventId);
        }, 5000);

        if (!process.env.platform || (process.env.platform && process.env.platform !== 'mobile')) {
            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let userAgentLC = ua && ua.toLowerCase ? ua.toLowerCase() : '';
            let notSupported =
                userAgentLC.indexOf('instagram') > -1
                    || userAgentLC.indexOf('opera') > -1
                    || userAgentLC.indexOf('opr') > -1
                    ? true : false;
            let iosChromeNotify = isIOS() && userAgentLC.indexOf('crios') > -1;
            let message;
            if (iosChromeNotify) {
                message = 'Please use safari app in order to use No Filter Network.';
            } else if (notSupported) {
                message = 'Please use safari or chrome app in order to use No Filter Network.';
            }
            this.setState({
                message: notSupported || iosChromeNotify,
                messageText: message
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { item, ticketId } = this.state;
        const { match, user, appStage, showLogInNotification } = this.props;

        let showPayment = stringToBoolean(getQueryVariable('checkout'));

        let prevMatchId =
            prevProps.match && prevProps.match.params && prevProps.match.params.eventId ?
                prevProps.match.params.eventId :
                prevProps.match && prevProps.match.params && prevProps.match.params.streamId ?
                    prevProps.match.params.streamId :
                    null;
        let matchId =
            match && match.params && match.params.eventId ?
                match.params.eventId :
                match && match.params && match.params.streamId ?
                    match.params.streamId :
                    null;

        if (prevMatchId && prevMatchId !== matchId) {
            firebaseClient.stopListeningToPublicEvent(prevMatchId);
        }
        if (prevMatchId && matchId && prevMatchId !== matchId) {
            this.setState({
                ticketId: null,
                item: null,
                ticketsLoaded: false,
                host: false,
                coHost: false,
                guest: false,
                moderator: false
            }, () => {
                this.checkUserTickets.call(this);
                this.getStreamItem.call(this);
            });
        }
        if (prevProps.user != user) {
            this.checkUserTickets.call(this);
            this.getStreamItem.call(this);
        }
        if (appStage && prevProps.appStage !== appStage && appStage === APP_STAGE_LOADED && !user && showLogInNotification && showPayment) {
            showLogInNotification(true);
        }
        if (prevState.item != item && item && ticketId) {
            firebaseClient.stopListeningToPublicEvent(prevState.item.id);
            if (ticketId && item && item.uid && user && user.uid && item.uid !== user.uid) {
                this.listenToPublicEvent(item.id);
                firebaseClient.subscribeToFanoutStateChanges(process.env.fanoutId);
                // firebaseClient.waiting(item.id);
            }
        }
        if (prevState.ticketId != ticketId && ticketId && item) {
            if (ticketId && item && item.uid && user && user.uid && item.uid !== user.uid) {
                this.listenToPublicEvent(item.id);
                firebaseClient.subscribeToFanoutStateChanges(process.env.fanoutId);
                // firebaseClient.waiting(item.id);
            }
        }
    }

    handleClickFeedback(item) {
        this.setState({
            feedback: true,
            feedbackItem: item
        }, () => {
            appClient.disableScroll(true);
        });
    }

    closeFeedback() {
        this.setState({
            feedback: false,
            feedbackItem: null
        }, () => {
            appClient.disableScroll(false);
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                cancelModal: false,
                hideModal: false
            });
        }
    }

    checkUserTickets() {
        const { match } = this.props || {};

        const { tickets, user, appStage, showLogInNotification } = this.props;

        if ((!match || !match.params || !match.params.streamId) && (!match || !match.params || !match.params.eventId))
            return;

        if (!this.state.item || !this.state.item.uid || (!user && appStage && appStage !== APP_STAGE_LOADED))
            return;

        let streamId = match.params.eventId ? match.params.eventId : match.params.streamId;
        let authorId = this.state.item ? this.state.item.uid : null;

        //console.log(this.state.item)

        // console.log("Stream ID", streamId, this.state);

        // Check current user tickets for selected stream
        firebaseClient.checkUserTickets(streamId, authorId).then(result => {
            let found = false;

            this.setState({
                ticketsLoaded: true
            });

            if (result && result.data && result.data.length) {
                result.data.forEach(payment => {
                    if (payment.eventId === streamId || payment.showId === "*") {
                        // console.log('found', payment);
                        if (payment.paymentStatus === 'success' || // Listener
                            payment.status == "confirmed" // Cohost
                        ) {
                            found = true;
                            this.setState({
                                ticketId: payment.paymentId || payment.id
                            });
                        }
                        if (payment.showId && payment.showId === "*" && payment.type === "subscription") {
                            found = true;
                            this.setState({
                                ticketId: payment.paymentId || payment.id
                            });
                        }
                    }
                });

                if (this.state.item && this.state.item.uid && !found) {
                    let showPayment = stringToBoolean(getQueryVariable('checkout'));

                    if (showPayment) {
                        if (user && user.id && this.state.item.uid !== user.id && !(this.state.item.type === 'stream' && !this.state.item.price)) {
                            this.setState({
                                paymentWrapper: showPayment
                            });
                        } else if (!user && showLogInNotification) {
                            showLogInNotification(true);
                        }
                    }
                }

                if (this.state.item) {
                    let showVideo = stringToBoolean(getQueryVariable('video'));

                    if (showVideo && this.state.item && this.state.item.type && this.state.item.type === 'stream') {
                        if (this.state.ticketId && this.state.item.type && this.state.item.type === 'stream' && this.state.item.parts && this.state.item.parts.length
                            || this.state.item.type && this.state.item.type === 'stream' && (this.state.ticketId || this.state.coHost || this.state.guest) && this.state.item.parts && this.state.item.parts.length
                            || this.state.item.uid && user && user.uid && user.uid === this.state.item.uid && this.state.item.type && this.state.item.type === 'stream' && this.state.item.status && this.state.item.status !== 'cancelled' && this.state.item.parts && this.state.item.parts.length) {
                            this.setState({
                                videoWrapper: true
                            });
                        } else if (user && user.id && this.state.item.uid !== user.id && isIOSPrice(this.state.item.price)) {
                            this.handleBuyTicket(this.state.ticketId, this.state.item);
                        } else if (!user && showLogInNotification) {
                            showLogInNotification(true);
                        }
                    }
                }
            } else if (!result || !result.data || !result.data.length) {
                if (!found) {
                    let processingTicket = [tickets].filter(item => item.paymentStatus && item.paymentStatus === 'in_processing' && item.eventId === streamId);

                    if (processingTicket.length > 0) {
                        found = true;
                        this.setState({
                            ticketId: processingTicket[0].id,
                        });
                    }
                }

                if (this.state.item && this.state.item.uid && !found) {
                    let showPayment = stringToBoolean(getQueryVariable('checkout'));

                    if (showPayment) {
                        if (user && user.id && this.state.item.uid !== user.id && !(this.state.item.type === 'stream' && !this.state.item.price)) {
                            this.setState({
                                paymentWrapper: showPayment
                            });
                        } else if (!user && showLogInNotification) {
                            showLogInNotification(true);
                        }
                    }
                }

                if (this.state.item) {
                    let showVideo = stringToBoolean(getQueryVariable('video'));

                    if (showVideo && this.state.item && this.state.item.type && this.state.item.type === 'stream') {
                        if (this.state.ticketId && this.state.item.type && this.state.item.type === 'stream' && this.state.item.parts && this.state.item.parts.length
                            || this.state.item.type && this.state.item.type === 'stream' && (this.state.ticketId || this.state.coHost || this.state.guest) && this.state.item.parts && this.state.item.parts.length
                            || this.state.item.uid && user && user.uid && user.uid === this.state.item.uid && this.state.item.type && this.state.item.type === 'stream' && this.state.item.status && this.state.item.status !== 'cancelled' && this.state.item.parts && this.state.item.parts.length) {
                            this.setState({
                                videoWrapper: true
                            });
                        } else if (user && user.id && this.state.item.uid !== user.id && isIOSPrice(this.state.item.price)) {
                            this.handleBuyTicket(this.state.ticketId, this.state.item);
                        } else if (!user && showLogInNotification) {
                            showLogInNotification(true);
                        }
                    }
                }
            }
        });
    }

    getStreamItem() {
        const { match, user } = this.props || {};

        if ((!match || !match.params || !match.params.streamId) && (!match || !match.params || !match.params.eventId))
            return;

        let streamId = match.params.streamId;
        let eventId = match.params.eventId;

        // console.log("Stream ID", streamId);

        if (eventId) {
            this.setState({
                ticketType: 'event-ticket'
            });
        }
        // console.log("Stream ID", streamId);

        // Check current user tickets for selected stream
        firebaseClient.getOne(eventId ? '/calendar' : '/posts', eventId ? eventId : streamId).then(result => {
            // console.log("Result: ", result);

            if (!result)
                return;

            this.setState({
                item: result.data
            }, () => {
                const { user } = this.props;
                if (this.state.item && this.state.item.id) {
                    firebaseClient.listenToSoldTicket(this.state.item, this.handleChangeSoldTicketsNumber);
                }
                if (this.state.item && this.state.ticketId && this.state.item.uid && user && user.uid && this.state.item.uid !== user.uid) {
                    this.listenToPublicEvent(this.state.item.id);
                    firebaseClient.subscribeToFanoutStateChanges(process.env.fanoutId);
                    // firebaseClient.waiting(this.state.item.id);
                }
                if (this.state.item && this.state.item.uid && user && user.id && this.state.item.uid === user.id) {
                    this.setState({
                        host: true
                    });
                } else {
                    this.setState({
                        host: false
                    });
                }
                if (this.state.item && this.state.item.id && this.state.item.cohosts && this.state.item.cohosts.length && user && user.id) {
                    this.state.item.cohosts.map((cohost) => {
                        if (cohost && cohost.id && cohost.id === user.id) {
                            this.setState({
                                coHost: true
                            }, () => {
                                if (cohost.status && cohost.status === 'confirmed') {
                                    this.listenToPublicEvent(this.state.item.id);
                                    // firebaseClient.waiting(this.state.item.id, 'cohost');
                                }
                            });
                        }
                    })
                } else {
                    this.setState({
                        coHost: false
                    });
                }
                if (this.state.item && this.state.item.id && this.state.item.guestSpeakers && this.state.item.guestSpeakers.length && user && user.id) {
                    this.state.item.guestSpeakers.map((guest) => {
                        if (guest && guest.id && guest.id === user.id) {
                            this.setState({
                                guest: true
                            }, () => {
                                if (guest.status && guest.status === 'confirmed') {
                                    this.listenToPublicEvent(this.state.item.id);
                                }
                            });
                        }
                    })
                } else {
                    this.setState({
                        guest: false
                    });
                }
                if (this.state.item && this.state.item.id && this.state.item.moderators && this.state.item.moderators.length && user && user.id) {
                    this.state.item.moderators.map((moderator) => {
                        if (moderator && moderator.id && moderator.id === user.id) {
                            this.setState({
                                moderator: true
                            }, () => {
                                if (moderator.status && moderator.status === 'confirmed') {
                                    this.listenToPublicEvent(this.state.item.id);
                                    // firebaseClient.waiting(this.state.item.id, 'moderator');
                                }
                            });
                        }
                    })
                } else {
                    this.setState({
                        moderator: false
                    });
                }
                // console.log("Now call check tickets!");

                if (this.isEllipsisActive()) {
                    this.setState({
                        readMoreBtn: true
                    });
                }
                this.checkUserTickets.call(this);
            });
        });
    }

    componentWillUnmount() {
        this.unmountComponent();
    }

    handleBeforeUnload() {
        this.unmountComponent();
    }

    unmountComponent() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        const { item } = this.state;

        if (this.publicEventTimeout) {
            clearInterval(this.publicEventTimeout);
        }

        if (item && item.id) {
            firebaseClient.stopListeningToPublicEvent(item.id);
            firebaseClient.unsubscribeToFanoutStateChanges(process.env.fanoutId);
            firebaseClient.stopListeningToSoldTicket(item);
        }
    }

    handleChangeSoldTicketsNumber(number, type) {
        switch (type) {
            case 'event':
                this.setState({
                    eventSoldTickets: number
                });
                break;
            case 'stream':
                this.setState({
                    streamSoldTickets: number
                });
                break;
            default:
                this.setState({
                    eventSoldTickets: number
                });
                break;
        }
    }

    handleBuyTicket(ticketId, item) {
        const { user, showLogInNotification, processingPayment, successPayment, resetPaymentValue } = this.props;

        if (item && item.type && item.type === 'stream' && !item.price) {
            if (user) {
                this.setState({
                    videoWrapper: true
                }, () => {
                    if (!ticketId) {
                        processingPayment();
                        firebaseClient.reserveTicket(item).then((res) => {
                            if (res && res.data) {
                                successPayment();
                                setTimeout(() => {
                                    this.handleShowStreamWrapper(res.data.paymentId)
                                    resetPaymentValue();
                                }, 5000);
                            }
                        });
                    }
                });
            } else if (!user && showLogInNotification) {
                showLogInNotification(true);
            }
        } else if (item && !item.price) {
            if (user) {
                this.setState({
                    paymentWrapper: true
                }, () => {
                    processingPayment();
                    firebaseClient.reserveTicket(item).then((res) => {
                        if (res && res.data) {
                            successPayment();
                            setTimeout(() => {
                                this.handleShowStreamWrapper(res.data.paymentId)
                                resetPaymentValue();
                            }, 5000);
                        }
                    });
                });
            } else if (!user && showLogInNotification) {
                showLogInNotification(true);
            }
        } else if (!ticketId) {
            if (process.env.platform && process.env.platform === 'mobile' && item && item.type && item.id && item.price) {
                appLauncherOpenVanityUrl(item.type, item.id);
            } else {
                this.setState({
                    paymentWrapper: true
                }, () => {
                    if (!user && showLogInNotification) {
                        showLogInNotification(true);
                    }
                });
            }
        }
    }

    isEllipsisActive() {
        let valid = false;

        let editorRef = this.editorRef ? this.editorRef : null;
        let elementRef = this.descRef && this.descRef.current ? this.descRef.current : null;

        if (editorRef && editorRef.editorContainer && editorRef.editorContainer.children && editorRef.editorContainer.children.length && editorRef.editorContainer.children[0] && (editorRef.editorContainer.children[0].offsetHeight < editorRef.editorContainer.children[0].scrollHeight)) {
            valid = true;
        } else if (elementRef && (elementRef.offsetHeight < elementRef.scrollHeight)) {
            valid = true;
        }

        return valid;
    }

    handleCopy(item) {
        if (item && item.id) {
            let page = item.type && item.type === 'event' ? 'event' : 'stream';
            const link = item.sharedLink
                ? item.sharedLink
                : `${firebaseClient.getDomain()}/${page}/${item.id}`;

            navigator.clipboard.writeText(link);
        }
    }

    handleResetDefaultValue() {
        this.setState({
            paymentWrapper: false,
            videoWrapper: false,
            ticketType: 'stream-ticket'
        });
    }

    handleDeleteItem(id, type) {
        //console.log('handleDeleteItem', id, type)
        this.setState({ deleting: true });
        firebaseClient.cancelEventOrPost({ postId: id, eventId: id, type: type }).then((result) => {
            // console.log("result", result);
            this.setState({ deleting: false, cancelModal: false });
            this.setState({
                item: result
            });
        });
    }

    handleHideItem(id, uid) {
        const { tickets } = this.props;
        let ticketId = tickets[id].id;
        this.setState({ deleting: true });
        firebaseClient.hideCohostVideo({ streamId: id, uid: uid, ticketId: ticketId }).then((result) => {
            // console.log("result", result);
            this.setState({
                deleting: false,
                hideModal: false,
                item: result
            });
        });
    }

    handleShowStreamWrapper(id) {

        const { item } = this.state;
        const { user, addProcessingTicket } = this.props;

        let ticket = {};

        ticket.id = item.id + "_processing";
        ticket.eventId = item.id;
        ticket.paymentId = id;
        ticket.paymentStatus = "in_processing";
        ticket.status = "processing";
        ticket.type = `${item.type}-ticket`;
        ticket.role = "listener";
        ticket.userId = user.uid;

        if (addProcessingTicket) {
            addProcessingTicket(ticket);
        }
        this.setState({
            paymentWrapper: false,
            ticketId: id
        });
    }

    handleShowVideoWrapper() {
        this.setState({
            videoWrapper: true
        });
    }

    render() {
        const { item, ticketId, paymentWrapper, videoWrapper, eventSoldTickets, streamSoldTickets, ticketType, message, messageText,
            cancelModal, hideModal, deleting, host, coHost, guest, moderator, ticketsLoaded, readMore, readMoreBtn, feedback, feedbackItem } = this.state;
        const { overlay, user, vanityOverlay, mobile, upcomingEvents, auth, roles, appStage } = this.props;

        let uevents = upcomingEvents ? upcomingEvents.filter((event) => {
            if (event && event.id && item && item.id && event.id !== item.id && (event.uid === item.uid
                || firebaseClient.checkCoHost(event, item.uid))) {
                return event;
            }
        }) : null;

        // console.log("Event status", item)
        let now = Date.now();
        let period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;
        let enterLivePeriod = (item && item.startDate ? item.startDate - (30 * 60 * 1000) : null);

        return (
            <div>
                {item ? <Helmet>
                    {/*Primary Meta Tags*/}
                    <meta charSet="utf-8" />
                    <title>{`NoFilter.net - ${item.title}`}</title>
                    <meta name="title" content={`NoFilter.net - ${item.title}`} />
                    <meta name="description" content={item.descriptionText || item.description} />
                    <link rel="canonical" href={`https://nofilter.net/event/${item.id}/${toSeoUrl(item.title)}`} />
                    {/*Open Graph / Facebook*/}
                    <meta property="og:url" content={`https://nofilter.net/event/${item.id}/${toSeoUrl(item.title)}`} />
                    <meta property="og:title" content={`NoFilter.net - ${item.title}`} />
                    <meta property="og:description" content={item.descriptionText || item.description} />
                    <meta property="og:image"
                        content={item && item.archiveCustomCover ? item.archiveCustomCover : item && item.poster ? item.poster : item && item.authorPic ? item.authorPic : logo} />
                    {/*Twitter*/}
                    <meta property="twitter:url"
                        content={`https://nofilter.net/event/${item.id}/${toSeoUrl(item.title)}`} />
                    <meta property="twitter:title" content={`NoFilter.net - ${item.title}`} />
                    <meta property="twitter:description" content={item.descriptionText || item.description} />
                    <meta property="twitter:image"
                        content={item && item.archiveCustomCover ? item.archiveCustomCover : item && item.poster ? item.poster : item && item.authorPic ? item.authorPic : logo} />
                </Helmet> : null}
                {item && appStage && appStage === APP_STAGE_LOADED ?
                    <div className='event-page-wrapper'>
                        <div className='event-page-details' style={{ backgroundImage: `url(${item && item.archiveCustomCover ? item.archiveCustomCover : item && item.poster ? item.poster : item && item.authorPic ? item.authorPic : null})` }}>
                            <div className='event-details'>
                                {item && item.status && item.status !== "cancelled" ?
                                    <div className='event-header-buttons'>
                                        {user && host && (user.administrator || enterLivePeriod && enterLivePeriod < now) && (item.startDate && item.startDate + period > now || item.status && item.status === 'live') && item.type && (item.type === 'event' || (item.type === 'stream' && item.startDate + period > now)) ?
                                            <Link to={`/streaming/${item.id}`}>
                                                <div className='event-header-button join-e'>
                                                    <img src={playIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Enter<br />Live<br />Stream
                                                        </p>
                                                </div>
                                            </Link>
                                            : (!host && ticketId && item.startDate + period > now && item.status && item.status !== 'live' && item.type && item.type !== 'stream') ?
                                                <div className='event-header-button copy-e disabled-e'>
                                                    <img src={closeIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Event<br />Has Not<br />Started
                                                        </p>
                                                </div>
                                                : null
                                        }
                                        {user && user.uid && item && item.id && item.uid && user.uid === item.uid && item.type && item.type === 'event' ?
                                            <div
                                                className='event-header-button close-e'
                                                onClick={() => this.setState({
                                                    cancelModal: true
                                                })}
                                            >
                                                <img src={closeIcon} loading='lazy' />
                                                {item && item.type == 'event' && item.startDate && item.startDate + period > now ?
                                                    <p className='event-header-text'>
                                                        Cancel<br />Event
                                                        </p>
                                                    : item && item.type == 'event' ?
                                                        <p className='event-header-text'>
                                                            Delete<br />Event
                                                        </p>
                                                        :
                                                        <p className='event-header-text'>
                                                            Delete<br />Stream
                                                        </p>
                                                }
                                            </div>
                                            : null
                                        }
                                        {user && user.uid && item && item.type == 'stream' && firebaseClient.checkCoHost(item, user.uid) ?
                                            <div
                                                className='event-header-button close-e'
                                                onClick={() => this.setState({
                                                    hideModal: true
                                                })}
                                            >
                                                <img src={closeIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Hide<br />Stream
                                                    </p>
                                            </div>
                                            : null
                                        }
                                        <div className='event-header-button copy-e'
                                            onClick={() => this.handleCopy(item)}
                                            data-for="event-copy-button"
                                            data-tip="Copied!"
                                        >
                                            <img src={linkIcon} loading='lazy' />
                                            <p className='event-header-text'>
                                                Copy<br />Event<br />Link
                                            </p>
                                            <ReactTooltip
                                                id="event-copy-button"
                                                isCapture={true}
                                                className=""
                                                place="top"
                                                type="dark"
                                                effect="solid"
                                                event="click"
                                                eventOff="click"
                                                delayHide={2000}
                                            />
                                        </div>
                                        {item && item.type && item.type === 'event' && item.id && item.uid && user && user.uid && user.uid === item.uid && item.status && item.status !== "cancelled" ?
                                            <Link to={`/calendar/${item.id}/${window.btoa('copyEvent')}`}>
                                                <div className='event-header-button copy-e'>
                                                    <img src={copyIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Duplicate<br />Event
                                                    </p>
                                                </div>
                                            </Link>
                                            : null
                                        }
                                        {item && item.id && item.uid && user && user.uid && user.uid === item.uid && item.startDate && item.startDate + period > now && item.status && item.status !== "cancelled" ?
                                            <Link to={item.type === 'event' ? `/calendar/${item.id}` : `/edit-stream/${item.id}`}>
                                                <div className='event-header-button edit-e'>
                                                    <img src={editIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Edit<br />Event
                                                            </p>
                                                </div>
                                            </Link>
                                            : null}
                                    </div> : item && item.status && item.status === "cancelled" ?
                                        <div className='event-header-buttons'>
                                            <div className='event-header-button close-e disabled-e'>
                                                <img src={closeIcon} loading='lazy' />
                                                {item && item.type == 'event' && item.startDate && item.startDate + period > now ?
                                                    <p className='event-header-text'>Event<br />Cancelled</p>
                                                    : item && item.type == 'event' ?
                                                        <p className='event-header-text'>Event<br />Deleted</p>
                                                        :
                                                        <p className='event-header-text'>Stream<br />Deleted</p>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                <p className='event-title'>{item.title}</p>
                                <Link
                                    to={`/browse?b=${item.cat.toLowerCase()}`}
                                    className='event-category'
                                >
                                    {item.cat}
                                </Link>
                                <Link to={`/profile/${item.uid}`} title={item.author} className='event-author-details'>
                                    <img className='author-img' src={item.authorPic} loading='lazy' />
                                    <div className='author-name'>{item.author}</div>
                                </Link>
                                {item && item.uid && user && user.uid && (item.uid === user.uid || user.administrator || firebaseClient.checkCoHost(item, user.uid) || firebaseClient.checkModerator(item, user.uid)) ?
                                    <div className='event-analytics'>
                                        {item.type === 'stream' ?
                                            <p>{`Tickets Sold Live/Recording: ${eventSoldTickets || 0}/${streamSoldTickets || 0}`}</p>
                                            :
                                            <p>{`Tickets Sold: ${eventSoldTickets || 0} of ${item.noOfTickets || 0}`}</p>
                                        }
                                    </div>
                                    : null
                                }
                                {item.description && ((typeof item.description !== 'string' && item.descriptionText) || typeof item.description === 'string') ?
                                    <div>
                                        <div ref={this.descRef} className={classNames('event-description', { 'read-more': readMore })}>
                                            {typeof item.description !== 'string' ?
                                                (
                                                    <Editor
                                                        toolbarHidden
                                                        readOnly
                                                        initialContentState={item.description}
                                                        editorRef={(ref) => this.editorRef = ref}
                                                    />
                                                )
                                                : item.description}
                                        </div>
                                        {readMoreBtn ?
                                            <Button
                                                type='text-blue'
                                                className='btn-read-more'
                                                onClick={() => this.setState({ readMore: !readMore })}
                                            >
                                                {`read ${readMore ? 'less' : 'more'}`}
                                            </Button>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                {item.type ?
                                    item.type === 'event' ?
                                        <p className='event-date'>{item && item.startDate ? `Start ${moment(new Date(item.startDate)).format('LL')} | ${moment(new Date(item.startDate)).format('LT')}` : ''}</p>
                                        : item.type === 'stream' ?
                                            <p className='event-date'>{item && item.startDate ? `${moment(new Date(item.startDate)).format('LL')}` : ''}</p>
                                            : null
                                    : null
                                }
                                <div className='event-buttons'>
                                    {((item && ticketsLoaded && (item.noOfTickets !== undefined) && item.startDate && ((item.startDate + period > now || item.status && item.status === 'live') && item.type && item.type === 'event' || item.type && item.type === 'stream') &&
                                        (user && user.id && !host && !coHost && !guest && !moderator && !ticketId ||
                                            item.uid && !user && item.startDate && ((item.startDate + period > now || item.status && item.status === 'live') && item.type && item.type === 'event' || item.type && item.type === 'stream') && !host && !coHost && !guest && !moderator && !ticketId)) && isIOSPrice(item.price)) ?
                                        ((item.noOfTickets > eventSoldTickets || item.type && item.type === 'stream') ?
                                            <Button
                                                type='default'
                                                className='btn-event-page'
                                                onClick={() => this.handleBuyTicket(ticketId, item)}
                                            >
                                                {item.type ?
                                                    item.type === 'event' ?
                                                        item.price ? `$${item.price} get ticket` : 'get free ticket'
                                                        : item.type === 'stream' ?
                                                            item.price ? `$${item.price} watch` : 'watch'
                                                            : null
                                                    : null
                                                }
                                            </Button>
                                            :
                                            <Button
                                                type='default'
                                                className='btn-event-page sold-out'
                                                disabled={true}
                                            >
                                                This event is sold out
                                            </Button>
                                        )
                                        : null
                                    }
                                    {ticketId && item.type && item.type === 'stream' && item.parts && item.parts.length || item.type && item.type === 'stream' && (ticketId || coHost || guest) && item.parts && item.parts.length || item.uid && user && user.uid && user.uid === item.uid && item.type && item.type === 'stream' && item.status && item.status !== 'cancelled' && item.parts && item.parts.length ?
                                        <Button
                                            type='default'
                                            className='btn-event-page'
                                            onClick={() => this.handleShowVideoWrapper()}
                                        >
                                            watch
                                        </Button>
                                        : null
                                    }
                                    {ticketId && item.uid && user && user.uid && user.uid !== item.uid && item.status && item.status !== 'cancelled' && item.type && ((item.type === 'stream') || (item.type === 'event' && item.startDate && item.startDate < now) || (item.status === 'live')) ?
                                        <Button
                                            type='default'
                                            className='btn-event-page'
                                            onClick={this.handleClickFeedback.bind(this, item)}
                                        >
                                            feedback
                                        </Button>
                                        : null
                                    }
                                </div>
                                {item.cohosts && item.cohosts.length ?
                                    <div className='event-page-cohosts'>
                                        <p className='cohosts-title'>{item.cohosts.length > 1 ? 'Co-Hosts' : 'Co-Host'}</p>
                                        <div className='cohosts-wrapper'>
                                            {item.cohosts.map((item, index) => {
                                                return (
                                                    <Link to={`/profile/${item.id}`} title={item.displayName} title={item.displayName} key={index} className='event-cohost-details'>
                                                        <img className='cohost-img' src={item.photoURL} loading='lazy' />
                                                        <div className='cohost-name'>{item.displayName}</div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : null
                                }
                                {item.guestSpeakers && item.guestSpeakers.length ?
                                    <div className='event-page-cohosts'>
                                        <p className='cohosts-title'>{item.guestSpeakers.length > 1 ? 'Guests' : 'Guest'}</p>
                                        <div className='cohosts-wrapper'>
                                            {item.guestSpeakers.map((item, index) => {
                                                return (
                                                    <Link to={`/profile/${item.id}`} title={item.displayName} title={item.displayName} key={index} className='event-cohost-details'>
                                                        <img className='cohost-img' src={item.photoURL} loading='lazy' />
                                                        <div className='cohost-name'>{item.displayName}</div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Loader type="page" />
                }
                <Cards
                    title='OTHER EVENTS BY THE HOST'
                    videos={uevents}
                    readMoreLink='/browse?b=upcoming-streams'
                />
                {(!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                    <BecomeHost />
                    : null
                }
                {paymentWrapper && item && user &&
                    <PaymentWrapper
                        item={item}
                        ticketType={ticketType}
                        resetValue={() => this.handleResetDefaultValue()}
                        successFunction={(id) => this.handleShowStreamWrapper(id)}
                    />
                }
                {videoWrapper && item &&
                    <VideoWrapper
                        item={item}
                        closeVideoWrapper={() => this.handleResetDefaultValue()}
                    />
                }
                {feedback && feedbackItem &&
                    <Feedback
                        close={() => this.closeFeedback()}
                        item={feedbackItem}
                    />
                }
                {cancelModal && item ?
                    <div className='vanity-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='vanity-modal-div'>
                            <div className='vanity-modal-title'>Are you sure?</div>
                            <div className='vanity-modal-text'>The {item && item.type == 'event' ? 'event' : 'stream'} will be permanently deleted!</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={deleting}
                                    onClick={() => this.handleDeleteItem(item.id, item.type)}
                                    loading={deleting}
                                >
                                    Delete
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        cancelModal: false
                                    })}
                                    disabled={deleting}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {hideModal && item && user && user.uid ?
                    <div className='vanity-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='vanity-modal-div'>
                            <div className='vanity-modal-title'>Are you sure?</div>
                            <div className='vanity-modal-text'>The {item && item.type == 'event' ? 'event' : 'stream'} will be hidden from your profile page permanently.</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={deleting}
                                    onClick={() => this.handleHideItem(item.id, user.uid)}
                                    loading={deleting}
                                >
                                    Hide
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        hideModal: false
                                    })}
                                    disabled={deleting}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {message &&
                    <Message
                        handleShowMessage={() => this.setState({ message: !message })}
                        messageText={messageText}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        fanoutState: state.app.fanoutState,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        user: state.firebase.user,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
        tickets: state.mystuff.tickets,
        upcomingEvents: state.firebase.events.upcoming
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProcessingTicket: (data) => {
            dispatch(mystuffActions.addProcessingTicket(data));
        },
        showLogInNotification: (value) => {
            dispatch(appActions.showLogInNotification(value));
        },
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        failedPayment: (error) => {
            dispatch(paymentActions.failedConfirmPayment(error));
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        },
    };
};

const EventPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventPage);

export default EventPageContainer;