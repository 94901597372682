export const APP_STATE_ACTION = 'APP_STATE_ACTION';
export const APP_FANOUT_STATE_ACTION = 'APP_FANOUT_STATE_ACTION';
export const APP_NAV_LIST_ACTION = 'APP_NAV_LIST_ACTION';
export const APP_LOG_IN_ACTION = 'APP_LOG_IN_ACTION';
export const APP_LOG_IN_NOTIFICATION_ACTION = 'APP_LOG_IN_NOTIFICATION_ACTION';
export const APP_SET_USER_AGENT_INFO_ACTION = 'APP_SET_USER_AGENT_INFO_ACTION';
export const APP_ORIENTATION_CHANGED_ACTION = 'APP_ORIENTATION_CHANGED_ACTION';
export const APP_PIXEL_RATIO_CHANGED_ACTION = 'APP_PIXEL_RATIO_CHANGED_ACTION';
export const APP_SIDE_BAR_ACTION = 'APP_SIDE_BAR_ACTION';
export const APP_STAGE_LOADING_ACTION = 'APP_STAGE_LOADING_ACTION';

export const APP_STAGE_NOT_LOADED = 0;
export const APP_STAGE_FB_INIT = 1;
export const APP_STAGE_APP_INIT = 2;
export const APP_STAGE_LOADED = 3;

export const APP_FANOUT_RUNNING = 'RUNNING';
export const APP_FANOUT_STARTING = 'STARTING';
export const APP_FANOUT_RESTARTING = 'RESTARTING';
export const APP_FANOUT_STOPPED = 'STOPPED';

export const appState = (status) => {
    return {
        type: APP_STATE_ACTION,
        payload: {
            status
        }
    };
}

export const appFanoutState = (fanoutState) => {
    return {
        type: APP_FANOUT_STATE_ACTION,
        payload: {
            fanoutState
        }
    };
}

export const showNavList = (value) => {
    return {
        type: APP_NAV_LIST_ACTION,
        payload: {
            navList: value,
            logIn: false,
            createAccount: false,
            logInSignup: false,
            logInNotification: false,
            logInNotificationType: ''
        }
    };
}
export const showLogIn = (logIn, createAccount = false, logInSignup = false) => {
    return {
        type: APP_LOG_IN_ACTION,
        payload: {
            logIn: logIn,
            createAccount: createAccount,
            logInSignup: logInSignup,
            logInNotification: false,
            logInNotificationType: '',
            navList: false
        }
    };
}

export const showLogInNotification = (value, type) => {
    return {
        type: APP_LOG_IN_NOTIFICATION_ACTION,
        payload: {
            logInNotification: value,
            logInNotificationType: type,
            logIn: false,
            createAccount: false,
            logInSignup: false,
            navList: false
        }
    };
}

export const orientationChanged = (value) => {
    return {
        type: APP_ORIENTATION_CHANGED_ACTION,
        payload: {
            orientation: value
        }
    };
}


export const pixelRatioChanged = (value) => {
    return {
        type: APP_PIXEL_RATIO_CHANGED_ACTION,
        payload: {
            dppi: value
        }
    };
}


export const setUAInfo = (value) => {
    return {
        type: APP_SET_USER_AGENT_INFO_ACTION,
        payload: {
            user_agent_info: value
        }
    };
}

export const showSideBar = (value) => {
    return {
        type: APP_SIDE_BAR_ACTION,
        payload: {
            sideBar: value
        }
    };
}
export const stageLoading = (value) => {
    return {
        type: APP_STAGE_LOADING_ACTION,
        payload: {
            stage: value
        }
    };
}