import React, { Component } from 'react';
import Button from '../Widget/Button';
import appClient from '../../utils/AppClient';
import firebaseClient from '../../utils/FirebaseClient';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'compliment',
            feedback: '',
            success: false,
            processing: false
        }

        this.handleSendFeedback = this.handleSendFeedback.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeFeedback = this.handleChangeFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
    }

    componentWillUnmount() {
        appClient.disableScroll(false);

        if (this.feedbackTimeout)
            clearTimeout(this.feedbackTimeout);
    }

    handleChangeType(e) {
        this.setState({
            type: e.target.value,
            success: false
        });
    }

    handleChangeFeedback(e) {
        this.setState({
            feedback: e.target.value,
            success: false
        });
    }

    handleSendFeedback() {
        const { type, feedback } = this.state;
        const { item } = this.props;

        if (type && feedback && item && item.id) {
            this.setState({ processing: true });
            firebaseClient.sendFeedback({
                type: type,
                feedback: feedback,
                eventId: item.id,
            }).then((res) => {
                this.setState({
                    type: 'compliment',
                    feedback: '',
                    success: true,
                    processing: false
                }, () => {
                    const { close } = this.props;

                    if (close) {
                        this.feedbackTimeout = setTimeout(() => {
                            const { success } = this.state;
                            if (success)
                                close();
                        }, 5000);
                    }
                });
            })
        }
    }

    closeFeedback() {
        const { close } = this.props;

        if (close)
            close();
    }

    render() {
        const { type, feedback, success, processing } = this.state;

        return (
            <div className='feedback-wrapper'>
                <div className='feedback-div'>
                    <p className='feedback-title'>Provide Your Feedback</p>
                    <select
                        className="form-control"
                        value={type}
                        disabled={processing}
                        onChange={this.handleChangeType}
                    >
                        <option value='compliment'>Pay a compliment to the Host</option>
                        <option value='suggestion'>Make a suggestion to the Host</option>
                        <option value='feature'>Suggest a new feature to No Filter</option>
                        <option value='technical'>Report a technical issue you experienced during this event</option>
                        <option value='complaints'>Report a complaint about the content shared during this event</option>
                    </select>
                    <textarea
                        type="text"
                        className="form-control"
                        placeholder="Write feedback"
                        value={feedback}
                        maxLength={250}
                        disabled={processing}
                        onChange={this.handleChangeFeedback}
                    />
                    <div className='feedback-buttons'>
                        <Button
                            type='default'
                            className='btn-feedback'
                            disabled={processing}
                            onClick={this.closeFeedback}
                        >
                            cancel
                        </Button>
                        <Button
                            type='default'
                            className='btn-feedback'
                            disabled={processing || !feedback || !type}
                            onClick={this.handleSendFeedback}
                            loading={processing}
                        >
                            send feedback
                        </Button>
                    </div>
                    {success && <p className='feedback-success'>Thank you for your feedback!</p>}
                </div>
            </div>
        )
    }
}

export default Feedback;