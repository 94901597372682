import React, { Component } from "react";
import { connect } from "react-redux";
import Button from '../Widget/Button';

class LogInNotification extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);

        const { handleShowLogIn } = this.props;

        this.timeout = setTimeout(() => {
            if(handleShowLogIn) {
                handleShowLogIn();
            }
        }, 30000);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);

        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.handleShowLogInNotification) {
                this.props.handleShowLogInNotification();
            }
        }
    }

    render() {
        const { handleShowLogIn, handleShowLogInNotification } = this.props;
        return (
            <div className="log-in-wrapper">
                <div ref={this.setWrapperRef} className="log-in-div">
                    <div className='log-in-title notification'>LOGIN TO NO FILTER NETWORK</div>
                    <div className="log-in-text notification">
                        You must have an account on No Filter Network to get a ticket, watch an event or host your own event.
                        <br />
                        Creating a free account on No Filter is easy and takes only a few seconds.
                    </div>
                    <div className='log-in-buttons'>
                        <Button type='default' className='btn-notification-continue' onClick={() => handleShowLogIn()}>Login/Create Account</Button>
                        <Button type='text' className='btn-notification-cancel' onClick={() => handleShowLogInNotification()}>Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        logInNotificationType: state.app.logInNotificationType
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const LogInNotificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LogInNotification);

export default LogInNotificationContainer;
