import React, { Component } from 'react';
import { connect } from "react-redux";
import BecomeHost from '../Advertisement/BecomeHost';
import ComponentHeader from '../Widget/ComponentHeader';
import tfh1 from '../../../resources/images/tfh1.svg';
import tfh2 from '../../../resources/images/tfh2.svg';
import tfh3 from '../../../resources/images/tfh3.svg';

class Recommendations extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { roles, auth } = this.props;

        return (
            <div>
                <ComponentHeader
                    text="tips for hosts"
                />
                <div className='tips-for-hosts-wrapper'>
                    <div className='tips-top-img'>
                        <p className='tips-top-text'>🌐</p>
                    </div>
                    <p className='tips-top-title'>Internet speeds</p>
                    <p className='tips-top-txt'>We highly recommend streaming from a wired connection instead of wireless</p>
                    <div className='tfh-wrapper'>
                        <div className='tfh-item'>
                            <p className='tfh-title-text'>10 mb/s download</p>
                            <p className='tfh-text'>no delay from host to viewer</p>
                        </div>
                        <div className='tfh-item'>
                            <p className='tfh-title-text'>6 mbps upload</p>
                            <p className='tfh-text'>to stream at 1080p 60fps</p>
                        </div>
                        <div className='tfh-item'>
                            <p className='tfh-title-text'>20+ mbps</p>
                            <p className='tfh-text'>to stream at 4K 30fps</p>
                        </div>
                    </div>
                </div>
                <div className='tips-for-hosts-wrapper blackBg'>
                    <div className='tfh-wrapper four'>
                        <div className='tfh-item'>
                            <div className='tfh-img-wrapper'>
                                <p className='tfh-img-text'>💻</p>
                            </div>
                            <p className='tfh-title'>Webcam</p>
                            <p className='tfh-text'>We recommend streaming at a min. resolution of 1080p with a frame rate of 30fps.</p>
                        </div>
                        <div className='tfh-item'>
                            <div className='tfh-img-wrapper'>
                                <p className='tfh-img-text'>🎤</p>
                            </div>
                            <p className='tfh-title'>Audio</p>
                            <p className='tfh-text'>Investing in proper audio is essential. Do not use built in microphones, using a USB microphone will take your stream quality to the next level.</p>
                        </div>
                        <div className='tfh-item'>
                            <div className='tfh-img-wrapper'>
                                <p className='tfh-img-text'>🖥</p>
                            </div>
                            <p className='tfh-title'>CPU</p>
                            <p className='tfh-text'>
                                Intel Core i5-4670 processor or AMD equivalent
                                <br />
                                <br />
                                OS: Windows 7 or newer
                                <br />
                                iOS: 10 or newer
                            </p>
                        </div>
                        <div className='tfh-item'>
                            <div className='tfh-img-wrapper'>
                                <p className='tfh-img-text'>🎙</p>
                            </div>
                            <p className='tfh-title'>Memory</p>
                            <p className='tfh-text'>4 GB RAM</p>
                        </div>
                    </div>
                </div>
                <div className='tips-for-hosts-wrapper'>
                    <div className='tips-top-img'>
                        <p className='tips-top-text'>💡</p>
                    </div>
                    <p className='tips-top-title'>Lighting Tips</p>
                    <div className='tfh-wrapper'>
                        <div className='tfh-item'>
                            <img src={tfh1} loading='lazy' />
                            <p className='tfh-title-text'>Avoid backlights</p>
                        </div>
                        <div className='tfh-item'>
                            <img src={tfh2} loading='lazy' />
                            <p className='tfh-title-text'>Use 2 different sources of light</p>
                        </div>
                        <div className='tfh-item'>
                            <img src={tfh3} loading='lazy' />
                            <p className='tfh-title-text'>Light sources should be aimed at the main focus point </p>
                            <p className='tfh-text'>(that’s going to be you!)</p>
                        </div>
                    </div>
                </div>
                {(!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                    <BecomeHost />
                    : null
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        roles: state.firebase.roles
    };
};

const RecommendationsContainer = connect(
    mapStateToProps,
    null //mapDispatchToProps
)(Recommendations);

export default RecommendationsContainer;