import {
    FIREBASE_SET_USER_ACTION,
    FIREBASE_GET_USERS_ACTION,
    FIREBASE_SET_HOSTS_ACTION,
    FIREBASE_LOG_IN_ACTION,
    FIREBASE_LOG_OUT_ACTION,
    FIREBASE_UPDATED_VIDEOS_ACTION,
    FIREBASE_SET_ROLES_ACTION,
    FIREBASE_GET_CATEGORIES_ACTION,
    FIREBASE_INIT_ACTION,
    FIREBASE_CLEAR_KNOCK_LIST_ACTION,
    FIREBASE_SET_AUDIENCE_LIST_ACTION,
    FIREBASE_SET_KNOCK_LIST_ACTION,
    FIREBASE_SET_KNOCK_LIST_ITEM_ACTION,
    FIREBASE_DELETE_KNOCK_LIST_ITEM_ACTION,
    FIREBASE_CLEAR_ACTIVE_PARTICIPANTS_LIST_ACTION,
    FIREBASE_DELETE_ACTIVE_PARTICIPANTS_ITEM_ACTION,
    FIREBASE_SET_ACTIVE_PARTICIPANTS_ITEM_ACTION,
    FIREBASE_SET_ACTIVE_PARTICIPANTS_LIST_ACTION,
    FIREBASE_SET_CHANNEL_LIST_ACTION,
    FIREBASE_GET_CALENDAR_LIST_ACTION,
    FIREBASE_SET_PRODUCTS_LIST_ACTION,
    FIREBASE_SET_STRIPE_DATA_ACTION,
    FIREBASE_SET_CARD_ACTION,
    FIREBASE_SET_SUBSCRIPTIONS_ACTION,
    FIREBASE_SET_TEASERS_ACTION,
    FIREBASE_GET_FEATURED_ACTION,
    FIREBASE_UPDATED_EVENTS_ACTION,
    FIREBASE_SET_ISLOGGEDIN_ACTION
} from '../actions/firebase_actions';

const initialState = {
    init: false,
    auth: false,
    audienceList: [],
    knockList: [],
    calendarList: [],
    activeParticipantsList: [],
    events: {
        my: null,
        upcoming: null,
        scheduled: null,
        following: null,
        all: null
    },
    videos: null,
    teasers: {}, 
    isLoggedIn: 'maybe' // maybe, yes, no
};

const firebase = (state = initialState, action) => {
    switch (action.type) {
        case FIREBASE_LOG_IN_ACTION:
        case FIREBASE_LOG_OUT_ACTION:
        case FIREBASE_UPDATED_EVENTS_ACTION:
        case FIREBASE_UPDATED_VIDEOS_ACTION:
        case FIREBASE_SET_ROLES_ACTION:
        case FIREBASE_GET_CATEGORIES_ACTION:
        case FIREBASE_INIT_ACTION:
        case FIREBASE_GET_USERS_ACTION:
        case FIREBASE_SET_HOSTS_ACTION:
        case FIREBASE_CLEAR_KNOCK_LIST_ACTION:
        case FIREBASE_SET_AUDIENCE_LIST_ACTION:
        case FIREBASE_SET_KNOCK_LIST_ACTION:
        case FIREBASE_CLEAR_ACTIVE_PARTICIPANTS_LIST_ACTION:
        case FIREBASE_SET_ACTIVE_PARTICIPANTS_LIST_ACTION:
        case FIREBASE_SET_CHANNEL_LIST_ACTION:
        case FIREBASE_GET_CALENDAR_LIST_ACTION:
        case FIREBASE_SET_PRODUCTS_LIST_ACTION:
        case FIREBASE_SET_STRIPE_DATA_ACTION:
        case FIREBASE_SET_CARD_ACTION:
        case FIREBASE_SET_SUBSCRIPTIONS_ACTION:
        case FIREBASE_SET_TEASERS_ACTION:
        case FIREBASE_SET_USER_ACTION:
        case FIREBASE_GET_FEATURED_ACTION:
        case FIREBASE_SET_ISLOGGEDIN_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, data);
        }
        case FIREBASE_SET_KNOCK_LIST_ITEM_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, { knockList: [data.knockList, ...state.knockList] });
        }
        case FIREBASE_DELETE_KNOCK_LIST_ITEM_ACTION: {
            const data = action.payload;
            let newArray = [];
            if(state.knockList && state.knockList.length) {
                newArray = state.knockList.filter((item) => {
                    if (item.uid !== data.uid) {
                        return item;
                    } else return null;
                })
            }
            return Object.assign({}, state, { knockList: [...newArray] });
        }
        case FIREBASE_SET_ACTIVE_PARTICIPANTS_ITEM_ACTION: {
            const data = action.payload;

            return Object.assign({}, state, { activeParticipantsList: [data.activeParticipantsList, ...state.activeParticipantsList] });
        }
        case FIREBASE_DELETE_ACTIVE_PARTICIPANTS_ITEM_ACTION: {
            const data = action.payload;
            let newArray = [];
            if(state.activeParticipantsList && state.activeParticipantsList.length) {
                newArray = state.activeParticipantsList.filter((item) => {
                    if (item.uid !== data.uid) {
                        return item;
                    } else return null;
                })
            }
            return Object.assign({}, state, { activeParticipantsList: [...newArray] });
        }
        default:
            return state;
    }
};

export default firebase;
