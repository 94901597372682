import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Switch, Route, Router } from 'react-router';
import { push as nativePush } from 'connected-react-router';
import Header from './components/Header';
import Home from './components/Home';
import Browse from './components/Browse';
import MyStuff from './components/MyStuff';
import Search from './components/Pages/Search';
import StreamingGuest from './components/Pages/StreamingGuest';
import StreamingAudience from './components/Pages/StreamingAudience';
import Ambassadors from './components/Ambassadors';
import Channel from './components/Channel';
import EditProfile from './components/Header/EditProfile';
import Calendar from './components/Pages/Calendar';
import * as appActions from "./actions/app_actions";
import Bowser from "bowser";
import Faq from './components/Footer/Faq';
import Contact from './components/Footer/Contact';
import AboutUs from './components/Footer/AboutUs';
import Recommendations from './components/Footer/Recommendations';
import UserPolicies from './components/Footer/UserPolicies';
import TermsConditions from './components/Footer/TermsConditions';
import Features from "./components/Footer/Features";
import EventPageEx from './components/Pages/EventPage-old';
import MemberSignup from './components/Pages/MemberSignup';
import HowToCreate from './components/Pages/HowToCreate';
import ResetPassword from "./components/Pages/ResetPassword";
import VerifyAccount from './components/Widget/VerifyAccount';
import { Helmet } from "react-helmet";
import logo from '../resources/icons/NF0620_Logo.png';
import Loader from "./components/Widget/Loader";
import CheckingLogin from "./components/Widget/CheckingLogin";
import { App as IonicApp } from '@capacitor/app';
import classNames from 'classnames';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import NotFound from "./components/Pages/NotFound";
import EventPage from "./components/Pages/EventPage";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
    // Create the query list.
    // console.log('here')
    this.orientationDetector = window.matchMedia("(orientation: landscape)");
    this.pixelRatioDetector = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
    this.props.handleOrientationChange(this.orientationDetector);  // The sooner the better
    this.props.handlePixelRatioChange(this.pixelRatioDetector);  // The sooner the better
    this.props.setUAInfo(Bowser.parse(window.navigator.userAgent));  // The sooner the better
    this.getLaunchUrl = this.getLaunchUrl.bind(this);
    this.handleDeepLink = this.handleDeepLink.bind(this);
    this.initializeIonicApp = this.initializeIonicApp.bind(this);
    this.initializeIonicApp();
  }

  async getLaunchUrl() {
    const urlOpen = await IonicApp.getLaunchUrl();
    this.handleDeepLink(urlOpen);
  }

  handleDeepLink(data) {
    if (!data || !data.url) {
      console.log(`handleDeepLink - nothing to handle: ${data}`);
      return;
    }
    const url = new URL(data.url);
    console.log('handleDeepLink - got url', url);
    let params = new URLSearchParams(url.search.substring(1));
    let link = params.get("link"); // is the string "Jonathan"
    if (link) {
      let url = new URL(link);
      console.log('handleDeepLink - pathname', url.pathname);
      if (url && url.pathname) {
        console.log('handleDeepLink - about to navigate to', url.pathname);
        this.props.pushToPathname(url.pathname);
      }
    }
  }

  initializeIonicApp() {
    console.log('About to initializeIonicApp');
    try {
      IonicApp.addListener('appUrlOpen', (data) => {
        console.log('appUrlOpen', data);
        this.handleDeepLink(data);
      });

      this.getLaunchUrl();
    } catch (e) {
      console.error('Could not initializeIonicApp', e.message)
    }
  }

  componentDidMount() {
    this.subscribeToMediaQueryChange(this.orientationDetector, this.props.handleOrientationChange);
    this.subscribeToMediaQueryChange(this.pixelRatioDetector, this.props.handlePixelRatioChange);
  }

  componentWillUnmount() {
    this.unsubscribeToMediaQueryChange(this.orientationDetector, this.props.handleOrientationChange);
    this.unsubscribeToMediaQueryChange(this.pixelRatioDetector, this.props.handlePixelRatioChange);
  }

  static getDerivedStateFromError(error) {
    // console.log('getDerivedStateFromError')
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // console.log('componentDidCatch')
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ hasError: true });
    console.error(error, errorInfo);
  }


  render() {
    const { appState, history, broadcasterUser, adminUser, premiumUser, user, broadcastMessage, sideBar } = this.props;

    return (
      <Router history={history}>
        <Helmet>
          {/*Primary Meta Tags*/}
          <meta charSet="utf-8" />
          <title>NoFilter.net</title>
          <meta name="title" content="NoFilter.net" />
          <meta name="description" content="Interactive virtual venue where members can use Real Time Streaming to connect with viewers from around the world." />
          {/*<link rel="canonical" href="http://mysite.com/example" />*/}
          {/*Open Graph / Facebook*/}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://nofilter.net/" />
          <meta property="og:title" content="NoFilter.net" />
          <meta property="og:description" content="Interactive virtual venue where members can use Real Time Streaming to connect with viewers from around the world." />
          <meta property="og:image" content={logo} />
          {/*Twitter*/}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://nofilter.net/" />
          <meta property="twitter:title" content="NoFilter.net" />
          <meta property="twitter:description" content="Interactive virtual venue where members can use Real Time Streaming to connect with viewers from around the world." />
          <meta property="twitter:image" content={logo} />
        </Helmet>
        <div className={classNames('bc-wrapper', { 'sidebar': sideBar })}>
          {this.props.appStage < 2 ?
            <Switch>
              <Fragment>
                <Route path='/' component={Header} />
                <Loader 
                  type="page" 
                  text="Loading"
                  dots
                />
              </Fragment>
            </Switch> :
            <Switch>
              <Fragment>
                <Route path='/' component={Header} />
                <Route exact path='/' component={Home} />
                <Route exact path='/browse' component={Browse} />
                <Route exact path='/mystuff' component={user ? MyStuff : CheckingLogin} />
                <Route exact path='/hosts' component={Ambassadors} />
                <Route exact path='/profile/:id' component={Channel} />
                <Route exact path='/search' component={Search} />
                <Route exact path='/edit-profile' component={user ? EditProfile : CheckingLogin} />
                <Route exact path='/guest' component={adminUser ? StreamingGuest : user ? Home : CheckingLogin} />
                <Route exact path='/guest/:conferenceAlias' component={user ? StreamingGuest : CheckingLogin} />
                <Route exact path='/guest/:conferenceAlias/:conferenceType' component={user ? StreamingGuest : CheckingLogin} />
                <Route exact path='/audience' component={adminUser ? StreamingAudience : user ? Home : CheckingLogin} />
                <Route exact path='/audience/:conferenceAlias' component={user ? StreamingAudience : CheckingLogin} />
                <Route exact path='/audience/:conferenceAlias/:conferenceType' component={user ? StreamingAudience : CheckingLogin} />
                <Route exact path='/calendar' component={broadcasterUser ? Calendar : user ? Home : CheckingLogin} />
                <Route exact path='/calendar/:eventId' component={broadcasterUser ? Calendar : user ? Home : CheckingLogin} />
                <Route exact path='/calendar/:eventId/:operationType' component={broadcasterUser ? Calendar : user ? Home : CheckingLogin} />
                <Route exact path='/faq' component={Faq} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/about-us' component={AboutUs} />
                <Route exact path='/tips-for-hosts' component={Recommendations} />
                <Route exact path='/privacy-policy' component={UserPolicies} />
                <Route exact path='/terms-of-service' component={TermsConditions} />
                <Route exact path='/features' component={Features} />
                <Route exact path='/stream' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/stream/:eventId' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/stream/:eventId/:streamText' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/edit-stream/:streamId' component={broadcasterUser ? Calendar : user ? Home : CheckingLogin} />
                <Route exect path="/signup" component={broadcasterUser ? EditProfile : MemberSignup} />
                <Route exact path='/how-to-create' component={HowToCreate} />
                <Route exact path="/reset-password/:pathId" component={ResetPassword} />
                {/* // v2 routes */}
                <Route exact path="/call/:linkId" render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/event' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/event/:eventId' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path='/event/:eventId/:eventText' render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path="/verify/:linkId" render={(props) => <EventPage {...props} redirectOnEnd="/" />} />
                <Route exact path="/eventreg/:linkId/*" render={(props) => <EventPage {...props} redirectOnEnd="/" redirectOnJoin="/live/:linkId/:event-title" />} />
                <Route exact path="/live/:linkId/*" render={(props) => <EventPage {...props} redirectOnEnd="/" redirectToLogin="/eventrg/:linkId" autoJoin={true} />} />
                {/* <Route exact path="*" component={NotFound} /> */}
              </Fragment>
            </Switch>}
          {broadcastMessage && <div className='message-overlay'>
            <div className='message-wrapper'>
              <div className='message-text'>{broadcastMessage}</div>
            </div>
          </div>}
          {this.state.hasError ?
            <div className='error-wrapper'>
              <div className='error-div'>
                <p className='error-title'>Network problem</p>
                <p className='error-message'>Please check your internet connection or try reload the page.</p>
                <button
                  className='btn-reload'
                  onClick={() => window.location.reload()}
                >
                  Reload
              </button>
              </div>
            </div>
            : null}
          {(!process.env.platform || process.env.platform && process.env.platform !== 'mobile') ?
            <SmartBanner
              title='No Filter Network'
              position='top'
              daysHidden={7}
              daysReminder={30}
            />
            : null}
          <VerifyAccount />
        </div>
      </Router>
    )
  }
  // --- Helpers ---------------------------

  // Add the callback function as a listener to the query list.
  subscribeToMediaQueryChange(mq, cb) {
    if (!mq || !cb)
      return;

    try {
      // Chrome & Firefox
      mq.addEventListener('change', cb);
    } catch (e1) {
      try {
        // Safari
        mq.addListener(cb);
      } catch (e2) {
        console.error(e2);
      }
    }

  }

  // Unsubscribe from listenerto the query list.
  unsubscribeToMediaQueryChange(mq, cb) {
    if (!mq || !cb)
      return;

    try {
      // Chrome & Firefox
      mq.removeEventListener('change', cb);
    } catch (e1) {
      try {
        // Safari
        mq.removeListener(cb);
      } catch (e2) {
        console.error(e2);
      }
    }

  }
}

const mapStateToProps = (state) => {
  return {
    appStage: state.app.stage,
    appState: state.app.status,
    firebaseInit: state.firebase.init,
    user: state.firebase.user,
    sideBar: state.app.sideBar,
    broadcastMessage: state.broadcast.broadcastMessage,
    broadcasterUser: state.firebase.roles && state.firebase.roles.broadcaster ? true : false,
    adminUser: state.firebase.roles && state.firebase.roles.administrator ? true : false,
    premiumUser: state.firebase.roles && state.firebase.roles.premium ? true : false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUAInfo: (data) => {
      dispatch(appActions.setUAInfo(data));
    },
    handleOrientationChange: (evt) => {
      if (!evt.matches) {
        // The viewport is currently in portrait orientation
        console.log('Current orientation: portrait');
        dispatch(appActions.orientationChanged('portrait'));
      } else {
        // The viewport is currently in landscape orientation
        console.log('Current orientation: landscape');
        dispatch(appActions.orientationChanged('landscape'));
      }
    },
    handlePixelRatioChange: (evt) => {
      let pr = window.devicePixelRatio;
      let prString = (pr * 100).toFixed(0);
      console.log(`Current dppi: ${prString}`, pr);
      dispatch(appActions.pixelRatioChanged(prString));
    },
    pushToPathname: (pathname) => {
      dispatch(nativePush(pathname));
    }
  };
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
