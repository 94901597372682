export const KNOCKS_KNOCKED_ACTION = 'KNOCKS_KNOCKED_ACTION';
export const KNOCKS_KNOCK_ACTION = 'KNOCKS_KNOCK_ACTION';
export const KNOCKS_ACCEPT_ACTION = 'KNOCKS_ACCEPT_ACTION';
export const KNOCKS_REJECT_ACTION = 'KNOCKS_REJECT_ACTION';
export const KNOCKS_REVOKE_ACTION = 'KNOCKS_REVOKE_ACTION';
export const CLEAR_KNOCKS_ACTION = 'CLEAR_KNOCKS_ACTION';

export const knocked = (data) => {
    return {
        type: KNOCKS_KNOCKED_ACTION,
        payload: data
    };
}

export const knock = (data) => {
    return {
        type: KNOCKS_KNOCK_ACTION,
        payload: data
    };
}

export const accept = (data) => {
    return {
        type: KNOCKS_ACCEPT_ACTION,
        payload: data
    };
}

export const reject = (data) => {
    return {
        type: KNOCKS_REJECT_ACTION,
        payload: data
    };
}

export const revoke = (data) => {
    return {
        type: KNOCKS_REVOKE_ACTION,
        payload: data
    };
}

export const clear = () => {
    return {
        type: CLEAR_KNOCKS_ACTION
    };
}