import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { push as nativePush } from 'connected-react-router';
import DateTime from 'react-datetime';
import momentZone from "moment-timezone";
import firebaseClient from '../../utils/FirebaseClient';
import {
    prices,
    contents,
    numTickets,
    getQueryVariable,
    stringToBoolean
} from '../../utils/text';
import { WithContext as ReactTags } from 'react-tag-input';
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, ContentState } from 'draft-js';
import Button from '../Widget/Button';

const KeyCodes = {
    comma: 188,
    enter: 13,
    tab: 9,
    space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab, KeyCodes.space];

class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postId: '',
            title: '',
            description: '',
            editorState: EditorState.createEmpty(),
            visibility: 'public',
            category: 'SPORTS',
            tvRating: false,
            contentLabels: [],
            coHostShow: false,
            coHostInput: '',
            guestShow: false,
            guestInput: '',
            moderatorsShow: false,
            moderatorsInput: '',
            autoVideo: false,
            muteAudio: false,
            estDuration: 60,
            estDurationList: [
                { value: 15, text: '15+ mins' },
                { value: 30, text: '30+ mins' },
                { value: 45, text: '45+ mins' },
                { value: 60, text: '60+ mins' },
                { value: 90, text: '90+ mins' },
                { value: 120, text: '120+ mins' },
                { value: 180, text: '180+ mins' }
            ],
            timeZone: momentZone.tz.guess(),
            tipsShow: true,
            price: 4.99,
            tipsValue: 4.99,
            numTicket: 500,
            watchLater: false,
            archivePrice: 0,
            expireTime: '',
            stopDate: '',
            customCoverShow: false,
            customCover: '',
            cohosts: [],
            guestSpeakers: [],
            moderators: [],
            oldCohosts: [],
            oldGuestSpeakers: [],
            oldModerators: [],
            cohostsSuggestions: [],
            guestSpeakersSuggestions: [],
            moderatorsSuggestions: [],
            vanityId: '',
            showVanityForm: false,
            noCoHostMsg: '',
            noGuestMsg: '',
            noModeratorMsg: '',
            update: false,
            copyEvent: false,
            scheduling: false,
            startDT: this.getDefaultTime(),
            featured: false,
            sharedLink: null,
            privileges: null,
            addFileDisabled: false,
            error: null
        }

        this.addFileRef = React.createRef();
        this.buttonRef = React.createRef();
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.validDate = this.validDate.bind(this);
    }

    componentDidMount() {
        moment.tz.setDefault();
        this.getEventItem.call(this);
        this.checkGodMode();
        firebaseClient.getUserHostingPrivileges().then((data) => {
            this.setState({
                privileges: data,
                watchLater: data && data.record_show ? this.state.watchLater ? this.state.watchLater : false : false,
                price: data && data.min_price_event && data.min_price_event > this.state.price ? data.min_price_event : this.state.price,
            });
            if (data && data.session_limit) {
                let newList = [];
                if (data.session_limit >= 15) {
                    newList.push({ value: 15, text: '15+ mins' })
                }
                if (data.session_limit >= 30) {
                    newList.push({ value: 30, text: '30+ mins' })
                }
                if (data.session_limit >= 45) {
                    newList.push({ value: 45, text: '45+ mins' })
                }
                if (data.session_limit >= 60) {
                    newList.push({ value: 60, text: '60+ mins' })
                }
                if (data.session_limit >= 90) {
                    newList.push({ value: 90, text: '90+ mins' })
                }
                if (data.session_limit >= 120) {
                    newList.push({ value: 120, text: '120+ mins' })
                }
                if (data.session_limit >= 180) {
                    newList.push({ value: 180, text: '180+ mins' })
                }

                this.setState({
                    estDurationList: newList,
                    estDuration: data && data.session_limit && data.session_limit < this.state.estDuration ? data.session_limit : this.state.estDuration || 60
                });
            }
        });
        this.unlisten = this.props.history.listen((location, action) => {
            this.checkGodMode();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;

        if (user && prevProps.user !== user) {
            this.checkGodMode();
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkGodMode() {
        const { timeZone } = this.state;
        const { user } = this.props;

        let gm = stringToBoolean(getQueryVariable('gm'));

        if (user && user.administrator) {
            if (gm) {
                this.setState({
                    visibility: 'private',
                    startDT: this.parseTime(moment().toDate().getTime(), timeZone),
                    archivePrice: 0,
                    price: 0
                });
            } else {
                this.setState({
                    visibility: 'public',
                    startDT: this.getDefaultTime(),
                    archivePrice: 0,
                    price: 4.99
                });
            }
        }
    }

    getDefaultTime() {
        const start = moment();

        start.add(1, 'days').set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

        const dateTime = moment(start).tz(momentZone.tz.guess());

        return dateTime;
    }

    getEventItem() {
        const { privileges } = this.state;
        const { match } = this.props || {};

        if ((!match || !match.params || !match.params.streamId) && (!match || !match.params || !match.params.eventId))
            return;

        let eventId = match.params.eventId;
        let streamId = match.params.streamId;

        let isStream = eventId ? false : true;

        let copyEvent = false;

        if (match && match.params && match.params.operationType && window.atob(match.params.operationType) === "copyEvent")
            copyEvent = true;

        // Check current user tickets for selected stream
        firebaseClient.getOne(eventId ? '/calendar' : '/posts', eventId ? eventId : streamId).then(result => {
            if (!result)
                return;

            let event = result.data;

            if (event && event.uid && this.props.user && this.props.user.uid && this.props.user.uid !== event.uid)
                return;

            let price = ((typeof event.price === 'string' || event.price instanceof String) ? parseFloat(event.price) : event.price)

            let description = event.description ? event.description : '';

            if (description && typeof description !== 'string') {
                description = convertFromRaw(event.description);
                description = EditorState.createWithContent(description);
            } else {
                description = EditorState.createWithContent(ContentState.createFromText(description))
            }

            this.setState({
                postId: !copyEvent && event.id ? event.id : '',
                title: event.title,
                description: event.description,
                editorState: description,
                visibility: event.visibility || 'public',
                category: event.cat,
                tvRating: event.tvRating && event.tvRating === '18+' ? true : false,
                contentLabels: event.contentLabels,
                coHostShow: event.cohosts && event.cohosts.length ? true : false,
                coHostInput: '',
                guestShow: event.guestSpeakers && event.guestSpeakers.length ? true : false,
                guestInput: '',
                moderatorsShow: event.moderators && event.moderators.length ? true : false,
                moderatorsInput: '',
                autoVideo: event.autoVideo,
                muteAudio: event.muteAudio,
                estDuration: privileges && privileges.session_limit && privileges.session_limit < event.estDuration ? privileges.session_limit : event.estDuration || 60,
                tipsShow: event.acceptTips,
                price: privileges && privileges.min_price_event && privileges.min_price_event > price ? privileges.min_price_event : price,
                tipsValue: ((typeof event.tipsValue === 'string' || event.tipsValue instanceof String) ? parseFloat(event.tipsValue) : event.tipsValue),
                numTicket: event.noOfTickets ? numTickets.includes(event.noOfTickets) ? event.noOfTickets : 500 : 500,
                watchLater: privileges ? privileges.record_show ? event.archiveEnabled ? event.archiveEnabled : false : false : event.archiveEnabled,
                archivePrice: ((typeof event.archivePrice === 'string' || event.archivePrice instanceof String) ? parseFloat(event.archivePrice) : event.archivePrice),
                expireTime: event.expireTime || '',
                stopDate: event.archiveStopDate,
                customCoverShow: event.archiveCustomCover ? true : false,
                customCover: event.archiveCustomCover,
                update: !copyEvent,
                copyEvent: copyEvent,
                timeZone: event.timeZone,
                cohosts: event.cohosts ? event.cohosts : [],
                oldCohosts: event.cohosts ? event.cohosts : [],
                guestSpeakers: event.guestSpeakers ? event.guestSpeakers : [],
                oldGuestSpeakers: event.guestSpeakers ? event.guestSpeakers : [],
                moderators: event.moderators ? event.moderators : [],
                oldModerators: event.moderators ? event.moderators : [],
                isStream: isStream,
                startDT: copyEvent ? this.getDefaultTime() : this.parseTime(event.startDate, event.timeZone, !copyEvent),
                stopDT: this.parseTime(event.archiveStopDate, event.timeZone, !copyEvent),
                featured: event.featured && !copyEvent ? event.featured : false,
                sharedLink: event.sharedLink || null
            });
        });
    }

    parseTime(time, zone, edit = false) {
        let now = Date.now(), start = null;

        if ((time > now) || edit) {
            start = moment(time ? new Date(time) : Date.now());
        } else {
            start = moment(Date.now());
        }

        const remainder = 5 - (start.minute() % 5);
        let dateTime = null;

        if (remainder && remainder < 5) {
            dateTime = moment(start).add(remainder, "minutes").tz(zone ? zone : momentZone.tz.guess());
        } else {
            dateTime = moment(start).tz(zone ? zone : momentZone.tz.guess());
        }

        return dateTime;
    }

    handleChangeContentLabels(e) {

        let labels = this.state.contentLabels;

        if (labels.includes(e.target.value)) {
            labels = labels.filter(label => label !== e.target.value)
        }
        else {
            labels.push(e.target.value)
        }

        this.setState({
            contentLabels: labels,
        })
    }

    handleImageChange(event) {
        const { user } = this.props;
        if (event.target.files && event.target.files[0] && user) {
            this.setState({
                addFileDisabled: true
            });
            let img = event.target.files[0];
            let id = `${user.uid}/${uuidv4()}`;
            firebaseClient.addImageToStorage(img, 'event', id).then((res) => {
                this.setState({
                    customCover: res,
                    addFileDisabled: false
                });
            })
        }
    }

    handleClickAddImage() {
        if (this.addFileRef && this.addFileRef.current && this.addFileRef.current.click) {
            this.addFileRef.current.click();
        }
    }

    handleAdd() {
        const {
            postId,
            visibility,
            title,
            estDuration,
            category,
            description,
            editorState,
            tvRating,
            contentLabels,
            numTicket,
            price,
            muteAudio,
            autoVideo,
            tipsShow,
            tipsValue,
            watchLater,
            customCover,
            customCoverShow,
            cohosts,
            guestSpeakers,
            moderators,
            stopDate,
            expireTime,
            update,
            timeZone,
            archivePrice,
            isStream,
            startDT,
            stopDT,
            featured,
            sharedLink,
            coHostShow,
            guestShow,
            moderatorsShow,
        } = this.state;

        const { user } = this.props;

        let event = {};

        let hostData = {
            id: user && user.uid ? user.uid : null,
            text: user && user.email ? user.email : null,
            email: user && user.email ? user.email : null,
            photoURL: user && user.profile_picture2 ? user.profile_picture2 : user && user.profile_picture ? user.profile_picture : null,
            displayName: user && user.username ? user.username : null
        }

        if (!isStream) {
            event = {
                postId: postId,
                type: 'event',
                visibility: visibility,
                title: title,
                description: description,
                descriptionText: editorState.getCurrentContent().getPlainText(),
                tvRating: tvRating ? '18+' : '',
                poster: user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : '')),
                startDate: startDT ? startDT.toDate().getTime() : moment().toDate().getTime(),
                estDuration: estDuration,
                cat: category,
                author: user && user.username ? user.username : '',
                authorPic: user && (user.profile_picture2 ? user.profile_picture2 : (user.profile_picture ? user.profile_picture : '')),
                uid: user && user.uid ? user.uid : '',
                contentLabels: contentLabels,
                noOfTickets: numTicket,
                price: ((typeof price === 'string' || price instanceof String) ? parseFloat(price) : price),
                expireTime: !expireTime ? '' : expireTime,
                muteAudio: muteAudio,
                autoVideo: autoVideo,
                acceptTips: tipsShow,
                tipsValue: ((typeof tipsValue === 'string' || tipsValue instanceof String) ? parseFloat(tipsValue) : tipsValue),
                timeZone: timeZone,
                archiveEnabled: watchLater,
                archivePrice: ((typeof archivePrice === 'string' || archivePrice instanceof String) ? parseFloat(archivePrice) : archivePrice),
                archiveCustomCover: customCoverShow ? customCover : '',
                archiveStartDate: startDT ? startDT.toDate().getTime() : moment().toDate().getTime(),
                archiveStopDate: stopDT ? stopDT.toDate().getTime() : startDT ? startDT.add(6, 'months').toDate().getTime() : moment().add(6, 'months').toDate().getTime(),
                hosts: [hostData],
                cohosts: coHostShow ? cohosts : [],
                guestSpeakers: guestShow ? guestSpeakers : [],
                moderators: moderatorsShow ? moderators : [],
                featured: featured,
                sharedLink: sharedLink
            }

        }
        else {
            event = {
                postId: postId,
                type: 'stream',
                visibility: visibility,
                title: title,
                description: description,
                descriptionText: editorState.getCurrentContent().getPlainText(),
                tvRating: tvRating ? '18+' : '',
                poster: user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : '')),
                estDuration: estDuration,
                cat: category,
                author: user && user.username ? user.username : '',
                authorPic: user && (user.profile_picture2 ? user.profile_picture2 : (user.profile_picture ? user.profile_picture : '')),
                uid: user && user.uid ? user.uid : '',
                contentLabels: contentLabels,
                noOfTickets: numTicket,
                price: ((typeof price === 'string' || price instanceof String) ? parseFloat(price) : price),
                expireTime: !(expireTime) ? '' : expireTime,
                acceptTips: tipsShow,
                tipsValue: ((typeof tipsValue === 'string' || tipsValue instanceof String) ? parseFloat(tipsValue) : tipsValue),
                timeZone: timeZone,
                archiveEnabled: watchLater,
                archivePrice: ((typeof archivePrice === 'string' || archivePrice instanceof String) ? parseFloat(archivePrice) : archivePrice),
                archiveCustomCover: customCover,
                archiveStopDate: stopDate,
                featured: featured,
                sharedLink: sharedLink
            }
        }

        if (event) {
            this.setState({
                scheduling: true,
                error: null
            });

            if (!isStream) {
                firebaseClient.saveCalendarEvent(event).then((res) => {
                    if (!update) {
                        this.setState({
                            vanityId: res,
                            showVanityForm: true,
                            scheduling: false
                        });
                    }
                    else {
                        let id = res;
                        this.setState({ scheduling: false });
                        this.props.pushToEvent(id);
                    }
                }).catch((e) => {
                    this.setState({
                        scheduling: false,
                        error: e && e.message ? e.message : 'Unknown error'
                    }, () => {
                        if (e && e.code && e.code === 'functions/unauthenticated') {
                            firebaseClient.logOut();
                        }
                    });
                });
            }
            else {
                firebaseClient.saveStream(event).then((res) => {
                    let id = res;
                    this.setState({ scheduling: false });
                    this.props.pushToStream(id);
                });
            }
        }
    }

    validDate(current) {
        var yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleCohostsDelete(i) {
        const { cohosts } = this.state;

        this.setState({
            cohosts: cohosts.filter((cohost, index) => index !== i)
        });
    }

    handleCohostsAddition(cohost) {
        if (cohost && cohost.email) {
            this.setState(state => ({ cohosts: [...state.cohosts, cohost], cohostsSuggestions: [] }));
        }
    }

    handleCohostsDrag(cohost, currPos, newPos) {
        const cohosts = [...this.state.cohosts];
        const newCohosts = cohosts.slice();

        newCohosts.splice(currPos, 1);
        newCohosts.splice(newPos, 0, cohost);

        this.setState({ cohosts: newCohosts });
    }

    handleCohostsInputChange(e) {

        let cohost = e;

        if (cohost)
            cohost = cohost.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({
                noCoHostMsg: 'Loading...'
            });
            firebaseClient.getFilteredUsersFS(cohost).then((res) => {
                if (res.data.length > 0) {

                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            cohostsSuggestions: suggestions,
                            noCoHostMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noCoHostMsg: 'There are no users with this email'
                    });
                }
            });
        } else {
            this.setState(
                {
                    cohostsSuggestions: [],
                    noCoHostMsg: ''
                }
            )
        }
    }

    handleGuestsDelete(i) {
        const { guestSpeakers } = this.state;

        this.setState({
            guestSpeakers: guestSpeakers.filter((guest, index) => index !== i)
        });
    }

    handleGuestsAddition(guest) {
        if (guest && guest.email) {
            this.setState(state => ({ guestSpeakers: [...state.guestSpeakers, guest], guestSpeakersSuggestions: [] }));
        }
    }

    handleGuestsDrag(guest, currPos, newPos) {
        const guestSpeakers = [...this.state.guestSpeakers];
        const newGuests = guestSpeakers.slice();

        newGuests.splice(currPos, 1);
        newGuests.splice(newPos, 0, guest);

        this.setState({ guestSpeakers: newGuests });
    }

    handleGuestsInputChange(e) {

        let guest = e;

        if (guest)
            guest = guest.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({ noGuestMsg: 'Loading...' });
            firebaseClient.getFilteredUsersFS(guest).then((res) => {
                if (res.data.length > 0) {

                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            guestSpeakersSuggestions: suggestions,
                            noGuestMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noGuestMsg: 'There are no users with this email'
                    });
                }
            });
        } else {
            this.setState(
                {
                    guestSpeakersSuggestions: [],
                    noGuestMsg: ''
                }
            )
        }
    }

    handleModeratorsDelete(i) {
        const { moderators } = this.state;

        this.setState({
            moderators: moderators.filter((moderator, index) => index !== i)
        });
    }

    handleModeratorsAddition(moderator) {
        if (moderator && moderator.email) {
            this.setState(state => ({ moderators: [...state.moderators, moderator], moderatorsSuggestions: [] }));
        }
    }

    handleModeratorsDrag(moderator, currPos, newPos) {
        const moderators = [...this.state.moderators];
        const newModerators = moderators.slice();

        newModerators.splice(currPos, 1);
        newModerators.splice(newPos, 0, moderator);

        this.setState({ moderators: newModerators });
    }

    handleModeratorsInputChange(e) {

        let moderator = e;

        if (moderator)
            moderator = moderator.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({ noModeratorMsg: 'Loading...' });
            firebaseClient.getFilteredUsersFS(moderator).then((res) => {
                if (res.data.length > 0) {

                    //
                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            moderatorsSuggestions: suggestions,
                            noModeratorMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noModeratorMsg: 'There are no users with this email'
                    });
                }
            })
        } else {
            this.setState(
                {
                    moderatorsSuggestions: [],
                    noModeratorMsg: ''
                }
            )
        }
    }

    validStartDate(current) {
        const { timeZone } = this.state;

        if (current.isAfter(moment())) {
            return current;
        } else return moment(this.parseTime(moment().toDate().getTime(), timeZone));
    }

    handleChangeStartDate(e) {
        this.setState({
            startDT: this.validStartDate(e)
        }, () => {
            const { expireTime } = this.state;

            if (expireTime) {
                let splitValue = expireTime.split(' ')[0];
                let splitAdd = expireTime.split(' ')[1];
                this.setState({
                    stopDT: e.clone().add(parseInt(splitValue), splitAdd)
                });
            }
        })
    }

    handleChangeExpireTime(e) {
        const { startDT } = this.state;

        let value = e.target.value;
        let splitValue = value.split(' ')[0];
        let splitAdd = value.split(' ')[1];
        this.setState({
            expireTime: value,
            stopDT: startDT.clone().add(parseInt(splitValue), splitAdd)
        });
    }

    render() {
        const {
            title,
            editorState,
            tvRating,
            visibility,
            contentLabels,
            coHostShow,
            guestShow,
            moderatorsShow,
            timeZone,
            customCover,
            customCoverShow,
            category,
            tipsShow,
            tipsValue,
            numTicket,
            price,
            watchLater,
            expireTime,
            estDuration,
            cohosts,
            cohostsSuggestions,
            guestSpeakers,
            guestSpeakersSuggestions,
            moderators,
            moderatorsSuggestions,
            showVanityForm,
            vanityId,
            noModeratorMsg,
            noCoHostMsg,
            noGuestMsg,
            update,
            archivePrice,
            scheduling,
            isStream,
            copyEvent,
            privileges,
            estDurationList,
            addFileDisabled,
            error
        } = this.state;
        const {
            categories,
            user
        } = this.props;
        let default_poster = user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : ''));

        return (
            <div>
                {!showVanityForm ?
                    <div className='form-wrapper'>
                        {copyEvent ?
                            <div className='copy-event-text'>Please pick a new date/time</div>
                            : null
                        }
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className='select-line'>
                                    <div className='select-text'>Type of Stream</div>
                                    <select
                                        className={classNames('form-control', { 'private': visibility === 'private' })}
                                        value={visibility}
                                        onChange={(e) => this.setState({ visibility: e.target.value })}
                                        disabled={scheduling}
                                    >
                                        <option value='public'>Public</option>
                                        <option value='private'>Private</option>
                                    </select>
                                </div>
                                <div className='input-line padding-none'>
                                    <input
                                        type="text"
                                        placeholder="Enter stream title (max 24 chars)"
                                        value={title}
                                        maxLength="24"
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                        disabled={scheduling}
                                    />
                                    {/* <div className='text-small'>
                                        <small className="form-text text-muted">Please keep to 24 characters long</small>
                                        <small className="form-text text-muted">{`${title && title.length ? title.length : '0'}/24`}</small>
                                    </div> */}
                                </div>
                                <div className='textarea-line'>
                                    <div className='textarea-text'>Event Description</div>
                                    <Editor
                                        toolbar={{
                                            options: ['inline', 'list', 'emoji', 'history'],
                                            inline: {
                                                inDropdown: false,
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                                options: ['bold', 'italic', 'underline']
                                            },
                                            list: {
                                                inDropdown: false,
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                                options: ['unordered', 'ordered']
                                            }
                                        }}
                                        editorState={editorState}
                                        onEditorStateChange={(e) => this.setState({ editorState: e })}
                                        onContentStateChange={(e) => this.setState({ description: e })}
                                        placeholder='Start typing description'
                                        stripPastedStyles
                                    />
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Co Host</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={coHostShow}
                                                onChange={(e) => this.setState({ coHostShow: e.target.checked })}
                                                checked={coHostShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(can also administer stream)</span>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !coHostShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={cohosts}
                                            autofocus={false}
                                            suggestions={cohostsSuggestions}
                                            handleDelete={this.handleCohostsDelete.bind(this)}
                                            handleAddition={this.handleCohostsAddition.bind(this)}
                                            handleDrag={this.handleCohostsDrag.bind(this)}
                                            handleInputChange={this.handleCohostsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noCoHostMsg && <div className='no-user-message'>{noCoHostMsg}</div>}
                                    </div>
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Guest</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={guestShow}
                                                onChange={(e) => this.setState({ guestShow: e.target.checked })}
                                                checked={guestShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(cannot administer stream)</span>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !guestShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={guestSpeakers}
                                            autofocus={false}
                                            suggestions={guestSpeakersSuggestions}
                                            handleDelete={this.handleGuestsDelete.bind(this)}
                                            handleAddition={this.handleGuestsAddition.bind(this)}
                                            handleDrag={this.handleGuestsDrag.bind(this)}
                                            handleInputChange={this.handleGuestsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noGuestMsg && <div className='no-user-message'>{noGuestMsg}</div>}
                                    </div>
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Moderator</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={moderatorsShow}
                                                onChange={(e) => this.setState({ moderatorsShow: e.target.checked })}
                                                checked={moderatorsShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(can administer stream,<br />is invisible to audience)</span>
                                    </div>
                                    <div className={classNames('form-window moderator', { 'form-disabled': !moderatorsShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={moderators}
                                            autofocus={false}
                                            suggestions={moderatorsSuggestions}
                                            handleDelete={this.handleModeratorsDelete.bind(this)}
                                            handleAddition={this.handleModeratorsAddition.bind(this)}
                                            handleDrag={this.handleModeratorsDrag.bind(this)}
                                            handleInputChange={this.handleModeratorsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noModeratorMsg && <div className='no-user-message'>{noModeratorMsg}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Tickets</div>
                                    </div>
                                    <div className='form-window'>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={price}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ price: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={numTicket}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ numTicket: parseInt(e.target.value) })}
                                                >
                                                    {numTickets.map((numTicket) => {
                                                        return (
                                                            <option key={numTicket} value={numTicket}>{numTicket}</option>
                                                        );
                                                    })}
                                                </select>
                                                <small className="form-text text-muted" style={{ lineHeight: '22px' }}>Max 50,000 tickets</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames('switch-line', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Accept Tips</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={tipsShow}
                                                onChange={(e) => this.setState({ tipsShow: e.target.checked })}
                                                checked={tipsShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !tipsShow })}>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={tipsValue}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ tipsValue: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames('date-time-line', { 'update-form': update || isStream })}>
                                    <div className='date-time-text'>
                                        Date / Time
                                        {user && user.administrator ?
                                            <Button
                                                type='default'
                                                className='btn-current-time'
                                                onClick={() => this.setState({ startDT: this.parseTime(moment().toDate().getTime(), timeZone) })}
                                            >
                                                Current time
                                            </Button>
                                            : null
                                        }
                                    </div>
                                    <DateTime
                                        inputProps={{
                                            placeholder: 'Start Date',
                                            className: 'date-input',
                                            disabled: scheduling,
                                            readOnly: true
                                        }}
                                        initialViewMode='days'
                                        value={this.state.startDT}
                                        isValidDate={this.validDate}
                                        onChange={this.handleChangeStartDate}
                                        displayTimeZone={timeZone || ''}
                                        timeConstraints={{ minutes: { step: 5 } }}
                                        utc={false}
                                    />
                                    <div className='date-time-flex'>
                                        <div className='date-time-text'>Est. duration</div>
                                        {estDurationList && estDurationList.length ?
                                            <div className='date-input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={estDuration}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ estDuration: parseInt(e.target.value) })}
                                                >
                                                    {estDurationList.map((item) => {
                                                        return (
                                                            <option key={item.value} value={item.value}>{item.text}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            : null}
                                    </div>
                                    <select
                                        className="form-control"
                                        value={timeZone}
                                        onChange={(e) => this.setState({ timeZone: e.target.value })}
                                        disabled={scheduling}
                                    >
                                        {moment.tz.names().map((item) => {
                                            return (
                                                <option key={item} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': privileges ? !privileges.record_show : false })}>
                                    <div className='switch-div'>
                                        <div className='switch-text--multi-row'>Make this event's recording available for people to watch later</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={watchLater}
                                                onChange={(e) => this.setState({ watchLater: e.target.checked })}
                                                checked={watchLater}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !watchLater })}>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={archivePrice}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ archivePrice: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={expireTime}
                                                    disabled={scheduling}
                                                    onChange={this.handleChangeExpireTime.bind(this)}
                                                >
                                                    <option disabled value=''>Expire Time</option>
                                                    <option value='1 d'>1 day</option>
                                                    <option value='2 d'>2 days</option>
                                                    <option value='1 w'>1 week</option>
                                                    <option value='2 w'>2 weeks</option>
                                                    <option value='1 M'>1 month</option>
                                                    <option value='6 M'>6 months</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                {categories && categories.length &&
                                    <div className='choose-line'>
                                        <div className='choose-text'>Categories</div>
                                        <div className="btn-group btn-group-toggle choose-button" data-toggle="buttons">
                                            {categories.map((item) => {
                                                return (
                                                    <label key={item.id} className={`btn btn-outline-secondary ${item.id === category ? 'active' : ''}`}>
                                                        <input
                                                            type="radio"
                                                            name={item.title}
                                                            id={item.id}
                                                            value={item.id}
                                                            onChange={(e) => this.setState({ category: e.target.value })}
                                                            autoComplete="off"
                                                            checked={item.id === category}
                                                            disabled={scheduling}
                                                        />
                                                        {item.title}
                                                    </label>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className='checkbox-line'>
                                    <div className='checkbox-text'>Maturity Rating*</div>
                                    <label className="myCheckbox">
                                        <input
                                            type="checkbox"
                                            value={tvRating ? true : false}
                                            onChange={(e) => this.setState({ tvRating: e.target.checked ? '18+' : '' })}
                                            checked={tvRating ? true : false}
                                            disabled={scheduling}
                                        />
                                        <span className='checkbox-img' />
                                        <span className='checkbox-mature'>Mature Content</span>
                                    </label>
                                    <small className="form-text text-muted">*Note: Selecting none will be marked for general audience</small>
                                </div>
                                {contents && contents.length &&
                                    <div className='switch-line padding-none'>
                                        <div className='switch-div'>
                                            <div className='switch-text'>Content Labels</div>
                                        </div>
                                        <div className='form-window'>
                                            <small className="form-text text-muted">The following content labels are added to the rating to identify the show's content.</small>
                                            <div className="btn-group btn-group-toggle content-labels-button" data-toggle="buttons">
                                                {contents.map((item) => {
                                                    return (
                                                        <div key={item.id} className='contentLabels-div'>
                                                            <label className={`btn btn-rating btn-outline-secondary ${contentLabels.includes(item.title) ? 'active' : ''}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={item.title}
                                                                    id={item.id}
                                                                    value={item.title}
                                                                    onChange={this.handleChangeContentLabels.bind(this)}
                                                                    autoComplete="off"
                                                                    checked={contentLabels.includes(item.title)}
                                                                    disabled={scheduling}
                                                                />
                                                                {item.id}
                                                            </label>
                                                            <small className="form-text text-muted">{item.title}</small>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className='switch-line padding-none'>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Use Custom Stream Cover</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={customCoverShow}
                                                onChange={(e) => this.setState({ customCoverShow: e.target.checked })}
                                                checked={customCoverShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !customCoverShow })}>
                                        <small className="form-text text-muted text-default">Note: Default User Profile image will be the default</small>
                                        <img src={customCover ? customCover : default_poster} loading='lazy' className='poster-img' />
                                        <input
                                            ref={this.addFileRef}
                                            className='add-file'
                                            type="file"
                                            accept="image/png,image/jpeg,image/svg+xml"
                                            onChange={this.handleImageChange.bind(this)}
                                            style={{ lineHeight: '40px', paddingRight: '5px' }}
                                        />
                                        <Button
                                            type='border'
                                            className='btn-choose-img'
                                            onClick={() => this.handleClickAddImage()}
                                            disabled={scheduling || addFileDisabled}
                                        >
                                            choose file
                                        </Button>
                                    </div>
                                </div>
                                {error ?
                                    <div className='switch-line'>
                                        <p className='error-calendar'>{error}</p>
                                    </div>
                                    :
                                    null
                                }
                                <div className='form-submit'>
                                    <Button
                                        type='default'
                                        className='btn-submit-button'
                                        onClick={() => this.handleAdd()}
                                        disabled={!title || !category || !numTicket || scheduling}
                                        loading={scheduling}
                                    >
                                        {update ? isStream ? 'Update Stream' : 'Update Event' : 'Schedule Event'}
                                    </Button>
                                </div>
                                <div className='form-note'>Note: You can go back and edit later</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='form-wrapper'>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className='vanity-link-line'>
                                    <div className='copy-vanity'>You have scheduled an event on No Filter Network!</div>
                                    <div className='copy-vanity'>You can view all your scheduled events on your MyStuff Page.</div>
                                </div>
                                <div className='form-submit'>
                                    <Button
                                        type='default'
                                        className='btn-submit-button'
                                        onClick={() => this.props.pushToEvent(vanityId)}
                                    >
                                        {`Confirm & Exit`}
                                    </Button>
                                </div>
                                <div className='form-note'>Note: You can revisit this page for future</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        categories: state.firebase.categories
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToEvent: (id) => {
            dispatch(nativePush(`/event/${id}`));
        },
        pushToStream: (id) => {
            dispatch(nativePush(`/stream/${id}`));
        }
    };
};

const CalendarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Calendar);

export default CalendarContainer;
