import React, { Component } from 'react';
import { connect } from "react-redux";
import Loader from './Loader';
import * as appActions from "../../actions/app_actions";

class CheckingLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        const { showLogInNotification, appStage, user } = this.props;

        if (appStage && appStage === appActions.APP_STAGE_LOADED && !user && showLogInNotification) {
            this.setState({
                loader: false
            }, () => {
                showLogInNotification();
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { showLogInNotification, appStage, user } = this.props;

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED && !user && showLogInNotification) {
            this.setState({
                loader: false
            }, () => {
                showLogInNotification();
            });
        }
    }

    render() {
        const { loader } = this.state;

        return (
            <div>
                {loader ?
                    <Loader />
                    : null
                }
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        user: state.firebase.user,
        logInNotification: state.app.logInNotification,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showLogInNotification: () => {
            dispatch(appActions.showLogInNotification(true, 'page'));
        }
    };
};

const CheckingLoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckingLogin);

export default CheckingLoginContainer;
