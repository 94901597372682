import React, { Component } from "react";
import { connect } from "react-redux";
import recordingActive from "../../../../resources/icons/record-active.svg";

class RecordingStatus extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { isRecording, recordingLocked } = this.props;

        return (
            <div>
                {(isRecording || recordingLocked) ?
                    <div className='recording-button'>
                        <img src={recordingActive} alt='recording' loading='lazy' />
                    </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isRecording: state.voxeet.controls.isRecording,
        recordingLocked: state.voxeet.controls.recordingLocked
    };
};

const RecordingStatusContainer = connect(
    mapStateToProps
)(RecordingStatus);

export default RecordingStatusContainer;
