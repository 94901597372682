import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ComponentHeader from '../Widget/ComponentHeader';
import QuestionEmailComponent from '../Footer/QuestionEmailComponent';

class Contact extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='bg-100'>
                <Helmet>
                    {/*Primary Meta Tags*/}
                    <meta charSet="utf-8" />
                    <title>NoFilter.net - Contact Us</title>
                    <meta name="title" content="NoFilter.net - Contact Us" />
                    <link rel="canonical" href="https://nofilter.net/contact" />
                    {/*Open Graph / Facebook*/}
                    <meta property="og:url" content="https://nofilter.net/contact" />
                    <meta property="og:title" content="NoFilter.net - Contact Us" />
                    {/*Twitter*/}
                    <meta property="twitter:url" content="https://nofilter.net/contact" />
                    <meta property="twitter:title" content="NoFilter.net - Contact Us" />
                </Helmet>
                <ComponentHeader text="contact us" />
                <QuestionEmailComponent />
            </div>
        )
    }
}

export default Contact;