import React, { Component } from 'react';
import { connect } from "react-redux";
import { push as nativePush } from 'connected-react-router';
import classNames from "classnames";
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import eyeIcon from '../../../resources/icons/eye.svg';
import eyeDisableIcon from '../../../resources/icons/disable_eye.svg';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            processing: false,
            password: '',
            confirmPassword: '',
            errorText: '',
            successText: '',
            pathId: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.changeInputType = this.changeInputType.bind(this);
        this.updateUserPassword = this.updateUserPassword.bind(this);
    }

    componentDidMount() {
        const { match } = this.props;

        if (match && match.params && match.params.pathId) {
            this.setState({
                pathId: match.params.pathId
            });
        }
    }

    validatePassword(password) {
        const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        return re.test(String(password));
    }

    componentWillUnmount() {
        if (this.succesTimeout) {
            clearTimeout(this.succesTimeout);
        }
    }

    updateUserPassword() {
        const { password, confirmPassword, pathId } = this.state;

        if (!pathId || !password || (password && !this.validatePassword(password)) || !confirmPassword || (confirmPassword && !this.validatePassword(confirmPassword)) || password !== confirmPassword) {
            this.checkForm();
        } else {
            this.setState({ processing: true });
            firebaseClient.changeUserPassword(pathId, password).then(() => {
                this.setState({
                    error: null,
                    processing: false,
                    password: '',
                    confirmPassword: '',
                    errorText: '',
                    successText: "Password changed successfully, use new password the next time you sign in.",
                    inputTypeFirst: 'password',
                    inputTypeSecond: 'password'
                }, () => {
                    this.succesTimeout = setTimeout(() => {
                        const { pushToHome } = this.props;
                        if (pushToHome)
                            pushToHome();
                    }, 4000);
                });
            }).catch(error => {
                this.setState({
                    error: error,
                    processing: false,
                    password: '',
                    confirmPassword: '',
                    errorText: "Some error occurred",
                    successText: '',
                    inputTypeFirst: 'password',
                    inputTypeSecond: 'password'
                });
            });
        }
    }

    reset() {
        this.setState({
            error: null,
            processing: false,
            password: '',
            confirmPassword: '',
            errorText: '',
            successText: '',
            pathId: '',
            inputTypeFirst: 'password',
            inputTypeSecond: 'password'
        });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.updateUserPassword();
        }
    }

    changeInputType(value) {
        const { inputTypeFirst, inputTypeSecond } = this.state;

        switch (value) {
            case 1:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
            case 2:
                if (inputTypeSecond && inputTypeSecond === 'password') {
                    this.setState({
                        inputTypeSecond: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeSecond: 'password'
                    });
                }
                break;
            default:
                if (inputTypeFirst && inputTypeFirst === 'password') {
                    this.setState({
                        inputTypeFirst: 'text'
                    });
                } else {
                    this.setState({
                        inputTypeFirst: 'password'
                    });
                }
                break;
        }
    }

    checkForm() {
        const { password, confirmPassword } = this.state;

        let message = '';

        if (!password) {
            message = "You must enter your password";
        } else if (password && !this.validatePassword(password)) {
            message = "You must enter a valid password";
        } else if (!confirmPassword) {
            message = "You must enter your confirmation password";
        } else if (confirmPassword && !this.validatePassword(confirmPassword)) {
            message = "You must enter a valid confirmation password";
        } else if (password !== confirmPassword) {
            message = "Passwords do not match";
        }

        this.setState({
            errorText: message
        });
    }

    render() {
        const { processing, password, confirmPassword, inputTypeFirst, inputTypeSecond, errorText, successText } = this.state;

        return (
            <div>
                {!processing ?
                    <div className='profile-div-wrapper'>
                        <div className='profile-edit-wrapper'>
                            <p className='profile-edit-title'>Reset password</p>
                            <div className='edit-profile-input-wrapper'>
                                <div className='edit-profile-input-line eye-input'>
                                    <p>New Password</p>
                                    <input
                                        className={classNames('password', { 'valid': password && this.validatePassword(password) })}
                                        type={inputTypeFirst}
                                        placeholder="Enter new password"
                                        disabled={processing}
                                        onChange={(e) => this.setState({ password: e.target.value, errorText: '', successText: '', })}
                                        value={password}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <img
                                        className='eye'
                                        onClick={() => this.changeInputType(1)}
                                        src={inputTypeFirst && inputTypeFirst === 'password' ? eyeIcon : eyeDisableIcon}
                                        alt='eye'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                            <div className='edit-profile-input-wrapper'>
                                <div className='edit-profile-input-line eye-input'>
                                    <p>Confirm Password</p>
                                    <input
                                        className={classNames('password', { 'valid': confirmPassword && this.validatePassword(confirmPassword) })}
                                        type={inputTypeSecond}
                                        placeholder="Confirm new password"
                                        disabled={processing}
                                        onChange={(e) => this.setState({ confirmPassword: e.target.value, errorText: '', successText: '', })}
                                        value={confirmPassword}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <img
                                        className='eye'
                                        onClick={() => this.changeInputType(2)}
                                        src={inputTypeSecond && inputTypeSecond === 'password' ? eyeIcon : eyeDisableIcon}
                                        alt='eye'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                            {errorText ? <p className='error-text'>{errorText}</p> : null}
                            {successText ? <p className='success-text'>{successText}</p> : null}
                            <div className='edit-profile-buttons'>
                                <Button
                                    type='default'
                                    className="btn-save-profile"
                                    onClick={this.updateUserPassword}
                                    disabled={processing}
                                    loading={processing}
                                >
                                    Change password
                                </Button>
                            </div>
                        </div>
                    </div>
                    : 
                    <Loader 
                        text="Resetting password"
                        type="page"
                    />
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pushToHome: () => {
            dispatch(nativePush(`/`));
        }
    };
};

const ResetPasswordContainer = connect(
    null,
    mapDispatchToProps
)(ResetPassword);

export default ResetPasswordContainer;