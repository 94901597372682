import {
  ADD_MESSAGE_CHAT,
  DELETE_MESSAGE_BY_ID_CHAT,
  DELETE_MESSAGES_BY_IDS_CHAT,
  RESET_BADGE_MESSAGE_CHAT,
  CLEAR_MESSAGES,
  ADD_HISTORY_CHAT,
  NEW_BADGE_MESSAGE_CHAT,
  SHOW_EMOJI_FORM_CHAT,
  HIDE_EMOJI_FORM_CHAT
} from "../actions/chat_actions";

const initialState = {
  newMessage: false,
  messages: [],
  emoji: null,
  showEmoji: false
};

const FanoutChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE_CHAT: {
      let messages = [...state.messages];
      messages.push(action.payload.chat);
      return {
        ...state,
        messages: [...messages]
      };
    }
    case DELETE_MESSAGE_BY_ID_CHAT: {
      let id = action.payload.id, messages = [...state.messages];

      const newArrMessages = messages.filter(object => {
        return object.id !== id;
      });

      return {
        ...state,
        messages: newArrMessages
      };
    }
    case DELETE_MESSAGES_BY_IDS_CHAT: {
      let ids = action.payload.ids, messages = [...state.messages];

      const newArrMessages = messages.filter(objectA => {
        return !ids.find(id => {
          return id && objectA.id === id;
        });
      });

      return {
        ...state,
        messages: newArrMessages
      };
    }
    case CLEAR_MESSAGES: {
      return Object.assign({}, initialState);
    }
    case ADD_HISTORY_CHAT: {
      let messages = [...action.payload];

      const newArrMessages = messages.filter(object => {
        return object !== null;
      });

      return {
        ...state,
        messages: [...newArrMessages]
      };
    }
    case NEW_BADGE_MESSAGE_CHAT: {
      return {
        ...state,
        newMessage: true
      };
    }
    case RESET_BADGE_MESSAGE_CHAT: {
      return {
        ...state,
        newMessage: false
      };
    }
    case SHOW_EMOJI_FORM_CHAT:
    case HIDE_EMOJI_FORM_CHAT: {
      const data = action.payload;

      return Object.assign({}, state, data);
    }
    default: {
      return state;
    }
  }
};

export default FanoutChatReducer;

