import React, { Component } from "react";
import ReactPlayer from "react-player";
import bowser from "bowser";
import firebaseClient from '../../../utils/FirebaseClient';

class FanoutVideoPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      safariAutoplay: bowser.safari
    };
    this.startVideoForSafari = this.startVideoForSafari.bind(this);
  }

  componentDidMount() {
    if (this.videoPresentation && this.props.duration) {
      let parseTS = this.props.duration || 0;
      if (parseTS) {
        parseTS++;
      }
      this.videoPresentation.seekTo(parseTS, 'seconds');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videoData.ts !== this.props.videoData.ts) {
      let parseTS = parseInt(this.props.videoData.ts / 1000) || 0;
      if (parseTS) {
        parseTS++;
      }
      this.videoPresentation.seekTo(parseTS, 'seconds');
    }
  }

  startVideoForSafari() {
    this.setState({ safariAutoplay: false });
    this.videoPresentation.getInternalPlayer().play();
  }

  render() {
    const { isVideoPresentation } = this.props;
    const { safariAutoplay } = this.state;
    const { ts, url, playing } = this.props.videoData;
    return (
      <div className="stream-media">
        {bowser.safari && this.state.safariAutoplay && (
          <div className="safari-autoplay-container">
            <button
              className="safari-autoplay"
              onClick={this.startVideoForSafari}
            >
              Start video
            </button>
          </div>
        )}
        <ReactPlayer
          className='video-participant'
          id="fullscreen-video"
          style={{ pointerEvents: 'none' }}
          url={url}
          playsinline
          ref={ref => (this.videoPresentation = ref)}
          playing={playing && !safariAutoplay ? true : false}
          controls={false}
          pip={true}
          onReady={() => this.props.sendRef(this.videoPresentation)}
          config={{
            file: {
              attributes: {
                autoPlay: true
              }
            },
            youtube: {
              playerVars: {
                enablejsapi: 1,
                origin: firebaseClient.getDomain()
              }
            }
          }}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default FanoutVideoPresentation;