import * as appActions from "../actions/app_actions";
import * as broadcastActions from "../actions/broadcast_actions";
import * as eventActions from "../actions/event_actions";
import * as firebaseActions from "../actions/firebase_actions";
import * as paymentActions from "../actions/payment_actions";
import * as mystuffActions from "../actions/mystuff_actions";
import { push as nativePush } from 'connected-react-router';
import imageCompression from 'browser-image-compression';
import { isIOS } from './text';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/compat/messaging";

// import { cfaSignInApple, cfaSignInGoogle } from "capacitor-firebase-auth"
// import { user } from "firebase-functions/lib/providers/auth";
import VoxeetSdk from "@voxeet/voxeet-web-sdk";
import klaviyoClient from './KlaviyoClient';

let workerInstance;

async function getTokenAlready(params) {
    var st = firebase.functions().httpsCallable('getStreamToken');

    var res = await st(params).then((result) => {
        console.log('Received token ');
        return result.data.access_token;
    }).catch((error) => {
        let code = error.code;
        let message = error.message;
        console.error('There was an error [%s] when calling getStreamToken', code, message);
        return {
            error: true,
            code,
            message
        }
        // console.log(postData);
    });
    return res;
}

async function getTokenAuthentication(params) {
    var st = firebase.functions().httpsCallable('getStreamToken');

    var res = await st({ refreshAuth: true, ...params }).then((result) => {
        console.log('Received token: ');
        return result.data.access_token;
    }).catch((error) => {
        var code = error.code;
        console.error('There was an error when calling the Cloud Function', error);
        return {};
    });
    return res;
}

function getMessagingObject() {
    // [START messaging_get_messaging_object]
    const messaging = firebase.messaging();
    // [END messaging_get_messaging_object]
}

function receiveMessage() {
    const messaging = firebase.messaging();
    // [START messaging_receive_message]
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        // ...
    });
    // [END messaging_receive_message]
}

function getMessagingToken() {
    const messaging = firebase.messaging();
    // [START messaging_get_token]
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    messaging.getToken({ vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });
    // [END messaging_get_token]
}

function requestMessagingPermission() {
    // [START messaging_request_permission]
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // TODO(developer): Retrieve a registration token for use with FCM.
            // ...
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
    // [END messaging_request_permission]
}

function deleteMessagingToken() {
    const messaging = firebase.messaging();

    // [START messaging_delete_token]
    messaging.deleteToken().then(() => {
        console.log('Token deleted.');
        // ...
    }).catch((err) => {
        console.log('Unable to delete token. ', err);
    });
    // [END messaging_delete_token]
}

class FirebaseClient {
    constructor(store) {
        this._store = store;
    }

    setStore(store) {
        this._store = store;
    }

    initFirebase() {
        let fireBase = new Promise((resolve, reject) => {
            try {
                const config = {
                    apiKey: process.env.apiKey,
                    authDomain: process.env.authDomain,
                    databaseURL: process.env.databaseURL,
                    projectId: process.env.projectId,
                    storageBucket: process.env.storageBucket,
                    messagingSenderId: process.env.messagingSenderId,
                    appId: process.env.appId,
                    measurementId: process.env.measurementId
                };

                firebase.initializeApp(config);
                firebase.analytics();
                // requestMessagingPermission();
                // workerInstance = new Worker(new URL('./messaging-service-worker.js', import.meta.url));
                if (firebase.app().name) {
                    console.log('Firebase initialized');
                    this._store.dispatch(firebaseActions.initFirebase(true));
                    resolve(true);
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    isUserLogged() {
        let valid = new Promise((resolve, reject) => {
            const user = firebase.auth().currentUser;

            user.getIdToken(true).then((token) => {
                if (token) {
                    resolve(true)
                } else {
                    resolve(false);
                    this.logOut();
                }
            }).catch((error) => {
                reject(error);
                this.logOut();
                console.log('getIdToken error', error);
            });
        });

        return valid;
    }

    init() {
        let appInit = new Promise((resolve, reject) => {
            try {
                if (firebase.app().name) {
                    firebase.auth().onAuthStateChanged((user) => {
                        if (user && user.uid) {
                            let { uid, displayName, email, photoURL, emailVerified } = user;
                            // console.log('User:', { uid, displayName, email, photoURL });
                            this.getUserData({ uid, displayName, email, photoURL, emailVerified }, true).then((user) => {
                                // console.log('NFB User: ', user);
                                this.getMyChannels();
                                this.listenToLiveUpdates();
                                this.getUserTicket(uid).then((tickets) => {
                                    this._store.dispatch(mystuffActions.setMyTickets(tickets));
                                    this._store.dispatch(firebaseActions.setLoggedIn('yes'));
                                    this.getMyStuffData(uid);
                                    this._store.dispatch(appActions.showLogIn(false));
                                    resolve(true);
                                }).catch(e => {
                                    console.error('Error getting user data', e.message);
                                    resolve(true);
                                });
                                this.readFromLiveTeasers();
                            });
                            klaviyoClient.load().then((ret) => {
                                console.log('About to call identify', { uid, displayName, email, photoURL })
                                klaviyoClient.identify({ uid, displayName, email, photoURL })
                            });

                        } else {
                            this.clearUserData();
                            resolve(true);
                        }
                    });

                    if (firebase.apps.length) {
                        // getPublicData
                        this.getPublicData();
                    }
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            this._store.dispatch(appActions.appState('error'));
            console.error('Aplication error: ' + reason);
            return Promise.resolve(null);
        });

        return appInit;

    }

    async everLoggedIn() {
        console.log('everLoggedIn - started')
        let openDB = new Promise((resolve, reject) => {
            let db;
            const requestOpen = indexedDB.open("firebaseLocalStorageDb");
            requestOpen.onerror = (event) => {
                console.error("everLoggedIn - Could not open db");
                reject();
            };
            requestOpen.onsuccess = (event) => {
                db = requestOpen.result;
                console.log('everLoggedIn - db opened')
                resolve(db);
            };
        });
        let check = new Promise(async (resolve, reject) => {
            try {
                let db = await openDB;
                if (!db) {
                    console.error('everLoggedIn - Could not get db');
                }
                const transaction = db.transaction(["firebaseLocalStorage"], "readonly");
                const objectStore = transaction.objectStore("firebaseLocalStorage");
                const request = objectStore.count();
                request.onerror = (event) => {
                    resolve(false);
                };
                request.onsuccess = (event) => {
                    console.log('everLoggedIn - Get user count', request.result);
                    // Do something with the request.result!
                    resolve(request.result > 0);
                };
            } catch (e) {
                console.error('everLoggedIn - error', e)
                resolve(false);
            }
        });

        this.loggedInTimeout = setTimeout(() => this.checkIsLoggedIn(), 30000); // check isLoggedIn after 30s

        return check;
    }

    checkIsLoggedIn() {
        const { isLoggedIn } = this._store.getState().firebase;

        if ((!isLoggedIn) || (isLoggedIn && isLoggedIn === 'maybe')) {
            this._store.dispatch(firebaseActions.setLoggedIn('no'));
        }
    }

    async refreshData() {
        await this.getPublicData();

        const { user } = this._store.getState().firebase;

        if (!user || (user && !user.uid))
            return null;

        this.getUserTicket(user.uid).then((tickets) => {
            this._store.dispatch(mystuffActions.setMyTickets(tickets));
            this.getMyStuffData(user.uid);
        }).catch(e => {
            console.error('Error getting user data', e.message);
        });
    }

    subscribeToFanoutStateChanges(fanoutId, callback = null) {
        if (fanoutId) {
            let fanoutRef = firebase.database().ref(`fanout/instances/${fanoutId}/state`);
            fanoutRef.off();
            fanoutRef.on('value', (data) => {
                if (data.exists()) {
                    console.log('Fanout App State', data.val());
                    if (callback)
                        callback(data.val());
                }
            });
        }
    }

    getUserToken() {
        return firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
            // Send token to your backend via HTTPS
            console.log('idToken', idToken);
            return idToken;
        }).catch(function (error) {
            // Handle error
        }) : null;

    }

    unsubscribeFromSignallingClientChanges(uuid, alias) {
        if (uuid && alias)
            firebase.database().ref("signalling/to_client/" + alias + "/" + uuid).off();
    }

    getDomain() {
        let domain = process.env.domain;
        if (domain) {
            if (domain.indexOf(':') === -1)
                return domain;
            let url = new URL(domain);
            return url.hostname;
        } else {
            let url = new URL(window.location.origin || '');
            return url.hostname;
        }
    }

    clearUserData() {
        const {
            user,
            auth,
            roles,
            channelList,
            stripeData,
            card,
            products,
            subscriptions,
            teasers
        } = this._store.getState().firebase;
        const {
            tickets
        } = this._store.getState().mystuff;

        this._store.dispatch(firebaseActions.setLoggedIn('no'));
        auth && this._store.dispatch(firebaseActions.logOut());
        user && this._store.dispatch(firebaseActions.setUser(null));
        roles && this._store.dispatch(firebaseActions.setRoles(null));
        channelList && this._store.dispatch(firebaseActions.setChannelList([]));
        stripeData && this._store.dispatch(firebaseActions.setStripeData(null));
        card && this._store.dispatch(firebaseActions.setCard(null));
        products && this._store.dispatch(firebaseActions.setProducts([]));
        subscriptions && this._store.dispatch(firebaseActions.setSubscriptionList([]));
        teasers && this._store.dispatch(firebaseActions.setTeasers({}));
        tickets && this._store.dispatch(mystuffActions.setMyTickets([]));
    }

    checkShowFollowButton(userId) {
        const { user, channelList } = this._store.getState().firebase;
        if (channelList && channelList.length && userId) {
            let check = channelList.some(el => el.uid === userId);
            if (check) {
                return false;
            } else if (!check && user && user.uid) {
                if (user.uid === userId) {
                    return false;
                } else {
                    return true;
                }
            } else return true;
        } else if (user && user.uid && userId) {
            if (user.uid === userId) {
                return false;
            } else {
                return true;
            }
        } else return true;
    }

    getPublicDataRT() {
        const safeParse = (json) => {
            let ret = null;
            try {
                ret = JSON.parse(json);
            } catch (e) {
                console.error('Could not parse data', json)
            }
            return ret;
        }
        const loadCachedData = (snapshot) => {
            if (snapshot.exists()) {
                // console.log('----------------------- Cached data:', snapshot.val());
                let cached = snapshot.val(), ret = {};
                Object.entries(cached).forEach(([key, json_value]) => {
                    if (json_value && (typeof json_value === 'string' || json_value instanceof String)) {
                        ret[key] = safeParse(json_value)
                    }
                });
                // console.log('----------------------- Parsed', ret);
                return ret;
            } else {
                console.log("No cached public data available");
                return null;
            }
        };
        const takeTwo = (error) => {
            return new Promise((resolve) => {
                setTimeout(() => firebase.database().ref('public/').get()
                    .then(loadCachedData)
                    .then((data) => {
                        return resolve(data);
                    })
                    .catch((error) => {
                        console.error(error);
                        return resolve(null);
                    }), 200);
            });
        };
        return firebase.database().ref('public/').get()
            .then(loadCachedData)
            .then((result) => {
                if (result !== null) {
                    return result
                } else
                    return takeTwo({ message: 'About to try once more' })
            })
            .catch(takeTwo);

    }
    async getPublicData() {
        let pdata = await this.getPublicDataRT();
        if (pdata === null ||
            !pdata.categories || !pdata.categories.length
        ) {
            // Load from db
            let rq = firebase.functions().httpsCallable('getPublicData');
            pdata = await rq().then((doc) => {
                // console.log('getPublicData response:', doc ? doc.data : null)
                if (!doc)
                    return null;
                return doc.data;
            });
        }
        let { categories, featured_events, events, videos, hosts } = pdata;
        try {
            // Categories
            if (categories) {
                let ctgs = categories.filter((item) => {
                    return item.type && item.type == 'category'
                }).sort((a, b) => {
                    if (a.priority < b.priority) {
                        return -1;
                    }
                    if (a.priority > b.priority) {
                        return 1;
                    }
                    return 0;
                });
                this._store.dispatch(firebaseActions.getCategories(ctgs));
            }
            // featured_events
            if (featured_events) {
                let featured = featured_events.map((item) => {
                    return item.id
                })
                this._store.dispatch(firebaseActions.getFeatured(featured));
            }
            // Load events
            if (events) {
                this._store.dispatch(firebaseActions.getCalendarList(events));
            }
            // Load videos
            if (videos) {
                this._store.dispatch(firebaseActions.getVideos(videos));
            }

            // Load Hosts
            if (hosts) {
                hosts.sort((a, b) => a.username && a.username.toLowerCase && b.username && b.username.toLowerCase && a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1);
                const ambassadors = hosts.length ? hosts.filter(item => item.ambassador || item.premium) : [];
                this._store.dispatch(firebaseActions.setHosts(ambassadors, hosts));
            }

            this.getMyStuffData();
            return null;
        } catch (e) {
            console.error('Could not load public data', e.message, e);
        };
    }

    getSearchVideosFS(startDate, live, category, start) {

        const collection = `/posts`;
        const where = {
            fieldPath: 'startDate',
            optStr: '>',
            value: startDate
        }
        const w2 = {
            fieldPath: 'live',
            optStr: '==',
            value: live
        }
        const where2 = live === true || live === false ? w2 : null;
        const w3 = {
            fieldPath: 'cat',
            optStr: '==',
            value: category
        }
        const where3 = category ? w3 : null;
        const orderBy = {
            fieldPath: 'startDate',
            directionStr: 'desc'
        }
        const limit = 200;
        const startAfter = start ? start : null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchObject(collection, where, where2, where3, orderBy, limit, startAfter);
    }

    checkCustomers(user) {
        this.getOne('/customers', user.uid).then((res) => {
            if (res && res.data) {
                let response = res.data;
                this.getCard(response.stripeId);
                this.getProducts();
                this.readSubscriptions(response.stripeId);
                this._store.dispatch(firebaseActions.setStripeData(response));
                // console.log('Stripe user', response);
            } else if (user) {
                this.createCustomer(user);
                console.warn(
                    `No Stripe customer found in Firestore for user: ${user.uid}`
                );
            }
        }).catch(e => {
            console.error(`Could not check customers: ${e.message}`)
        });
    }

    sendInquireEmail(broadcaster_name, broadcaster_email, inquiry_name, email, daytime_contact, message) {
        let ref = firebase.functions().httpsCallable('sendInquireEmailFS');
        let that = this;
        return ref({
            broadcaster_name: broadcaster_name,
            broadcaster_email: broadcaster_email,
            inquiry_name, inquiry_email: email,
            daytime_contact: daytime_contact,
            message: message
        }).then((result) => {
            // console.log('Sent email', email);
            // this.getMyChannels();
            return result.data;
        }).catch(e => {
            console.error(`Could not send Inquire email: ${e.message}`)
        });
    }

    sendFaqQuestionEmail(subject, message) {
        let ref = firebase.functions().httpsCallable('sendFaqQuestionEmailFS');
        return ref({
            subject: subject,
            message: message
        }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send FAQ email: ${e.message}`)
        });
    }

    sendFeedback(data) {
        let ref = firebase.functions().httpsCallable('sendFeedback');
        return ref(data).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send feedback: ${e.message}`)
        });
    }

    sendWelcomeMail() {
        let ref = firebase.functions().httpsCallable('sendWelcomeMail');
        return ref({}).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send welcome mail: ${e.message}`)
        });
    }

    getVideoLinks(alias, download = false) {
        let ref = firebase.functions().httpsCallable('getVideoLinks');
        return ref({ alias, download }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not get video links: ${e.message}`)
        });
    }

    sendSupportMail(userId, type) {
        let ref = firebase.functions().httpsCallable('sendSupportMail');
        return ref({ userId, type }).then((result) => {
            return result;
        });
    }

    setHostPrivileges(userId) {
        let ref = firebase.functions().httpsCallable('setHostPrivileges');
        return ref({ userId }).then((result) => {
            return result;
        });
    }

    getFilteredUsersFS(email) {

        const collection = `/users`;
        const where = {
            fieldPath: 'email',
            optStr: '>=',
            value: email
        }
        const where2 = {
            fieldPath: 'email',
            optStr: '<=',
            value: email + '\uf8ff'
        }

        const where3 = null;
        const orderBy = {
            fieldPath: 'email',
        }
        const limit = 1000;
        const startAfter = null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchObject(collection, where, where2, where3, orderBy, limit, startAfter);
    }

    getUserTicket(uid, streamId = null, status = null, limit = 1000) {
        const collection = "/tickets";
        const where = {
            fieldPath: 'userId',
            optStr: "==",
            value: uid
        }

        // const where2 = streamId ? {
        //     fieldPath: "streamId",
        //     optStr: "==",
        //     value: streamId
        // } : null;

        // const where3 = (typeof status === 'string' && status.length > 0) ?
        //     {
        //         fieldPath: "paymentStatus",
        //         optStr: "==",
        //         value: status
        //     } : null

        // return this.getAllSearchObject(collection, where, where2, where3, null, limit, null);


        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('getUserTickets');
                return readObjectRef({ streamId }).then((result) => {
                    console.log('User tickets received');
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });

        return objects;
    }

    reserveTicket(item) {
        let reserve = new Promise((resolve, reject) => {
            try {
                let reserveTicketRef = firebase.functions().httpsCallable('reserveTicketV2');
                return reserveTicketRef({ item: item, id: uuidv4() }).then((result) => {
                    // console.log('Reserve Ticket', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Reserve Ticket', e);
                reject(e.message);
            }
        });
        return reserve;
    }

    makeDynamicLink(data) {
        let link = new Promise((resolve, reject) => {
            try {
                let makeDynamicLinkRef = firebase.functions().httpsCallable('makeDynamicLink');
                return makeDynamicLinkRef(data).then((result) => {
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error makeDynamicLink', e);
                reject(e.message);
            }
        });
        return link;
    }

    follow(uid, name, pic) {
        // We are trying to subscribe to this streamer's channel

        let ref = firebase.functions().httpsCallable('addChannel');
        let that = this;
        ref({ uid: uid, displayName: name, picture: pic }).then(() => {
            console.log('Added channel');
            this.getMyChannels();
        });
    }

    unfollow(uid) {
        // We are trying to subscribe to this streamer's channel

        let ref = firebase.functions().httpsCallable('removeChannel');
        let that = this;
        ref({ uid: uid }).then((res) => {
            console.log('Remove channel');
            let btn = document.getElementById(`btn-unfollow-${uid}`);
            if (btn) {
                btn.style.pointerEvents = 'auto';
                btn.style.opacity = '1';
            }
            this.getMyChannels();
        });
    }

    getMyChannels() {
        let channel = new Promise((resolve, reject) => {
            try {
                let getMyChannelsRef = firebase.functions().httpsCallable('getMyChannels');
                return getMyChannelsRef().then((result) => {
                    //console.log("RESULT:", result);
                    if (result.data) {
                        this._store.dispatch(firebaseActions.setChannelList(result.data));
                    }
                    // console.log('Results received');
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get List', e);
                reject(e.message);
            }
        });
        return channel;
    }

    logIn(value) {
        if (!firebase.auth().currentUser) {
            if (process.env.platform && process.env.platform === 'mobile') {
                // if (value === 'google') {
                //     cfaSignInGoogle().subscribe();
                // } else if (value === 'apple') {
                //     cfaSignInApple().subscribe();
                // }
            } else {
                let provider = value;
                if (provider === 'google') {
                    provider = new firebase.auth.GoogleAuthProvider();
                } else if (provider === 'yahoo') {
                    provider = new firebase.auth.OAuthProvider('yahoo.com');
                } else if (provider === 'apple') {
                    provider = new firebase.auth.OAuthProvider('apple.com')
                } else {
                    provider = new firebase.auth.FacebookAuthProvider();
                }

                return firebase.auth().signInWithPopup(provider).then((result) => {
                    // var token = result.credential.accessToken;
                    // var user = result.user;
                    console.log('login');
                })
            }
        } else {
            return firebase.auth().signOut();
        }
    }

    signInWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
                console.log('login signInWithEmailAndPassword');
            })
        } else {
            firebase.auth().signOut();
            return Promise.resolve();
        }
    }

    createUserWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().createUserWithEmailAndPassword(email, password).then((result) => {
                console.log('create createUserWithEmailAndPassword');
            });
        } else {
            return Promise.resolve();
        }
    }

    sendPasswordResetMail(email) {
        let sendPasswordResetRef = firebase.functions().httpsCallable('sendPasswordResetMail');
        return sendPasswordResetRef({ email }).then((result) => {
            console.log('result sendPasswordResetMail', result);
            return;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    verifyEmail() {
        console.log('call verify email');
        return firebase.auth().currentUser.sendEmailVerification().then(() => {
            console.log('Email verification sent!');
        });
    }

    changeUserPassword(path, password) {
        let changeUserPasswordRef = firebase.functions().httpsCallable('changeUserPassword');
        return changeUserPasswordRef({ path, password }).then((result) => {
            console.log('result changeUserPassword', result);
            return;
        });
    }

    /**
     * Start account pairing
     * @param err Error returned by signInWithPopup
     * @description
     * At this point, you should let the user know that they already have an \
     * account with a different provider, and validate they want to sign in
     * with the new provider.
     * Note: Browsers usually block popups triggered asynchronously, so in
     * real app, you should ask the user to click on a "Continue" button
     * that will trigger signInWithPopup().
     */
    pairAccounts(err) {
        if (err) {
            // User's email already exists.
            console.log('About to start pairing', err)
            // The pending credential.
            let pendingCred = err.credential;
            // The provider account's email address.
            let email = err.email;
            console.log('-++++++++++++++++', pendingCred, email)
            // Get the sign-in methods for this email.
            return firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
                console.log('++++++++++++++++', methods[0], methods)
                // If the user has several sign-in methods, the first method
                // in the list will be the "recommended" method to use.
                let provider;
                switch (methods[0]) {
                    case 'google.com':
                        provider = new firebase.auth.GoogleAuthProvider();
                        break;
                    case 'microsoft.com':
                        provider = new firebase.auth.OAuthProvider('microsoft.com');
                        break;
                    case 'apple.com':
                        provider = new firebase.auth.OAuthProvider('apple.com')
                        break;
                    case 'facebook.com':
                        provider = new firebase.auth.FacebookAuthProvider();
                        break;

                    case 'password': {
                        // TODO: Ask the user for their password.
                        // In real scenario, you should handle this asynchronously.
                        // var password = promptUserForPassword();
                        // firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
                        //     return result.user.linkWithCredential(pendingCred);
                        // }).then(() => {
                        //     // Facebook account successfully linked to the existing user.
                        //     goToApp();
                        // });
                        // return;
                        break;
                    }
                    default:
                        throw (new Error('Unknown auth provider'));
                }
                return firebase.auth().signInWithPopup(provider).then(result => {
                    // Note: Identity Platform doesn't control the provider's sign-in
                    // flow, so it's possible for the user to sign in with an account
                    // with a different email from the first one.

                    // Link the Facebook credential. We have access to the pending
                    // credential, so we can directly call the link method.
                    result.user.linkWithCredential(pendingCred).then(usercred => {
                        // Success.
                        // goToApp();
                    });
                });
            });
        } else {
            console.error('Could not pair accounts. Missing pending account');
            firebase.auth().signOut();
            throw (new Error('Could not pair accounts. Missing pending account'));
        }
    }

    logOut() {
        let logOut = new Promise((resolve, reject) => {
            try {
                firebase.auth().signOut();
                resolve(true)
            } catch (e) {
                console.log('FIREBASE ERROR', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Log out error: ' + reason);
            return Promise.resolve();
        });

        return logOut;
    }

    /**
     * Get current user products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    getUserProducts() {
        let getUserProductsRef = firebase.functions().httpsCallable('getUserProducts');
        return getUserProductsRef({}).then((result) => {
            // console.log('RESULT user products', result.data);
            return result.data;
        }).catch((error) => {
            return null;
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    /**
     * Get current user products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    getUserProductsById(id) {
        let getUserProductsRef = firebase.functions().httpsCallable('getUserProducts');
        return getUserProductsRef({ id: id }).then((result) => {
            // console.log('RESULT user products', result.data);
            return result.data;
        }).catch((error) => {
            return null;
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    /**
     * Save current user products
     * @param userId
     * @param products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    saveUserProducts(userId, products) {
        const { user } = this._store.getState().firebase;
        let saveUserProductsRef = firebase.functions().httpsCallable('saveUserProducts');
        var postData = {
            userId, products
        };
        return saveUserProductsRef(postData).then((result) => {
            // console.log('RESULT save user products', result.data);
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
            throw error;
        });

    }

    /**
     * Save current user products
     * @param userId
     * @param products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    cancelHostingSubscription(user_privileges_id) {
        let cancelHostingSubscriptionRef = firebase.functions().httpsCallable('cancelHostingSubscription');
        var postData = {
            id: user_privileges_id
        };
        return cancelHostingSubscriptionRef(postData).then((result) => {
            // console.log('RESULT cancelHostingSubscription', result.data);
            return result;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    startStreaming(alias) {
        let ssRef = firebase.functions().httpsCallable('startStreaming');
        ssRef({ alias }).then((result) => {
            console.log('Start streaming', result);
            const { fanoutId } = result.data || {};
            const { teaser } = result.data || {};
            if (alias) {
                //console.log('About to call listenToWaiting', alias, fanoutId);
                this.listenToWaiting(alias, fanoutId || '');
            }
            // Start recording teaser
            if (!isIOS() && teaser) {
                this.recAndUploadTeaser(alias);
            }
            // Get camera stream
        }).catch((error) => {
            let code = error.code;
            let message = error.message;
            let details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
            // console.log(postData);
        });
    }

    getStreamToken(params) {
        return getTokenAlready(params);
    }

    getSessionToken(params) {
        return getTokenAuthentication(params);
    }

    getOne(collection, id) {
        let readObjectRef = firebase.functions().httpsCallable('readObject');
        return readObjectRef({ collection, id }).then((result) => {
            // console.log("RESULT:", result, id);
            const resultJson = Object.assign({}, result.data, { id: id });
            return { data: resultJson };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getAllFiltered(collection, where, orderBy, limit = 50, startAt = null) {
        let category = new Promise((resolve, reject) => {
            try {
                // console.log("About to get filtered data from:", { collection, where, orderBy, limit, startAt });
                let readObjectRef = firebase.functions().httpsCallable('readFilteredObjects');
                return readObjectRef({ collection, where, orderBy, limit, startAt }).then((result) => {
                    // console.log('Filtered result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Category', e);
                reject(e.message);
            }
        });
        return category;
    }

    getAllSearchObject(collection, where, where2, where3, orderBy, limit = 200, startAfter = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                // console.log("About to get search data from:", { collection, where, where2, where3, orderBy, limit, startAfter });
                let readObjectRef = firebase.functions().httpsCallable('readSearchObjects');
                return readObjectRef({ collection, where, where2, where3, orderBy, limit, startAfter }).then((result) => {
                    // console.log('Search result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    getAllSearchUsers(where, where2, where3, orderBy, limit = 200, startAfter = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('readSearchUsers');
                return readObjectRef({ where, where2, where3, orderBy, limit, startAfter }).then((result) => {
                    // console.log('Search result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    add(collection, data, id) {
        //console.log("About to add data to:", { collection });
        // return null;
        let createObjectRef = firebase.functions().httpsCallable('createObject');
        return createObjectRef({ collection, data, id }).then((result) => {
            //console.log("add RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    update(collection, data, id) {
        // console.log("About to update data to:", { collection, data, id });
        // return null;
        let opRef = firebase.functions().httpsCallable('updateObject');
        return opRef({ collection, data, id }).then((result) => {
            // console.log("update RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    delete(collection, id, subcollection = null) {
        // console.log("About to update data to:", { collection, id, subcollection });
        // return null;
        let opRef = firebase.functions().httpsCallable('deleteObject');
        return opRef({ collection, id, subcollection }).then((result) => {
            // console.log("delete RESULT:", result);
            return id;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    checkPass(password) {
        // console.log("About to check data from:", { password });
        let checkPasswordRef = firebase.functions().httpsCallable('checkPassword');
        return checkPasswordRef({ password }).then((result) => {
            // console.log("Password Check Result:", result);
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getUserHostingPrivileges() {
        let rq = firebase.functions().httpsCallable('getUserHostingPrivileges');
        return rq(/*user*/).then((doc) => {
            // console.log('getUserPrivileges response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;
            // console.log('getUserPrivileges response:', udata)
            return udata;
        });
    }

    getUserPrivileges() {
        let rq = firebase.functions().httpsCallable('getUserPrivileges');
        return rq(/*user*/).then((doc) => {
            // console.log('getUserPrivileges response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;
            // console.log('getUserPrivileges response:', udata)
            return udata;
        });
    }

    getPrivileges() {
        const { user } = this._store.getState().firebase;

        let uid = user && user.uid ? user.uid : user && user.id ? user.id : null;

        if (uid) {
            return this.getOne('/user_privileges', uid).then((res) => {
                let retValue = res.data;

                if (retValue && retValue.id) {
                    delete retValue['id'];
                }

                return retValue;
            })
        } else return {};
    }

    getUserData(user, auth) {
        if (!user)
            return Promise.resolve(null);
        const userDetails = {
            uid: user.uid ? user.uid : null,
            displayName: user.displayName ? user.displayName : null,
            photoURL: user.photoURL ? user.photoURL : null,
            email: user.email ? user.email : null,
            emailVerified: user.emailVerified ? user.emailVerified : null,
            isAnonymous: user.isAnonymous ? user.isAnonymous : null,
            providerData: user.providerData ? user.providerData : null
        }
        if (!user.uid) {
            // Should not be here
            console.warn('User without uid found!', user)
            this._store.dispatch(firebaseActions.setUser(userDetails));
            return Promise.resolve(userDetails);
        }
        let rq = firebase.functions().httpsCallable('getUserData');
        return rq(user).then((doc) => {
            // console.log('getUserData response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;

            const { logInSignup } = this._store.getState().app;

            // console.log('getUserData response:', udata)
            if (udata && udata.roles) {
                this._store.dispatch(firebaseActions.setRoles(udata.roles));
            }
            if (auth) {
                this._store.dispatch(firebaseActions.logIn(true));
            } //else
            if (udata && udata.user) {
                let combined = Object.assign({}, userDetails, udata.user);
                this._store.dispatch(firebaseActions.setUser(combined));

                if (logInSignup)
                    this.pushToSignup();

                return combined;
            }
            return null;
        }).catch(e => {
            console.error('Could not load user data', e.message);
        });
    }

    updateUserData(user) {
        // Set nfb data,
        if (user && user.uid) {
            return this.getOne('/users', user.uid).then((res) => {
                if (res && res.data) {
                    let nfb_user_data = res.data;
                    // console.log('NFB user data', nfb_user_data)
                    return Object.assign({}, user, nfb_user_data);
                }
                return user;
            }).then(udata => {
                this._store.dispatch(firebaseActions.setUser(udata));
                return udata;
            }).catch(e => {
                //  Check if user exists
            });
        }
        return Promise.resolve(user);
    }

    getAllComments(post_id) {
        const collection = `/posts/${post_id}/comments`;
        // console.log("About to get data from:", { collection });
        return this.getAllFiltered(collection);
    }

    getLikes() {
        const collection = `/userlikes`;
        // console.log("About to get data from:", { collection });
        return this.getAllFiltered(collection);
    }

    handleImageUpload(imageFile) {
        let img = new Promise((resolve, reject) => {
            let size = imageFile.size / 1024 / 1024;
            // console.log('originalFile', imageFile); // true
            // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            // console.log(`originalFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`);

            if (size > 1) {
                var options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        let size = compressedFile.size / 1024 / 1024;
                        // console.log('compressedFile', compressedFile); // true
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                        // console.log(`compressedFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`); // smaller than maxSizeMB

                        resolve(compressedFile); // write your own logic
                    })
                    .catch(function (error) {
                        reject(error.message);
                    });
            } else {
                resolve(imageFile)
            }
        })
        return img;
    }

    addImageToStorage(file, type, id) {
        let storageAdd = new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            this.handleImageUpload(file).then((resizeImg) => {
                if (resizeImg) {
                    // Create the file metadata
                    var metadata = {
                        contentType: resizeImg && resizeImg.type ? resizeImg.type : 'image/jpeg'
                    };

                    let folder = 'profile_pictures/';

                    if (type && type === 'event') {
                        folder = 'events_pictures/'
                    }
                    // Upload file and metadata to the object 'images/mountains.jpg'
                    var uploadTask = storageRef.child(folder + id).put(resizeImg, metadata);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            // console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    // console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    // console.log('Upload is running');
                                    break;
                            }
                        }, (error) => {
                            reject(error)
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;

                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;

                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                            }
                        }, function () {
                            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                // console.log('File available at', downloadURL);
                                return resolve(downloadURL);
                            });
                        });
                }
            });
        })
        return storageAdd;
    }

    getNumberUsersWaitingEvent(room) {
        let number = new Promise((resolve, reject) => {
            try {
                var waitingRef = firebase.database().ref(`waiting/${room}`);
                waitingRef.off();
                waitingRef.on('value', (data) => {
                    if (window.changeWaitingNumber) {
                        window.changeWaitingNumber(data.numChildren());
                    }
                    if (window.setWaitingNumber) {
                        window.setWaitingNumber(data.numChildren());
                    }
                });
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return number;
    }

    updateUser(uid, data) {
        let callableRef = firebase.functions().httpsCallable('updateUser');
        return callableRef({ userId: uid, userData: data }).then((result) => {
            return result.data;
        });
    }

    checkUserTickets(streamId, authorId) {

        const { user } = this._store.getState().firebase;
        if (!user)
            return Promise.resolve(false);
        const collection = `/tickets`;
        const where = {
            fieldPath: 'eventId',
            optStr: '=',
            value: streamId
        }

        const where2 = {
            fieldPath: 'userId',
            optStr: '=',
            value: user.uid
        }

        const where3 = null;
        // const where3 = {
        //     fieldPath: 'paymentStatus',
        //     optStr: 'in',
        //     value: ['success', 'cancelled']
        // }
        const orderBy = null
        const limit = 100;

        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('checkUserTicket');
                return readObjectRef({ streamId: streamId, authorId: authorId }).then((result) => {
                    // console.log('Ticket received', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;

    }

    checkUserSubscription(authorId) {

        const { user } = this._store.getState().firebase;
        if (!user)
            return Promise.resolve(false);

        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('checkSubscriptions');
                return readObjectRef({ authorId: authorId }).then((result) => {
                    // console.log('Subscription received', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;

    }

    cancelUserSubscription(authorId, ticketType) {
        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('cancelUserSubscription');
                return readObjectRef({ authorId: authorId, ticketType: ticketType }).then((result) => {
                    // console.log('Subscription cancelled', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    cancelUserTicket(id) {
        return this.update('/tickets', { paymentStatus: 'cancelled' }, id);
    }

    injectCalendarEvent(event) {
        const { user, calendarList } = this._store.getState().firebase;
        let uid = null;
        if (user && user.uid) {
            uid = user.uid;
        }

        let eventCollections = calendarList;

        if (event && (!event.visibility || event.visibility === "public")) {
            const updatedCollections = eventCollections.filter(ev => ev.id != event.id);
            updatedCollections.push(event);
            this.sortCalendarEvents(updatedCollections);
            this.getMyStuffData();
        }
    }

    listenToLiveUpdates() {
        var luRef = firebase.database().ref('liveupdates');
        var that = this;
        luRef.off();
        luRef.on('child_added', function (data) {
            that.injectCalendarEvent(data.val());
            // console.log('New event injected', data.val());
        });
    }

    listenToStreamingDetails(alias, setFunction) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var streamingRef = firebase.database().ref(`streaming/${alias}`);
            var that = this;
            streamingRef.off();
            streamingRef.on('value', function (data) {
                let val = data.val() && data.val().streaming ? true : false
                // console.log('Streaming RT:', data.val());
                setFunction(val);
            });
        }
    }

    stopListenToStreamingDetails(alias) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var streamingRef = firebase.database().ref(`streaming/${alias}`);
            streamingRef.off();
        }
    }

    listenToGuest(alias, setFunction) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var guestRef = firebase.database().ref(`participants/${alias}/${user.uid}`);
            var that = this;
            guestRef.off();
            guestRef.on('value', function (data) {
                let val = data.val() && data.val().uid && data.val().uid === user.uid ? true : false
                // if (data.val()) { console.log('GUEST:', data.val()); }
                setFunction(val);
            });
        }
    }

    stopListeningToGuest(alias) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var guestRef = firebase.database().ref(`participants/${alias}/${user.uid}`);
            guestRef.off();
        }
    }

    listenToSoldTicket(item, setNumber) {
        let eventSoldNumber = 0, streamSoldNumber = 0;
        var eventSoldRef = firebase.database().ref(`calendar/${item.id}/tickets/sold`);
        eventSoldRef.off();
        eventSoldRef.on('value', async function (data) {
            eventSoldNumber = data.val() ? data.val() : 0;
            setNumber(eventSoldNumber, 'event');
        });

        if (item && ((item.type && item.type === 'stream') || (item.status && item.status === 'ended'))) {
            var postSoldRef = firebase.database().ref(`posts/${item.id}/tickets/sold`);
            postSoldRef.off();
            postSoldRef.on('value', async function (data) {
                streamSoldNumber = data.val() ? data.val() : 0;
                setNumber(streamSoldNumber, 'stream');
            });
        }
    }

    stopListeningToSoldTicket(item) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var eventSoldRef = firebase.database().ref(`calendar/${item.id}/tickets/sold`);
            eventSoldRef.off();
            if (item && ((item.type && item.type === 'stream') || (item.status && item.status === 'ended'))) {
                var streamSoldRef = firebase.database().ref(`posts/${item.id}/tickets/sold`);
                streamSoldRef.off();
            }
        }
    }

    listenToWaiting(room, fanoutId) {
        //console.log('listenToWaiting', room, fanoutId);
        var waitingRef = firebase.database().ref(`waiting/${room}`);
        var that = this;
        waitingRef.off();
        waitingRef.on('value', function (data) {
            data.forEach(function (snapshot) {
                that.approveEntry(snapshot.val().uid, room, snapshot.val().type, fanoutId);
            });
        });
        // waitingRef.remove();
        // waitingRef.on('child_added', function (data) {
        //     that.approveEntry(data.val().uid, room, data.val().type, fanoutId);
        //     // console.log('This user waiting: ', data.val().name);
        // });
    }

    listenToAnswerForEntry(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var entryRef = firebase.database().ref(`entry/${room}`);
            var that = this;
            entryRef.off();
            entryRef.on('child_added', function (data) {
                // console.log('We can join room: ', data.val());
                const { type, fanoutId, room } = data.val();
                console.log('We can join fanout: ', type, fanoutId, room);
                that._store.dispatch(broadcastActions.setFanoutId(fanoutId));
                const message = (type && type === 'cohost' || type && type === 'guest' || type && type === 'moderator') ? 'Moving into stream' : 'Moving to audience';
                firebase.database().ref(`entry/${room}`).remove();
                entryRef.off();
                // var room = data.val().room;
                try {
                    that._store.dispatch(broadcastActions.setBroadcastMessage(message));
                    if (type && type === 'cohost' || type && type === 'guest' || type && type === 'moderator' && room) {
                        that.joinRoom(room);
                    } else if (room) {
                        that.joinRoomListener(room);
                    }
                } catch (e) {
                    console.log('ERROR:listenToAnswerForEntry', e);
                }
            });
        }
    }

    stopListeningToAnswerForEntry(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var entryRef = firebase.database().ref(`entry/${room}`);
            firebase.database().ref(`entry/${room}`).remove();
            entryRef.off();
            entryRef.remove();
            var waitingRef = firebase.database().ref(`waiting/${room}/${user.uid}`);
            waitingRef.off();
            waitingRef.remove();
        }
    }

    listenToPublicEvent(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var publicEventRef = firebase.database().ref(`public/event/${room}`);
            var that = this;
            publicEventRef.off();
            publicEventRef.on('value', function (data) {
                let publicEventData = data.val();
                if (publicEventData && publicEventData.id && publicEventData.uid) {
                    const { fanout_id, id, uid, streaming } = publicEventData;
                    console.log('We can join fanout: ', fanout_id, id, uid, streaming);

                    if (streaming && id) {
                        that._store.dispatch(broadcastActions.setFanoutId(fanout_id));
                        that._store.dispatch(eventActions.setEventStatus({ fanoutId: fanout_id, eventAuthor: uid, eventId: id, isStreaming: streaming }));
                        publicEventRef.off();
                        try {
                            that._store.dispatch(broadcastActions.setBroadcastMessage('Moving into stream'));
                            if (id) {
                                that.joinRoom(id);
                            }
                        } catch (e) {
                            console.log('ERROR:listenToPublicEvent', e);
                        }
                    }
                }
            });
        }
    }

    stopListeningToPublicEvent(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            let publicEventRef = firebase.database().ref(`public/event/${room}`);
            publicEventRef.off();
        }
    }

    listenToEventStatus(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            let publicEventRef = firebase.database().ref(`public/event/${room}`);
            let that = this;
            publicEventRef.off();
            publicEventRef.on('value', (data) => {
                let publicEventData = data.val();
                if (publicEventData) {
                    const { fanout_id, id, uid, streaming } = publicEventData;
                    console.log('Event status changed: ', fanout_id, id, uid, streaming);

                    if (id) {
                        that._store.dispatch(broadcastActions.setFanoutId(fanout_id));
                        that._store.dispatch(eventActions.setEventStatus({ fanoutId: fanout_id, eventAuthor: uid, eventId: id, isStreaming: streaming }));
                    }
                }
            });
        }
    }

    stopListeningToEventStatus(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            let publicEventRef = firebase.database().ref(`public/event/${room}`);
            publicEventRef.off();
        }
    }

    stopListeningToWaiting(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var waitingRef = firebase.database().ref(`waiting/${room}`);
            waitingRef.off();
            waitingRef.remove();
        }
    }

    stopAudience(room) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var waitingRef = firebase.database().ref(`waiting/${room}`);
            waitingRef.off();
        }
    }

    waiting(room, type) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var waitingData = {
                name: user.username ? user.username : 'Unknown',
                uid: user.uid,
                type: type ? type : 'audience'
            };
            let updates = {};
            updates[`waiting/${room}/${user.uid}`] = waitingData;
            firebase.database().ref().update(updates);
        }
    }

    approveEntry(uid, room, type, fanoutId) {
        try {
            // uid is the user id of the user who we just let in
            // room is, well, the room
            var approveData = {
                room: room,
                type: type,
                fanoutId: fanoutId || ''
            };
            let updates = {};
            updates[`entry/${room}/${uid}`] = approveData;
            // console.log('Updates: ', updates);
            firebase.database().ref().update(updates);
            firebase.database().ref(`waiting/${room}`).remove();
        } catch (e) {
            console.error('approveEntry failed', e.message, approveData, uid);
        }
    }

    listenToKnocks(alias) {
        var knocksRef = firebase.database().ref('knocks/' + alias);
        var that = this;
        knocksRef.off();
        knocksRef.on('child_added', function (data) {
            that._store.dispatch(firebaseActions.setKnockListItem({ displayName: data.val().name, uid: data.val().uid }));
            // console.log('This user knocked: ', data.val().name);
        });
        knocksRef.on('child_removed', function (data) {
            that._store.dispatch(firebaseActions.deleteKnockListItem(data.val().uid));
            // console.log('knockList remove user', data.val().name);
        });
    }

    listenToAnswers(alias, VoxeetSdk, onClose) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid && alias) {
            var answersRef = firebase.database().ref('answers/' + alias);
            answersRef.remove();
            let that = this;
            answersRef.off();
            answersRef.on('child_added', function (data) {
                if (data.val && data.val().uid && user && user.uid && data.val().uid === user.uid) {
                    // console.log('We can join room: ', data.val().room);
                    firebase.database().ref('answers/' + alias + '/' + user.uid).remove();
                    answersRef.off();
                    let room = data.val().room;
                    if (VoxeetSdk) {
                        try {
                            that._store.dispatch(broadcastActions.setBroadcastMessage('Moving into stream'));
                            if (VoxeetSdk.conference && VoxeetSdk.conference.leave) {
                                VoxeetSdk.conference.leave().then(() => {
                                    if (VoxeetSdk.session && VoxeetSdk.session.close) {
                                        Promise.resolve().then(() => {
                                            VoxeetSdk.session.close().catch(e => {
                                                console.warn('Warning: Session', e);
                                            });
                                        }).then(() => {
                                            console.log('About to joint the room');
                                            if (onClose)
                                                onClose(room);
                                            else
                                                that.joinRoom(room);
                                        })

                                    }
                                }).catch(e => {
                                    console.warn('Warning: SDK leave', e);
                                });
                            }
                        } catch (e) {
                            console.error('ERROR:listenToAnswers', e);
                        }
                    } else {
                        try {
                            that._store.dispatch(broadcastActions.setBroadcastMessage('Moving into stream'));
                            if (onClose)
                                onClose(room);
                            else
                                that.joinRoom(room);
                        } catch (e) {
                            console.error('ERROR:listenToAnswers', e);
                        }
                    }
                }
            });
        }
    }

    participantJoined(alias) {
        const { user } = this._store.getState().firebase;
        if (alias && user && user.uid) {
            var participantData = {
                name: user.username ? user.username : 'Unknown',
                uid: user.uid
            };
            var updates = {};
            updates[`participants/${alias}/${user.uid}`] = participantData;
            firebase.database().ref().update(updates);
        }
    }

    stopListeningToAnswers(alias) {
        const { user } = this._store.getState().firebase;
        if (alias && user && user.uid) {
            var answersRef = firebase.database().ref('answers/' + alias + '/' + user.uid);
            // firebase.database().ref('answers/'  + user.uid).remove();
            answersRef.off();
            answersRef.remove();
        }
    }

    removeKnock(alias, uid) {
        if (alias && uid) {
            var knocksRef = firebase.database().ref(`knocks/${alias}/${uid}`);
            knocksRef.off();
            knocksRef.remove();
        }
    }

    stopListeningToKnocks(alias) {
        if (alias) {
            var knocksRef = firebase.database().ref('knocks/' + alias);
            knocksRef.off();
            // knocksRef.remove();
            var participantsRef = firebase.database().ref('participants/' + alias);
            participantsRef.off();
            // participantsRef.remove();
        }
    }

    stopListeningToSwitch(alias) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid && alias) {
            var listenersRef = firebase.database().ref('listeners/' + alias + '/' + user.uid);
            listenersRef.off();
            listenersRef.remove();
            var participantsRef = firebase.database().ref('participants/' + alias + '/' + user.uid);
            participantsRef.off();
            participantsRef.remove();
        }
    }

    listenToActiveParticipants(alias) {
        var participantsRef = firebase.database().ref('participants/' + alias);
        var that = this;
        participantsRef.off();
        participantsRef.on('child_added', function (data) {
            that._store.dispatch(firebaseActions.setActiveParticipantsItem({
                displayName: data.val().name,
                uid: data.val().uid
            }));
            // console.log('This user listener: ', data.val().name);
        });
        participantsRef.on('child_removed', function (data) {
            that._store.dispatch(firebaseActions.deleteActiveParticipantsItem(data.val().uid));
            // console.log('activeParticipantsList remove user', data.val().name);
        });
    }

    listenToAudience(alias) {
        var audienceRef = firebase.database().ref('audience/' + alias);
        var that = this;
        audienceRef.off();
        audienceRef.on('value', function (data) {
            let audienceArray = [];
            data.forEach((snapshot) => {
                audienceArray.push(snapshot ? snapshot.val() : {})
            });
            that._store.dispatch(firebaseActions.setAudienceList(audienceArray));
            // console.log('audienceList', audienceArray);
        });
    }

    stopListeningToAudience(alias) {
        if (alias) {
            var audienceRef = firebase.database().ref(`audience/${alias}`);
            audienceRef.off();
        }
    }

    listenSwitchToListener(alias, VoxeetSdk) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid && alias) {
            var listenersRef = firebase.database().ref('listeners/' + alias);
            listenersRef.remove();
            var that = this;
            listenersRef.off();
            listenersRef.on('child_added', function (data) {
                if (data.val && data.val().uid && user && user.uid && data.val().uid === user.uid) {
                    // console.log('We can join room listener: ', data.val().room);
                    firebase.database().ref('listeners/' + alias + '/' + user.uid).remove();
                    listenersRef.off();
                    let room = data.val().room;
                    if (VoxeetSdk) {
                        try {
                            that._store.dispatch(broadcastActions.setBroadcastMessage('Moving to audience'));
                            if (VoxeetSdk.conference && VoxeetSdk.conference.leave) {
                                VoxeetSdk.conference.leave().then(() => {
                                    if (VoxeetSdk.session && VoxeetSdk.session.close) {
                                        Promise.resolve().then(() => {
                                            return VoxeetSdk.session.close().catch(e => {
                                                console.warn('Warning: Session', e);
                                            });
                                        }).then(() => {
                                            console.log('About to joint the room', room);
                                            return that.joinRoomListener(room);
                                        })

                                    }
                                }).catch(e => {
                                    console.warn('Warning: SDK leave', e);
                                });
                            }
                        } catch (e) {
                            console.error('ERROR:listenSwitchToListener', e);
                        }
                    }
                }
            });
        }
    }

    knock(alias) {
        let knock = new Promise((resolve, reject) => {
            const { user } = this._store.getState().firebase;
            if (user && user.uid && alias) {
                var knocksRef = firebase.database().ref('knocks/' + alias + '/' + user.uid);

                knocksRef.get().then((snapshot) => {
                    if (snapshot.exists()) {
                        // console.log("exists!", snapshot.val());
                        knocksRef.off();
                        knocksRef.remove().then(() => {
                            resolve({ active: false });
                        });
                    } else {
                        let found = false;
                        this.checkUserTickets(alias).then((res) => {
                            if (res && res.data && res.data.length) {
                                res.data.map((ticket) => {
                                    if (ticket.eventId === alias && ticket.userId === user.uid && ticket.paymentStatus === 'success'
                                        || ticket.type == 'subscription' && (ticket.streamId == '*' || ticket.showId == '*')) {
                                        found = true;
                                    }
                                });

                                if (found) {
                                    knocksRef.set({
                                        name: user.username ? user.username : 'Unknown',
                                        uid: user.uid
                                    }).then(() => {
                                        knocksRef.off();
                                        resolve({ active: true })
                                    });
                                } else {
                                    resolve({ active: false });
                                }
                            } else {
                                resolve({ active: false });
                            }
                        });
                    }
                });
            }
        });
        return knock;
    }

    streamingRT(alias, uid, room, streaming) {
        // uid is the user id of the user who we just let in
        // room is, well, the room
        var streamingData = {
            room: room,
            uid: uid,
            streaming: streaming
        };
        var updates = {};
        updates['streaming/' + alias] = streamingData;
        firebase.database().ref().update(updates);
        // console.log('Updates: ', updates);
    }

    letIn(alias, uid, room) {
        // uid is the user id of the user who we just let in
        // room is, well, the room

        firebase.database().ref('knocks/' + alias + '/' + uid).remove()
            .then(() => {
                // Create temp ticket
                let callableRef = firebase.functions().httpsCallable('createTempTicketV2');
                callableRef({ userId: uid, eventId: alias }).then(() => {
                    // Let user in
                    let letinData = {
                        room: room,
                        uid: uid
                    };
                    let updates = {};

                    updates['answers/' + alias + '/' + uid] = letinData;
                    firebase.database().ref().update(updates);
                    // console.log('Updates: ', updates);
                }).catch((error) => {
                    console.error('There was an error when calling the Cloud Function createTempTicket', error);
                });
            }).catch((error) => {
                console.error('There was an error when calling RT remove /knocks', error);
            });
    }

    createGuestTicket(alias) {
        // Create guest ticket
        let callableRef = firebase.functions().httpsCallable('createGuestTicketV2');
        return callableRef({ eventId: alias }).catch((error) => {
            console.error('There was an error when calling the Cloud Function createGuestTicket', error);
        });
    }

    getGuestTicket(data) {
        // Get guest ticket
        let callableRef = firebase.functions().httpsCallable('getGuestTicket');
        return callableRef(data).then((res) => {
            return res.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function getGuestTicket', error);
            throw error;
        });
    }

    switchToListener(alias, uid, room) {
        // uid is the user id of the user who we just let in
        // room is, well, the room
        var listenerData = {
            room: room,
            uid: uid
        };
        var updates = {};
        updates['listeners/' + alias + '/' + uid] = listenerData;
        firebase.database().ref().update(updates);
        // console.log('Updates: ', updates);
        firebase.database().ref('participants/' + alias + '/' + uid).remove();
    }

    pushToSignup() {
        this._store.dispatch(nativePush('/signup?checkout=1'));
    }

    joinRoom(room) {
        this._store.dispatch(nativePush('/streaming/' + room + `/${window.btoa('speaker')}`));
    }

    joinRoomListener(room) {
        this._store.dispatch(nativePush(`/streaming/${room}/${window.btoa('listenOnly')}`));
    }

    getUsersFS(start, end) {
        const where = {
            fieldPath: 'sortId',
            optStr: '<',
            value: end ? end : 0
        }

        const where2 = {
            fieldPath: 'broadcaster',
            optStr: '==',
            value: true
        }

        const orderBy = {
            fieldPath: 'sortId'
        }
        const limit = 500;
        const startAfter = start ? start : null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchUsers(where, where2, null, orderBy, limit, startAfter);
    }

    sortCalendarEvents(array) {
        const { user, featured } = this._store.getState().firebase;
        let uid = null;
        if (user && user.uid) {
            uid = user.uid;
        }

        if (array && array.length) {
            let eventCollections = {
                upcoming: [],
                my: [],
                scheduled: [],
                featured: [],
                all: []
            }
            let now = Date.now()

            array.map((item) => {
                let period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;
                eventCollections.all.push(item);
                if (item && (item.startDate + period > now || item.status && (item.status === 'live' || item.status === 'started')) && (!item.visibility || item.visibility === "public")) {
                    // tickets are the real display filter here, private events should not be accessible by clients who didn't buy tickets
                    eventCollections.upcoming.push(item);
                }
                if (item && item.uid === uid) {
                    eventCollections.my.push(item);
                }
                if (item && item.visibility && item.visibility === "public") {
                    eventCollections.scheduled.push(item);
                }
                if (item && featured && featured.length && featured.indexOf(item.id) !== -1 && (item.startDate + period > now || item.status && (item.status === 'live' || item.status === 'started')) && item.visibility && item.visibility === "public") {
                    eventCollections.featured.push(item);
                }
            });

            let compareFn = (a, b) => {
                if (a.startDate < b.startDate) {
                    return -1;
                }
                if (a.startDate > b.startDate) {
                    return 1;
                }
                return 0;
            }

            eventCollections.upcoming.sort(compareFn);

            // console.log('About to sort calendar events', eventCollections)
            this._store.dispatch(firebaseActions.updatedEvents(eventCollections));
            this.getMyStuffData();
        }
    }

    sortingVideos(array) {
        const { user } = this._store.getState().firebase;
        const { tickets } = this._store.getState().mystuff;
        let uid = null;
        if (user && user.uid) {
            uid = user.uid;
        }

        let videoCollections = {
            live: [],
            videos: []
        };

        if (array && array.length) {
            // videoCollections['videos'] = array;
            array.map((item) => {
                if (item.live) {
                    videoCollections.live.push(item);
                } else {
                    let cat = item.cat ? item.cat.toLowerCase() : 'general'
                    if (!videoCollections[cat])
                        videoCollections[cat] = [];
                    if (item.archiveEnabled)
                        videoCollections[cat].push(item);
                    if (item && item.visibility && item.visibility === "public" || item && item.visibility && uid && item.uid && (item.uid === uid || this.checkCoHost(item, uid) || this.checkGuest(item, uid) || this.checkModerator(item, uid)) && item.visibility === "private" || item && !item.visibility || tickets[item.id]) {
                        videoCollections.videos.push(item);
                    }
                }
            });
            let compareFn = (a, b) => {
                if (a.startDate > b.startDate) {
                    return -1;
                }
                if (a.startDate < b.startDate) {
                    return 1;
                }
                return 0;
            }
            // Sort by date
            for (const cat in videoCollections) {
                videoCollections[cat].sort(compareFn);
            }

            videoCollections.live.sort(compareFn);
            videoCollections.videos.sort(compareFn);

            this._store.dispatch(firebaseActions.updatedVideos(videoCollections));
            this.getMyStuffData();
        }
    }

    getMyStuffData(user_id) {
        const { events, videos, live, user, channelList, hosts } = this._store.getState().firebase;
        const { tickets } = this._store.getState().mystuff;

        let uid = user_id || null;

        if (!uid && user && user.uid) {
            uid = user.uid;
        }

        if (!uid)
            return;

        let authorIds = channelList ? channelList.map((item) => {
            return item.uid;
        }) : null;

        let upcomingEvents = [], pastEvents = [], vault = [], live_streams = [], private_vault = [], hostingEvents = [];
        let hostedEvents = [], followingEvents = [], hostSubscriptions = [], subscribedToHosts = [], now = Date.now();

        if (tickets) {
            let tarray = Object.values(tickets);

            hostSubscriptions = tarray.filter((item) =>
                item.type == 'subscription' && (item.streamId == '*' || item.showId == '*'));
        }

        if (hostSubscriptions && hostSubscriptions.length && hosts && hosts.length) {
            authorIds = hostSubscriptions.map(item => item.authorId)
            subscribedToHosts = hosts.filter(a => authorIds.indexOf(a.id) !== -1);
        }

        if (live && live.length) {
            live.map((item) => {
                if (!tickets[item.id])
                    return;
                live_streams.push(item);
            });
        }

        if (videos && videos.length) {
            videos.map((item) => {
                if (!tickets[item.id])
                    return;
                if (
                    (tickets[item.id].authorId === uid)
                    || (tickets[item.id].role === 'host')
                    || (tickets[item.id].role === 'cohost')
                    || (tickets[item.id].role === 'guest')
                    || (tickets[item.id].role === 'moderator')
                ) {
                    if (item.archiveEnabled)
                        vault.push(item);
                    else
                        private_vault.push(item);
                } else {
                    pastEvents.push(item);
                }
            });
        }

        if (events && events.all && events.all.length) {
            events.all.map((item) => {
                let period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;

                if (authorIds && authorIds.indexOf(item.uid) !== -1) {
                    if ((item.startDate + period > now || item.status && (item.status === 'live' || item.status === 'started')) && item.visibility && item.visibility === 'public') {
                        followingEvents.push(item);
                    }
                }

                let ticket = tickets[item.id] || hostSubscriptions.find(t => t.authorId === item.uid);

                if (!ticket)
                    return;

                if (ticket.authorId === uid || (ticket.userId && ticket.userId === uid && ticket.role && (ticket.role === 'cohost' || ticket.role === 'guest' || ticket.role === 'moderator') && ticket.status && ticket.status === 'confirmed')) {
                    if (item.startDate + period > now || item.status && (item.status === 'live' || item.status === 'started')) {
                        hostingEvents.push(item);
                    } else {
                        hostedEvents.push(item);
                    }
                } else {
                    if (item.startDate + period > now || item.status && (item.status === 'live' || item.status === 'started')) {
                        upcomingEvents.push(item);
                    }
                }
            });
        }

        let compareFn = (a, b) => {
            if (a.startDate < b.startDate) {
                return -1;
            }
            if (a.startDate > b.startDate) {
                return 1;
            }
            return 0;
        }

        hostingEvents.sort(compareFn);
        upcomingEvents.sort(compareFn);
        live_streams.sort(compareFn);
        followingEvents.sort(compareFn);

        let myStuff = {
            vault,
            private_vault,
            live: live_streams,
            upcomingEvents,
            pastEvents,
            hostingEvents,
            hostedEvents,
            followingEvents,
            hostSubscriptions,
            subscribedToHosts
        }
        this._store.dispatch(mystuffActions.setMyStuff(myStuff));
    }

    prepareFeaturedEvents() {
        const { events, live, videos } = this._store.getState().firebase;
        //console.log('About to get featured events', featured, events)

        let featuredEvents = events && events.upcoming ? events.upcoming.filter((item) => {
            if (item && item.featured) {
                return item;
            }
        }) : [];

        let featuredLive = live && live.length ? live.filter((item) => {
            if (item && item.featured && item.live && item.archiveEnabled) {
                return item;
            }
        }) : [];

        let featuredStream = videos && videos.length ? videos.filter((item) => {
            if (item && item.featured && item.archiveEnabled) {
                return item;
            }
        }) : [];
        //console.log('featuredEvents', featuredEvents);
        let newFeatured = [...featuredLive, ...featuredEvents, ...featuredStream]

        newFeatured = newFeatured.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
        );
        events.featured = newFeatured;
        this._store.dispatch(firebaseActions.updatedEvents(events));
    }

    checkPaymentMethod(uid) {
        let callableRef = firebase.functions().httpsCallable('checkPaymentMethod');
        return callableRef({ uid: uid }).then((result) => {
            console.log('RESULT checkPaymentMethod', result.data);
            let { paymentMethod } = (result && result.data) ? result.data : null;
            return paymentMethod;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function checkPaymentMethod', error);
        });
    }

    getEventFanoutId(eventId) {
        let callableRef = firebase.functions().httpsCallable('getEventFanoutId');
        return callableRef({ eventId }).then((result) => {
            console.log('RESULT getEventFanoutId', result.data);
            let fanoutId = (result && result.data) ? result.data : null;
            this._store.dispatch(broadcastActions.setFanoutId(fanoutId));
            return fanoutId;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function getEventFanoutId', error);
        });
    }

    setEventStreaming(eventId, isStreaming) {
        let callableRef = firebase.functions().httpsCallable('setEventStreaming');
        return callableRef({ eventId, isStreaming }).then((result) => {
            console.log('RESULT setEventStreaming', result.data);
            let isStreaming = (result && result.data) ? result.data : false;
            return isStreaming;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function getEventFanoutId', error);
            return false;
        });
    }

    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    createPaymentIntents(paymentData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('createPaymentIntentsV2');
        return callableRef(paymentData).then((result) => {
            console.log('RESULT createPaymentIntents', result.data);
            let { clientSecret } = (result && result.data) ? result.data : null;
            this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return clientSecret;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function createPaymentIntents', error);
            this._store.dispatch(paymentActions.failedPaymentIntents(error));
            return null;
        });
    }

    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    createPaymentIntentsOrThrowError(paymentData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('createPaymentIntentsV2');
        return callableRef(paymentData).then((result) => {
            console.log('RESULT createPaymentIntents', result.data);
            let { clientSecret } = (result && result.data) ? result.data : null;
            this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return clientSecret;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function createPaymentIntents', error);
            this._store.dispatch(paymentActions.failedPaymentIntents(error));
            throw error;
            return null;
        });
    }


    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    saveCalendarEvent(saveData) {
        return this.isUserLogged().then((res) => {
            if (res) {
                let callableRef = firebase.functions().httpsCallable('saveCalendarEventFSV2');
                return callableRef(saveData).then((result) => {
                    return result.data;
                }).catch((error) => {
                    console.error('There was an error when calling the Cloud Function saveCalendarEventFS', error);
                    throw error;
                });
            }
        }).catch((e) => {
            console.error('User not logged', e);
            throw e;
        });
    }

    saveStream(saveData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('saveStreamFS');
        return callableRef(saveData).then((result) => {
            // console.log('RESULT saveStreamFS', result.data);
            let { eventId } = (result && result.data) ? result.data : null;
            // this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function saveStreamFS', error);
            // this._store.dispatch(paymentActions.failedPaymentIntents(error));
        });
    }

    cancelEventOrPost(data, type) {
        // console.log('cancelEventOrPost', data);
        let callable = (data.type == 'event') ? 'deleteCalendarEventFSV2' : 'cancelVideo';
        let callableRef = firebase.functions().httpsCallable(callable);
        return callableRef(data).then((result) => {
            // console.log('RESULT cancelEventOrPost', result.data);
            let { eventId } = (result && result.data) ? result.data : { eventId: data.postId };
            // this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function ', error);
            // this._store.dispatch(paymentActions.failedPaymentIntents(error));
        });
    }

    confirmInvitationTicket(data) {
        let callableRef = firebase.functions().httpsCallable('confirmInvitationTicket');

        return callableRef(data).then((result) => {
            // console.log('RESULT confirmInvitationTicket', result.data);
            return result.data;
        })
    }

    submitSignupData(saveData) {
        let callableRef = firebase.functions().httpsCallable('submitSignupDataFS');
        return callableRef(saveData).then((result) => {
            console.log('RESULT submitSignupData', result.data);
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function submitSignupDataFS', error);
        });
    }

    hideCohostVideo(data) {
        let callableRef = firebase.functions().httpsCallable('hideCohostVideoV2');

        return callableRef(data).then((result) => {
            // console.log('RESULT hideCohostVideo', result.data);
            return result.data;
        })
    }

    subscribeToFanoutStateChanges(fanoutId, callback = null) {
        if (fanoutId) {
            let fanoutRef = firebase.database().ref(`fanout/instances/${fanoutId}/state`);
            fanoutRef.off();
            fanoutRef.on('value', (data) => {
                if (data.exists()) {
                    this._store.dispatch(appActions.appFanoutState(data.val()));
                    if (callback)
                        callback(data.val());
                }
            });
        }
    }

    unsubscribeToFanoutStateChanges(fanoutId) {
        if (fanoutId)
            firebase.database().ref(`fanout/instances/${fanoutId}/state`).off();
    }

    subscribeToSignallingClientChanges(uuid, alias, callback) {
        let client_str = "signalling/to_client/" + alias + "/" + uuid;
        let signallingRef = firebase.database().ref(client_str);
        signallingRef.off();
        signallingRef.on('value', (data) => {
            let obj = {};
            data.forEach((snapshot) => {
                obj[snapshot.key] = snapshot.val();
            });
            if (Object.keys(obj).length)
                callback(obj);
        });
    }

    unsubscribeFromSignallingClientChanges(uuid, alias) {
        if (uuid && alias)
            firebase.database().ref("signalling/to_client/" + alias + "/" + uuid).off();
    }

    sendToSignallingServerUpdate(data) {
        //console.log('Starting sendToSignallingServerUpdate', data)
        //TODO: remove this later, for testing make it switchable between Fanout instances
        //      Leave the typing of actual ip if want to connect to GCP instance
        switch (data.request) {
            case 'create-room':
                //console.log('create-room sendToSignallingServerUpdate', data)
                let signalRef = firebase.functions().httpsCallable('signalServerRequest');
                return signalRef(data).then((result) => {
                    // console.log("Successful request", data.request);
                    return Promise.resolve(true);
                }).catch((error) => {
                    console.error("error with signalServerRequest", error);
                    return Promise.resolve(false);
                });
                break;
            default:
                console.log(data.request + ' sendToSignallingServerUpdate', data)
                const { alias } = data
                const { user } = this._store.getState().firebase;

                let userUid = user && user.uid ? user.uid : false;
                if (!alias || !userUid) {
                    console.error("Params missing for sendToSignallingServerUpdate", alias, data);
                    return Promise.resolve(false);
                }
                return firebase.database().ref(`signalling/to_server/${data.fanoutId}/${userUid}`).update(data).then(() => {
                    if (data.request == 'delete-entry') {
                        // Cleanup RT database
                        firebase.database().ref(`audience/${alias}/${userUid}`).remove();
                        firebase.database().ref(`signalling/to_client/${alias}/${userUid}`).remove();
                    }
                    return true;
                });
        }
    }

    deleteSignallingClientEntry(alias, fanoutId) {
        let data = {
            request: "delete-entry",
            alias: alias,
            fanoutId
        };
        this.sendToSignallingServerUpdate(data);
    }

    checkCoHost(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.cohosts && item.cohosts.length) {
            item.cohosts.map((cohost) => {
                if (cohost && cohost.id && cohost.id === userUid && cohost.status && cohost.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    checkHidenIn(item, type) {
        let valid = false;

        if (item && type && item.hiddenIn && item.hiddenIn.length && item.hiddenIn.includes(type)) {
            valid = true;
        }

        return valid;
    }

    checkGuest(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.guestSpeakers && item.guestSpeakers.length) {
            item.guestSpeakers.map((guest) => {
                if (guest && guest.id && guest.id === userUid && guest.status && guest.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    checkModerator(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.moderators && item.moderators.length) {
            item.moderators.map((moderator) => {
                if (moderator && moderator.id && moderator.id === userUid && moderator.status && moderator.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    readFromLiveTeasers() {
        // Create a reference with an initial file path and name
        var storage = firebase.storage();

        firebase.database().ref('teasers/list').get().then((snapshot) => {
            if (snapshot.exists()) {
                // console.log('-----------------------', snapshot.val());
                let list = snapshot.val();
                let teasers = {};
                if (list && Object.keys(list).length) {
                    Object.entries(list).forEach(async ([entry, eventId]) => {
                        teasers[eventId] = await storage.ref(`teasers/${entry}`).getDownloadURL().then((url) => {
                            // `url` is the download URL for 'images/stars.jpg'
                            return new Promise((resolve, reject) => {
                                // This can be downloaded directly:
                                var xhr = new XMLHttpRequest();
                                xhr.responseType = 'blob';
                                xhr.onreadystatechange = (evt) => {
                                    if (xhr.readyState === 4) {
                                        if (xhr.status === 200) {
                                            let reader = new window.FileReader();
                                            reader.readAsText(xhr.response);
                                            reader.onloadend = function () {
                                                resolve(reader.result);
                                            }
                                        } else {
                                            reject(new Error("Ajax error for " + url + ": " + xhr.status));
                                        }
                                    }
                                };
                                xhr.open('GET', url);
                                xhr.send();

                            })
                                ;
                        }).catch((error) => {
                            // Handle any errors
                        });;
                    });
                }
                this._store.dispatch(firebaseActions.setTeasers(teasers));
            } else {
                // console.log("No teasers available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case firebaseActions.FIREBASE_CREATE_CARD_ACTION: {
                    const data = action.payload;
                    this.createCard(data.stripeId, data.tokenId);
                    break;
                }
                case firebaseActions.FIREBASE_DELETE_CARD_ACTION: {
                    const data = action.payload;
                    this.deleteCard(data.stripeId, data.cardId);
                    break;
                }
                case firebaseActions.FIREBASE_CREATE_SUBSCRIPTION_ACTION: {
                    const data = action.payload;
                    this.createSubscription(data.stripeId, data.priceId);
                    break;
                }
                case firebaseActions.FIREBASE_CANCEL_SUBSCRIPTION_ACTION: {
                    const data = action.payload;
                    this.cancelSubscription(data.subscriptionId);
                    break;
                }
                case firebaseActions.FIREBASE_GET_VIDEOS_ACTION: {
                    const data = action.payload;
                    this.sortingVideos(data.videos);
                    break;
                }
                case firebaseActions.FIREBASE_SET_PROVIDER_ACTION: {
                    const data = action.payload;
                    this.logIn(data.provider);
                    break;
                }
                case firebaseActions.FIREBASE_LOG_OUT_ACTION: {
                    this.logOut();
                    break;
                }
                case firebaseActions.FIREBASE_START_STREAMING_ACTION: {
                    const data = action.payload;
                    this.startStreaming(data.alias);
                    break;
                }
                case firebaseActions.FIREBASE_CHECK_STRIPE_CUSTOMERS_ACTION: {
                    const data = action.payload;
                    // console.log('Check Stripe', data);
                    this.checkCustomers(data.user);
                    break;
                }
                case firebaseActions.FIREBASE_GET_CALENDAR_LIST_ACTION: {
                    const data = action.payload;
                    // console.log('Events', data);
                    this.sortCalendarEvents(data.calendarList);
                    break;
                }

                case paymentActions.PAYMENT_CREATE_INTENTS_ACTION: {
                    const data = action.payload;
                    // console.log('Create stripe intents', data);
                    this.createPaymentIntents(data);
                    break;
                }

                case firebaseActions.FIREBASE_SET_CHANNEL_LIST_ACTION: {
                    this.getMyStuffData();
                    break;
                }

                case firebaseActions.FIREBASE_GET_FEATURED_ACTION:
                case firebaseActions.FIREBASE_UPDATED_VIDEOS_ACTION: {
                    this.prepareFeaturedEvents();
                    break;
                }
            }
            return res;
        };
    }

    /**
     * Record and upload teaser and return data url
     * @returns {Promise<any[]>|*}
     */
    recAndUploadTeaser(eventId) {
        console.log('About to start recording teaser for event', eventId);
        return this.recordTeaser().then((dataUrl) => {
            console.log('About to start uploading teaser');
            let callableRef = firebase.functions().httpsCallable('uploadTeaser');
            return callableRef({ uri: dataUrl, eventId }).then((result) => {
                console.log('Successfully uploaded teaser', result.data);
                return result.data;
            }).catch((error) => {
                console.error('There was an error when calling the Cloud Function uploadTeaser', error);
            });
        }).catch((error) => {
            console.error('There was an error while recording and uploading video', error);
        });

    }

    /**
     * Record teaser and return data url
     * @returns {Promise<any[]>|*}
     */
    recordTeaser() {
        return this.getCurrentUserMediaStream().then((stream) => {
            console.log('Got user stream', stream);
            if (!stream)
                return Promise.resolve(null);
            let result = new Promise((resolve, reject) => {
                let chunks = [];
                let mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
                console.log('About to start recording');
                mediaRecorder.start();
                setTimeout(() => {
                    console.log('About to start recording');
                    // stop after 5 sec
                    mediaRecorder.stop();
                }, 5000);

                mediaRecorder.onstop = (e) => {
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    console.log("recorder stopped");
                    let blob = new Blob(chunks, { 'type': 'video/webm' });
                    var a = new FileReader();
                    a.onload = (e) => {
                        resolve(e.target.result);
                    }
                    a.readAsDataURL(blob);
                }

                mediaRecorder.ondataavailable = (e) => {
                    if (e.data.size > 0)
                        chunks.push(e.data);
                }
                mediaRecorder.onerror = (event) => {
                    let error = event.error;

                    switch (error.name) {
                        case InvalidStateError:
                            console.error("You can't record the video right now. Try again later.");
                            break;
                        case SecurityError:
                            console.error("Recording the specified source is not allowed due to security restrictions.");
                            break;
                        default:
                            console.error("A problem occurred while trying to record the video.");
                            break;
                    }
                    reject(e);
                };
            });
            return result;
        }).catch((err) => {
            console.log('Could not record the video: ' + err);
        });

    }

    /**
     * Get user media stream based on cookies
     * @returns {Promise<any[]>|*}
     */
    getCurrentUserMediaStream() {

        let videoEnabled = Cookies.get("videoEnabled");
        if (!videoEnabled || videoEnabled.toLowerCase() == 'false') {
            console.warn('Video not enabled');
            return Promise.resolve(null); // Video not enabled
        }

        let cameraCookies = Cookies.get("camera");

        if (typeof cameraCookies === 'string' || cameraCookies instanceof String) {
            try {
                cameraCookies = JSON.parse(cameraCookies);
            } catch (e) {
                console.warn('Parse Camera Cookies Error', e);
            }
        }

        if (!cameraCookies || (cameraCookies && !cameraCookies.deviceId)) {
            console.warn('Camera not selected');
            return Promise.resolve(null); // Camera not selected
        }
        let videoCookieExist = false;
        const constraints = {
            audio: false,
            video: {
                width: { max: 640, ideal: 480 },
                height: { max: 360, ideal: 270 }
            }
        }
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((source) => {
                if (cameraCookies && source && source.deviceId && cameraCookies.deviceId == source.deviceId) videoCookieExist = true;
            });
            if (
                devices.length == 0 ||
                (devices.length == 1 && devices[0].deviceId == "")
            ) {
                console.warn('Camera not found');
                return Promise.resolve(null); // Camera not found
            } else {
                if (!videoCookieExist) {
                    console.warn('Camera not found');
                    return Promise.resolve(null); // Camera not selected;
                }
                constraints.video = {
                    deviceId: { exact: cameraCookies.deviceId },
                };
                console.log('About to get video with consttraints', constraints)
                return navigator.mediaDevices.getUserMedia(constraints);
            }
        });
    }

    /**
     * Close current conference and/or session if any
     * @returns {*}
     */
    closeConferenceAndSession() {
        console.log('Close already opened conferences and sessions');
        if (VoxeetSdk.conference && VoxeetSdk.conference.leave) {
            return VoxeetSdk.conference.leave().then(() => {
                if (VoxeetSdk.session && VoxeetSdk.session.close) {
                    return VoxeetSdk.session.close().catch(e => {
                        console.warn('Warning: Session', e);
                    });
                }
            }).catch(e => {
                console.warn('Could not leave the conference: ', e);
            });
        } else if (VoxeetSdk.session && VoxeetSdk.session.close) {
            return VoxeetSdk.session.close().catch(e => {
                console.warn('Could not close the session Session', e);
            });
        }
    }
}

export default new FirebaseClient();
