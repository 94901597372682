import React, { Component } from 'react';
import classNames from "classnames";
import ComponentHeader from '../Widget/ComponentHeader';
import path from '../../../resources/icons/path.svg';
import { terms } from '../../utils/text';

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'no-filter',
            input: '',
            termsList: terms
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeTermsSelect = this.handleChangeTermsSelect.bind(this);
        this.handleChangeTerms = this.handleChangeTerms.bind(this);
    }

    componentDidMount() {
        this.setFocus();
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleChangeTermsSelect(e) {
        let value = e.target.value;
        this.setState({
            tab: value
        });
    }

    handleChangeTerms(value) {
        this.setState({
            tab: value
        }, () => {
            this.setFocus();
        });
    }

    setFocus() {
        let el = document.getElementsByClassName('button-focus')[0];

        if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            this.timeout = setTimeout(() => {
                el.focus();
            }, 500);
        }
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        }, () => {
            const { input } = this.state;

            let inputValue = input.toLowerCase();

            if (inputValue && inputValue.length) {
                let newTermsList = {};
                newTermsList.noFilter = this.searchArray(terms.noFilter, inputValue);
                newTermsList.registration = this.searchArray(terms.registration, inputValue);
                newTermsList.payments = this.searchArray(terms.payments, inputValue);
                newTermsList.responsibility = this.searchArray(terms.responsibility, inputValue);
                newTermsList.ownership = this.searchArray(terms.ownership, inputValue);
                newTermsList.userConduct = this.searchArray(terms.userConduct, inputValue);
                newTermsList.disclaimer = this.searchArray(terms.disclaimer, inputValue);
                newTermsList.limitation = this.searchArray(terms.limitation, inputValue);
                newTermsList.remedies = this.searchArray(terms.remedies, inputValue);
                newTermsList.generalProvisions = this.searchArray(terms.generalProvisions, inputValue);
                this.setState({
                    termsList: newTermsList
                });
            } else {
                this.setState({
                    termsList: terms
                });
            }
        });
    }

    searchArray(value, inputValue) {
        let exist = false
        if (value && value.props && value.props.children) {
            let children1 = value.props.children;
            if (children1 && Array.isArray(children1)) {
                children1.map((item) => {
                    let children2 = item && item.props && item.props.children ? item.props.children : null;
                    if (children2 && Array.isArray(children2)) {
                        children2.map((item) => {
                            let children3 = item && item.props && item.props.children ? item.props.children : null;
                            if (children3 && children3.toLowerCase && children3.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            } else if (children3 && children3.toLowerCase && children3.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            } else if (item && (typeof item === 'string' || item instanceof String) && item.toLowerCase && item.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            }
                        })
                    } else if (children2 && children2.toLowerCase && children2.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    } else if (item && (typeof item === 'string' || item instanceof String) && item.toLowerCase && item.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    }
                })
            } else if (children1.toLowerCase && children1.toLowerCase().indexOf(inputValue) !== -1) {
                exist = true;
            }
        }


        if (exist) {
            return value;
        } else return null;
    }

    renderTerms() {
        const { tab, termsList } = this.state;

        switch (tab) {
            case 'no-filter': {
                return termsList.noFilter;
            }
            case 'registration': {
                return termsList.registration;
            }
            case 'payments': {
                return termsList.payments;
            }
            case 'responsibility': {
                return termsList.responsibility;
            }
            case 'ownership': {
                return termsList.ownership;
            }
            case 'user-conduct': {
                return termsList.userConduct;
            }
            case 'disclaimer': {
                return termsList.disclaimer;
            }
            case 'limitation': {
                return termsList.limitation;
            }
            case 'remedies': {
                return termsList.remedies;
            }
            case 'general-provisions': {
                return termsList.generalProvisions;
            }
            default: {
                return <div />;
            }
        }
    }

    render() {
        const { input, tab, termsList } = this.state;

        return (
            <div>
                <ComponentHeader text="terms of service" />
                <div className='faq-search-wrapper'>
                    <div className='faq-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='What are you looking for ?'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                    <p className="last-update">Last Updated Date: January 1st, 2021</p>
                </div>
                {!termsList || (!termsList.noFilter && !termsList.registration && !termsList.payments
                    && !termsList.responsibility && !termsList.ownership && !termsList.userConduct && !termsList.disclaimer
                    && !termsList.limitation && !termsList.remedies && !termsList.generalProvisions) ?
                    <div className='faq-wrapper'>
                        <p className='faq-search-message'>We found nothing</p>
                    </div>
                    :
                    <div className='faq-wrapper'>
                        <select
                            className="faq-select"
                            value={tab}
                            onChange={this.handleChangeTermsSelect}
                        >
                            {termsList && termsList.noFilter ?
                                <option value='no-filter'>No Filter</option>
                                : null
                            }
                            {termsList && termsList.registration ?
                                <option value='registration'>Registration</option>
                                : null
                            }
                            {termsList && termsList.payments ?
                                <option value='payments'>Payments</option>
                                : null
                            }
                            {termsList && termsList.responsibility ?
                                <option value='responsibility'>Responsibility for Content</option>
                                : null
                            }
                            {termsList && termsList.ownership ?
                                <option value='ownership'>Ownership</option>
                                : null
                            }
                            {termsList && termsList.userConduct ?
                                <option value='user-conduct'>User Conduct</option>
                                : null
                            }
                            {termsList && termsList.disclaimer ?
                                <option value='disclaimer'>Disclaimer of Warranties</option>
                                : null
                            }
                            {termsList && termsList.limitation ?
                                <option value='limitation'>Limitation of Liability</option>
                                : null
                            }
                            {termsList && termsList.remedies ?
                                <option value='remedies'>Remedies</option>
                                : null
                            }
                            {termsList && termsList.generalProvisions ?
                                <option value='general-provisions'>General Provisions</option>
                                : null
                            }
                        </select>
                        <div className='faq-items terms-conditions'>
                            {termsList && termsList.noFilter ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('no-filter')}
                                    className={classNames({ 'active': tab && tab === 'no-filter' })}
                                >
                                    No Filter
                            </span>
                            </div> : null}
                            {termsList && termsList.registration ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('registration')}
                                    className={classNames({ 'active': tab && tab === 'registration' })}
                                >
                                    Registration
                            </span>
                            </div> : null}
                            {termsList && termsList.payments ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('payments')}
                                    className={classNames({ 'active': tab && tab === 'payments' })}
                                >
                                    Payments
                            </span>
                            </div> : null}
                            {termsList && termsList.responsibility ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('responsibility')}
                                    className={classNames({ 'active': tab && tab === 'responsibility' })}
                                >
                                    Responsibility for Content
                            </span>
                            </div> : null}
                            {termsList && termsList.ownership ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('ownership')}
                                    className={classNames({ 'active': tab && tab === 'ownership' })}
                                >
                                    Ownership
                            </span>
                            </div> : null}
                            {termsList && termsList.userConduct ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('user-conduct')}
                                    className={classNames({ 'active': tab && tab === 'user-conduct' })}
                                >
                                    User Conduct
                            </span>
                            </div> : null}
                            {termsList && termsList.disclaimer ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('disclaimer')}
                                    className={classNames({ 'active': tab && tab === 'disclaimer' })}
                                >
                                    Disclaimer of Warranties
                            </span>
                            </div> : null}
                            {termsList && termsList.limitation ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('limitation')}
                                    className={classNames({ 'active': tab && tab === 'limitation' })}
                                >
                                    Limitation of Liability
                            </span>
                            </div> : null}
                            {termsList && termsList.remedies ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('remedies')}
                                    className={classNames({ 'active': tab && tab === 'remedies' })}
                                >
                                    Remedies
                            </span>
                            </div> : null}
                            {termsList && termsList.generalProvisions ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangeTerms('general-provisions')}
                                    className={classNames({ 'active': tab && tab === 'general-provisions' })}
                                >
                                    General Provisions
                            </span>
                            </div> : null}
                        </div>
                        <div className='faq-content terms-conditions'>
                            {this.renderTerms()}
                        </div>
                    </div>}
            </div>
        )
    }
}


export default TermsConditions;