import React, { Component } from 'react';

export default class Copyright extends Component {
    render() {
        return (
            <div className='copyright'>
                <div className='copyright-item'>
                    ©2021 No Filter
                </div>
            </div>
        )
    }
}
