import React, { Component } from 'react';
import classNames from "classnames";
import ComponentHeader from '../Widget/ComponentHeader';
import path from '../../../resources/icons/path.svg';
import { privacy } from '../../utils/text';

class UserPolicies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: 'personal-information',
            input: '',
            privacyList: privacy
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangePoliciesSelect = this.handleChangePoliciesSelect.bind(this);
        this.handleChangePolicies = this.handleChangePolicies.bind(this);
    }

    componentDidMount() {
        this.setFocus();
    }

    componentWillUnmount() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleChangePoliciesSelect(e) {
        let value = e.target.value;
        this.setState({
            tab: value
        });
    }

    handleChangePolicies(value) {
        this.setState({
            tab: value
        }, () => {
            this.setFocus();
        });
    }

    setFocus() {
        let el = document.getElementsByClassName('button-focus')[0];

        if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            this.timeout = setTimeout(() => {
                el.focus();
            }, 500);
        }
    }

    handleChangeInput(e) {
        this.setState({
            input: e.target.value
        }, () => {
            const { input } = this.state;

            let inputValue = input.toLowerCase();

            if (inputValue && inputValue.length) {
                let newPrivacyList = {};
                newPrivacyList.personalInformation = this.searchArray(privacy.personalInformation, inputValue);
                newPrivacyList.useInformation = this.searchArray(privacy.useInformation, inputValue);
                newPrivacyList.shareInformation = this.searchArray(privacy.shareInformation, inputValue);
                newPrivacyList.choices = this.searchArray(privacy.choices, inputValue);
                newPrivacyList.otherSites = this.searchArray(privacy.otherSites, inputValue);
                newPrivacyList.security = this.searchArray(privacy.security, inputValue);
                newPrivacyList.international = this.searchArray(privacy.international, inputValue);
                newPrivacyList.children = this.searchArray(privacy.children, inputValue);
                newPrivacyList.changes = this.searchArray(privacy.changes, inputValue);
                newPrivacyList.contact = this.searchArray(privacy.contact, inputValue);
                this.setState({
                    privacyList: newPrivacyList
                });
            } else {
                this.setState({
                    privacyList: privacy
                });
            }
        });
    }

    searchArray(value, inputValue) {
        let exist = false
        if (value && value.props && value.props.children) {
            let children1 = value.props.children;
            if (children1 && Array.isArray(children1)) {
                children1.map((item) => {
                    let children2 = item && item.props && item.props.children ? item.props.children : null;
                    if (children2 && Array.isArray(children2)) {
                        children2.map((item) => {
                            let children3 = item && item.props && item.props.children ? item.props.children : null;
                            if (children3 && children3.toLowerCase && children3.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            } else if (children3 && children3.toLowerCase && children3.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            } else if (item && (typeof item === 'string' || item instanceof String) && item.toLowerCase && item.toLowerCase().indexOf(inputValue) !== -1) {
                                exist = true;
                            }
                        })
                    } else if (children2 && children2.toLowerCase && children2.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    } else if (item && (typeof item === 'string' || item instanceof String) && item.toLowerCase && item.toLowerCase().indexOf(inputValue) !== -1) {
                        exist = true;
                    }
                })
            } else if (children1.toLowerCase && children1.toLowerCase().indexOf(inputValue) !== -1) {
                exist = true;
            }
        }


        if (exist) {
            return value;
        } else return null;
    }

    renderPrivacy() {
        const { tab, privacyList } = this.state;

        switch (tab) {
            case 'personal-information': {
                return privacyList.personalInformation;
            }
            case 'use-information': {
                return privacyList.useInformation;
            }
            case 'share-information': {
                return privacyList.shareInformation;
            }
            case 'choices': {
                return privacyList.choices;
            }
            case 'other-sites': {
                return privacyList.otherSites;
            }
            case 'security': {
                return privacyList.security;
            }
            case 'international': {
                return privacyList.international;
            }
            case 'children': {
                return privacyList.children;
            }
            case 'changes': {
                return privacyList.changes;
            }
            case 'contact': {
                return privacyList.contact;
            }
            default: {
                return <div />;
            }
        }
    }

    render() {
        const { input, tab, privacyList } = this.state;

        return (
            <div>
                <ComponentHeader text="privacy policy" />
                <div className='faq-search-wrapper'>
                    <div className='faq-search'>
                        <img src={path} className='path' loading='lazy' />
                        <input
                            type="text"
                            className="search-input"
                            placeholder='What are you looking for ?'
                            value={input}
                            onChange={this.handleChangeInput}
                        />
                    </div>
                    <p className="last-update">Effective as of January 1st, 2021.</p>
                </div>
                <div className='faq-search-text-wrapper'>
                    <p className='faq-search-text'>This Privacy Policy (“<span>Privacy Policy</span>”) describes the privacy practices No Filter, Inc. and our subsidiaries and affiliates (collectively “<span>No Filter</span>,” “<span>we</span>,” “<span>us</span>” or “<span>our</span>”), and how we handle personal information that we collect through our digital properties that link to this Privacy Policy, including our websites and mobile applications (collectively, the “<span>Service</span>”), as well as through social media, our marketing activities, and other activities described in this Privacy Policy.</p>
                </div>
                {false ?
                    <div className='faq-wrapper'>
                        <p className='faq-search-message'>We found nothing</p>
                    </div>
                    :
                    <div className='faq-wrapper'>
                        <select
                            className="faq-select"
                            value={tab}
                            onChange={this.handleChangePoliciesSelect}
                        >
                            {privacyList && privacyList.personalInformation ?
                                <option value='personal-information'>Personal information we collect</option>
                                : null
                            }
                            {privacyList && privacyList.useInformation ?
                                <option value='use-information'>How we use your personal information</option>
                                : null
                            }
                            {privacyList && privacyList.shareInformation ?
                                <option value='share-information'>How we share your personal information</option>
                                : null
                            }
                            {privacyList && privacyList.choices ?
                                <option value='choices'>Your choices</option>
                                : null
                            }
                            {privacyList && privacyList.otherSites ?
                                <option value='other-sites'>Other sites and services</option>
                                : null
                            }
                            {privacyList && privacyList.security ?
                                <option value='security'>Security</option>
                                : null
                            }
                            {privacyList && privacyList.international ?
                                <option value='international'>International data transfer</option>
                                : null
                            }
                            {privacyList && privacyList.children ?
                                <option value='children'>Children</option>
                                : null
                            }
                            {privacyList && privacyList.changes ?
                                <option value='changes'>Changes to this Privacy Policy</option>
                                : null
                            }
                            {privacyList && privacyList.contact ?
                                <option value='contact'>How to contact us</option>
                                : null
                            }
                        </select>
                        <div className='faq-items user-policies'>
                            {privacyList && privacyList.personalInformation ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('personal-information')}
                                    className={classNames({ 'active': tab && tab === 'personal-information' })}
                                >
                                    Personal information we collect
                                </span>
                            </div> : null}
                            {privacyList && privacyList.useInformation ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('use-information')}
                                    className={classNames({ 'active': tab && tab === 'use-information' })}
                                >
                                    How we use your personal information
                                </span>
                            </div> : null}
                            {privacyList && privacyList.shareInformation ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('share-information')}
                                    className={classNames({ 'active': tab && tab === 'share-information' })}
                                >
                                    How we share your personal information
                                </span>
                            </div> : null}
                            {privacyList && privacyList.choices ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('choices')}
                                    className={classNames({ 'active': tab && tab === 'choices' })}
                                >
                                    Your choices
                                </span>
                            </div> : null}
                            {privacyList && privacyList.otherSites ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('other-sites')}
                                    className={classNames({ 'active': tab && tab === 'other-sites' })}
                                >
                                    Other sites and services
                                </span>
                            </div> : null}
                            {privacyList && privacyList.security ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('security')}
                                    className={classNames({ 'active': tab && tab === 'security' })}
                                >
                                    Security
                                </span>
                            </div> : null}
                            {privacyList && privacyList.international ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('international')}
                                    className={classNames({ 'active': tab && tab === 'international' })}
                                >
                                    International data transfer
                                </span>
                            </div> : null}
                            {privacyList && privacyList.children ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('children')}
                                    className={classNames({ 'active': tab && tab === 'children' })}
                                >
                                    Children
                                </span>
                            </div> : null}
                            {privacyList && privacyList.changes ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('changes')}
                                    className={classNames({ 'active': tab && tab === 'changes' })}
                                >
                                    Changes to this Privacy Policy
                                </span>
                            </div> : null}
                            {privacyList && privacyList.contact ? <div className='faq-item'>
                                <span
                                    onClick={() => this.handleChangePolicies('contact')}
                                    className={classNames({ 'active': tab && tab === 'contact' })}
                                >
                                    How to contact us
                                </span>
                            </div> : null}
                        </div>
                        <div className='faq-content user-policies'>
                            {this.renderPrivacy()}
                        </div>
                    </div>}
            </div>
        )
    }
}


export default UserPolicies;