import React, { Component } from "react";
import { connect } from 'react-redux';
import Cookies from "js-cookie";
import classNames from "classnames";
import { push as nativePush } from 'connected-react-router';
import * as firebaseActions from "../../actions/firebase_actions";
import * as broadcastActions from "../../actions/broadcast_actions";
import * as chatActions from "../../actions/chat_actions";
import VoxeetSdk from "@voxeet/voxeet-web-sdk";
import {
  ConferenceRoom,
  VoxeetProvider
} from "@voxeet/react-components";
import "@voxeet/react-components/dist/voxeet-react-components.css";
import fanoutClient from '../../utils/FanoutClient';
import { getAppStore } from '../../utils/Context';
import { Defines } from '../../utils/FanoutDefines';
import ActionsButtons from "./Voxeet/ActionsButtons";
import ResizableRoster from "./Voxeet/ResizableRoster";
import StreamingCounter from "./Voxeet/StreamingCounter";
import Loader from '../Widget/Loader';
import { dolbyStrings } from '../../utils/text';

VoxeetSdk.conference.fetch = null;

class StreamingVoxeet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      at: '',
      isError: false,
      errorCode: null,
      conferenceAlias: null
    }
    this.sendMessage = this.sendMessage.bind(this);
    this.handleChatMessage = this.handleChatMessage.bind(this);
    this.getCallState = this.getCallState.bind(this);
    this.handleTokenFetched = this.handleTokenFetched.bind(this);
    this.handleOnLeave = this.handleOnLeave.bind(this);
    this.handleOnConnect = this.handleOnConnect.bind(this);
    this.leaveAndCloseSession = this.leaveAndCloseSession.bind(this);
    this.detectPreConfig = this.detectPreConfig.bind(this);
    this.detectRetryButton = this.detectRetryButton.bind(this);
    this.connect = this.connect.bind(this);
    this.reconnectRoom = this.reconnectRoom.bind(this);
    fanoutClient.on('chatMessage', this.handleChatMessage);
    fanoutClient.on('tokenFetched', this.handleTokenFetched);
    fanoutClient.on('reconnect', this.reconnectRoom);
  }

  sendMessage(message) {
    const { username, name, uid } = this.props;

    if (message) {
      const chat = {
        title: Defines.Fanout.DataChannel.Chat,
        content: message,
        time: Date.now(),
        type: "text",
        name: username ? username : (name ? name : 'Unknown'),
        ownerId: uid ? uid : '',
        avatarUrl: null
      };
      fanoutClient.sendChatMessage(JSON.stringify(chat), this.getCallState());
    }
  }

  componentWillUnmount() {
    fanoutClient.removeListener('chatMessage', this.handleChatMessage);
    fanoutClient.removeListener('tokenFetched', this.handleTokenFetched);
    this.leaveAndCloseSession();

    const { hideMessage } = this.props;

    if (hideMessage)
      hideMessage();

    if (this.detectTimeout) {
      clearTimeout(this.detectTimeout);
    }
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
    if (this.pushTimeout) {
      clearTimeout(this.pushTimeout);
    }
  }

  leaveAndCloseSession() {
    if (VoxeetSdk) {
      try {
        if (VoxeetSdk.conference && VoxeetSdk.conference.leave) {
          return VoxeetSdk.conference.leave().then(() => {
            if (VoxeetSdk.session && VoxeetSdk.session.close && VoxeetSdk.session.participant) {
              Promise.resolve().then(() => {
                VoxeetSdk.session.close().catch(e => {
                  console.warn('Warning: Session', e);
                });
              })
            }
          }).catch(e => {
            console.warn('Warning: SDK leave', e);
          });
        }
      } catch (e) {
        console.error('ERROR:componentWillUnmount', e);
      }
    }
    return Promise.resolve();
  }

  componentDidMount() {
    const { eventId, eventItem, role, isOwner } = this.props;
    console.log('componentDidMount', this.props);

    if (eventId) {
      this.setState({
        conferenceAlias: eventId
      }, () => {
        this.connect();
      });
    }

    this.detectPreConfig();
    this.detectRetryButton();

    // if (eventItem && role) {
    //   fanoutClient.checkOverrunsTimeout(eventItem, role, isOwner);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { eventItem, role, isOwner } = this.props;

    // if (eventItem && role && ((eventItem !== prevProps.eventItem) || (role !== prevProps.role))) {
    //   fanoutClient.checkOverrunsTimeout(eventItem, role, isOwner);
    // }
  }

  connect() {
    fanoutClient.sendChatConnect(this.getCallState());
    fanoutClient.updateSignallingServer('chat-history', {}, this.getCallState());
    this.getToken();
  }

  detectPreConfig() {
    const joinElement = document.getElementsByClassName("start-conference")[0];

    if (!joinElement) {
      this.detectTimeout = setTimeout(this.detectPreConfig, 1000);
    } else {
      if (this.detectTimeout) {
        clearTimeout(this.detectTimeout);
      }

      joinElement.focus();

      const { handleJoined } = this.props;

      if (handleJoined) {
        handleJoined();
      }
    }
  }

  detectRetryButton() {
    const retryBtnElement = document.getElementsByClassName("retry-devices")[0];

    if (!retryBtnElement) {
      this.retryTimeout = setTimeout(this.detectRetryButton, 1000);
    } else {
      if (this.retryTimeout) {
        clearTimeout(this.retryTimeout);
      }

      retryBtnElement.onclick = (e) => {
        e.stopPropagation();
        Cookies.remove('input');
        Cookies.remove('output');
        window.location.reload();
      };

      const { handleJoined } = this.props;

      if (handleJoined) {
        handleJoined();
      }
    }
  }

  /**
   * Set token to component state if passed in props or request from WS
   */
  getToken() {
    if (this.props.token) {
      this.setState({
        at: this.props.token
      }, () => {
        console.log('Already had token');
      });
      return;
    }

    fanoutClient.getDolbyToken();
  }

  /**
   * Return Promise that resolves as token
   * @returns {Promise<unknown>}
   */
  refreshToken() {
    return fanoutClient.refreshDolbyToken();
  }

  /**
   * Handle 'tokenFetched' event: If fetched - set token to state or set error if not
   * @param data
   */
  handleTokenFetched(data) {
    if (data.error) {
      this.setState({
        isError: true,
        errorCode: data.error
      }, () => {
        console.error('getStreamToken error', data.message);
        this.pushTimeout = setTimeout(() => {
          const { pushToLink } = this.props;

          if (pushToLink) {
            pushToLink('/');
          }
        }, 5000);
      });
    } else {
      this.setState({
        at: data.token
      }, () => {
        console.log('getStreamToken success');
      });
    }
  }

  handleOnLeave() {
    console.log("VoxeetRoom handleOnLeave called");
    return this.leaveAndCloseSession().then(() => {
      // return fanoutClient.cleanTheRoom();
    })
  }

  handleOnConnect() {
    console.log("VoxeetRoom handleOnConnect called");
    const { handleJoined } = this.props;

    if (handleJoined) {
      handleJoined();
    }

    if (this.detectTimeout) {
      clearTimeout(this.detectTimeout);
    }
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
  }

  async reconnectRoom() {
    const { user } = this.props;

    console.log('--- About to reconnect ----------', user && user.email ? user.email : null)
    // await this.cleanupRoom();
    // setTimeout(async () => {
    await fanoutClient.reconnectAsMe(user && user.email ? user.email : null);
    fanoutClient.sendChatConnect(this.getCallState());
    fanoutClient.updateSignallingServer('chat-history', {}, this.getCallState());
    // }, 2000);
    console.log('--- Reconnected ----------')
  }

  handleChatMessage(data) {
    console.log('Got chat message', data);
    const { addMessage } = this.props;
    const dataObj = (data.payload);
    const { title } = dataObj;

    if (title && title === Defines.Fanout.DataChannel.Chat && addMessage) {
      console.log('Handling chat message');
      addMessage(dataObj);
    }

  }

  getCallState() {
    const { name, eventId, uid, username } = this.props;

    console.log('getCallState', username, eventId)

    if (eventId) {
      return {
        fanoutId: process.env.fanoutId,
        username: username ? username : name ? name : 'unknown',
        uid: uid,
        routerId: this.routerId,
        sessionId: this.sessionId,
        conferenceAlias: eventId,
      }
    } else {
      return null;
    }
  }

  renderError(errorCode) {

    let message = '', notification = false, showButton = false;

    switch (errorCode) {
      case Defines.Response.TooEarly:
        message = `It's still early to start the event, try again later.`;
        notification = true;
        break;
      default:
        message = 'Could not complete operation';
        showButton = true;
        break;
    }
    return (
      <div className='error-wrapper'>
        <div className='error-div'>
          <p className='error-title'>{notification ? "Too Early" : showButton ? "Network problem" : "Error"}</p>
          <p className={classNames('error-message', { 'notification': notification })}>{message}</p>
          {showButton ?
            <button
              className='btn-reload'
              onClick={() => window.location.reload()}
            >
              Reload
                    </button>
            : null
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      at,
      conferenceAlias,
      isError,
      errorCode
    } = this.state;
    const {
      username,
      name,
      userPhoto,
      eventItem,
      role,
      knockRevoked,
      handleExit,
      eventId,
      user,
      knockGranted
    } = this.props;

    const settings = {
      conferenceAlias: conferenceAlias,
      oauthToken: at
    };

    window.confStarterPhoto = userPhoto;

    let customExternalId = null

    let externalId = { 
      title: eventItem.title, 
      uid: `${(user && user.uid) + '-' + Math.floor(Math.random() * 50000 + 1)}`, 
      category: eventItem.cat, 
      poster: eventItem.poster 
    }

    try {
      customExternalId = JSON.stringify(externalId);
    } catch (e) {
      customExternalId = "Unknown"
      console.error('ERROR:stringify externalId', e);
    }

    let userInfo = {
      name: role && role !== 'moderator' ? username ? username : name ? name : 'Unknown' : 'Moderator',
      avatarUrl: role && role !== 'moderator' ? userPhoto : null,
      externalId: customExternalId
    };

    if(userInfo && JSON.stringify(userInfo) && JSON.stringify(userInfo).length && JSON.stringify(userInfo).length > 1219) {
      userInfo.avatarUrl = null;
      customExternalId = externalId;

      try {
        delete customExternalId.poster
        customExternalId = JSON.stringify(customExternalId);
      } catch (e) {
        customExternalId = "Unknown"
        console.error('ERROR:delete or stringify externalId', e);
      }

      userInfo.externalId = customExternalId;
    }

    var constraints = {
      audio: true,
      video: true
    };

    var videoRatio = {
      width: 1280,
      height: 720
    };

    let displayModes = ["tiles", "speaker"];

    if (VoxeetSdk.isElectron) {
      displayModes = ["tiles", "speaker", "list"];
    }

    VoxeetSdk.region('us');

    return (
      <div className='voxeet-wrapper'>
        {(isError) ?
          this.renderError(errorCode) :
          at ?
            <VoxeetProvider store={getAppStore()}>
              <ConferenceRoom
                autoJoin={true}
                userInfo={userInfo}
                preConfig={role && role !== 'audience' ? false : true}
                displayActions={
                  (role && ((role === 'presenter') || (role === 'moderator'))) ?
                    ["mute", "recording", "share", "video", "attendees", "chat", "knocks", "streaming"]
                    : ((role && role === 'guest_speaker') || knockGranted) ?
                      ["mute", "share", "video", "attendees", "chat"]
                      : ["mute", "video", "attendees", "chat"]
                }
                dolbyVoice={true}
                isListener={false}
                isDemo={false}
                liveRecordingEnabled={true}
                videoCodec={"VP8"}
                isManualKickAllowed={true}
                isAdmin={(role && ((role === 'presenter') || (role === 'moderator')))}
                kickOnHangUp={false}
                chromeExtensionId={"efdjhmbmjlhomjhnnmpeeillhpnldoje"}
                displayModes={displayModes}
                simulcast={false}
                videoRatio={videoRatio}
                disableSounds={true}
                handleOnLeave={this.handleOnLeave}
                handleOnConnect={this.handleOnConnect}
                isWidget={false}
                isElectron={VoxeetSdk.isElectron}
                constraints={constraints}
                oauthToken={settings.oauthToken}
                conferenceAlias={settings.conferenceAlias}
                actionsButtons={(props) => {
                  return <ActionsButtons
                    {...props}
                    conferenceAlias={conferenceAlias}
                    leave={() => {
                      console.log("VoxeetRoom leave called");
                      return this.leaveAndCloseSession().then(() => {
                        fanoutClient.sendEndCall(this.getCallState());
                        fanoutClient.sendChatDisconnect(this.getCallState());
                        if (handleExit) {
                          handleExit(true);
                        }
                        return fanoutClient.cleanTheRoom();
                      })
                    }}
                    isListener={false}
                    VoxeetSdk={VoxeetSdk}
                    eventItem={eventItem}
                    isBottomBar={true}
                    getCallState={this.getCallState}
                    knockRevoked={knockRevoked}
                    role={role}
                    eventId={eventId}
                  />
                }}
                refreshTokenCallback={this.refreshToken}
                attendeesList={(props) => {
                  return <ResizableRoster
                    {...props}
                    VoxeetSdk={VoxeetSdk}
                    user={user}
                    eventItem={eventItem}
                    role={role}
                    sendMessage={this.sendMessage}
                    getCallState={this.getCallState}
                  />
                }}
                customLocalizedStrings={dolbyStrings}
              />
            </VoxeetProvider>
            :
            <Loader
              blackBg={true}
              text="Connecting"
              dots
              type="page"
            />
        }
        <StreamingCounter
          role={role}
        />
      </div>
    );
  }
}

StreamingVoxeet.defaultProps = {
  conferenceName: "conference_name",
  userName: "Guest " + Math.floor(Math.random() * 100 + 1),
  // actionsButtons: <ActionsButtons />,
};

const mapStateToProps = (state) => {
  return {
    user: state.firebase.user,
    orientation: state.app.orientation,
    recording: state.broadcast.recording,
    activeModerator: state.broadcast.activeModerator,
    mobile: state.app.user_agent_info.platform.type === 'mobile',
    broadcastMessage: state.broadcast.broadcastMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideos: (videos) => {
      dispatch(firebaseActions.getVideos(videos));
    },
    clearAudienceList: () => {
      dispatch(firebaseActions.setAudienceList([]));
    },
    clearKnockList: () => {
      dispatch(firebaseActions.clearKnockList());
    },
    clearActiveParticipantsList: () => {
      dispatch(firebaseActions.clearActiveParticipantsList());
    },
    setDefaultSettings: () => {
      dispatch(broadcastActions.setDefaultSettings());
    },
    setMessage: (value) => {
      dispatch(broadcastActions.setBroadcastMessage(value));
    },
    addMessage: (data) => {
      dispatch(chatActions.addMessage(data));
    },
    pushToLink: (path) => {
      dispatch(nativePush(path));
    }
  };
};

const StreamingVoxeetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamingVoxeet);

export default StreamingVoxeetContainer;
