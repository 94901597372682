import React, { Component } from "react";
import { connect } from "react-redux";
import LogInMail from './LogInMail';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import appleIcon from '../../../resources/icons/nav/apple.svg';
import yahooIcon from '../../../resources/icons/nav/yahoo.svg';
import googleIcon from '../../../resources/icons/google_logo.svg';
import mailIcon from '../../../resources/icons/mail-white.svg';
import closeIcon from '../../../resources/icons/nav/x.svg';

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      pairing: false,
      error: null,
      showError: false,
      showPairing: false,
      loaderText: '',
      logInMail: false
    }
    this.setProvider = this.setProvider.bind(this);
    this.reset = this.reset.bind(this);
    this.startPairing = this.startPairing.bind(this);
    this.handleShowMailForm = this.handleShowMailForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleShowLogIn } = this.props;

    if (this.props.auth && prevProps.auth !== this.props.auth && handleShowLogIn) {
      handleShowLogIn();
    }
  }

  setProvider(provider) {
    if (provider) {
      this.setState({
        processing: true,
        showError: false,
        showPairing: false,
        loaderText: ''
      }, () => {
        firebaseClient.logIn(provider).catch(error => {
          if (error.code === 'auth/account-exists-with-different-credential') {
            this.setState({
              showPairing: true,
              showError: false,
              processing: false,
              error,
              loaderText: ''
            });
          } else if (error.code === 'auth/popup-closed-by-user') {
            this.setState({
              showPairing: false,
              showError: false,
              processing: true,
              error,
              loaderText: 'Login cancelled'
            }, () => {
              setTimeout(() => {
                this.reset();
              }, 2000);
            });
          } else {
            this.setState({
              showError: true,
              showPairing: false,
              processing: false,
              error,
              loaderText: ''
            });
          }
        });
      });
    }
  }

  startPairing() {
    if (this.state.error) {
      this.setState({
        processing: true,
        showError: false,
        showPairing: false,
        loaderText: ''
      }, () => {
        firebaseClient.pairAccounts(this.state.error).catch(error => {
          this.setState({
            showError: true,
            showPairing: false,
            processing: false,
            error,
            loaderText: ''
          });
        });
      });
    } else {
      console.error('Could not find pending credentials');
    }
  }

  handleShowMailForm() {
    this.setState({
      logInMail: true
    });
  }

  reset() {
    this.setState({
      showError: false,
      logInMail: false,
      showPairing: false,
      processing: false,
      error: null,
      loaderText: ''
    });
  }

  render() {
    const { processing, logInMail, showError, showPairing, loaderText } = this.state;
    const { handleShowLogIn, createAccount, logInSignup } = this.props;

    return (
      <div className="log-in-wrapper">
        {logInMail ?
          <LogInMail
            close={this.reset}
          />
          : showError ?
            <div className='log-in-div'>
              <div className="log-in-close">
                <img
                  src={closeIcon}
                  loading='lazy'
                  aria-hidden="true"
                  onClick={handleShowLogIn}
                />
              </div>
              <p className='log-in-title'>Error</p>
              <p className='log-in-text error'>Some error occurred</p>
              <div className="choose-buttons">
                <Button
                  type='border'
                  className='btn-choose'
                  onClick={this.reset}
                >
                  <p>Cancel</p>
                </Button>
              </div>
            </div> :
            processing ?
              <div className='log-in-div'>
                <Loader
                  text={loaderText ? loaderText : "Loading"}
                  login={true}
                  dots={!loaderText}
                />
              </div> :
              showPairing ?
                <div className='log-in-div'>
                  <div className="log-in-close">
                    <img
                      src={closeIcon}
                      loading='lazy'
                      aria-hidden="true"
                      onClick={handleShowLogIn}
                    />
                  </div>
                  <p className='log-in-title'>Pairing</p>
                  <p className='log-in-text'>
                    You have already signed up with a different auth provider for that email.
                    <br />
                    Would you like to pair accounts?
                  </p>
                  <div className="choose-buttons">
                    <Button
                      type='default'
                      className='btn-choose'
                      onClick={this.startPairing}
                    >
                      <p>Pair</p>
                    </Button>
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={this.reset}
                    >
                      <p>Cancel</p>
                    </Button>
                  </div>
                </div>
                :
                <div className="log-in-div">
                  <div className="log-in-close">
                    <img
                      src={closeIcon}
                      loading='lazy'
                      aria-hidden="true"
                      onClick={handleShowLogIn}
                    />
                  </div>
                  {createAccount || logInSignup ?
                    <p className="log-in-title createAccount">
                      {createAccount ?
                        "Verify Your Email to Create Account"
                        :
                        "Use Your Email to Create a No Filter Account"
                      }
                    </p>
                    :
                    <p className="log-in-title">
                      log in
                  </p>
                  }
                  <div className="choose-buttons">
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={() => this.setProvider("apple")}
                      disabled={processing}
                    >
                      <img
                        src={appleIcon}
                        loading='lazy'
                        className='apple-icon'
                      />
                      {createAccount || logInSignup ? 'create account with apple' : 'sign in with apple'}
                    </Button>
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={() => this.setProvider("google")}
                      disabled={processing}
                    >
                      <img
                        src={googleIcon}
                        loading='lazy'
                        className='google-icon'
                      />
                      {createAccount || logInSignup ? 'create account with google' : 'sign in with google'}
                    </Button>
                    {process.env.platform && process.env.platform !== 'mobile'
                      || !process.env.platform ?
                      <Button
                        type='border'
                        className='btn-choose'
                        onClick={() => this.setProvider("yahoo")}
                        disabled={processing}
                      >
                        <img
                          src={yahooIcon}
                          loading='lazy'
                          className='yahoo-icon'
                        />
                        {createAccount || logInSignup ? 'create account with yahoo' : 'sign in with yahoo'}
                      </Button>
                      : null
                    }
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={this.handleShowMailForm}
                      disabled={processing}
                    >
                      <img
                        src={mailIcon}
                        loading='lazy'
                        className='mail-icon'
                      />
                      {createAccount || logInSignup ? 'create account with mail' : 'sign in with mail'}
                    </Button>
                  </div>
                </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    createAccount: state.app.createAccount,
    logInSignup: state.app.logInSignup
  };
};

const LogInContainer = connect(
  mapStateToProps
)(LogIn);

export default LogInContainer;
