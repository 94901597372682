import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Cards from './Widget/Cards';
import Button from './Widget/Button';
import BecomeHost from './Advertisement/BecomeHost';
import { Editor } from "react-draft-wysiwyg";
import { Link } from 'react-router-dom';
import firebaseClient from './../utils/FirebaseClient';
import * as firebaseActions from "../actions/firebase_actions";
import * as mystuffActions from "../actions/mystuff_actions";
import * as appActions from "../actions/app_actions";
import Loader from './Widget/Loader';
import InquiryWrapperContainer from './Pages/InquiryWrapper';
import SubscripionPaymentWrapperContainer from './Payment/SubscriptionPaymentWrapper';
import { stringToBoolean, validLink } from '../utils/text';
import instagramIcon from "../../resources/icons/nav/instagram.png";
import twitterIcon from "../../resources/icons/nav/twitter.png";
import linkedInIcon from "../../resources/icons/nav/linkedIn.png";
import linkIcon from '../../resources/icons/link5.svg';

class Channel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channelDetails: null,
            follow: null,
            message: "",
            inquireForm: false,
            interests: [],
            displayPrivate: false,
            paymentWrapper: false,
            "price-month-*": undefined,
            "price-year-*": undefined,
            priceIndex: null,
            myTickets: [],
            followDisabled: false,
            ticketsLoaded: false,
            deleteAccount: false,
            deletingUser: false,
            successMessage: "",
            errorMessage: "",
            tooltipText: ""
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.channelTimeout = null;
        this.handleSubscriptionClick = this.handleSubscriptionClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                deleteAccount: false
            });
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);

        const { appStage } = this.props;

        if (appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }

        this.getChannelDetails();
        this.getUserProducts();
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('props', this.props);
        const { channelDetails } = this.state;
        const { match, channelList, user, appStage } = this.props;
        const id = match.params.id;
        const exid = (prevProps && prevProps.match) ? prevProps.match.params.id : null;

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }

        if (exid !== id) {
            this.getChannelDetails();
            this.getUserProducts();
            return;
        }

        if (this.props.user && prevProps.user !== this.props.user) {
            if (channelDetails && channelDetails.id && channelList) {
                let authorIds = channelList && channelList.length ? channelList.map((item) => {
                    return item.uid;
                }) : null;
                if (authorIds && authorIds.indexOf(channelDetails.id) !== -1) {
                    if (user && user.uid && user.uid !== channelDetails.id) {
                        this.setState({
                            follow: true
                        });
                    }
                } else if (user && user.uid && user.uid !== channelDetails.id) {
                    this.setState({
                        follow: false
                    });
                }
            }
            this.setState({
                followDisabled: false
            });
            this.getUserProducts();
        }
        if (this.state.channelDetails && prevState.channelDetails !== this.state.channelDetails) {
            if (channelDetails && channelDetails.id && channelList) {
                let authorIds = channelList && channelList.length ? channelList.map((item) => {
                    return item.uid;
                }) : null;
                if (authorIds && authorIds.indexOf(channelDetails.id) !== -1) {
                    if (user && user.uid && user.uid !== channelDetails.id) {
                        this.setState({
                            follow: true
                        });
                    }
                } else if (user && user.uid && user.uid !== channelDetails.id) {
                    this.setState({
                        follow: false
                    });
                }
            }
            this.setState({
                followDisabled: false
            });
        }
        if (channelList && prevProps.channelList !== channelList) {
            if (channelDetails && channelDetails.id && channelList) {
                let authorIds = channelList && channelList.length ? channelList.map((item) => {
                    return item.uid;
                }) : null;
                if (authorIds && authorIds.indexOf(channelDetails.id) !== -1) {
                    if (user && user.uid && user.uid !== channelDetails.id) {
                        this.setState({
                            follow: true
                        });
                    }
                } else if (user && user.uid && user.uid !== channelDetails.id) {
                    this.setState({
                        follow: false
                    });
                }
            }
            this.setState({
                followDisabled: false
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);

        if (this.channelTimeout) {
            clearTimeout(this.channelTimeout);
            this.channelTimeout = null;
        }

        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
    }

    handleAddMyStuff(item) {
        const { follow } = this.state;

        if (item.id && !follow && item) {
            this.setState({
                followDisabled: true
            });
            let name = item.first_name && item.last_name ? `${item.first_name} ${item.last_name}` : item.username ? item.username : '';
            let imgUrl = item && item.profile_picture2 ? item.profile_picture2 : item && item.profile_picture ? item.profile_picture : null;
            this.follow(item.id, name, imgUrl);
        } else if (item.id && follow) {

            this.setState({
                followDisabled: true
            });
            this.unfollow(item.id);
        }

        // console.log('Add MyStuff', item.id);
    }

    follow(uid, name, pic) {
        firebaseClient.follow(uid, name, pic);
    }

    unfollow(uid) {
        firebaseClient.unfollow(uid);
    }

    getChannelDetails() {
        const { match, firebaseInit, user, channelList } = this.props;
        const id = match.params.id;

        if (firebaseInit && id) {
            firebaseClient.getOne('users', id).then((res) => {
                if (res.data) {

                    let displayPrivate = false;

                    if (res.data.interests) {
                        res.data.interests.split("/n").forEach(element => {
                            if (element !== "") {
                                displayPrivate = true;
                            }
                        });
                    }

                    this.setState({
                        channelDetails: res.data,
                        displayPrivate: displayPrivate,
                    }, () => {
                        if (res.data && res.data.id && channelList) {
                            let authorIds = channelList && channelList.length ? channelList.map((item) => {
                                return item.uid;
                            }) : null;
                            if (authorIds && authorIds.indexOf(res.data.id) !== -1) {
                                if (user && user.uid && user.uid !== res.data.id) {
                                    this.setState({
                                        follow: true
                                    });
                                }
                            } else if (user && user.uid && user.uid !== res.data.id) {
                                this.setState({
                                    follow: false
                                });
                            }
                        }
                    })
                }
            });
        } else {
            this.channelTimeout = setTimeout(() => {
                this.getChannelDetails(id)
            }, 500);
        }
    }

    getUserProducts() {
        // Fetch user products

        const { match, user } = this.props;


        const id = match.params.id;


        if (user) {
            firebaseClient.getUserProductsById(id).then(products => {
                console.log('User products', products);
                let update = {
                    products: products
                };
                if (products) {
                    products.forEach(product => {
                        let { prices } = product;
                        if (prices) {
                            prices.forEach(price => {
                                update[`price-${price.interval}-${product.show}`] = parseFloat(price.value);
                            });
                        }
                    });
                }
                this.setState(update, () => {
                    // console.log('Prices updated', this.state);
                });
            })
        }

    }

    handleCopy(link, ref) {
        if (link) {
            let onCopyOk = () => {
                console.log('Copy link success', link, ref);
                if (ref) {
                    this.setState({
                        tooltipText: "Copied!"
                    }, () => {
                        ReactTooltip.show(ref);
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }
            let onCopyNOk = (e) => {
                console.error(`Could not write link [${link}] to clipboard: ${e.message}`)
                if (ref) {
                    this.setState({
                        tooltipText: "Could not copy"
                    }, () => {
                        ReactTooltip.show(ref);
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }

            navigator.clipboard.writeText(link)
                .then(onCopyOk)
                .catch(onCopyNOk);
        }
    }

    handleInquireClick() {

        this.setState({ inquireForm: true });

    }

    handleSubscriptionClick(index) {
        this.setState({ paymentWrapper: true, priceIndex: index });
    }

    handleCancelSubscriptionClick(index) {
        const { match } = this.props;
        const authorId = match.params.id;
        const interval = this.state.products[0].prices[index].interval;

        firebaseClient.cancelUserSubscription(authorId, interval).then(res => {
            // console.log("Subscription cancelled");
        })

    }

    handleResetDefaultValue() {
        this.setState({ inquireForm: false, paymentWrapper: false })
    }

    handleDeleteUser(user) {
        if (user && user.uid) {
            this.setState({ deletingUser: true });
            firebaseClient.sendSupportMail(user.uid, 'delete-account').then(() => {
                this.setState({
                    deletingUser: false,
                    deleteAccount: false,
                    errorMessage: "",
                    successMessage: user.broadcaster ?
                        "Thank you for your unsubscribe request. It has been sent to our Customer Support team, who will reach out to you to discuss and agree a plan to delete your account and content."
                        : "Thank you for your unsubscribe request. It has been sent to our Customer Support team, who can delete your account and information."
                }, () => {
                    this.messageTimeout = setTimeout(() => {
                        this.setState({
                            successMessage: "",
                            errorMessage: ""
                        });
                    }, 10000);
                });
            }).catch((e) => {
                this.setState({
                    deletingUser: false,
                    deleteAccount: false,
                    successMessage: "",
                    errorMessage: "Error deleting user"
                }, () => {
                    this.messageTimeout = setTimeout(() => {
                        this.setState({
                            successMessage: "",
                            errorMessage: ""
                        });
                    }, 10000);
                });
            });
        }
    }

    render() {
        const { match } = this.props;
        const uid = match.params.id;
        const { channelDetails, follow, followDisabled, inquireForm, paymentWrapper, products, deleteAccount, deletingUser, successMessage, errorMessage, tooltipText } = this.state;
        const { user, upcomingEvents, videos, hostSubscriptions, tickets, auth, roles } = this.props;
        let privateAvailable = false;
        if (channelDetails && channelDetails.interests && channelDetails.interests.split) {
            channelDetails.interests.split("/n").forEach(element => {
                if (element.replace(/\s+/g, '') !== "")
                    privateAvailable = true;
            })
        }

        let uevents = upcomingEvents ? upcomingEvents.filter((item) => {
            if (item && !firebaseClient.checkHidenIn(item, 'upcoming') && (item.uid === uid || firebaseClient.checkCoHost(item, uid))) {
                return item;
            }
        }) : null;

        let uvideos = videos && videos.filter ? videos.filter((item) => {
            if (item && !firebaseClient.checkHidenIn(item, 'vault') && (item.uid === uid || firebaseClient.checkCoHost(item, uid)) && item.archiveEnabled) {
                return item;
            }
        }) : null;

        let userUid = match && match.params && match.params.id ? match.params.id : '';

        let avatar = channelDetails ?
            (channelDetails.profile_picture2 ?
                channelDetails.profile_picture2 :
                (channelDetails.profile_picture ? channelDetails.profile_picture : null)) :
            null;

        return (
            <div>
                {paymentWrapper && user && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                    <SubscripionPaymentWrapperContainer
                        subscriptionType="subscription"
                        subscriptionPlan={this.state.products[0].prices[this.state.priceIndex].interval}
                        productId={this.state.products[0].id}
                        resetValue={() => this.handleResetDefaultValue()}
                        successFunction={() => window.location.reload()}
                        item={channelDetails}
                        showPrice={this.state["price-month-*"] ? this.state["price-month-*"] : 0}
                        title={`Confirm Your Subscription to ${channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}`}
                    />
                    : channelDetails ?
                        <div className='profile-div-wrapper'>
                            <div className='profile-div-details'>
                                <div className={classNames('profile-left', { 'no-bio': !channelDetails.biography })}>
                                    <img src={avatar} className='profile-img' loading='lazy' />
                                    <div className='profile-username profile-p'>
                                        <p>{channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}</p>
                                        <div className='profile-buttons'>
                                            {userUid && user && user.uid && user.uid !== userUid && products && products.length && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) && (!process.env.platform || process.env.platform && process.env.platform !== 'mobile') ?
                                                products.map(product => {
                                                    return product.prices.map((price, index) => {
                                                        if (price && price.interval && price.interval === 'month' && Object.values(tickets).filter(ticket => ticket.authorId === uid && ticket.status === 'processing').length > 0) {
                                                            return (
                                                                <Button type="default" key={index} className='btn-subscribe' disabled={true}>Processing Payment</Button>
                                                            )
                                                        }
                                                        else if (price && price.interval && price.interval === 'month' && (!hostSubscriptions || hostSubscriptions && hostSubscriptions.filter(host => host.authorId === channelDetails.id).length === 0)) {
                                                            return (
                                                                <Button type="default" key={index} className='btn-subscribe' onClick={() => this.handleSubscriptionClick(index)}>Subscribe {`${price.currency && price.currency === 'usd' ? '$' : ''}${price.value ? price.value : 0}`}</Button>
                                                                // <Button type="default" key={index} className='btn-subscribe' onClick={() => this.handleSubscriptionClick(index)}>Subscribe {`${price.currency && price.currency === 'usd' ? '$' : ''}${price.value ? price.value : 0} per ${price.interval ? price.interval : ''}`}</Button>
                                                            )
                                                        } else if (price && price.interval && price.interval === 'month') {
                                                            return (
                                                                <Button type="default" key={index} className='btn-subscribe' disabled={true}>Subscribed</Button>
                                                            )
                                                        }
                                                    })
                                                })
                                                : null}
                                            {userUid && user && user.uid && user.broadcaster && user.uid === userUid ?
                                                <Link to='/edit-profile'>
                                                    <Button type='default' className='btn-edit-profile'>
                                                        Personal Settings
                                                </Button>
                                                </Link>
                                                : follow !== null ? <Button type='default' disabled={followDisabled} onClick={() => this.handleAddMyStuff(channelDetails)} className='btn-edit-profile'>{follow ? 'unfollow' : 'follow'}</Button>
                                                    : null
                                            }
                                            {userUid && user && user.uid && user.uid === userUid ?
                                                <Button
                                                    type='default'
                                                    className='btn-edit-profile'
                                                    onClick={() => this.setState({ deleteAccount: true })}
                                                    disabled={deletingUser}
                                                >
                                                    Delete My Account
                                                </Button>
                                                : null
                                            }
                                            {channelDetails.twitter && validLink(channelDetails.twitter) ?
                                                <a href={channelDetails.twitter} target="_blank">
                                                    <Button type='text' className='btn-social-icon'>
                                                        <img title='Twitter' src={twitterIcon} />
                                                    </Button>
                                                </a>
                                                : null
                                            }
                                            {channelDetails.instagram && validLink(channelDetails.instagram) ?
                                                <a href={channelDetails.instagram} target="_blank">
                                                    <Button type='text' className='btn-social-icon'>
                                                        <img title='Instagram' src={instagramIcon} />
                                                    </Button>
                                                </a>
                                                : null
                                            }
                                            {channelDetails.linkedIn && validLink(channelDetails.linkedIn) ?
                                                <a href={channelDetails.linkedIn} target="_blank">
                                                    <Button type='text' className='btn-social-icon'>
                                                        <img title='LinkedIn' src={linkedInIcon} />
                                                    </Button>
                                                </a>
                                                : null
                                            }
                                            {channelDetails.broadcaster && channelDetails.sharedLink && validLink(channelDetails.sharedLink) ?
                                                <span>
                                                    <Button type='text' className='btn-social-icon' onClick={() => this.handleCopy(channelDetails.sharedLink, this.firstTooltip)}>
                                                        <img
                                                            title='Copy link'
                                                            id="img-item"
                                                            src={linkIcon}
                                                            data-for="host-copy"
                                                            data-tip
                                                            ref={ref => this.firstTooltip = ref}
                                                        />
                                                    </Button>
                                                    <ReactTooltip
                                                        id="host-copy"
                                                        isCapture={true}
                                                        className="host-copy-tooltip"
                                                        place="top"
                                                        type="dark"
                                                        effect="solid"
                                                        event='mousedown'
                                                        eventOff='mouseleave'
                                                    >
                                                        {tooltipText}
                                                    </ReactTooltip>
                                                </span>
                                                : null
                                            }
                                        </div>
                                        {userUid && user && user.uid && user.uid !== userUid && products && products.length && (!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) ?
                                            products.map(product => {
                                                return product.prices.map((price, index) => {
                                                    if (price && price.interval && price.interval === 'month' && (!hostSubscriptions || hostSubscriptions && hostSubscriptions.filter(host => host.authorId === channelDetails.id).length === 0)) {
                                                        return (
                                                            <p key={index} className='text-p'>{`Subscribe monthly to get access to all of ${channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}'s content on No Filter.`}</p>
                                                        )
                                                    }
                                                })
                                            })
                                            : null}
                                        {userUid && user && user.uid && user.uid !== userUid && follow === false ?
                                            <p className='text-p'>Follow to have their streams automatically appear in your 'MyStuff' Page.</p>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className='profile-right profile-bio'>
                                    {channelDetails.biography ?
                                        <div className='bio-text'>
                                            {channelDetails.biography && typeof channelDetails.biography !== 'string' ?
                                                (
                                                    <Editor
                                                        toolbarHidden
                                                        readOnly
                                                        initialContentState={channelDetails.biography}
                                                    />
                                                )
                                                : channelDetails.biography}
                                        </div> : null
                                    }
                                </div>
                            </div>
                            <Cards
                                title='Upcoming streams'
                                videos={uevents}
                                readMoreLink={`/browse?b=upcoming-streams&u=${channelDetails.id}&a=${channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}`}
                            />
                            {privateAvailable && <div className='prof-inquire-wrapper'>
                                <div className='prof-inquire-div'>
                                    <p className='prof-inquire-title'>
                                        book {channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}<br />
                                        for private event
                                </p>
                                    <Button
                                        type='default'
                                        className="btn-inquire"
                                        onClick={this.handleInquireClick.bind(this)}>
                                        inquire
                                </Button>
                                </div>
                                <div className='prof-inquire-items-div'>
                                    {channelDetails && channelDetails.interests && channelDetails.interests.split("/n").map((interest, index) => {
                                        if (interest && interest.trim()) {
                                            return (
                                                <p key={index} className="prof-inquire-item-p">{interest}</p>
                                            )
                                        }
                                    })}
                                </div>
                            </div>}
                            <Cards
                                title={`${channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}'s Vault`}
                                videos={uvideos}
                                readMoreLink={`/browse?b=all&u=${channelDetails.id}&a=${channelDetails.username ? channelDetails.username : `${channelDetails.first_name} ${channelDetails.last_name}`}`}
                            />
                            {(!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                                <BecomeHost />
                                : null
                            }
                            {/* <Cards
                            title='hosts'
                            videos={ambassadors}
                            hosts={true}
                            readMoreLink='/hosts'
                        /> */}
                        </div>
                        :
                        <Loader 
                            type="page" 
                            text="Loading"
                            dots
                        />
                }
                {inquireForm &&
                    <InquiryWrapperContainer
                        closeInquiryWrapper={() => this.handleResetDefaultValue()}
                        broadcaster_name={channelDetails.first_name + " " + channelDetails.last_name}
                        broadcaster_username={channelDetails.username}
                        broadcaster_email={channelDetails.email}
                    />}
                {deleteAccount ?
                    <div className='vanity-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='vanity-modal-div'>
                            <div className='vanity-modal-title'>Delete My Account</div>
                            <div className='vanity-modal-text'>Are you sure you want to delete your No Filter account and all your content?</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={deletingUser}
                                    onClick={() => this.handleDeleteUser(user)}
                                    loading={deletingUser}
                                >
                                    Continue
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        deleteAccount: false
                                    })}
                                    disabled={deletingUser}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {successMessage || errorMessage ?
                    <div className='vanity-modal-wrapper'>
                        <div ref={this.setWrapperRef} className='vanity-modal-div'>
                            <div className={classNames('vanity-modal-title', { 'error-color': errorMessage })}>{errorMessage ? 'Error' : 'Success'}</div>
                            <div className='vanity-modal-text'>{errorMessage ? errorMessage : successMessage}</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        successMessage: '',
                                        errorMessage: ''
                                    })}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        videos: state.firebase.videos,
        user: state.firebase.user,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        upcomingEvents: state.firebase.events.upcoming,
        firebaseInit: state.firebase.init,
        channelList: state.firebase.channelList,
        hostSubscriptions: state.mystuff.hostSubscriptions,
        tickets: state.mystuff.tickets,
        appStage: state.app.stage,
        ambassadors: state.firebase.ambassadors
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllVideos: (list) => {
            dispatch(firebaseActions.getVideos(list));
        },
        setMyTickets: (tickets) => {
            dispatch(mystuffActions.setMyTickets(tickets));
        }
    };
};

const ChannelContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Channel);

export default ChannelContainer;
